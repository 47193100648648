<template>
    <div >
      <common-header></common-header>   
      
      <div class="background">
        <img :src="imgSrc" width="100%" height="100%" alt/>
        <div class="content">
            <div class="leftCon">
                <div class="leftBj">
                    <div class="flex alignCenter biaoqq">
                        <img style="width:46px;height:46px;margin-right: 8px;border-radius: 50%;" src="../assets/images/logobqq1.png" />
                        <span>标圈圈</span>
                    </div>
                    <div style="margin:10px 0 0 20px">
                        <div class="flex alignCenter ltext">
                        <div class="cicle"></div>
                        <div>一站式标讯服务平台</div>
                        </div>
                        <div class="flex alignCenter ltext">
                            <div class="cicle"></div>
                            <div>精准查询招标采购信息</div>
                        </div>
                        <div class="flex alignCenter ltext">
                            <div class="cicle"></div>
                            <div>全流程招标信息服务</div>
                        </div>
                        <div class="flex alignCenter ltext">
                            <div class="cicle"></div>
                            <div>全网招采情报分析</div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="rightCon">
                <div class="rightBj">
                    <div>
                        <el-tabs v-model="activeName" @tab-click="handleClick">
                           <!-- <el-tab-pane label="密码登录" name="one"> -->
                                <div class="register">密码登录</div>
                                <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                 
                                    <el-form-item label="手机号" prop="user">
                                        <el-input placeholder="请输入手机号" v-model.number="ruleForm.user" maxlength="11"></el-input>
                                    </el-form-item>
                               
                                   <el-form-item label="密码" prop="pass">
                                        <el-input type="password" placeholder="请输入密码" v-model="ruleForm.pass" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="" prop="" style="margin-bottom:15px">
                                        <div class="flex jVerify">
                                        <slide-verify  @success="onSuccess" 
                                        @fail="onFail" @refresh="onRefresh" slider-text="向右滑动" :imgs="imgs" 
                                        :accuracy="accuracy"></slide-verify>
                                    </div>
                                    </el-form-item>

                                     <!-- @click="submitForm('ruleForm')"   @keyup.enter.native="submitForm('ruleForm')"-->
                                    <el-form-item style="margin-bottom: 10px !important;">
                                        <el-button type="primary" @click="submitForm('ruleForm')" >提交</el-button>
                                    </el-form-item>
                                   
                                    <div class="flex jutSpace btnw">
                                        <div>
                                            <router-link to="/forgetPwd">忘记密码</router-link> 
                                        </div>
                                        <div >
                                            <router-link to="/register" style="color: #0078FF;margin-right: 10px;">立即注册</router-link>
                                        </div>
                                    </div>
                                  
                                    
                                </el-form>
                             <!-- </el-tab-pane>  -->
                            <!-- <el-tab-pane label="验证码登录" name="two">验证码登录</el-tab-pane> -->
                        </el-tabs>
                    </div>
                </div>
            </div>

        </div>
    </div>
     
    </div>
</template>
<script>
    import img0 from '../assets/images/img.jpg';
    import img1 from '../assets/images/img1.jpg';
    import img2 from '../assets/images/img2.jpg';
    import img3 from '../assets/images/img3.jpg';
    import img4 from '../assets/images/img4.jpg';
    import img5 from '../assets/images/img5.jpg';
 import CommonHeader from '@/components/CommonHeader.vue';
 export default { 
    components:{
       CommonHeader
       }, 
        data() {
      let checkAge = (rule, value, callback) => {
          var reg = /^1[3-9]\d{9}$/;
          if (value === "") {
            return callback(new Error("请输入手机号码"));
          } else if (!Number.isInteger(value)) {
            return callback(new Error("手机号码必须是数字"));
          } else if (value.toString().length !== 11) {
            return callback(new Error("手机号码必须是11位数字"));
          } else if (!reg.test(value)) {
            return callback(new Error("请输入有效的手机号码"));
          } else {
            callback();
          }
        };

      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass');
          }
          callback();
        }
      };
            return {
                imgSrc: require("../assets/images/login_bj.png"),
                activeName: 'one',

                ruleForm: {
                pass: '',
                user: ''
                },
                rules: {
                pass: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                user: [
                    { validator: checkAge, trigger: 'blur' }
                ]
                },
                yzMsg:'',

                //图片验证
                text: '向右滑动->',
                imgs: [
                  img0,
                  img1,
                  img2,
                  img3,
                  img4,
                  img5,
                ],
                accuracy: 5, 
                isFlag:''
            };
        },
        created() {
            this.isWidth="min-width: 100%;"
           
        },
        mounted() {
            this.isWidth="min-width: 100%;"
   
           // console.log(this.$route.query.isFlagA)
            if(this.$route.query.isFlagA!=undefined&&this.$route.query.isFlagA!=''){
                this.isFlag=this.$route.query.isFlagA
            }
           
        },
        updated() {
            this.isWidth="min-width: 100%;" 
        },
        
        methods: {
            handleClick(tab, event) {
                console.log(tab, event);
            },
            onSuccess(){
                this.yzMsg = '1'
            },
            onFail(){
                this.yzMsg = '2'
            },
            onRefresh(){
                this.yzMsg = ''
            },

            submitForm(formName) {
                var that=this
               // console.log(that.yzMsg)
                if(that.yzMsg!=1){
                    that.$message.error('验证失败，请刷新重试')
                }else{
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    //console.log(this.ruleForm)  //输入的值
                    that.$axios.post('/j_spring_security_check?j_username='+that.ruleForm.user+'&j_password='+that.ruleForm.pass
                    +'&ajaxTag=ajaxTag'+'&phoneToken=phoneToken'+'&wxType=5'+'&deviceMac=1'+'&deviceImei=1'+'&deviceName=1')
                    .then(function (res) {
                     console.log(res);
                      if(res.data.success == "true" && res.data.userInfo!=null){
                        // 提示成功信息
                        that.$message({
                            message: '登录成功',
                            type: 'success'
                            }); 
                            
                        
                        localStorage.setItem("phoneNo", JSON.stringify(res.data.userInfo.phoneNo));
                        that.$store.state.phoneNo = res.data.userInfo.phoneNo
                         
                         if(that.isFlag==1){
                             window.history.back()
                         }else{
                            setTimeout(()=>{  //设置延迟执行
                                that.$router.push({path: '/'});
                            },600);
                        }
                    
                      }else{
                        if(res.data.ecode == "00010013"){
                         that.$message.error('当前用户不存在')
                       }else if(res.data.ecode == "00010004"){
                         that.$message.error('该手机号未注册')
					   }else{
                         that.$message.error(res.data.errorInfo)
                       }
                    
                     }
                    })
                    .catch(function (err) {
                        console.log(err);
                        that.$message.error('登录失败，请重试')
                    })
                   
                } else {
                    console.log('error submit!!');
                    return false;
                }
                });
               } 
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },

        }
    }
</script>
<style scoped lang="less">

.background {
    width: 100%;
    height: 100%;
    z-index: -1;
    position: fixed;
    top: 74px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    min-height: 980px;
    overflow: hidden;
    }
 .content{
    display: flex;
    position: absolute;
    left: 60%;
    top: 55%;
    transform: translate(-50%,-50%);
    width: 90%;
    height: 95%;
 }
 .leftCon{
    background: url('../assets/images/login-left.png');
    width: 35%;
    height: 60%;
    background-size: 100% 100%;
 }
 .rightCon{
    background: url('../assets/images/login_right.png');
    width: 37.5%;
    height: 60%;
    background-size: 100% 100%;
    position: relative;
 }
 .cicle{
    width: 8px;
    height: 8px;
    background: #F5F7FA;
    opacity: 0.4;
    margin-right: 20px;
 }
 .ltext{
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 44px;
 }
 .leftBj{
    margin: 40px;
    .biaoqq{
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 44px;
    }
 }
 .rightBj{
    position: absolute;
    width: 404px;
    top: 50%;
    left: 48%;
    transform: translate(-50%, -50%);
 }
 /deep/.el-tabs__item{
    font-size: 24px;
    line-height: 20px;
 }
 /deep/.el-tabs__header{
    margin:0 I !important;
 }
 /deep/.el-form-item{
    margin-bottom: 20px;
 }
 /deep/ .el-form-item__label{
          width: 85px !important;
          font-size: 17px;
        }
 /deep/ .el-form-item__content{
            margin-left: 90px !important;
            line-height: 48px !important;
        }
/deep/.el-input__inner{
    font-size: 17px;
    height: 45px;
    line-height: 45px;
}
    .btn{
        text-align: center;
       padding: 0 10px;
    }
    .btns{
        width: 100%;
        height: 55px;
        background: #0078FF;
        border-radius: 30px;
        font-size: 25px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 55px;
        margin-top: 50px;
        cursor: pointer;
    }
    .btnw{
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #B9C3D1;
        cursor: pointer;
    }
    a{
        color: #B9C3D1;
    }
    /deep/.el-button{
         width: 100%;
        font-size: 16px;
    letter-spacing: 3px;
    }
    /deep/.jVerify{
        #slideVerify{
        //     width:100% !important;
        //     canvas{
        //         width:100% !important;
        //         height: 155px;
        //    }
        //    .slide-verify-block{
        //     width:65px !important;
        //    }
            .slide-verify-slider{
                margin-top: -8px;
                //width: 99% !important;
            }
        }
    }
  
    .register{
        color:#409EFF;
        font-size:24px;
        margin-bottom: 25px;
    }
    
    
    .container-active .slide-verify-slider-mask-item{
        height: 35px;
    }
</style>