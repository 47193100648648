<template>
    <div>
        <common-header @change="amountChange($event)" :message="beiji"></common-header>   
        <div class="halfC">
            <h2 class="title">{{ detailCon.title }}</h2>
            <div class="time">{{ detailCon.createTime }}</div>
            <div v-html="detailCon.content"></div>
            <div class="imgs" v-for="(item,index) in detailCon.imgList" :key="index">
                <img :src="item.imgLocalDir" :alt="item.imgName" />
            </div>
        </div>

        <common-foot></common-foot>
        <common-active :showTk="beiji" :showActivity="flagActivity" @change="amountChanges($event)"></common-active>
    </div>
</template>

<script>
   import CommonHeader from '@/components/CommonHeader.vue';
   import CommonFoot from '@/components/CommonFoot.vue';
   import CommonActive from '@/components/CommonActive.vue';
    export default {
      components:{
       CommonHeader,
       CommonFoot,
       CommonActive
       }, 
        data() {
            return {
                noticeId:'',
                detailCon:'',
                beiji:false,
                flagActivity:'',
        };
        },
        created(){
            this.noticeId = this.$route.query.noticeId
        },
        mounted() {
            this.noticeDetails();
        },


        methods: {
            amountChange(e){
              this.beiji=e
            },
            amountChanges(e){
              this.beiji=e
              this.flagActivity='1'
            },
            noticeDetails(){
               var that=this 
                that.$axios.post('/webInterface/getPublishMessage?productMessageId='+that.noticeId).then(res => {
                  console.log(res)
                  that.detailCon=res.data.publishProductMessage  
                }).catch(err => {
                    console.log('请求失败')
                })
            }

          },
         
    }
</script>
<style scoped lang="less">
  .halfC{
  width: 1140px;
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  border-radius: 5px;
  margin: 15px auto;
  padding: 30px 30px;
}
.title{

}
.time{
    font-size: 15px;
    color: #999;
    text-align: right;
}
</style>