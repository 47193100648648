<template>
    <div>
        <common-header @change="amountChange($event)" :message="beiji"></common-header>   
         <div class="conAll">
            <div class="halfC" style="margin-top:15px">
                <div class="sVDY6WmQ">
                    <div class="VyEwnPk">
                        <img :src="companyList.logo" />
                    </div>
                    <div class="eHWU1UFm">
                        <div class="ovaWkJTW C7-lDgbT">
                            <div class="title">{{companyList.name}}</div>
                            <div class="KLJC8c9N">
                                <div class="flex alignCenter jutCenter xgjIcon">           
                                    <div class="flex alignCenter iconHalf" v-for="(item,index) in iconList" :key="index" @click="smNavgite(item.id,index)">
                                        <img class="iconImg" :src="item.src" />
                                        <el-button v-if="item.id==1" :plain="true">{{item.text}}</el-button>
                                        <el-button v-else :plain="true">{{item.text}}</el-button>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div class="UFsIDHAo">
                            <span class="ant-tag ant-tag-blue bordered" v-if="companyList.regStatus!=''&&companyList.regStatus!=undefined">{{companyList.regStatus}}</span>
                            <span class="ant-tag ant-tag-orange bordered" v-if="companyList.companyOrgType!=''&&companyList.companyOrgType!=undefined">{{companyList.companyOrgType}}</span>
                            <span class="ant-tag ant-tag-green bordered" v-if="companyList.categoryCode!=''&&companyList.categoryCode!=undefined">{{companyList.categoryCode}}</span>
                        </div>
                        <div class="Ne7YC53U">
                            <div class="fiklj9cw">
                                <div class="smailDiv" v-if="companyList.legalEntityId!=''&&companyList.legalEntityId!=undefined">
                                    <div>法定代表人：</div>
                                    <div>{{ companyList.legalEntityId }}</div>
                                </div>
                                <div class="smailDiv" v-if="companyList.regCapitalAmount!=''&&companyList.regCapitalAmount!=undefined">
                                    <div>注册资本：</div>
                                    <div>{{companyList.regCapitalAmount}}</div>
                                </div>
                                <div class="smailDiv" v-if="companyList.fromTime!=''&&companyList.fromTime!=undefined">
                                    <div>成立日期：</div>
                                    <div>{{companyList.fromTime }}</div>
                                </div>
                            </div>
                            <div class="fiklj9cw">
                                <div class="smailDiv" v-if="companyList.regLocation!=''&&companyList.regLocation!=undefined">
                                    <div>企业地址：</div>
                                    <div>{{companyList.regLocation}}</div>
                                </div>
                                <div class="smailDiv" v-if="companyList.agentDeptPhone!=''&&companyList.agentDeptPhone!=undefined">
                                    <div>联系方式：</div>
                                    <div>{{ companyList.agentDeptPhone }}</div>
                                </div>
                            </div>
                            <div class="fiklj9cw">
                                <div class="smailDiv" style="display:flex" v-if="companyList.businessScope!=''&&companyList.businessScope!=undefined">
                                    <div>企业简介：</div>
                                   <div class="name" style="flex:1">
                                     <span
                                        :style="{ 'max-height': status ? textHeight : '' }"
                                        :class="{ statusText: status }"
                                        class="titleText"
                                        ref="desc">
                                        {{ companyList.businessScope }}
                                     </span>
                                     <span
                                        v-if="idShowText"
                                        @click="status = !status"
                                        :class="{ openSpan: status }"
                                        class="openClose">{{ status ? "展开" : "收起" }}</span>
                                  </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
           
            <div class="halfC" style="margin-top:15px" v-if="cpList!=''">
                <div>
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="招采产品" name="first" v-if="cpList!=''">
                            <div class="name"  style="display: flex;justify-content: space-between;margin-top:5px">
                                <div class="flex areaCode titleText" ref="descs" style="padding-right: 20px;"
                                      :style="{ 'max-height': statuss ? textHeights : '' }"
                                      :class="{ statusTexts: statuss }">
                                  <div v-for="(item, index) in cpList" :key="item.id" >
                                        <div @click="proviceChang(index,item.productId)"  class="item" :class="{ selected: item.productId == numberOne}" >
                                            {{item.productName}}{{item.productNo}}
                                        </div>
                                    </div>
                                </div>
                                <div
                                v-if="idShowTexts"
                                @click="statuss = !statuss"
                                :class="{ openSpans: statuss }"
                                class="openClose">{{ statuss ? "更多" : "收起" }}</div>
                            </div>
                            
                        </el-tab-pane>

                        <!-- <el-tab-pane label="中标产品" name="second">中标产品</el-tab-pane> -->
                    </el-tabs>
                </div> 
                <div></div>
            </div>


  
            <div class="halfC tabCon" style="margin-top: 15px;" id="tabsQ">
              <div>
               <el-tabs v-model="tabName" @tab-click="handleClick">
                  <el-tab-pane label="基本信息" name="1">
                     <div class="naTitle">工商信息</div>  
                     <div class="cominfo-normal">
                       <table class="ntable sborder" >
                         <tr>
                            <td class="td" >
                                企业名称 
                            </td>
                            <td class="tds">
                                <span class="copy-value">{{detalList.name}}</span>
                            </td>
                            <td class="td">
                                法定代表人 
                            </td>
                            <td class="tds">
                                <span class="copy-value">{{detalList.legalEntityId}}</span>
                            </td>
                         </tr>
                         <tr>
                            <td  class="td">
                                统一社会信用代码 
                            </td>
                            <td class="tds">
                                <span class="copy-value">{{detalList.creditCode}}</span>
                            </td>
                            <td  class="td">
                                登记状态 
                            </td>
                            <td class="tds">
                                <span class="copy-value">{{detalList.regStatus}}</span>
                            </td>
                         </tr>
                         <tr>
                            <td class="td">
                                成立日期 
                            </td>
                            <td class="tds">
                                <span class="copy-value">{{detalList.estiblishTime}}</span>
                            </td>
                            <td class="td">
                                所属地区 
                            </td>
                            <td class="tds">
                                <span class="copy-value">{{detalList.base}}</span>
                            </td>
                         </tr>

                         <tr>
                            <td class="td">
                                注册资本 
                            </td>
                            <td class="tds">
                                <span class="copy-value">{{detalList.regCapital}}</span>
                            </td>
                            <td class="td">
                                实缴资本 
                            </td>
                            <td class="tds">
                                <span class="copy-value">{{detalList.actualCapitalAmount}}</span>
                            </td>
                         </tr>
                         <tr>
                            <td class="td">
                                组织机构代码 
                            </td>
                            <td class="tds">
                                <span class="copy-value">{{detalList.orgNumber}}</span>
                            </td>
                            <td class="td">
                                工商注册号 
                            </td>
                            <td class="tds">
                                <span class="copy-value">{{detalList.regNumber}}</span>
                            </td>
                         </tr>
                         <!-- <tr>
                            <td class="td">
                                纳税人识别号 
                            </td>
                            <td class="tds">
                                <span class="copy-value">{{detalList.orgNumber}}</span>
                            </td>
                            <td class="td">
                                纳税人资质 
                            </td>
                            <td class="tds">
                                <span class="copy-value">{{detalList.actualCapitalAmount}}</span>
                            </td> 
                         </tr> -->
                         <tr>
                            <td class="td">
                                企业类型 
                            </td>
                            <td class="tds">
                                <span class="copy-value">{{detalList.companyOrgType}}</span>
                            </td>
                            <td class="td">
                                所属行业 
                            </td>
                            <td class="tds">
                                <span class="copy-value">{{detalList.categoryCode}}</span>
                            </td>
                         </tr>
                         <tr>
                            <td class="td">
                                营业期限 
                            </td>
                            <td class="tds">
                                <span class="copy-value">{{detalList.fromTime}} <span v-if="detalList.toTime!=''&&detalList.toTime!=undefined">至</span> {{detalList.toTime}}</span>
                            </td>
                            <td class="td">
                                核准日期 
                            </td>
                            <td class="tds">
                                <span class="copy-value">{{detalList.approvedTime}}</span>
                            </td>
                         </tr>
                         <tr>
                            <td class="td">
                                登记机关 
                            </td>
                            <td class="tds">
                                <span class="copy-value">{{detalList.regInstitute}}</span>
                            </td>
                            <td class="td">
                                参保人数 
                            </td>
                            <td class="tds">
                                <span class="copy-value">{{detalList.socialSecurityStaffNum}}</span>
                            </td>
                         </tr>
                         <tr>
                            <td class="td">
                                注册地址 
                            </td>
                            <td colspan="3" class="tds2">
                                <span class="copy-value">{{detalList.regLocation}}</span>
                            </td>
                         </tr>
                         <tr>
                            <td class="td">
                                经营范围 
                            </td>
                            <td colspan="3" class="tds2">
                                <span class="copy-value">{{detalList.businessScope}}</span>
                            </td>
                         </tr>


                       </table>
                     </div>
                     <div class="naTitle">股东信息</div> 
                     <div class="gdinfo-normal ntable tab2">
                        <el-table key="2"
                            :data="tableData2"
                            border
                            style="width: 100%">
                            <el-table-column
                            type="index"
                            label="序号"
                            width="80">
                            </el-table-column>
                            <el-table-column
                            prop="holderId"
                            label="股东名称"
                            width="300">
                            </el-table-column>
                            <el-table-column
                            prop="holderType"
                            label="股东类型"
                            width="280">
                            </el-table-column>
                            <el-table-column
                            prop="credentialType"
                            label="证照/证件类型"
                            width="280">
                            </el-table-column>
                            <el-table-column
                            prop="credentialNo"
                            label="证照/证件号码">
                            </el-table-column>
                        </el-table>
                    </div> 
                     <!-- 分页 -->
                     <!-- <div>
                        <el-pagination class="fyMargin"
                            @current-change="handleCurrentChange($event,'2')"
                            background
                            :current-page="currentPage2"
                            :page-size="pageSize"
                            layout="total, prev, pager, next, jumper"
                            :total="total2">
                            </el-pagination>
                    </div> -->

                     <div class="naTitle">主要人员</div> 
                     <div class="zyinfo-normal ntable" style="margin-bottom: 20px;">
                        <div class="flex">
                        <div class="half" v-for="(item,index) in tableData3" :key="index">
                            <div class="halfName">{{item.enHumanName.name}}</div>
                            <div>{{item.staffType}}</div>
                        </div>
                       </div>
                     </div>
                    
                     <div class="naTitle">变更信息</div> 
                     <div class="gdinfo-normal ntable tab7">
                        <el-table key="7"
                            :data="tableData7"
                            border
                            style="width: 100%">
                            <el-table-column
                            prop="index1"
                            label="序号"
                            width="80">
                            </el-table-column>
                            <el-table-column
                            prop="changeItem"
                            label="变更事项"
                            width="200">
                            </el-table-column>
                            <el-table-column
                            prop="contentBefore"
                            label="变更前内容"
                            width="325">
                            </el-table-column>
                            <el-table-column
                            prop="contentAfter"
                            label="变更后内容"
                            width="325">
                            </el-table-column>
                            <el-table-column
                            prop="changeTime"
                            label="变更日期">
                            </el-table-column>
                        </el-table>
                     </div> 
                     <!-- 分页 -->
                     <div>
                        <el-pagination class="fyMargin"
                            @current-change="handleCurrentChange($event,'7')"
                            background
                            :current-page="currentPage7"
                            :page-size="pageSize"
                            layout="total, prev, pager, next, jumper"
                            :total="total7">
                        </el-pagination>
                    </div>
                     <div class="naTitle">分支机构信息</div> 
                     <div class="gdinfo-normal ntable">
                        <el-table key="4"
                            :data="tableData4"
                            border
                            style="width: 100%">
                            <el-table-column
                            prop="index1"
                            label="序号"
                            width="80">
                            </el-table-column>
                            <el-table-column
                            prop="name"
                            label="公司名称"
                            width="220">
                            </el-table-column>
                            <el-table-column
                            prop="creditCode"
                            label="统一社会信用代码/注册号"
                            width="315">
                            </el-table-column>
                            <el-table-column
                            prop="regInstitute"
                            label="登记机关"
                            width="315">
                            </el-table-column>
                            <el-table-column
                            prop="estiblishTime"
                            label="成立日期">
                            </el-table-column>
                        </el-table>
                     </div>  
                     <!-- 分页 -->
                     <div>
                        <el-pagination style="margin-top: 30px;text-align: center;"
                            @current-change="handleCurrentChange($event,'4')"
                            background
                            :current-page="currentPage4"
                            :page-size="pageSize"
                            layout="total, prev, pager, next, jumper"
                            :total="total4">
                            </el-pagination>
                    </div> 
                     <div class="naTitle">知识产权信息</div> 
                     <div class="gdinfo-normal ntable tab10">
                        <el-table key="10"
                            :data="tableData10"
                            border
                            style="width: 100%">
                            <el-table-column
                            prop="index1"
                            label="序号"
                            width="80">
                            </el-table-column>
                            <el-table-column
                            prop="title"
                            label="名称"
                            width="220">
                            </el-table-column>
                            <el-table-column
                            prop="appNumber"
                            label="申请号"
                            width="315">
                            </el-table-column>
                            <el-table-column
                            prop="appDate"
                            label="申请日期"
                            width="315">
                            </el-table-column>
                            <el-table-column
                            prop="applicantName"
                            label="专利权人">
                            </el-table-column>
                        </el-table>
                     </div> 
                     <div>
                        <el-pagination class="fyMargin"
                            @current-change="handleCurrentChange($event,'10')"
                            background
                            :current-page="currentPage10"
                            :page-size="pageSize"
                            layout="total, prev, pager, next, jumper"
                            :total="total10">
                            </el-pagination>
                    </div> 
                    <div class="naTitle">抽查检查结果信息</div> 
                     <div class="gdinfo-normal ntable tab13">
                        <el-table key="13"
                            :data="tableData13"
                            border
                            style="width: 100%">
                            <el-table-column
                            prop="index1"
                            label="序号"
                            width="80">
                            </el-table-column>
                            <el-table-column
                            prop="checkOrg"
                            label="检查实施机关"
                            width="190">
                            </el-table-column>
                            <el-table-column
                            prop="checkType"
                            label="类型"
                            width="130">
                            </el-table-column>
                            <el-table-column
                            prop="checkDate"
                            label="日期"
                            width="180">
                            </el-table-column>
                            <el-table-column
                            prop="checkResult"
                            label="结果">
                            </el-table-column>
                        </el-table>
                     </div> 
                     <div>
                        <el-pagination class="fyMargin"
                            @current-change="handleCurrentChange($event,'13')"
                            background
                            :current-page="currentPage13"
                            :page-size="pageSize"
                            layout="total, prev, pager, next, jumper"
                            :total="total13">
                            </el-pagination>
                    </div>
                    <div class="naTitle">双随机抽查结果信息</div> 
                     <div class="gdinfo-normal ntable tab17">
                        <el-table key="17"
                            :data="tableData17"
                            border
                            style="width: 100%">
                            <el-table-column
                            prop="index1"
                            label="序号"
                            width="80">
                            </el-table-column>
                            <el-table-column
                            prop="checkPlanNum"
                            label="抽查计划编号"
                            width="130">
                            </el-table-column>
                            <el-table-column
                            prop="checkPlanName"
                            label="抽查计划名称"
                            width="150">
                            </el-table-column>
                            <el-table-column
                            prop="checkTaskNum"
                            label="抽查任务编号"
                            width="130">
                            </el-table-column>
                            <el-table-column
                            prop="checkTaskName"
                            label="抽查任务名称"
                            width="130">
                            </el-table-column>
                            <el-table-column
                            prop="checkType"
                            label="抽查类型"
                            width="130">
                            </el-table-column>
                            <el-table-column
                            prop="checkDepartment"
                            label="抽查机关"
                            width="130">
                            </el-table-column>
                            <el-table-column
                            prop="checkDate"
                            label="抽查完成日期"
                            width="130">
                            </el-table-column>
                            <el-table-column
                            prop="checkResult"
                            label="抽查结果">
                            </el-table-column>
                        </el-table>
                     </div> 
                     <div>
                        <el-pagination class="fyMargin"
                            @current-change="handleCurrentChange($event,'17')"
                            background
                            :current-page="currentPage17"
                            :page-size="pageSize"
                            layout="total, prev, pager, next, jumper"
                            :total="total17">
                            </el-pagination>
                    </div>


                 </el-tab-pane> 
                  <el-tab-pane label="企业标讯" name="2"> 
                    <div class="flex jutSpace" style="border-bottom: 1px solid #F3F3F3;padding-bottom: 20px;align-items: center;">
                        <div class="tabLeft flex" >
                            招投标 <span>{{total}}</span>
                        </div>
                        <div class="tabRight flex">
                            <div class="flex" style="margin-right: 9px;">
                                <el-input v-model="input2" placeholder="请输入内容"></el-input> 
                                <el-button type="primary" @click="getInputValue">搜索</el-button>
                            </div>

                            <div class="flex " >
                                <div style="position: relative;margin-right: 9px;" >
                                    <el-select v-model="qySx1"    @change="sxOnes($event,1)">
                                        <el-option
                                            v-for="(item, index) in qyArr" :key="index"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                                    </el-select>
                                </div>
                                <div>
                                    <el-select v-model="qySx2"   @change="sxOnes($event,2)">
                                        <el-option 
                                        v-for="(item, index) in yearArr" :key="index"
                                        :label="item.value"
                                        :value="item.value">
                                        </el-option>
                                </el-select>
                                </div>

                                <!-- <div style="position: relative;margin-right: 9px;">
                                <el-cascader placeholder="省份地区" :options="options" clearable :show-all-levels="false"></el-cascader >
                                </div> -->

                            </div> 


                        </div>
                    </div>
                    <div class="ant-spin-nested-loading">
                    
                    <ul class="ant-list-items" v-for="(item,index) in listAll" :key="index">
                    <!-- 中标公告 -->
                    <div class="items-half" v-if="item.dataType==1">
                        <div class="tc_list_item tc_list_hover">
                            <div class="title" @click="zbDetail(item.id)">
                                <div>{{ item.name }}</div>
                            </div>
                            <div class="ant-content">
                                <div class="gGdJlei">
                                    <span class="ant-tag ant-tag-orange bordered">中标结果 | 中标</span>
                                    <!-- | {{item.cityCode}} | {{item.countyCode}} -->
                                    <span class="ant-tag bordered" v-if="item.provinceCode!=''&&item.provinceCode!=undefined">{{ item.provinceCode }} </span>
                                </div>  
                                <div>
                                    <div class="fiklj9cw">
                                        <div class="smailDiv" v-if="item.bidCode!=''&&item.bidCode!=undefined">
                                            <div>项目编号:</div>
                                            <div>{{item.bidCode}}</div>
                                        </div>
                                        <div class="smailDiv">
                                            <div>招采单位:</div>
                                            <div >{{item.bidDeptName}}</div>
                                        </div>
                                        <div class="smailDiv">
                                            <div>代理单位:</div>
                                            <!-- <div v-if="item.agentDeptId!=''" @click="companyDetail(item.agentDeptId)" class="blueColor">{{ item.agentDeptName }}</div> 
                                            <div v-else >{{ item.agentDeptName }}</div>  -->
                                            <div >{{ item.agentDeptName }}</div> 
                                        </div>
                                    </div>
                                    <div v-if="item.pageWinPackageList!=''&&item.pageWinPackageList!=undefined">
                                    <div class="fiklj9cw" v-for="(items,indexs) in item.pageWinPackageList" :key="indexs">
                                        <div class="smailDiv" v-if="items.windeptname!=''&&items.windeptname!=undefined">
                                            <div>中标单位:</div>
                                            <div>{{ items.windeptname }}</div>
                                            <!-- <div v-if="items.windeptId!=''" @click="companyDetail(items.windeptId)" class="blueColor">{{ items.windeptname }}</div> 
                                            <div v-else >{{ items.windeptname }}</div>  -->
                                        </div>
                                        <div class="smailDiv" v-if="items.winamountStr!=''&&items.winamountStr!=undefined">
                                            <div>中标金额:</div>
                                            <div>{{items.winamountStr}}</div>
                                        </div> 
                                    </div>
                                    </div> 

                                    <!-- <div class="fiklj9cw">
                                        <div class="smailDiv">
                                            <div>中标单位:</div>
                                            <a href="" target="_blank" class="blueColor">{{item.pageWinPackageList[0].windeptname}}</a>
                                        </div>
                                        <div class="smailDiv">
                                            <div>中标金额:</div>
                                            <a href="" target="_blank" class="blueColor">{{item.pageWinPackageList[0].winamountStr}}</a>
                                        </div>
                                        <div class="smailDiv">
                                            <div>联系方式:</div>
                                            <div>{{item.agentDeptPhone}}</div>
                                        </div>
                                    </div> -->
                                    <div class="fiklj9cw">
                                        
                                    
                                        <div class="smailDiv" v-if="item.issueTimeStr!=''&&item.issueTimeStr!=undefined">
                                            <div>发布时间:</div>
                                            <div>{{item.issueTimeStr}}</div>
                                        </div>
                                        <!-- <div class="smailDiv">
                                            <div>开标时间:</div>
                                            <div>{{item.openTime}}</div>
                                        </div> -->
                                        <div class="smailDiv" v-if="item.deadTimeStr!=''&&item.deadTimeStr!=undefined">
                                            <div v-if="item.dataSubType==101">预计采购时间:</div>
                                             <div v-else>投标截止时间:</div>
                                            <div>{{item.deadTimeStr}}</div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div> 

                    <!-- 招标公告 -->
                    <div class="items-half" v-else>
                        <div class="tc_list_item tc_list_hover">
                            <div class="title" @click="zbDetail(item.id)">
                                <div>{{ item.name }}</div> 
                            </div>
                            <div class="ant-content">
                                <div class="gGdJlei">
                                    <span class="ant-tag ant-tag-blue bordered">招标公告 | 招标</span>
                                    <span class="ant-tag bordered" v-if="item.provinceCode!=''&&item.provinceCode!=undefined">{{ item.provinceCode }} </span>
                                </div>  
                                <div>
                                    <div class="fiklj9cw">
                                        <div class="smailDiv" v-if="item.bidCode!=''&&item.bidCode!=undefined">
                                            <div>项目编号:</div>
                                            <div>{{item.bidCode}}</div>
                                        </div>
                                        <div class="smailDiv" v-if="item.bidDeptName!=''&&item.bidDeptName!=undefined">
                                            <div>招采单位:</div>
                                            <div >{{item.bidDeptName}}</div>
                                        </div>
                                        <div class="smailDiv" v-if="item.agentDeptName!=''&&item.agentDeptName!=undefined">
                                            <div>代理单位:</div>
                                            <!-- <div v-if="item.agentDeptId!=''" @click="companyDetail(item.agentDeptId)" class="blueColor">{{ item.agentDeptName }}</div> 
                                            <div v-else >{{ item.agentDeptName }}</div>  -->
                                            <div  >{{ item.agentDeptName }}</div> 
                                        </div>

                                        <!-- <div class="smailDiv" v-if="item.bidDeptName!=''&&item.bidDeptName!=undefined">
                                            <div>招采单位:</div>
                                            <div>{{item.bidDeptName}}</div>
                                        </div>
                                        <div class="smailDiv" v-if="item.agentDeptName!=''&&item.agentDeptName!=undefined">
                                            <div>代理单位:</div>
                                            <a href="" target="_blank" class="blueColor">{{item.agentDeptName}}</a>
                                        </div> -->
                                    </div>
                                    <div class="fiklj9cw">
                                        <div class="smailDiv" v-if="item.budgetAmount!=''&&item.budgetAmount!=undefined">
                                            <div>预算金额:</div>
                                            <div>{{item.budgetAmount}}</div>
                                        </div>
                                        <div class="smailDiv" v-if="item.agentDeptPhone!=''&&item.agentDeptPhone!=undefined">
                                            <div>联系方式:</div>
                                            <div>{{item.agentDeptPhone}}</div>
                                        </div>
                                    </div>
                                    <div class="fiklj9cw">
                                        <div class="smailDiv" v-if="item.issueTimeStr!=''&&item.issueTimeStr!=undefined">
                                            <div>发布时间:</div>
                                            <div>{{item.issueTimeStr}}</div>
                                        </div>
                                        <!-- <div class="smailDiv">
                                            <div>开标时间:</div>
                                            <div>{{item.openTime}}</div>
                                        </div> -->
                                        <!-- <div class="smailDiv">
                                            <div>标书获取截止时间：</div>
                                            <div>2022-12-08 19:00</div>
                                        </div> -->
                                        <div class="smailDiv" v-if="item.deadTimeStr!=''&&item.deadTimeStr!=undefined">
                                            <div v-if="item.dataSubType==101">预计采购时间:</div>
                                            <div v-else>投标截止时间:</div>
                                            <div>{{item.deadTimeStr}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </ul>  

               </div>

                    <!-- 分页 -->
                    <div class="pagination" >
                        <el-pagination
                            @current-change="handleCurrentChanges"
                            background
                            :current-page="currentPage"
                            :page-size="pageSizes"
                            layout="total, prev, pager, next, jumper"
                            :total="total">
                        </el-pagination>
                   </div>
                  </el-tab-pane>
                </el-tabs> 


              </div>
            </div>    


            

         </div>


    <!-- 弹框二维码支付 -->
    <div class="tkPay">
        <el-dialog
        :visible.sync="ewmVisible"
        width="666px"
        :before-close="handleClose">
        <div class="nav" v-if="isPay==true">
            <div class="ktText">开通产品套餐，查看更多数据</div>
            <div class="nav-top">
               <div v-if="isChao">
                    <div class="tabs flex">
                        <div class="btn" :class="{active : itemIndex == 1}" @click="btnClick(1,'7163018607638296736')">
                            <div>普通用户</div>
                            <div style="font-size: 12px;color: #999999;">单条查询,省钱、省心、省时间</div>
                            <div style="font-size: 12px;color: #FF0000;">￥<span style="font-size:30px">0.5</span>元/条</div>
                            <i class="tc_purchase-order_check"></i>
                        </div>
                        <div class="btn" :class="{active : itemIndex == 2}" @click="btnClick(2,'5166747643280060474')">
                            <div>1年VIP</div>
                            <div style="font-size: 12px;color: #999999;">标讯信息，随时随地，想查就查</div>
                            <div style="font-size: 12px;color: #FF0000;">￥<span style="font-size:30px">999</span>元/年</div>
                            <i class="tc_purchase-order_check"></i>
                        </div>
                    </div>
                    <div class="flex jutCenter" style="margin-top: 30px;">
                        <div @click="payfor" class="kaiTong">立即开通</div>
                    </div>
               </div>
               <div v-else class="tabs">
                  <div class="btn active"  style="text-align: center;">
                            <div>高级VIP</div>
                            <div style="font-size: 12px;color: #999999;">更多特权，超值服务</div>
                            <div style="font-size: 30px;color: #999999;">敬请期待</div>
                            <i class="tc_purchase-order_check"></i>
                    </div>
               </div>
            </div>

            <div  class="nav-tabel">
                <img src="../assets/images/tcTabel.png" class="tcTabelImg" />
            </div>
        </div>

        <div v-else class="navK">
            <div class="ktText">开通产品套餐，查看更多数据</div>
            <div class="paySuccess" v-if="paySuccess">
                <el-alert :title="messageTitle" type="success"  center show-icon :closable="false"></el-alert>
            </div>
            <div class="fYcolor">
                <div class="cpLeft">
                    <img :src="ewmImg" class="ewmImg" />
                    <div class="flex jutCenter">
                        <img src="../assets/images/sao.png" style="width:17px;height:17px;margin-right: 5px;" />
                        <div style="color:#999999">使用<span style="color:#1E88FF">微信</span>扫码支付</div>
                    </div>
                </div>
                <div class="cpRight">
                    <div>开通账号：{{phoneNo}}</div>
                    <div>
                        <div>开通套餐：{{orderPay.name}}</div>
                        <div>到期时间：{{orderPay.days}}</div>
                        <div v-if="orderPay.days=='永久'">实付金额：<span style="color:#FF0000">￥<span style="font-size:30px">{{orderPay.salePrice}}</span>元/条</span></div>
                        <div v-else>实付金额：<span style="color:#FF0000">￥<span style="font-size:30px">{{orderPay.salePrice}}</span>元/年</span></div>
                    </div>
                    <!-- <div style="color: #999999;margin-top: 5px;">发票获取：完成支付后可在【个人中心-我的发票】中申请</div> -->
                    
                </div>
            </div>
        </div>
        </el-dialog>
      </div>



         <el-drawer
            title="标题"
            :visible.sync="isShow3"
            size="400px">
                <div style="padding: 0 20px;font-size: 14px;">
                    <div class="gjTitle">跟进内容</div>
                    <el-input
                        type="textarea"
                        maxlength="50"
                        @blur="gjText"
                        :rows="4"
                        placeholder="请输入跟进内容(最多输入50个字符)"
                        v-model="gjtextarea">
                    </el-input>

                </div>

                <div class="demo-drawer__footer">
                    <el-button @click="cancelForm">取 消</el-button>
                    <el-button type="primary" @click="sure" >保 存</el-button>
                </div>


        </el-drawer>


        <common-foot></common-foot>
        <common-active :showTk="beiji" :showActivity="flagActivity" @change="amountChanges($event)"></common-active>
        
    </div>
</template>

<script>
   import CommonHeader from '@/components/CommonHeader.vue';
   import CommonFoot from '@/components/CommonFoot.vue';
   import CommonActive from '@/components/CommonActive.vue';
    export default {
      components:{
       CommonHeader,
       CommonFoot,
       CommonActive
       }, 
        data() {
            return {
             currentPage: 1,
             pageSizes:10,
             total:0,
             input2:'',
             flag:'',
             
             iconList:[
                // {id:'1',src:require('../assets/images/aicon1.png'),text:'监控'},
                {id:'1',src:require('../assets/images/aicon2.png'),text:'收藏'},
                // {id:'2',src:require('../assets/images/aicon3.png'),text:'跟进'},
             ],
             isFlag:'0',
             isShow3:false,
             gjtextarea:'',
             textHeight: null,
            status: false,
            idShowText: false,
            activeName: 'first',
            cpList:[],
            numberOne:'',
            textHeights: null,
            statuss: false,
            idShowTexts: false, 
           
                companyList:[],
                listAll:[],
               
                yearArr:[],
                
                qyArr:[{
                    value: '',
                    label: '全部角色'
                    }, {
                    value: '0',
                    label: '中标单位'
                    }, {
                    value: '1',
                    label: '招标单位'
                    }, {
                    value: '2',
                    label: '代理单位'
                    }, ],
                    value1:'',
                    value2:'',
                    qySx1:'企业角色',
                    qySx2:'发布时间',

                    beiji:false,
                    tabName: '1',
                    detalList:[],
                    tableData2:[],
                    tableData3:[],
                    tableData4:[],
                    tableData7:[],
                    tableData10:[],
                    tableData13:[],
                    tableData17:[],

                    pageSize:5,
                   
                    currentPage2: 1,
                    total2:0,
                    currentPage4:1,
                    total4:0,
                    currentPage7: 1,
                    total7:0,
                    currentPage10: 1,
                    total10:0,
                    currentPage13: 1,
                    total13:0,
                    currentPage17: 1,
                    total17:0,



                    phoneNo:'',
                    ewmVisible:false,
                    itemIndex:1,
                    isPay:true,
                    isChao:true,

                    dialogVisible:false,
                    gjtext:'',  //关键词
                    goodsId:'7163018607638296736',   //vip套餐id
                    objectIds:'', //点击详情时的Id
                    timerT: null, // 定时器
                    orderPay:[],
                    ewmImg:'',
                    paySuccess:false,
                    totalTime:3,
                    messageTitle:'',
                    tempPage:'',
                    flagActivity:'',
            };
        },
        created(){
            this.flag = this.$route.query.flag
        },
        mounted() {
            let now   = new Date();
            let yearn  = now.getFullYear();
            let yearArrs=[]
            yearArrs.push(yearn,yearn-1,yearn-2,yearn-3,yearn-4,yearn-5,)
            this.yearArr=[
                {label:'',value:'全部时间'},
                {label:1,value:yearArrs[0]},
                {label:2,value:yearArrs[1]},
                {label:3,value:yearArrs[2]},
                {label:4,value:yearArrs[3]},
                {label:5,value:yearArrs[4]},
                {label:6,value:yearArrs[5]},
            ]

            this.$nextTick(() => {
                setTimeout(() => {
                    this.calculateText();
                    this.calculateTexts();
                }, 1000);
            });
            this.showDetails();
            this.showzbList('1','','','')
            this.zcList()
            this.companyDetailsGd('2')
            this.companyDetails('1','1')
            this.companyDetails('3','1') 
            this.companyDetails('4','1') //分支机构信息
            this.companyDetails('7','1') //变更记录
            this.companyDetails('10','1') //知识产权信息

            this.companyDetails('13','1') //变更记录
            this.companyDetails('17','1') //知识产权信息

            let phoneNos=JSON.parse(localStorage.getItem('phoneNo'))
            this.phoneNo=phoneNos
        },
        methods: {
            handleClick(event) {
                this.tabName=event._props.name
            },
            amountChange(e){
              this.beiji=e
            },
            amountChanges(e,flag){ 
              this.beiji=e
              this.flagActivity='1'
            },
          getInputValue(){
           this.showzbList(this.currentPage,this.input2,this.value1,this.value2)
          },
          zcList(){
               var that=this   //+'&pageSize=-1'
                that.$axios.post('/webInterface/queryProductInfoList?companyId='+that.flag+'&productType=1').then(res => {
                   // console.log(res)
                 that.cpList=res.data.enProductInfoList  
                }).catch(err => {
                    console.log('请求失败')
                })

          },
          showDetails(){
            //查询公司详情
                var that=this
                that.$axios.post('/webInterface/queryEnCompany?companyId='+that.flag).then(res => {
                   //console.log(res)
                   if(res.data.resultcode==-9999){
                      that.$message.warning('登录信息失效，请重新登录')
                        setTimeout(()=>{  //设置延迟执行
                            that.$router.push({path: '/login'});
                       },1000);
                   }else{
                      that.isShouc=res.data.bookmarkState
                      that.companyList=res.data.enCompany
                     if(that.isShouc==1){
                        that.iconList[0].src=require('../assets/images/aicon22.png')
                        that.iconList[0].text='取消收藏'
                        that.isFlag=1
                     }
                   }
                    
                }).catch(err => {
                    console.log('请求失败')
                })
            },
           companyDetailsGd(flag){
                var that=this
                that.$axios.post('/webInterface/queryEnCompanyDetail?enCompanyId='+that.flag+'&dataType=1'+'&flag='+flag).then(res => {
                   if(res.data.resultcode==1){
                   that.tableData2=res.data.enCompanyHolderList
                  }
                }).catch(err => {
                    console.log('请求失败')
                })
            },
            companyDetails(flag,currentP){
                var that=this
                that.$axios.post('/webInterface/queryEnCompanyDetail?enCompanyId='+that.flag+'&dataType=1'+'&flag='+flag+'&pageSize='+that.pageSize+'&pageNo='+currentP).then(res => {
                   if(res.data.resultcode==1){
                    if(flag==1){
                        that.detalList=res.data.enCompany
                    }else if(flag==3){
                        that.tableData3=res.data.enCompanyStaffList
                    } else if(flag==4){
                        let list=res.data.enCompanyList
                        let arr = []
                        that.total4=res.data.totalCount
                        list.forEach((item,index)=>{
                            arr.push(Object.assign({},item,{index1: (index+1)+(that.pageSize*(that.currentPage4-1))}))
                        })
                        that.tableData4=arr
                    }else if(flag==7){
                        // that.tableData7=res.data.enCompanyChangeList
                        // that.total7=res.data.totalCount
                        let list=res.data.enCompanyChangeList
                        let arr = []
                        that.total7=res.data.totalCount
                        list.forEach((item,index)=>{
                            arr.push(Object.assign({},item,{index1: (index+1)+(that.pageSize*(that.currentPage7-1))}))
                        })
                        that.tableData7=arr
                    }else if(flag==10){
                        // that.tableData10=res.data.enCompanyPatentList
                        // that.total10=res.data.totalCount
                        let list=res.data.enCompanyPatentList
                        let arr = []
                        that.total10=res.data.totalCount
                        list.forEach((item,index)=>{
                            arr.push(Object.assign({},item,{index1: (index+1)+(that.pageSize*(that.currentPage10-1))}))
                        })
                        that.tableData10=arr
                    }else if(flag==13){
                        // that.tableData13=res.data.enCompanyCheckInfoList
                        // that.total13=res.data.totalCount
                        let list=res.data.enCompanyCheckInfoList
                        let arr = []
                        that.total13=res.data.totalCount
                        list.forEach((item,index)=>{
                            arr.push(Object.assign({},item,{index1: (index+1)+(that.pageSize*(that.currentPage13-1))}))
                        })
                        that.tableData13=arr
                    }else if(flag==17){
                        // that.tableData17=res.data.enCompanyDoubleRandomCheckInfoList
                        // that.total17=res.data.totalCount
                        let list=res.data.enCompanyDoubleRandomCheckInfoList
                        let arr = []
                        that.total17=res.data.totalCount
                        list.forEach((item,index)=>{
                            arr.push(Object.assign({},item,{index1: (index+1)+(that.pageSize*(that.currentPage17-1))}))
                        })
                        that.tableData17=arr
                    }
                   }else{
                     console.log('查询失败')
                   }
                    
                }).catch(err => {
                    console.log('请求失败')
                })
            },
            //查询招投标
            showzbList(pagePage,searchCon,qyjs,time){
                var that=this
                if(time=='全部时间'){
                    time=''
                }
                that.$axios.get('/webInterface/queryScripingpageList', {
                    params:{
                        companyId: that.flag,
                        dataType: '',
                        pageSize:that.pageSizes,
                        pageNo:pagePage,
                        searchData:searchCon,
                        unitRoleType:qyjs,
                        issueTime:time
                    }
                })
                    .then(function (res) {
                       console.log(res)
                        if(res.data.resultcode==1){
                            that.listAll = res.data.scripingpageList
                            that.total=res.data.totalCount
                        }else if(res.data.resultcode==-9999){
                           
                        }else{
                            that.$message.warning(res.data.message)
                            that.listAll =[]
                            that.total=0
                            that.currentPage=1
                            that.showzbList(that.currentPage,that.input2,that.value1,that.value2)
                        }
                       
                    })
                    .catch(function (err) {
                        console.log(err);
                    })
            },
            smNavgite(flag,index){
                var that=this
                if(index==0){
                    if(that.isFlag==1){
                        that.$axios.get('/webInterface/upBookmark', {  //params参数必写 , 如果没有参数传{}也可以
                                params: {  
                                    objectId:that.companyList.id,
                                    objectType:1
                                }
                            })
                            .then(function (res) {
                            console.log(res);
                              if(res.data.resultcode==1){
                                that.iconList[index].src=require('../assets/images/aicon2.png')
                                that.iconList[index].text='收藏'
                                that.isFlag=0
                                that.$message({
                                    message: '取消收藏成功',
                                    type: 'success'
                                });
                              }
                            })
                            .catch(function (err) {
                                console.log(err);
                            })
                       
                    }else{
                        that.$axios.get('/webInterface/upBookmark', {  //params参数必写 , 如果没有参数传{}也可以
                                params: {  
                                    objectId:that.companyList.id,
                                    objectType:1
                                }
                            })
                            .then(function (res) {
                            console.log(res);
                              if(res.data.resultcode==1){

                                that.iconList[index].src=require('../assets/images/aicon22.png')
                                that.iconList[index].text='取消收藏'
                                that.isFlag++
                                that.$message({
                                    message: '收藏成功',
                                    type: 'success'
                                });
                              }
                            })
                            .catch(function (err) {
                                console.log(err);
                            })

                    } 
                    
               }else if(index==1){
                  this.isShow3=true
               }
            },
            gjText(e){
               // console.log(this.gjtextarea)
            },
            cancelForm(){
                this.isShow3=false
            },
            sure(){

            },
            sxOnes(e,flag){
                console.log(e)
                if(flag==1){
                    this.value1=e  //筛选条件
                }else{
                    this.value2=e  //筛选条件
                }
                this.showzbList(this.currentPage,this.input2,this.value1,this.value2)
             },
            calculateText() {
                // 这是默认两行数据的高度，一行的高度可以自定义 可以*3 *4达到三行或者四行显示展示和收起的效果
                let twoHeight = 22 * 2;
                this.textHeight = `${twoHeight}px`;
                let curHeight = this.$refs.desc.offsetHeight;
                if (curHeight > twoHeight) {
                    this.status = true;
                    this.idShowText = true;
                } else {
                    this.status = false;
                    this.idShowText = false;
                }
            },
            calculateTexts() {
                // 这是默认两行数据的高度，一行的高度可以自定义 可以*3 *4达到三行或者四行显示展示和收起的效果
                let twoHeight = 40 * 2;
                this.textHeights = `${twoHeight}px`;
                // let curHeight = this.$refs.descs.offsetHeight;
                // if (curHeight > twoHeight) {
                //     this.statuss = true;
                //     this.idShowTexts = true;
                // } else {
                //     this.statuss = false;
                //     this.idShowTexts = false;
                // }
            },
            proviceChang(index,areaCode) {
                this.numberOne = areaCode
            },
            handleCurrentChange(val,flag) {
                if(flag==2){
                  this.currentPage2=val
                  this.companyDetailsGd(flag)
                }else if(flag==4){
                  this.currentPage4=val
                  this.companyDetails(flag,val)
                }else if(flag==7){
                  this.currentPage7=val
                  this.companyDetails(flag,val)
                }else if(flag==10){
                  this.currentPage10=val
                  this.companyDetails(flag,val)
                }else if(flag==13){
                  this.currentPage13=val
                  this.companyDetails(flag,val)
                }else if(flag==17){
                  this.currentPage17=val
                  this.companyDetails(flag,val)
                }
            },
            handleCurrentChanges(val){
                this.currentPage=val
                this.showzbList(this.currentPage,this.input2,this.value1,this.value2)
                document.querySelector('#tabsQ').scrollIntoView({
                    behavior: "smooth"
                });
            },
            companyDetail(flag){
                const {href}=this.$router.resolve({
                // 跳转到的页面路径
                path: '/companyDetails',
                // 传递的参数集合
                query: {
                    flag: flag
                }
              })
              window.open(href,'_blank')
            },
            zbDetail(flag){
            let phoneNos=JSON.parse(localStorage.getItem('phoneNo'))
            var that=this
            that.objectIds=flag
            that.activeNameT='1'
            that.vipFlag=3
            that.goodsId='7163018607638296736'

            if(phoneNos!=null&&phoneNos!=''){
                    that.$axios.get('/webInterface/checkVipAuth', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            objectType:2,
                            objectId:flag
                        }
                    })
                    .then(function (res) {
                       console.log(res);
                       if(res.data.resultcode==1){
                        const {href}=that.$router.resolve({
                            // 跳转到的页面路径
                            path: '/zbDetails',
                            query: {
                                flag: flag,
                            }
                        })
                        window.open(href,'_blank')
                       }else{
                           that.ewmVisible=true
                            that.isChao=true //不是vip  isChao=true 弹框支付
                        
                       } 
                    })
                    .catch(function (err) { 
                    })
                }else{
                    setTimeout(()=>{  //设置延迟执行
                        this.$router.push({path: '/login'});
                    },600);
                }
            },

             //关闭支付弹框
             handleClose(){
                this.itemIndex=1
                this.goodsId='7163018607638296736'
                this.ewmVisible=false
                this.isPay=true
                clearInterval(this.timerT)
                this.timerT = null
            },
            btnClick(index,goodsId){
                this.itemIndex =index
                this.goodsId=goodsId
            },
            payfor(){
                var that=this
                let objectId=''
                let objectType=2   //标讯
                if(that.itemIndex==1){
                    objectId=that.objectIds
                }
               that.$axios.get('/webInterface/generateOrder', {  //params参数必写 , 如果没有参数传{}也可以
                    params: {  
                        goodsId:that.goodsId,  //套餐id
                        orderIds:'',
                        objectId:objectId, //单条详情id
                        quantity:'1',
                        freight:0,
                        payType:2,  //1是支付宝支付，2是微信支付
                        objectType:objectType,   //标讯
                    }
                })
                .then(function (res) {
                    console.log(res);
                    if(res.data.resultcode==1){
                        that.ewmImg=res.data.reStr  //二维码
                        that.isPay=false   
                        that.$axios.post('/webInterface/queryGoodsInfo?id='+that.goodsId)
                        .then(function (res) {
                            console.log(res);
                            if(res.data.resultcode==1){
                                 that.orderPay=res.data.goodsInfo                               
                            }
                        })
                        .catch(function (err) { 
                        })

                        that.timerT = setInterval(() => {
                            //  定时器中执行的代码
                                that.check(res.data.orderInfo.id)
                         }, 3000)
                    }
                })
                .catch(function (err) { 
                })
            },
            check(orderId){
                var that=this
               that.$axios.post('/webInterface/queryOrderInfo?orderId='+orderId+'&flag=1')
                .then(function (res) {
                    console.log(res);
                 if(res.data.resultcode=='1'&&res.data.orderInfo.state!=0){
                     that.paySuccess=true
                     that.messageTitle='支付成功，'+that.totalTime +'秒后将跳转至详情页面'
                     let clock = setInterval(() => {
                        that.totalTime--
                        that.messageTitle='支付成功，'+that.totalTime +'秒后将跳转至详情页面'
                        //that.messageTitle='支付成功，将跳转至详情页面'
                        if (that.totalTime==0) {     			//当倒计时小于0时清除定时器
                            clearInterval(clock)
                            that.ewmVisible=false
                            that.isPay=true
                            that.paySuccess=false
                            clearInterval(that.timerT)
                            that.totalTime = 3
                            that.timerT = null
                            //标讯

                            that.$router.push({
                                     path: '/zbDetails',
                                     query: {
                                     flag: that.objectIds, 
                                  }
                                })

                            // const routerdata = that.$router.resolve({
                            //          path: '/zbDetails',
                            //          query: {
                            //          flag: that.objectIds, 
                            //       }
                            //     })
                            //   const newhref = routerdata.href
                            //   that.tempPage.location = newhref
                            

                        }
                    },500)
					}else{
                        that.paySuccess=false
                        clearInterval(clock)
                        that.isPay=true
                        clearInterval(that.timerT)
                        that.totalTime = 3
                        that.timerT = null

                    }
                })
                .catch(function (err) { 
                })
            },

        }
    }
</script>
<style scoped lang="less">
  
  .halfC{
    width: 1140px;
    background: #FFFFFF;
    border: 1px solid #F3F3F3;
    border-radius: 5px;
    margin: 0 auto;
    padding: 30px 30px;
  }
  .sVDY6WmQ {
    display: flex;
    padding: 20px 0 10px;
  }
  .VyEwnPk{
    flex: none;
  }
  .VyEwnPk img {
    width: 60px;
    height: 60px;
    border-radius: 6px;
}
.eHWU1UFm {
    flex: 1;
    min-width: 0;
    padding-left: 20px;
}
.ovaWkJTW {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.KLJC8c9N {
    display: flex;
    align-items: center;
}
.fiklj9cw {
    color: #333;
}
.fiklj9cw .smailDiv {
    line-height: 22px;
    margin: 5px 0;
    max-width: 100%;
}
.fiklj9cw .smailDiv, .fiklj9cw .smailDiv>div {
    display: inline-block;
}
.fiklj9cw .smailDiv>div:first-child {
    color: #999;
    padding-right: 10px;
}
.fiklj9cw .smailDiv:not(:last-child) {
    margin-right: 30px;
}
.Ne7YC53U{
    margin-top:10px
}
.KLJC8c9N{
    .el-button {
        border:none;
        outline:none;
        margin-top: 2px;
        padding:0
    }
}
.iconImg{
    width: 18px;
    height: 18px;
    margin-right: 5px;
}
.title{
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
}



.name {
    position: relative;
    font-size: 16px;
  .openClose {
    color: #0078FF;
    cursor: pointer;
  }
  .openSpan {
    position: absolute;
    right: 0;
    bottom: 2px;
    cursor: pointer;
  }
  .openSpans {
    // position: absolute;
    // right: 0;
    // bottom: 2px;
    cursor: pointer;
  }
  .statusText {
    overflow: hidden;
    display: block;
  }
  .statusText:after {
    content: "...";
    position: absolute;
    bottom: 0;
    right: 2px;
    width: 48px;
    padding-left: 30px;
    background: linear-gradient(to right, rgba(255,255,255,0.2), #fff 45%);
  }
  .statusTexts:after {
    overflow: hidden;
    display: flex;
  }
  .statusTexts:after {
   // content: "...";
    position: absolute;
    bottom: 0;
    right: 2px;
    width: 48px;
    padding-left: 30px;
    background: linear-gradient(to right, rgba(255,255,255,0.2), #fff 45%);
  }
}
/deep/.el-tabs__item{
    height: 34px !important;
    line-height: 22px;
    font-size: 16px !important;
}

.areaCode,.timeCode{
  flex-wrap: wrap;
  cursor: pointer;
  width: 1050px;
}
.areaCode .item,.timeCode .item{
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin-right: 8px;
    padding: 0 12px;
    margin-bottom: 10px;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    border-radius: 2px;
}
.selected{
  background: #EFF7FF;
  border-radius: 4px;
  font-weight: 400;
  color: #0078FF; 
 
}
/deep/.el-select{
.el-input{
    width: 109px;
  }
}  
/deep/.el-cascader{
  .el-input{
    width: 109px;
  }
}




.ant-spin-nested-loading {
    position: relative;
}
.ant-list-items {
    margin: 0;
    padding: 0;
    list-style: none;
}
.ant-list-items:last-child{
    border-bottom:20px
  }
.tc_list_item.tc_list_hover {
    transition: background-color .2s;
}
.tc_list_item {
    position: relative;
    border-bottom: 1px solid #F3F3F3;
    padding: 10px 0 14px 0;

}
.title{
    font-size: 18px;
    font-weight: 700;
    padding: 10px 0;
    color: #333;
    cursor: pointer;
}

.gGdJlei{
    margin-bottom: 10px;
}
.fiklj9cw {
    color: #333;
}
.fiklj9cw .smailDiv {
    line-height: 22px;
    margin: 5px 0;
    max-width: 100%;
}
.fiklj9cw .smailDiv, .fiklj9cw .smailDiv>div {
    display: inline-block;
}
.fiklj9cw .smailDiv>div:first-child {
    color: #999;
    padding-right: 10px;
}
.fiklj9cw .smailDiv:not(:last-child) {
    margin-right: 30px;
}
.blueColor{
    color: #0078FF;
    cursor: pointer;
}
a{
    color:#000
}
.pagination{
    text-align: center;
    margin-top: 28px;
}
.naTitle{
    margin-bottom: 10px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
}
.ntable {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 15px;
    font-size:14px;
    border-spacing: 0;
    .td {
    padding: 10px 10px 11px 10px;
    word-break: break-all;
   // font-size: 16px;
    line-height: 1.6;
    color: #333;
    word-wrap: break-word;
    padding-right: 6px;
    background: #EFF4FF;
    // border: 1px solid #D0DEFF;
     width:15%;
     border-bottom: 1px solid #D0DEFF;
       border-right: 1px solid #D0DEFF;
       font-weight: bold;
    }
    .tds{
        width: 30%;
        padding: 10px 10px 11px 10px;
        word-break: break-all;
       // font-size: 16px;
        line-height: 1.6;
        color: #606266;
        word-wrap: break-word;
        padding-right: 6px;
        background: #FFFFFF;
        border-bottom: 1px solid #D0DEFF;
       border-right: 1px solid #D0DEFF;
      //  border: 1px solid #D0DEFF;
    }
    
    .tds2{
      
        padding: 10px 10px 11px 10px;
        word-break: break-all;
        //font-size: 16px;
        line-height: 1.6;
        color: #606266;
        word-wrap: break-word;
        padding-right: 6px;
        background: #FFFFFF;
        border-bottom: 1px solid #D0DEFF;
    }
}
.half{
    background: #EFF4FF;
    border: 1px solid #D0DEFF;
    min-width: 90px;
    text-align: center;
    padding: 13px 0;
    margin-right: 15px;
    border-radius: 2px;
}
.sborder{
    border: 1px solid #D0DEFF;
}
/deep/.tab2{
    .el-table{
        tbody{
        .el-table__row td{
           text-align:center
        }
    }
    }
}
/deep/.el-table--border, .el-table--group {
    border: 1px solid #D0DEFF;
    .el-table thead{
    color: #333;
    }
    tbody{
        .el-table__row td:first-child{
           text-align:center
        }
    }
}
/deep/.el-table th.el-table__cell{
    background-color:#EFF4FF;
    color: #333;
    text-align: center;
}
/deep/.el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    border-bottom: 1px solid #D0DEFF;
}
/deep/.el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #D0DEFF;
}
.ntable tr:last-child td{
    border-bottom: none;
   // border-right: none;
}
.ntable tr .tds:last-child{
   // border-bottom: none;
    border-right: none;
}
.ntable tr .tds2:last-child{
   // border-bottom: none;
    border-right: none;
}
.fyMargin{
    margin-top: 15px;
    text-align: center;
    margin-bottom: 12px;
}
.halfName{
    color: #409EFF;
    font-size: 16px;
}
/deep/.iconHalf{
    margin-right: 15px;
    cursor: pointer;
    .el-button{
        span{
        font-size: 14px;
        color:#606266;
    }
}
}


/deep/.tkPay{
    .el-dialog{
        border-radius: 5px;
    .el-dialog__header{
        background-image: url(../assets/images/tcTop.png);
        width:100%;
        height:140px;
        padding:0;
        .el-dialog__close{
            color: #fff;
        }
    }
    .el-dialog__body{
       border-radius: 5px;
       padding: 0;
       padding-bottom: 20px;
       .nav{
        .nav-top{
            padding: 30px 30px 20px 20px;
            margin: -50px 15px 20px 15px;
            background: #fff;
           // box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 8%);
            box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.08);
            border-radius: 10px;
        }
        .nav-tabel{
            margin: 20px 15px 0 15px;
            max-height: 300px;
            overflow: auto;
        }
       }
       .navK{
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.08);
        border-radius: 10px;
        margin: -50px 15px 0 15px;
        padding: 30px;
        .fYcolor{
            background: #FFFCF6;
            border-radius: 5px;
            padding: 15px;
            display:flex;
            .cpLeft{
                width: 160px;
                height: 200px;
                background: #FFFFFF;
                border-radius: 5px;
                margin-right: 60px;
                .ewmImg{
                    width:160px;
                    height:160px;
                    margin-bottom:7px;
                }
            }
            .cpRight{
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #222222;
                line-height: 30px;
                margin-top: 20px;
            }
        }
       }
    }
  }
}

.tcTabelImg{
    width:100%;
    height:757px
}
.kaiTong{
    background-image: url(../assets/images/kaiTong.png);
    width:200px;
    height:46px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #222222;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.ktText{
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #B28054;
    position: absolute;
    top: 20px;
    left: 94px;
}
.tabs{
    .btn{
        position: relative;
        flex: 1;
        margin: 0 10px;
        border: 1px solid #CCCCCC;
        border-radius: 5px;
        padding: 19px 15px;
        cursor: pointer;
        transition: border-color .2s;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #222222;
        .tc_purchase-order_check {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 30px;
            height: 30px;
        }
    }
    .active{
        border-color: #432107;
        background-color: #fff;
        .tc_purchase-order_check{
            background-image: url(../assets/images/gou.png);
           background-size: 100% 100%;
        }
    }
}
.rows{
    justify-content: space-between;
}
.paySuccess{
        position: absolute;
        top: 77px;
        left: 50%;
        transform: translateX(-50%);
    }
</style>