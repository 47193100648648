<template>
    <div ref="tabFrom">
          
          <div class="flex chooseArea" style="align-items: flex-start;">
            <div style="color: #999999;width: 90px;line-height: 30px;">选择地区：</div>
            <!-- 省 -->
             <div class="flex areaCode" >
                    <div v-for="(item, index) in proviceList" :key="item.id" >
                        <div @click="proviceChang(index,item.areaCode)"   class="item" :class="{ selected: index == numberOne}">
                            {{item.areaName}}
                        </div>
                    </div>
             </div>
           </div>
            <!-- 市 -->
            <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px" v-if="isCity"> 
                <div style="color: #999999;width: 90px;line-height: 30px;" >城市：</div>
                <div class="flex areaCode" style=" margin-left:0;">
                    <div v-for="(item, index) in cityList" :key="item.id"  >
                        <div @click="cityChang(index,item.areaCode)" class="item"  :class="{ selected: index == numberTwo}">{{item.areaName}}</div>
                    </div>
                </div> 
            </div>
            <!-- 县 -->
            <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px" v-if="isCountry"> 
                <div style="color: #999999;width: 90px;line-height: 30px;" >区县：</div>
                <div class="flex areaCode" style=" margin-left:0;">
                    <div v-for="(item, index) in areaList" :key="item.id"  >
                        <div @click="areaChang(index,item.areaCode)" class="item"  :class="{ selected: index == numberArea}">{{item.areaName}}</div>
                    </div>
                </div> 
             </div>  
         
         <div v-if="clickTabs!=5"> 
         <!-- 发布时间 -->
             <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px"  >
                <div style="color: #999999;width: 90px;line-height: 30px;">选择时间：</div>
                <div class="flex timeCode" >
                    <div v-for="(item, index) in timeList" :key="index"  >
                        <div @click="timeChang(index,item.dictionaryOptionId)" class="item"  
                        :class="{ selected: index == numberThree&&valueYear==''&&valueYearzdy==''}">
                        {{item.optionName}}</div>
                    </div>

                    <!-- <el-date-picker
                    v-model="valueYear"
                    type="year"
                    @change="hqYears"
                    :picker-options="pickerOptions1"
                    placeholder="往年">
                    </el-date-picker>   -->
                   <!-- <select class="selectitem" v-model="valueYear" @change="hqYears($event)" :class="{selected: valueYear!=='none'&&valueYearzdy==''}"> 
                        <option value="none">往年</option>
                        <option v-for="item in yearArr" :key="item.value"
                        :label="item.value"
                        :value="item.label">
                        </option>
                    </select>  -->

                    <div style="position: relative;">
                        <el-select class="selectitem" v-model="valueYear" placeholder="往年" @change="hqYears($event)" 
                        :class="{selected: valueYear!==''&&valueYearzdy==''}">
                            <el-option
                            v-for="(item, index) in yearArr" :key="index"
                            :label="item.value"
                            :value="item.value">
                            </el-option>
                        </el-select>
                        <img class="jtImgsx" src="../assets/images/jt.png"  />
                    </div> 

                    
                    <div style="position: relative;">
                        <el-date-picker 
                        v-model="valueYearzdy"
                        type="daterange"
                        range-separator=""
                        :class="{selected: valueYear==''&&valueYearzdy!==''}"
                        :picker-options="pickerOptions0"
                        @change="zdyYears"
                        start-placeholder="自定义"
                        end-placeholder="">
                        </el-date-picker>
                      <img :class="valueYearzdy==''?'jtImg':'jtImgs'" src="../assets/images/jt.png"  />
                    </div> 

                </div>
             </div>  
            </div>
           

            <!-- 信息类型 -->  
            <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px"  v-if="clickTabs==1">
                <div style="color: #999999;width: 90px;line-height: 30px;">信息类型：</div>
                <div class="flex timeCode" >
                    <div v-for="(item, index) in messageList" :key="index"  >
                        <div @click="messageChang(index,item.areaCode)" class="item"  :class="{ selected: index == number4}">{{item.areaName}}</div>
                    </div>
                </div>
            </div>
           
           <!-- 招标类型 -->
           <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px" v-else-if="clickTabs==2">
                <div style="color: #999999;width: 90px;line-height: 30px;">招标类型</div>
                <div class="flex timeCode" >
                    <div v-for="(item, index) in zbList" :key="index"  >
                        <div @click="zbChang(index,item.dictionaryOptionId,'1')" class="item"  :class="{ selected: index == number5}">{{item.optionName}}</div>
                    </div>
                </div>
            </div> 

             <!-- 中标类型 -->
              <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px" v-else-if="clickTabs==3">
                <div style="color: #999999;width: 90px;line-height: 30px;">中标类型</div>
                <div class="flex timeCode" >
                    <div v-for="(item, index) in zbList1" :key="index"  >
                        <div @click="zbChang(index,item.dictionaryOptionId,'2')" class="item"  :class="{ selected: index == number6}">{{item.optionName}}</div>
                    </div>
                </div>
              </div>  
            <!-- 审批阶段 -->
            <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px" v-else-if="clickTabs==4">
                <div style="color: #999999;width: 90px;line-height: 30px;">审批阶段</div>
                <div class="flex timeCode" >
                    <div v-for="(item, index) in zbList2" :key="index"  >
                        <div @click="zbChang(index,item.dictionaryOptionId,'3')" class="item"  :class="{ selected: index == number7}">{{item.optionName}}</div>
                    </div>
                </div>
            </div> 
           

            <!-- 企业角色 -->
            <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px" v-if="clickTabs==5">
                <div style="color: #999999;width: 90px;line-height: 30px;">企业角色</div>
                <div class="flex timeCode" >
                    <div v-for="(item, index) in zbList3" :key="index"  >
                        <div @click="zbChang(index,item.dictionaryOptionId,'4')" class="item"  :class="{ selected: index == number8}">{{item.optionName}}</div>
                    </div>
                </div>
            </div>  


            <!-- 更多筛选 -->
            <div class="flex chooseArea" style="align-items: center;margin-top:20px">
                <div style="color: #999999;width: 90px;line-height: 30px;">更多筛选：</div>
                <div class="flex timeCode" >

                    <div style="position: relative;margin-right: 9px;" v-if="clickTabs==5">
                     <el-select v-model="sxvalue9" placeholder="注册资本" @change="sxOne($event,'9')">
                        <el-option
                        v-for="(item, index) in sxList9" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId">
                        </el-option>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                  </div>
                  <div style="position: relative;margin-right: 9px;" v-if="clickTabs==5">
                     <el-select v-model="sxvalue10" placeholder="成立年限" @change="sxOne($event,'10')">
                        <el-option
                        v-for="(item, index) in sxList10" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId">
                        </el-option>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                  </div>




                  <div style="position: relative;margin-right: 9px;" v-if="clickTabs!==4">
                     <el-select v-model="sxvalue1" placeholder="行业类型" @change="sxOne($event,'1')"  >
                        <el-option
                        v-for="(item, index) in sxList1" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId">
                        </el-option>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                  </div>

                  <div style="position: relative;margin-right: 9px;" v-if="clickTabs!=4&&clickTabs!=5">
                     <el-select v-model="sxvalue2" placeholder="招采类型" @change="sxOne($event,'2')">
                        <el-option
                        v-for="(item, index) in sxList2" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId">
                        </el-option>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                  </div>

                  <div style="position: relative;margin-right: 9px;" v-if="clickTabs!=4&&clickTabs!=5">
                     <el-select v-model="sxvalue3" placeholder="招采单位" @change="sxOne($event,'3')">
                        <el-option
                        v-for="(item, index) in sxList3" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId">
                        </el-option>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                  </div>

                  <div style="position: relative;margin-right: 9px;" v-if="clickTabs==1||clickTabs==2">
                     <el-select v-model="sxvalue4" placeholder="预算金额" @change="sxOne($event,'4')">
                        <el-option
                        v-for="(item, index) in sxList4" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId">
                        </el-option>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                  </div>

                  <div style="position: relative;margin-right: 9px;" v-if="clickTabs!=4&&clickTabs!=5">
                     <el-select v-model="sxvalue5" placeholder="代理单位" @change="sxOne($event,'5')">
                        <el-option
                        v-for="(item, index) in sxList5" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId">
                        </el-option>
                    </el-select> 
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                  </div>

                  <div style="position: relative;margin-right: 9px;" v-if="clickTabs==1||clickTabs==3">
                     <el-select v-model="sxvalue6" placeholder="中标金额" @change="sxOne($event,'6')">
                        <el-option
                        v-for="(item, index) in sxList6" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId">
                        </el-option>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                  </div>



                 <!-- 省份地区 -->
                 <div style="position: relative;margin-right: 9px;" v-if="clickTabs==5">
                     <el-select v-model="sxvalue11" placeholder="证书信息" @change="sxOne($event,'11')">
                        <el-option
                        v-for="(item, index) in sxList11" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId">
                        </el-option>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                  </div>

                  <div style="position: relative;margin-right: 9px;" v-if="clickTabs==5">
                     <el-select v-model="sxvalue12" placeholder="建筑资质" @change="sxOne($event,'12')">
                        <el-option
                        v-for="(item, index) in sxList12" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId">
                        </el-option>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                  </div>




                  <div style="position: relative;margin-right: 9px;"  v-if="clickTabs!=4">
                     <el-select v-model="sxvalue7" placeholder="联系方式" @change="sxOne($event,'7')">
                        <el-option
                        v-for="(item, index) in sxList7" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId">
                        </el-option>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                  </div>
                  <div style="position: relative;margin-right: 9px;" v-if="clickTabs!=5">
                     <el-select v-model="sxvalue8" placeholder="附件" @change="sxOne($event,'8')">
                        <el-option
                        v-for="(item, index) in sxList8" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId">
                        </el-option>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                  </div>

                </div>

            </div>
       

    </div>
</template>
<script>
    export default { 
        props:['clickTabs'],
        data() {
            return {
                numberOne: 0,
                numberTwo:'',
                numberArea:'',
                numberThree:'',
                number4:0,
                qyClass:0,
                provinceCode:'',
                cityCode:'',
                areaCode:'',
                proviceList:[
                  
                ],
                cityList:[
                   
                ],
                isCity:false,
                areaList:[
                   
                ],
                isCountry:false,
                timeCode:'',
                timeList:[],
                valueYear: '',
                messageList:[
                    {areaCode:'',areaName:'全部'},
                    {areaCode:'1',areaName:'招标公告'},
                    {areaCode:'2',areaName:'中标结果'},
                    // {areaCode:'3',areaName:'拟建项目'},
                ],
                messageCode:'',
                sxList1:[],
                sxvalue1: '',
                sxList2: [ ],
                sxvalue2: '',
                sxList3: [],
                sxvalue3: '',
                sxList4: [ ],
                sxvalue4: '',
                sxList5: [ ],
                sxvalue5: '',
                sxList6: [],
                sxvalue6: '',
                sxList7: [ ],
                sxvalue7: '',
                sxList8: [ ],
                sxvalue8: '',

                sxList9: [],
                sxvalue9: '',
                sxList10: [ ],
                sxvalue10: '',
                sxList11: [ ],
                sxvalue11: '',
                sxList12: [ ],
                sxvalue12: '',

                valueYearzdy:'',
                startTime:'',
                endTime:'',
                yearArr:[],
                pickerOptions0: {
                    disabledDate(time) {
                        return time.getTime() > Date.now() - 8.64e6;//如果没有后面的-8.64e6就是不可以选择今天的
                     }
                }, 
                aa:'',
                number5:0,
                number6:0,
                number7:0,
                number8:0,
                zbList:[],
                zbvalue:'',
                zbList1:[],
                zbvalue1:'',
                zbList2:[],
                zbvalue2:'',
                zbList3:[],
                zbvalue3:'',
                zdyTime:''
              };
        },
       
        mounted(){
            //获取当前年份的前4年
            let now   = new Date();
            let yearn  = now.getFullYear();
            let yearArrs=[]
            yearArrs.push(yearn-4,yearn-3,yearn-2,yearn-1)
            this.yearArr=[
                {label:1,value:yearArrs[0]},
                {label:2,value:yearArrs[1]},
                {label:3,value:yearArrs[2]},
                {label:4,value:yearArrs[3]},
            ]

         
          if(this.clickTabs==''){
            this.clickTabs='1'
          }
   
            this.sxList('pub_date')  //发布时间
            this.sxList('industry_category')  //更多筛选-发布类型
            this.sxList('bidding_procurement_type')  //招采类型
            this.sxList('bidding_unit')   //招采单位
            this.sxList('budget_amount')  //预算金额
            this.sxList('agency')  
            this.sxList('bid_winning_amount')  
            this.sxList('contact_information')  
            this.sxList('enclosure')  
            this.sxList('bidding_type')     //招标类型
            this.sxList('bid_winning_type') 
            this.sxList('approval_stage') 
            this.sxList('enterprise_role')

            this.sxList('registered_capital') 
            this.sxList('establishment_period')
            this.sxList('certificate_information') 
            this.sxList('building_qualification')
         
           this.provice('')

          this.searchEvent()
        },
       
        
        methods: {
            provice(code){
                this.$axios.post('/webInterface/queryAreaList?parentAreaCode='+code).then(res => {
                    //console.log(res.data) 
                    if (res.data) {
                        let aa= {areaCode:'',areaName:'全部'}
                     if(code==''){
                       this.proviceList=res.data.localareaList
                       this.proviceList.unshift(aa)
                    }else{
                        this.cityList=res.data.localareaList
                        this.cityList.unshift(aa)
                    } 
                    }
                }).catch(err => {
                    console.log('请求失败')
                })
            },
            provices(code){
                this.$axios.post('/webInterface/queryAreaList?parentAreaCode='+code).then(res => {
                    //console.log(res.data) 
                    if (res.data) {
                        let aa= {areaCode:'',areaName:'全部'}
                       this.areaList=res.data.localareaList
                       this.areaList.unshift(aa)

                    }
                }).catch(err => {
                    console.log('请求失败')
                })
            },
            sxList(text){
                this.$axios.post('/webInterface/querySysRefList?optionCode='+text).then(response => {
                   // console.log(response)
                    if (response.data) {
                           let aa= {dictionaryOptionId:'',optionName:'全部'}
                        if(text=='industry_category'){
                            this.sxList1=response.data.sysRefListList  //行业分类
                        }else if(text=='pub_date'){
                            this.timeList=response.data.sysRefListList   //时间选择
                            this.timeList.unshift(aa)
                            this.timeCode=this.timeList[5].dictionaryOptionId
                            this.numberThree=5 
                        }else if(text=='bidding_procurement_type'){
                            this.sxList2=response.data.sysRefListList //招采分类
                        }else if(text=='bidding_unit'){
                            this.sxList3=response.data.sysRefListList   //招采单位
                        }else if(text=='budget_amount'){
                            this.sxList4=response.data.sysRefListList   //招采单位
                        }else if(text=='agency'){
                            this.sxList5=response.data.sysRefListList   //招采单位
                        }else if(text=='bid_winning_amount'){
                            this.sxList6=response.data.sysRefListList   //招采单位
                        }else if(text=='contact_information'){
                            this.sxList7=response.data.sysRefListList   //招采单位
                        }else if(text=='enclosure'){
                            this.sxList8=response.data.sysRefListList   //招采单位
                        }else if(text=='bidding_type'){
                            
                            this.zbList=response.data.sysRefListList   //招标类型
                            this.zbList.unshift(aa)
                        }else if(text=='bid_winning_type'){
                            this.zbList1=response.data.sysRefListList   //中标类型
                            this.zbList1.unshift(aa)
                        }else if(text=='approval_stage'){
                            this.zbList2=response.data.sysRefListList   //招标类型
                            this.zbList2.unshift(aa)
                        }else if(text=='enterprise_role'){
                            this.zbList3=response.data.sysRefListList   //招标类型
                        }

                        else if(text=='registered_capital'){
                            this.sxList9=response.data.sysRefListList   //招标类型
                        }else if(text=='establishment_period'){
                            this.sxList10=response.data.sysRefListList   //招标类型
                        }else if(text=='certificate_information'){
                            this.sxList11=response.data.sysRefListList   //招标类型
                        }else if(text=='building_qualification'){
                            this.sxList12=response.data.sysRefListList   //招标类型
                        }
                        
                        
                    }
                }).catch(err => {
                    console.log('请求失败')
                })
            },
           //点击省
           proviceChang(index,areaCode) {
                this.numberOne = index;
                this.provinceCode=areaCode
               this.provice(areaCode)
               this.areaList=[]
               this.isCountry=false
                if(index!=0){
                    this.isCity=true 
                }else{
                    this.isCity=false
                    this.isCountry=false
                }
            },
            //点击市
            cityChang(index,areaCode) {
                this.numberTwo = index;
                this.cityCode=areaCode
                this.provices(areaCode)
                if(index!=0){
                    this.isCountry=true
                }else{
                    this.isCountry=false
                }
            },
            areaChang(index,areaCode) {
                this.numberArea = index;
                this.areaCode=areaCode
                //console.log(areaCode)
            },
            hqYears(e){
                this.valueYear=e
                this.valueYearzdy=''  //自定义时间清空
                this.zdyTime=''   
                this.startTime=''   //自定义开始时间清空
                this.endTime=''    //自定义结束时间清空
                this.timeCode=''   //时间清空
              //  this.searchEvent() 
            },
            zdyYears(){
               this.startTime=this.timeZh(this.valueYearzdy[0])  //开始时间
               this.endTime=this.timeZh(this.valueYearzdy[1])   //结束时间
               this.zdyTime=this.startTime+'-'+this.endTime
               //this.wnTime='' 
               this.valueYear=''  //往年
               this.timeCode=''  //时间
              // console.log(this.startTime+this.endTime)
              //this.searchEvent() 
            },
           
            timeZh(time){
                var date = new Date(time)
                var y = date.getFullYear()
                var m = date.getMonth() + 1
                m = m < 10 ? ('0' + m) : m
                var d = date.getDate()
                d = d < 10 ? ('0' + d) : d
                const times = y + '-' + m + '-' + d
                return times
            },
            timeChang(index,areaCode) {
                this.numberThree = index;
                this.timeCode=areaCode  //时间
                //this.wnTime='' 
                this.valueYear=''  //往年
                this.valueYearzdy=''  //自定义
                this.currentPage=1
                this.searchEvent() 
            },
            messageChang(index,areaCode) {
                this.number4 = index;
                this.messageCode=areaCode
               // console.log(areaCode)
               
            },
            zbChang(index,areaCode,flag){
              if(flag==1){
                this.zbvalue=areaCode   //招标类型
                this.number5=index
              }else if(flag==2){
                this.zbvalue1=areaCode   //中标类型
                this.number6=index
              }else if(flag==3){
                this.zbvalue2=areaCode  //审批阶段
                this.number7=index
              }else if(flag==4){
                this.zbvalue3=areaCode   //企业角色
                this.number8=index
              }
              this.searchEvent()
            },
            sxOne(e,flag){
                if(flag==1){
                    this.sxvalue1=e
                }else if(flag==2){
                    this.sxvalue2=e
                }else if(flag==3){
                    this.sxvalue3=e
                }else if(flag==4){
                    this.sxvalue4=e
                }else if(flag==5){
                    this.sxvalue5=e
                }else if(flag==6){
                    this.sxvalue6=e
                }else if(flag==7){
                    this.sxvalue7=e
                }else if(flag==8){
                    this.sxvalue8=e
                }
                else if(flag==9){
                    this.sxvalue9=e
                }else if(flag==10){
                    this.sxvalue10=e
                }else if(flag==11){
                    this.sxvalue11=e
                }else if(flag==12){
                    this.sxvalue12=e
                }
                this.searchEvent()
             },
            searchEvent(){
                // console.log(this.provinceCode)  //省
                // console.log(this.cityCode)  //市
                // console.log(this.areaCode)  //区
                // console.log(this.timeCode)   //选择时间
                // console.log(this.valueYear)  //往年
                // console.log(this.zdyTime)  //自定义时间
                // console.log(this.messageCode)  //信息类型
                // console.log(this.sxvalue1)      //行业分类 
                // console.log(this.sxvalue2)      //招采类型 
                // console.log(this.sxvalue3)      //招采单位 
                // console.log(this.sxvalue4)      //预算金额 
                // console.log(this.sxvalue5)      //代理单位 
                // console.log(this.sxvalue6)      //中标金额 
                // console.log(this.sxvalue7)      //联系方式 
                // console.log(this.sxvalue8)      //附件 
                // console.log(this.sxvalue9)      //注册资本 
                // console.log(this.sxvalue10)      //成立年限 
                // console.log(this.sxvalue11)      //证书信息 
                // console.log(this.sxvalue12)      //建筑资质
                
                let addressCode=''
                if(this.cityCode==''){
                    addressCode= this.provinceCode
                }else if(this.areaCode==''){
                    addressCode= this.cityCode
                }else{
                    addressCode=this.areaCode
                }

                this.$emit('child-event',addressCode,this.timeCode,this.messageCode,this.sxvalue1,this.sxvalue2,this.sxvalue3,
                this.sxvalue4,this.sxvalue5,this.sxvalue6,this.sxvalue7,this.sxvalue8,this.sxvalue9,this.sxvalue10,this.sxvalue11,
                this.sxvalue12,this.zbvalue,this.zbvalue1,this.zbvalue2,this.zbvalue3);

            } 

        }
    }
</script>
<style scoped lang="less">

  /* tab切换 */
.chooseArea{
  font-size: 16px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #222222;
}
.areaCode,.timeCode{
  flex-wrap: wrap;
  cursor: pointer;
  width: 1050px;
}
.areaCode .item,.timeCode .item{
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-right: 8px;
  padding: 0 12px;
  margin-bottom: 10px;
}
.selected{
  background: #EFF7FF;
  border-radius: 4px;
  font-weight: 400;
  color: #0078FF; 
 
}
.tabCon{
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #222222;
  margin-top: 20px;
}
.conRows{
  border-bottom: 1px solid #F3F3F3;
  padding: 20px 0;
}
input[name="color"]::-webkit-input-placeholder { /* WebKit browsers */
color: red;
font-size:15px;
}
select{
    border: none;
    outline: none;
    margin-right: 22px;
    font-size:16px
}

// /deep/.el-input {
//     position: relative;
//     font-size: 16px;
//     height: 32px;
//     width:100px;

//     .el-input__inner {
//     height: 33px;
//     line-height: 30px;
//     border: none !important;
//     outline: none !important;
//     padding-left: 15px !important;
//     padding-right: 0px !important; 
//     }
//     .el-input__inner::input-placeholder{
//         color:#222;
//     }
//     .el-input__inner::-webkit-input-placeholder { /* WebKit browsers */ 
//         color: #222; 
//         } 
//     .el-input__prefix{
//         display:none
//     }
//     .el-input__icon {
//     line-height: 32px !important;
//     width: 16px !important;
//    }
// }
/deep/.el-cascader{
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    .el-input{
        width: 108px !important;
        font-size: 16px !important;
    .el-input__inner{
        height: 30px !important;
        line-height: 30px !important;
        border: none;
        outline: none;
        padding-left: 12px;
        padding-right: 11px;
    }
    .el-input__inner::input-placeholder{
            color:#222;
        }
        .el-input__inner::-webkit-input-placeholder { /* WebKit browsers */ 
          color: #222; 
        }
    .el-input__suffix{
        display:none
    }
  }
}
/deep/.el-select{
    height: 30px;
    line-height: 30px;
    .el-input{
        width: 108px !important;
        font-size: 16px !important;
        .el-input__inner{
            height: 30px !important;
            line-height: 30px !important;
            border: none;
            outline: none;
            padding-left: 12px;
             padding-right: 11px;
        }
        .el-input__inner::input-placeholder{
            color:#222;
        }
        .el-input__inner::-webkit-input-placeholder { /* WebKit browsers */ 
          color: #222; 
        }
        .el-input__suffix{
            display:none
        }
    }
}
/deep/ .el-date-editor {
    height: 33px;
    line-height: 30px;
    border: none !important;
    outline: none !important;
    padding-left: 10px !important;
    width: 210px;
    padding-right: 12px;
     .el-range-separator{
        display: none;
     }
     .el-input__icon{
       display: none;
    } 
    .el-range-input{
      width: 90px !important; 
      font-size: 16px !important;
      text-align: left;
      }
      .el-range-input::input-placeholder{
        color:#222;
     }
    .el-range-input::-webkit-input-placeholder { /* WebKit browsers */ 
        color: #222; 
     }
    }
    .selectitem{
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin-bottom: 10px;
        padding:0
    }
    /deep/.selected{
        .el-range-input{
            background: none;
        border-radius: 4px;
        font-weight: 400;
        color: #0078FF;
      }
    }
    .jtImg{
        width: 11px;height: 12px;position: absolute;right: 132px;top: 9px;
    }
    .jtImgs{
        width: 11px;height: 12px;position: absolute;right:8px;top: 9px;
    }
    .el-popper{
        margin-top:0
    }
    .jtImgsx{
        width: 11px;height: 12px;position: absolute;right:14px;top: 9px;
    }
    /deep/.selectitem {
       .el-input{
        width: 76px !important;
        .el-input__inner{
            padding-right: 10px !important;
            padding-left: 11px;
            background: none;
            color: #0078FF;
        }
       }
    }
</style>