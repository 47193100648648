import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
// 创建一个对象 vuex实例
const store = new Vuex.Store({
	state: {
		userInfo:{},
		token:'',
        phoneNo:'',
	 },
	mutations: {  //管理员，如果外面的组件想要操作，state中的值，需要这个方法。
		// handleUserName(state,c){  //保存token
		// 	state.phoneNo = c
		// }, 
		
		// get_userInfo(state,ccc){
		// 	state.userInfo=ccc
		// },
		// get_token(state,token){
		// 	state.token=token
		// }, 
		// get_flag(state,a){ 
		// 	state.tcflag=a
		// },
	},
    actions: {}
})
// 导出这个实例
export default store
