<template>
    <div>
        <common-header @change="amountChange($event)" :message="beiji"></common-header>

        <div class="container ">
            <div class="title">《用于协议》</div>
            <div style="max-height: 100%;overflow-y: auto;">
                <div>尊敬的用户,你好：</div>
                <div style="text-indent:2em">请您在使用本产品标圈圈（微信小程序端）之前，请务必仔细阅读并理解《用户协议》（以下简称“本协议”）中规定的多项权利和限制。</div>
                <div style="text-indent:2em">我们一向尊重并会严格保护用户在使用本产品时的合法权益（包括用户隐私、用户数据等）不受到任何侵犯。</div>
                <div style="text-indent:2em">
                    本协议（包括本文最后部分的隐私政策）是用户（包括通过各种合法途径获取到本产品的自然人、法人或其他组织机构，以下简称“用户”或“您”）与我们之间针对本产品相关事项最终的、完整的且排他的协议，并取代、合并之前的当事人之间关于上述事项的讨论和协议。
                </div>
                <div style="text-indent:2em">
                    本协议将对用户使用本产品的行为产生法律约束力，您已承诺和保证有权利和能力订立本协议。用户开始使用本产品将视为已经接受本协议，请认真阅读并理解本协议中各种条款，包括免除和限制我们的免责条款和对用户的权利限制（未成年人审阅时应由法定监护人陪同）。
                </div>
                <div style="text-indent:2em;font-weight: bold;">第一条 标圈圈服务简介</div>
                <div style="text-indent:2em">标圈圈向其会员用户提供包括招标、采购、项目、拍卖、招商等服务在内的互联网信息服务，标圈圈所提供的相关服务说明详见标圈圈微信小程序相关产品及服务介绍。
                </div>
                <div style="text-indent:2em;font-weight: bold;">第二条 用户身份</div>
                <div style="text-indent:2em">1.
                    依据中华人民共和国相关法律，凡具有民事权利能力和民事行为能力，依法独立享有民事权利和承担民事义务的企业、组织和个人，皆有资格申请成为标圈圈的用户。用户在此保证所填写的用户信息是真实、准确、完整、及时的，并且没有任何引人误解或者虚假的陈述，且保证标圈圈可以通过用户所填写的联系方式与用户取得联系。
                </div>
                <div style="text-indent:2em">2.
                    用户应根据标圈圈对于相关服务的要求及时提供相应的身份证明等资料，否则标圈圈有权拒绝向该会员或用户提供相关服务。用户承诺将及时更新其用户信息以维持该等信息的有效性。</div>
                <div style="text-indent:2em">3. 用户注册成功后将得到一个用户名和密码，用户凭用户名和密码享受标圈圈向其会员用户提供的商品和服务。</div>
                <div style="text-indent:2em">4. 用户将对用户名和密码安全负全部责任，并且用户对以其用户名进行的所有活动和事件负全责。用户有权根据标圈圈规定的程序修改或者重置密码。</div>
                <div style="text-indent:2em">5. 用户若发现任何非法使用用户账号或存在安全漏洞的情况，请立即通告标圈圈。</div>
                <div style="text-indent:2em;font-weight: bold;">第三条 信息使用</div>
                <div style="text-indent:2em">1. 未经标圈圈书面许可，用户不得利用标圈圈所提供的服务以及获得的信息出售、转售或用于其他商业用途。标圈圈保留以下权利：</div>
                <div style="text-indent:2.5em">1.1如发现有用户利用标圈圈所提供的服务和信息，用于商业用途并与标圈圈形成竞争或损害标圈圈的利益，在证据确凿的前提下，单方面终止其使用资格。</div>
                <div style="text-indent:2.5em">1.2 如认定申请注册的用户与标圈圈形成同业竞争并对标圈圈的正常运营造成损害时，拒绝其申请和注册。</div>
                <div style="text-indent:2em">2.
                    未经标圈圈书面许可，用户不得将标圈圈的账号、密码、相关服务以及获得的信息出售、转售、赠与、转借、租供给第三方或用于其它商业性用途。否则，由此造成的一切后果和责任由用户承担；同时，标圈圈有权单方面中止为其提供的服务。
                </div>
                <div style="text-indent:2em">3.
                    禁止使用机器人、蜘蛛软件等任何已知或未知的技术、方法和软件来收集本网站的内容、干扰本网站及使用者的正常工作和活动，如发生以上行为，标圈圈有权单方面停止服务。</div>
                <div style="text-indent:2em">4. 用户应对其在标圈圈上发布的任何信息承担全部责任。</div>
                <div style="text-indent:2em">5.
                    如果用户以恶意破解等非法手段将标圈圈提供的服务内容与标圈圈平台分离的行为（例如毫无根据地恶意修改标圈圈提供的信息），均不属于本协议中约定提供的服务。由此引起的一切法律后果由行为人负责，标圈圈将依法追究行为人的法律责任。
                </div>

                <div style="text-indent:2em;font-weight: bold;">第四条 服务条款的修改和服务体系修订</div>
                <div style="text-indent:2em">
                    标圈圈有权在必要时修改服务条款，标圈圈服务条款一旦发生变动，将会在重要页面上提示修改内容。如果不同意所改动的内容，用户可以主动取消获得的网络服务。如果用户继续享用标圈圈络服务，则视为接受服务条款的变动。标圈圈保留随时修改其服务体系和价格而不需通知用户的权利，标圈圈修改其服务和价格体系之前，用户就具体服务与标圈圈达成协议并已按照约定交纳费用的，标圈圈将按照已达成的协议执行至已交纳费用的服务期期满。
                </div>

                <div style="text-indent:2em;font-weight: bold;">第五条 用户的权利和义务</div>
                <div style="text-indent:2em">1. 用户有权利拥有自己在标圈圈的用户名和密码并有权利使用自己的用户名和密码随时登录标圈圈网站的会员专区。</div>
                <div style="text-indent:2em">2. 用户有权利享受标圈圈提供的互联网技术和信息服务，并有权利在接受标圈圈提供的服务时，获得标圈圈的技术支持、咨询等服务，服务内容详见标圈圈相关产品介绍。
                </div>
                <div style="text-indent:2em">3. 用户保证不会利用技术或其他手段破坏及扰乱标圈圈站以及标圈圈其他客户的网站。</div>
                <div style="text-indent:2em">4.
                    用户应尊重标圈圈及其他第三方的知识产权和其他合法权利，并保证在发生上述事件时尽力保护标圈圈及其股东、雇员、合作伙伴等免于因该等事件受到影响或损失；标圈圈保留用户侵犯标圈圈知识产权时，终止向该用户提供服务并不退还任何款项的权利。
                </div>
                <div style="text-indent:2em">5.
                    对由于用户向标圈圈提供的联络方式有误以及用户用于接受标圈圈邮件的电子邮箱安全性、稳定性不佳而导致的一切后果，用户应自行承担责任，包括但不限于因用户未能及时收到标圈圈的相关通知而导致的后果和损失。</div>
                <div style="text-indent:2em">6.
                    用户保证其使用标圈圈服务时将遵从国家、地方法律法规、行业惯例和社会公共道德，不会利用标圈圈提供的服务进行存储、发布、传播如下信息和内容：违反国家法律法规政策的任何内容（信息）；违反国家规定的政治宣传和/或新闻信息；涉及国家秘密和/或安全的信息；封建迷信和/或淫秽、色情、下流的信息或教唆犯罪的信息；博彩有奖、赌博游戏；违反国家民族和宗教政策的信息；妨碍互联网运行安全的信息；侵害他人合法权益的信息和/或其他有损于社会秩序、社会治安、公共道德的信息或内容。用户同时承诺不得为他人发布上述不符合国家规定和/或本服务条款约定的信息内容提供任何便利，包括但不限于设置URL、BANNER链接等。用户承认标圈圈有权在用户违反上述条款时，有权终止向用户提供服务并不予退还任何款项，因用户上述行为给标圈圈造成损失的，用户应予赔偿。
                </div>

                <div style="text-indent:2em;font-weight: bold;">第六条 标圈圈的权利和义务</div>
                <div style="text-indent:2em">1 标圈圈应根据用户选择的服务以及交纳款项的情况向用户提供合格的网络技术和信息服务。</div>
                <div style="text-indent:2em">2 标圈圈承诺对用户资料采取对外保密措施，不向第三方披露用户资料，不授权第三方使用用户资料，除非：</div>
                <div style="text-indent:2.5em">2.1 依据本协议条款或者用户与标圈圈之间其他服务协议、合同、在线条款等规定可以提供；</div>
                <div style="text-indent:2.5em">2.2 依据法律法规的规定应当提供；</div>
                <div style="text-indent:2.5em">
                    行政、司法等有权部门要求标圈圈提供；用户同意标圈圈向第三方提供；标圈圈解决举报事件、提起诉讼而提交的；标圈圈为防止严重违法行为或涉嫌犯罪行为发生而采取必要合理行动所必须提交的；标圈圈为向用户提供产品、服务、信息而向第三方提供的，包括标圈圈通过第三方的技术及服务向用户提供产品、服务、信息的情况。
                </div>

                <div style="text-indent:2em">3 标圈圈有权使用用户资料，有权利对用户进行审查并决定是否接受用户成为标圈圈会员或是否与用户进行某一交易。</div>
                <div style="text-indent:2em">4
                    标圈圈保留在用户违反国家、地方法律法规规定或违反本在线注册条款的情况下终止为用户提供服务并终止用户账号的权利，并且在任何情况下，标圈圈对任何间接、偶然、特殊及继起的损害不负责任。</div>

                <div style="text-indent:2em;font-weight: bold;">第七条 服务的终止</div>
                <div style="text-indent:2em">1. 用户有权随时申请终止其会员资格或其在标圈圈申请的一项或多项服务，但已交纳款项不得要求退还。</div>
                <div style="text-indent:2em">2. 标圈圈有权根据实际情况决定取消为用户提供的一项或多项服务，但应退还用户为该服务所交纳款项的剩余部分，除此之外，标圈圈不承担任何责任。</div>

                <div style="text-indent:2em;font-weight: bold;">第八条 争议解决及法律适用</div>
                <div style="text-indent:2em">1 因本服务条款有关的一切争议，双方当事人应通过友好协商方式解决。如果协商未成，双方同意向标圈圈主要经营地的人民法院起诉。</div>
                <div style="text-indent:2em">2 本注册条款的效力、解释、履行和争议的解决均适用中华人民共和国法律法规和计算机、互联网行业的规范。</div>

                <div style="text-indent:2em;font-weight: bold;">第九条 不可抗力</div>
                <div style="text-indent:2em">1 因不可抗力或者其他意外事件，使得本条款履行不可能、不必要或者无意义的，遭受不可抗力、意外事件的一方不承担责任。</div>
                <div style="text-indent:2em">2
                    不可抗力、意外事件是指不能预见、不能克服并不能避免且对一方或双方当事人造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。</div>
                <div style="text-indent:2em">3 用户同意鉴于互联网的特殊性，黑客攻击、互联网连通中断或者系统故障等属于不可抗力，由此给用户或者第三方造成的损失不应由标圈圈承担。</div>

                <div style="text-indent:2em;font-weight: bold;">第十条 隐私权政策</div>
                <div style="text-indent:2em">标圈圈尊重并保护所有使用标圈圈服务用户的个人隐私。为了给您提供更加准确、更个性化的服务，标圈圈会按照本隐私权政策的规定使用和披露您的个人信息。
                    但标圈圈将以高度的勤勉、审慎的态度对待您的个人信息。除非有法律和政府的强制规定，在事先未征得您许可或者授权的情况下，标圈圈不会将您的信息对外披露或者向第三方提供。标圈圈会不时更新本隐私权政策。您在同意标圈圈用户协议之时，即视为您已经同意本隐私权政策全部内容。
                    本隐私权政策属于标圈圈用户协议不可分割的一部分。</div>

                <div style="text-indent:2em;font-weight: bold;">第十一条 免责声明</div>
                <div style="text-indent:2em">标圈圈在此特别声明对如下事宜不承担任何法律责任：</div>
                <div style="text-indent:2em">1. 标圈圈在此声明，对您使用网站、与本网站相关的任何内容、服务或其它链接至本网站的站点、内容均不作直接、间接、法定、约定的保证。</div>
                <div style="text-indent:2em">2.
                    无论在任何原因下（包括但不限于疏忽原因），对您或任何人通过使用本网站上的信息或由本网站链接的信息，或其他与本网站链接的网站信息所导致的损失或损害（包括直接、间接、特别或后果性的损失或损害，例如收入或利润之损失，电脑系统之损坏或数据丢失等后果），责任均由使用者自行承担（包括但不限于疏忽责任）
                </div>
                <div style="text-indent:2em">3. 使用者对本网站的使用即表明同意承担浏览本网站的全部风险，对使用者在本网站存取资料所导致的任何直接、相关的、后果性的、间接的或金钱上的损失不承担任何责任。
                </div>
                <div style="text-indent:2em">4.
                标书代购免责条款，标圈圈本着诚信、安全和及时的原则根据客户的需求提供标书代购服务，因客户提交资料不全、描述不准确的原因导致标圈圈无法完成标书代购，标圈圈及其法人实体、委托执行机构不承担任何责任；
                因业主所发布的项目变动、时间更改、标书属性（包括但不限于时间、内容、范围等）变更及业主本身所受法律法规、自身资质等问题（包括但不限于），视为不可抗力的一部分，因不可抗力导致标书代购不成功，标圈圈及其法人实体、委托执行机构不承担任何责任；
            </div>
            <div style="text-indent:2em">用户在此再次保证已经完全阅读并理解了上述会员注册条款并自愿正式进入标圈圈会员在线注册程序，接受上述所有条款的约束。</div>
        </div>
    </div>

    <common-foot></common-foot>
    <common-active :showTk="beiji" :showActivity="flagActivity" @change="amountChanges($event)"></common-active>

</div></template>

<script>
import CommonHeader from '@/components/CommonHeader.vue';
import CommonFoot from '@/components/CommonFoot.vue';
import CommonActive from '@/components/CommonActive.vue';

export default {
    inject: ['reload'],
    components: {
        CommonHeader,
        CommonFoot,
        CommonActive
    },
    data() {
        return {
            beiji: false,
            flagActivity: '',
        };
    },

    methods: {
        amountChange(e) {
            this.beiji = e
        },
        async amountChanges(e) {
            this.beiji = e
            this.flagActivity = '1'
            let that = this
            setTimeout(function () {
                if (that.$route.query.str != '' && that.$route.query.str != undefined) {
                    that.str = that.$route.query.str
                }

                if (that.beiji == false) {
                    if (that.str == 1) {
                        that.activeIndex = 4
                    }
                }
            }, 1000)

        },

    }
}
</script>
<style scoped lang="less">.container {
    width: 1160px;
    background: #FFFFFF;
    border-radius: 5px;
    margin: 0 auto;
    padding: 17px 20px;
    margin-top: 20px;
}

.title {
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    margin-bottom: 6px;
}</style>