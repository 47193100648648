<template>
    <div>
        <common-header @change="amountChange($event)" :message="beiji"></common-header>   
        
      
        <div class="halfC" style="margin-top: 15px;">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <div class="flex" style="padding-bottom: 20px;border-bottom: 1px solid #F3F3F3;margin-bottom: 22px;width:660px">
                    <div class="ju-tinput">
                        <input type="text" placeholder="请输入项目名称、关键词等" class="inputClass" v-model="searchCon">
                        <img v-if="searchCon!=''&&searchCon!=null" src="../assets/images/guanbi.png" class="closeImg"  @click="del" />
                        <div class="searchBtn" @click="searchClick">搜索</div>
                    </div>
                </div> 

                <el-tab-pane :label="item.label" :name="item.name" v-for="(item,index) in tabsArr" :key="index" >
                   <div v-if="isShow">
                   <div class="flex chooseArea" style="align-items: flex-start;">
                    <div style="color: #999999;width: 90px;line-height: 30px;">选择地区：</div>
                    <!-- 省 -->
                    <div class="flex areaCode " :style="{ 'max-height': status ? textHeight : '' }"
                                        :class="{ statusText: status }"
                                        ref="descs">
                            <div v-for="(item, index) in proviceList" :key="index" style="display: inline-block;">
                                <div  @click="proviceChang(index,item.areaCode)"   class="item" :class="{ selected: index == numberOne}">
                                    {{item.areaName}}
                                </div>
                            </div>
                    </div>
                   
                    <div
                        v-if="idShowText"
                        @click="showStaus"
                        :class="{ openSpan: status }"
                        class="openClose">{{ status ? "展开" : "收起" }}
                        <img v-if="status==true" class="jtImgsxsb" src="../assets/images/xiajt.png"  />
                        <img v-else class="jtImgsxsb" src="../assets/images/shangjt1.png"  />
                    </div>
                    </div>           
                    <!-- 市 -->
                    <div class="flex chooseArea" style="align-items: flex-start;margin-top:10px" v-if="isCity"> 
                    <div style="color: #999999;width: 90px;line-height: 30px;" >城市：</div>
                        <div class="flex areaCode" style=" margin-left:0;">
                            <div v-for="(item, index) in cityList" :key="item.id"  >
                                <div @click="cityChang(index,item.areaCode)" class="item"  :class="{ selected: index == numberTwo}">{{item.areaName}}</div>
                            </div>
                        </div> 
                    </div>
                    <!-- 县 -->
                    <div class="flex chooseArea" style="align-items: flex-start;margin-top:10px" v-if="isCountry"> 
                        <div style="color: #999999;width: 90px;line-height: 30px;" >区县：</div>
                        <div class="flex areaCode" style=" margin-left:0;">
                            <div v-for="(item, index) in areaList" :key="item.id"  >
                                <div @click="areaChang(index,item.areaCode)" class="item"  :class="{ selected: index == numberArea}">{{item.areaName}}</div>
                            </div>
                        </div> 
                    </div>  
           

         
         <div v-if="clickTabs!=5"> 
         <!-- 发布时间 -->
             <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px"  >
                <div style="color: #999999;width: 90px;line-height: 30px;">选择时间：</div>
                <div class="flex timeCode" >
                    <div v-for="(item, index) in timeList" :key="index"  >
                       
                        <div v-if="item.optionCode==5" style="margin-right: 13px;"> 
                            <div style="position: relative;" class="faTime">
                                <el-select class="selectitem" v-model="valueYear" placeholder="往年" @change="hqYears($event,item.dictionaryOptionId)" 
                                :class="{selected: valueYear!==''&&valueYearzdy==''}">
                                    <el-option
                                    v-for="(item, index) in yearArr" :key="index"
                                    :label="item.value"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                                <img class="jtImgsxsa" src="../assets/images/jt.png"  />
                            </div> 
                        </div> 
                        <div v-else-if="item.optionCode==4">
                            <div style="position: relative;">
                                <el-date-picker 
                                v-model="valueYearzdy"
                                type="daterange"
                                range-separator=""
                                :class="{selected: valueYear==''&&valueYearzdy!==''}"
                                :picker-options="pickerOptions0"
                                @change="zdyYears(item.dictionaryOptionId)"
                                start-placeholder="自定义"
                                end-placeholder="">
                                </el-date-picker>
                            <img :class="valueYearzdy==''?'jtImg':'jtImgs'" src="../assets/images/jt.png"  />
                            </div> 
                        </div>

                        <div @click="timeChang(index,item.dictionaryOptionId)" class="item"  v-else
                        :class="{ selected: index == numberThree&&valueYear==''&&valueYearzdy==''}">
                        {{item.optionName}}</div>

                    </div>
                  
                </div>
             </div>  
         </div>
           

            <!-- 信息类型 -->  
            <div v-if="clickTabs==1">
                <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px"  >
                    <div style="color: #999999;width: 90px;line-height: 30px;">信息类型：</div>
                   <div>
                    <div class="flex timeCode" >
                        <div v-for="(item, index) in messageList" :key="index"  >
                            <div @click="messageChang(index,item.areaCode)" class="item"  :class="{ selected: index == number4}">{{item.areaName}}</div>
                        </div>
                    </div>
                    <div class="flex timeCode" style="margin-top: 10px;" v-if="number4!=''">
                        <div v-for="(item, index) in zbList" :key="index"  >
                            <div @click="messageChangs(index,item.dictionaryOptionId)" class="item"  :class="{ selected: index == number4s}">
                                {{item.optionName}}</div>
                        </div>
                     </div> 

                   </div>
                </div>
            </div>

            <div v-if="clickTabs!=1">
                <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px"  >
                    <div style="color: #999999;width: 90px;line-height: 30px;">信息类型：</div>
                   <div class="flex timeCode">
                        <div v-for="(item, index) in zbList" :key="index"  >
                            <div @click="messageChangs(index,item.dictionaryOptionId)" class="item"  :class="{ selected: index == number4s}">
                                {{item.optionName}}</div>
                        </div>
                     </div> 

                   </div>
               
            </div>

           
           <!-- 招标类型 -->
             <!-- <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px" v-else-if="clickTabs==2">
                <div style="color: #999999;width: 90px;line-height: 30px;">招标类型</div>
                <div class="flex timeCode" >
                    <div v-for="(item, index) in zbList" :key="index"  >
                        <div @click="zbChang(index,item.dictionaryOptionId,'1')" class="item"  :class="{ selected: index == number5}">{{item.optionName}}</div>
                    </div>
                </div>
            </div>   -->

             <!-- 中标类型 -->
              <!-- <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px" v-else-if="clickTabs==3">
                <div style="color: #999999;width: 90px;line-height: 30px;">中标类型</div>
                <div class="flex timeCode" >
                    <div v-for="(item, index) in zbList1" :key="index"  >
                        <div @click="zbChang(index,item.dictionaryOptionId,'2')" class="item"  :class="{ selected: index == number6}">{{item.optionName}}</div>
                    </div>
                </div>
              </div>   -->
            <!-- 审批阶段 -->
            <!-- <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px" v-else-if="clickTabs==4">
                <div style="color: #999999;width: 90px;line-height: 30px;">审批阶段</div>
                <div class="flex timeCode" >
                    <div v-for="(item, index) in zbList2" :key="index"  >
                        <div @click="zbChang(index,item.dictionaryOptionId,'3')" class="item"  :class="{ selected: index == number7}">{{item.optionName}}</div>
                    </div>
                </div>
            </div>  -->
           

            <!-- 企业角色 -->
            <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px" v-if="clickTabs==5">
                <div style="color: #999999;width: 90px;line-height: 30px;">企业角色</div>
                <div class="flex timeCode" >
                    <div v-for="(item, index) in zbList3" :key="index"  >
                        <div @click="zbChang(index,item.dictionaryOptionId,'4')" class="item"  :class="{ selected: index == number8}">{{item.optionName}}</div>
                    </div>
                </div>
            </div>  


            <!-- 更多筛选 -->
            <div class="flex chooseArea" style="align-items: center;margin-top:20px">
                <div style="color: #999999;width: 90px;line-height: 30px;">更多筛选：</div>
                <div class="flex timeCode aShaixuan" >

                    <div style="position: relative;margin-right: 12px;" v-if="clickTabs==5">
                      <div v-if="phoneNo!=''&&phoneNo!=null" >
                        <el-select  v-model="sxvalue9" placeholder="注册资本" @change="sxOne($event,'9')" @focus="moreSx()">
                            <div v-for="(item, index) in sxList9" :key="index" >
                                <div v-if="item.optionName=='自定义'"></div>
                                <el-option v-else
                                :label="item.optionName"
                                :value="item.dictionaryOptionId+','+item.optionName">
                            </el-option>
                            </div>
                            </el-select>
                            <img class="jtImgsx" src="../assets/images/jt.png"  />
                     </div>  
                     <div v-else @click="moreSx()" class="sxTexts">
                        <span >注册资本</span>
                        <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                     </div> 
                  </div>
                  <div style="position: relative;margin-right: 12px;" v-if="clickTabs==5">
                    <div v-if="phoneNo!=''&&phoneNo!=null" >
                     <el-select v-model="sxvalue10" placeholder="成立年限" @change="sxOne($event,'10')" @focus="moreSx()">
                        <div v-for="(item, index) in sxList10" :key="index">
                            <div v-if="item.optionName=='自定义'"></div>
                            <el-option v-else
                            :label="item.optionName"
                             :value="item.dictionaryOptionId+','+item.optionName">
                        </el-option>
                        </div>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                    </div>
                    <div v-else @click="moreSx()" class="sxTexts">
                        <span>成立年限</span>
                        <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                     </div> 
                  </div>


                  <div style="position: relative;margin-right: 12px;" v-if="clickTabs!=5">
                     <div v-if="phoneNo!=''&&phoneNo!=null" >
                     <el-select v-model="sxvalue1" placeholder="行业类型" @change="sxOne($event,'1')"  @focus="moreSx()">
                        <el-option
                        v-for="(item, index) in sxList1" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId+','+item.optionName">
                        </el-option>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                   </div>
                    <div v-else @click="moreSx()" class="sxTexts">
                        <span >行业类型</span>
                        <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                    </div> 
                  </div>

                  <div style="position: relative;margin-right: 12px;">
                    <div v-if="phoneNo!=''&&phoneNo!=null" >
                     <el-select v-model="sxvalue2" placeholder="招采类型" @change="sxOne($event,'2')" @focus="moreSx()">
                        <el-option
                        v-for="(item, index) in sxList2" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId+','+item.optionName">
                        </el-option>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                    </div>
                    <div v-else @click="moreSx()" class="sxTexts">
                        <span >招采类型</span>
                        <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                    </div> 

                  </div>

                  <!-- <div style="position: relative;margin-right: 12px;" v-if="clickTabs!=4&&clickTabs!=5">
                     <el-select v-model="sxvalue3" placeholder="招采单位" @change="sxOne($event,'3')">
                        <el-option
                        v-for="(item, index) in sxList3" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId">
                        </el-option>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                  </div> -->
                 <div style="position: relative;margin-right: 12px;" >
                    <div v-if="phoneNo!=''&&phoneNo!=null" >
                      <el-select v-model="sxvalue4" placeholder="预算金额" @change="sxOne($event,'4')" @focus="moreSx()">
                        <div  v-for="(item, index) in sxList4" :key="index">
                            <div v-if="item.optionName=='自定义'"></div>
                            <el-option  v-else
                            @mouseover="mouseOver1(item.optionName)"
                            :label="item.optionName"
                            :value="item.dictionaryOptionId+','+item.optionName">
                            </el-option>
                        </div>
                     </el-select> 
                     <img class="jtImgsx" src="../assets/images/jt.png"  />
                   </div>
                   <div v-else @click="moreSx()" class="sxTexts">
                        <span >预算金额</span>
                        <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                    </div> 

                    <!-- <div class="ant-popover-content" >
                          <div class="ant-popover-inner">
                                <div class="tc_select_number-range">
                                    <div >
                                        <el-form ref="form" :model="form">
                                            <el-form-item >
                                                <span>从</span>
                                                <el-input v-model="form.price1"></el-input>
                                                <span>万</span>
                                            </el-form-item>
                                            <el-form-item >
                                                <span>从</span>
                                                <el-input v-model="form.price2"></el-input>
                                                <span>万</span>
                                            </el-form-item>
                                        </el-form>
                                    </div>
                                    <div class="tc_select_btns">
                                        <el-button type="text" @click="resetForm('form')">清空</el-button>
                                        <el-button type="text" @click="submitForm('form')">确定</el-button>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>    


                  <div style="position: relative;margin-right: 12px;" >
                    <div v-if="phoneNo!=''&&phoneNo!=null" >
                     <el-select v-model="sxvalue5" placeholder="代理单位" @change="sxOne($event,'5')" @focus="moreSx()">
                        <el-option
                        v-for="(item, index) in sxList5" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId+','+item.optionName">
                        </el-option>
                     </el-select> 
                     <img class="jtImgsx" src="../assets/images/jt.png"  />
                    </div>
                    <div v-else @click="moreSx()" class="sxTexts">
                        <span >代理单位</span>
                        <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                    </div> 
                  </div>

                  <div style="position: relative;margin-right: 12px;">
                    <div v-if="phoneNo!=''&&phoneNo!=null" >
                        <el-select v-model="sxvalue6" placeholder="中标金额" @change="sxOne($event,'6')" @focus="moreSx()">
                            <div  v-for="(item, index) in sxList6" :key="index">
                                <div v-if="item.optionName=='自定义'"></div>
                                <el-option  v-else
                                :label="item.optionName"
                                :value="item.dictionaryOptionId+','+item.optionName">
                                </el-option> 
                            </div>
                        </el-select>
                        <img class="jtImgsx" src="../assets/images/jt.png"  />
                    </div>
                    <div v-else @click="moreSx()" class="sxTexts">
                        <span >中标金额</span>
                        <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                    </div> 
                  </div>


                 <!-- <div style="position: relative;margin-right: 12px;" v-if="clickTabs==5">
                     <el-select v-model="sxvalue11" placeholder="证书信息" @change="sxOne($event,'11')">
                        <el-option
                        v-for="(item, index) in sxList11" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId">
                        </el-option>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                  </div> -->

                  <!-- <div style="position: relative;margin-right: 12px;" v-if="clickTabs==5">
                     <el-select v-model="sxvalue12" placeholder="建筑资质" @change="sxOne($event,'12')">
                        <el-option
                        v-for="(item, index) in sxList12" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId">
                        </el-option>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                  </div> -->




                  <div style="position: relative;margin-right: 12px;"  v-if="clickTabs==5">
                    <div v-if="phoneNo!=''&&phoneNo!=null" >
                        <el-select v-model="sxvalue77" placeholder="联系方式" @change="sxOne($event,'77')" @focus="moreSx()">
                            <el-option
                            v-for="(item, index) in sxList77" :key="index"
                            :label="item.optionName"
                            :value="item.dictionaryOptionId+','+item.optionName">
                            </el-option>
                        </el-select>
                        <img class="jtImgsx" src="../assets/images/jt.png"  />
                    </div>
                    <div v-else @click="moreSx()"  class="sxTexts">
                        <span>联系方式</span>
                        <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                    </div> 
                  </div>
                  <div style="position: relative;margin-right: 12px;"  v-else>
                    <div v-if="phoneNo!=''&&phoneNo!=null" >
                     <el-select v-model="sxvalue7" placeholder="联系方式" @change="sxOne($event,'7')" @focus="moreSx()">
                        <el-option
                        v-for="(item, index) in sxList7" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId+','+item.optionName">
                        </el-option>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                   </div>
                   <div v-else @click="moreSx()"  class="sxTexts">
                        <span >联系方式</span>
                        <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                    </div> 
                  </div>
                  <!-- <div style="position: relative;margin-right: 12px;" v-if="clickTabs!=5">
                     <el-select v-model="sxvalue8" placeholder="附件" @change="sxOne($event,'8')">
                        <el-option
                        v-for="(item, index) in sxList8" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId">
                        </el-option>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                  </div> -->
                </div>
            </div>


            </div>
        </el-tab-pane>  
        </el-tabs>
      </div>

      <div class="open">
        <div @click="showAll = !showAll" class="statusA">
            <div style="position: absolute;top: -28px;">{{flag}}</div>
        </div>
      </div>

      <div class="halfC tabCon" style="margin-top: 15px;" id="tabsQ">
        
         <div class="flex jutSpace" style="border-bottom: 1px solid #F3F3F3;padding-bottom: 20px;">
           <div class="tabLeft" v-if="clickTabs==1">
              标圈圈已帮您筛选出<span style="color: red;">{{totalA}}</span>条招投标信息
           </div>
           <div class="tabLeft" v-else-if="clickTabs==2">
              标圈圈已帮您筛选出<span style="color: red;">{{totalA}}</span>条招标信息
           </div>
           <div class="tabLeft" v-else-if="clickTabs==3">
              标圈圈已帮您筛选出<span style="color: red;">{{totalA}}</span>条中标信息
           </div>
           <div class="tabLeft" v-else-if="clickTabs==4">
              标圈圈已帮您筛选出<span style="color: red;">{{totalA}}</span>条拟建信息
           </div>
           <div class="tabLeft" v-else-if="clickTabs==5">
             搜索到<span style="color: red;">{{totalA}}</span>家标讯企业
           </div>

           <div class="tabRight flex">
                <div style="position: relative;" v-if="clickTabs==5">
                     <el-select class="rightM" v-model="pxvalue2"  @change="pxChange2($event)">
                        <el-option value="" label="智能排序"></el-option>
                        <el-option value="1" label="注册资本从高到低"></el-option>
                        <el-option value="2" label="注册资本从低到高"></el-option>
                        <el-option value="3" label="成立日期从晚到早"></el-option>
                        <el-option value="4" label="成立日期从早到晚"></el-option>
                    </el-select>
                    <img class="jtImgsxs" src="../assets/images/jt.png"  />
                </div>


               <div style="position: relative;" v-else>
                     <el-select class="rightM" v-model="pxvalue"  @change="pxChange($event)">
                        <el-option value="" label="智能排序"></el-option>
                        <el-option value="1" label="发布时间从早到晚"></el-option>
                        <el-option value="2" label="发布时间从晚到早"></el-option>
                    </el-select>
                    <img class="jtImgsxs" src="../assets/images/jt.png"  />
                </div>

                <!-- <el-button class="rightM" style="color: #000;" @click="batch">批量操作</el-button>
                <el-button type="primary">立即导出</el-button> -->
           </div>
         </div>
        <div v-if="clickTabs!=5">
         <div class="contentAll">
           <div class="ant-spin-nested-loading">
            <ul class="ant-list-items" v-for="(item,index) in listAll" :key="index">
                 <!-- 中标公告 -->
                <div class="items-half" v-if="item.dataType==1">
                    <div class="tc_list_item tc_list_hover">
                        <div class="title" @click="zbDetail(item.id)">
                             <div v-html="item.name"></div> 
                        </div>
                        <div class="ant-content">
                            <div class="gGdJlei">
                                <span class="ant-tag ant-tag-orange bordered">{{item.dataTypeStr }} 
                                    <span v-if="item.dataSubTypeStr!=''&&item.dataSubTypeStr!=undefined">| {{item.dataSubTypeStr}}</span>
                                </span>
                                <span class="ant-tag bordered" v-if="item.provinceCode!=''&&item.provinceCode!=undefined">
                                    <span v-if="item.provinceCode!=''&&item.provinceCode!=undefined">{{ item.provinceCode }}</span>
                                    <span v-if="item.cityCode!=''&&item.cityCode!=undefined"> | {{ item.cityCode }} </span>
                                    <span v-if="item.countyCode!=''&&item.countyCode!=undefined"> | {{ item.countyCode }} </span>
                                </span>     
                                <span  v-if="item.industryCategory!=''&&item.industryCategory!=undefined">
                                    <span class="ant-tag bordered"
                                    v-for="(items,indexs) in arrPao[index]" :key="indexs">
                                        {{ items }} 
                                    </span>
                                </span>
                                <span  v-if="item.procurementType!=''&&item.procurementType!=undefined">
                                    <span class="ant-tag bordered"
                                    v-for="(items,indexs) in arrPro[index]" :key="indexs">
                                        {{ items }} 
                                    </span>
                                </span>

                            </div>  
                         
                            <div class="fiklj9cw">
                                     <div class="smailDiv" v-if="item.bidCode!=''&&item.bidCode!=undefined">
                                        <div>项目编号:</div>
                                        <div>{{item.bidCode}}</div>
                                    </div>
                                    <div class="smailDiv" v-if="item.bidDeptName!=''&&item.bidDeptName!=undefined">
                                        <div>招采单位:</div>
                                        <div >{{ item.bidDeptName }}</div>
                                        <!-- <div v-if="item.bidDeptId!=''" @click="companyDetail(item.bidDeptId)" class="blueColor">{{ item.bidDeptName }}</div> 
                                         <div v-else >{{ item.bidDeptName }}</div>  -->
                                    </div>
                                    <div class="smailDiv" v-if="item.agentDeptName!=''&&item.agentDeptName!=undefined">
                                        <div>代理单位:</div>
                                        <div >{{ item.agentDeptName }}</div> 
                                        <!-- <div v-if="item.agentDeptId!=''" @click="companyDetail(item.agentDeptId)" class="blueColor">{{ item.agentDeptName }}</div> 
                                         <div v-else >{{ item.agentDeptName }}</div>  -->
                                    </div>
                              
                               
                               <div v-if="item.pageWinPackageList!=''&&item.pageWinPackageList!=undefined">
                                  <div class="fiklj9cw" v-for="(items,indexs) in item.pageWinPackageList" :key="indexs">
                                     <div class="smailDiv">
                                        <div>中标单位:</div>
                                        <div >{{ items.windeptname }}</div> 
                                        <!-- <div v-if="items.windeptId!=''" @click="companyDetail(items.windeptId)" class="blueColor">{{ items.windeptname }}</div> 
                                         <div v-else >{{ items.windeptname }}</div>  -->
                                      </div>
                                      <div class="smailDiv">
                                        <div>中标金额:</div>
                                        <div>{{items.winamountStr}}</div>
                                      </div> 
                                  </div>
                                </div> 
                                <div class="fiklj9cw">
                                    <div class="smailDiv" v-if="item.agentDeptPhone!=''&&item.agentDeptPhone!=undefined">
                                        <div>联系方式:</div>
                                        <div>{{item.agentDeptPhone}}</div>
                                    </div>
                                    <div class="smailDiv" v-if="item.issueTimeStr!=''&&item.issueTimeStr!=undefined">
                                        <div>发布时间:</div>
                                        <div>{{item.issueTimeStr}}</div>
                                    </div>
                                    <!-- <div class="smailDiv" v-if="item.openTime!=''&&item.openTime!=undefined">
                                        <div>开标时间：</div>
                                        <div>{{item.openTime}}</div>
                                    </div> -->
                                </div> 


                            </div>
                        </div>
                    </div>
                </div> 

                <!-- 拟建公告 -->
                <div class="items-half" v-else-if="item.dataType==2">
                    <div class="tc_list_item tc_list_hover">
                        <div class="title" @click="zbDetail(item.id)">
                             <div v-html="item.name"></div> 
                        </div>
                        <div class="ant-content">
                            <div class="gGdJlei">
                                <span class="ant-tag ant-tag-green bordered">{{item.dataTypeStr }} 
                                    <span v-if="item.dataSubTypeStr!=''&&item.dataSubTypeStr!=undefined">| {{item.dataSubTypeStr}}</span>
                                </span>
                                <span class="ant-tag bordered" v-if="item.provinceCode!=''&&item.provinceCode!=undefined">
                                    <span v-if="item.provinceCode!=''&&item.provinceCode!=undefined">{{ item.provinceCode }}</span>
                                    <span v-if="item.cityCode!=''&&item.cityCode!=undefined"> | {{ item.cityCode }} </span>
                                    <span v-if="item.countyCode!=''&&item.countyCode!=undefined"> | {{ item.countyCode }} </span>
                                </span> 
                                <span  v-if="item.industryCategory!=''&&item.industryCategory!=undefined">
                                    <span class="ant-tag bordered"
                                    v-for="(items,indexs) in arrPao[index]" :key="indexs">
                                        {{ items }} 
                                    </span>
                                </span>
                                <span  v-if="item.procurementType!=''&&item.procurementType!=undefined">
                                    <span class="ant-tag bordered"
                                    v-for="(items,indexs) in arrPro[index]" :key="indexs">
                                        {{ items }} 
                                    </span>
                                </span>    
                            </div>  
                         
                            <div class="fiklj9cw">
                                     <div class="smailDiv" v-if="item.bidCode!=''&&item.bidCode!=undefined">
                                        <div>项目编号:</div>
                                        <div>{{item.bidCode}}</div>
                                    </div>
                                    <div class="smailDiv" v-if="item.bidDeptName!=''&&item.bidDeptName!=undefined">
                                        <div>招采单位:</div>
                                        <div >{{ item.bidDeptName }}</div>
                                        <!-- <div v-if="item.bidDeptId!=''" @click="companyDetail(item.bidDeptId)" class="blueColor">{{ item.bidDeptName }}</div> 
                                         <div v-else >{{ item.bidDeptName }}</div>  -->
                                    </div>
                                   
                             
                                <div class="fiklj9cw">
                                    <div class="smailDiv" v-if="item.agentDeptPhone!=''&&item.agentDeptPhone!=undefined">
                                        <div>联系方式:</div>
                                        <div>{{item.agentDeptPhone}}</div>
                                    </div>
                                    <div class="smailDiv" v-if="item.issueTimeStr!=''&&item.issueTimeStr!=undefined">
                                        <div>发布时间:</div>
                                        <div>{{item.issueTimeStr}}</div>
                                    </div>
                                    <div class="smailDiv" v-if="item.openTimeStr!=''&&item.openTimeStr!=undefined">
                                        <div>审批时间:</div>
                                        <div>{{item.openTimeStr}}</div>
                                    </div>
                                </div> 


                            </div>
                        </div>
                    </div>
                </div> 

                <!-- 招标公告 -->
                <div class="items-half" v-else>
                    <div class="tc_list_item tc_list_hover">
                        <div class="title" @click="zbDetail(item.id)">
                            <div v-html="item.name"></div> 
                        </div>
                        <div class="ant-content">
                            <div class="gGdJlei">
                                <span class="ant-tag ant-tag-blue bordered">{{item.dataTypeStr }} 
                                    <span v-if="item.dataSubTypeStr!=''&&item.dataSubTypeStr!=undefined">| {{item.dataSubTypeStr}}</span>
                                </span>
                                <span class="ant-tag bordered" v-if="item.provinceCode!=''&&item.provinceCode!=undefined">
                                    <span v-if="item.provinceCode!=''&&item.provinceCode!=undefined">{{ item.provinceCode }}</span>
                                    <span v-if="item.cityCode!=''&&item.cityCode!=undefined"> | {{ item.cityCode }} </span>
                                    <span v-if="item.countyCode!=''&&item.countyCode!=undefined"> | {{ item.countyCode }} </span>
                                </span> 
                                <span  v-if="item.industryCategory!=''&&item.industryCategory!=undefined">
                                    <span class="ant-tag bordered"
                                    v-for="(items,indexs) in arrPao[index]" :key="indexs">
                                        {{ items }} 
                                    </span>
                                </span>
                                <span  v-if="item.procurementType!=''&&item.procurementType!=undefined">
                                    <span class="ant-tag bordered"
                                    v-for="(items,indexs) in arrPro[index]" :key="indexs">
                                        {{ items }} 
                                    </span>
                                </span>    
                            </div>  
                                <div class="fiklj9cw">
                                     <div class="smailDiv" v-if="item.bidCode!=''&&item.bidCode!=undefined">
                                        <div>项目编号:</div>
                                        <div>{{item.bidCode}}</div>
                                    </div>
                                    <div class="smailDiv" v-if="item.bidDeptName!=''&&item.bidDeptName!=undefined">
                                        <div>招采单位:</div>
                                        <div>{{ item.bidDeptName }}</div>
                                    </div>
                                    <div class="smailDiv" v-if="item.agentDeptName!=''&&item.agentDeptName!=undefined">
                                        <div>代理单位:</div>
                                        <div >{{ item.agentDeptName }}</div> 
                                    </div>

                                <div class="fiklj9cw">
                                    
                                    <div class="smailDiv" v-if="item.budgetAmountStr!=''&&item.budgetAmountStr!=undefined">
                                        <div>预算金额:</div>
                                        <div>{{item.budgetAmountStr}}</div>
                                    </div>
                                    <div class="smailDiv" v-if="item.agentDeptPhone!=''&&item.agentDeptPhone!=undefined">
                                        <div>联系方式:</div>
                                        <div>{{item.agentDeptPhone}}</div>
                                    </div> 
                                </div>
                                <div class="fiklj9cw">
                                    <div class="smailDiv" v-if="item.issueTimeStr!=''&&item.issueTimeStr!=undefined">
                                        <div>发布时间:</div>
                                        <div>{{item.issueTimeStr}}</div>
                                    </div>
                                    <div class="smailDiv" v-if="item.deadTimeStr!=''&&item.deadTimeStr!=undefined">
                                        <div v-if="item.dataSubType==101">预计采购时间:</div>
                                        <div v-else>投标截止时间:</div>
                                        <div>{{item.deadTimeStr}}</div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            
            </ul>  
           </div>
         </div>
       </div>
        <div v-else>
            <!-- 标讯企业 -->
            <div class="ant-spin-container">
                <div class="ant-list-items" v-for="(item,index) in gysList" :key="index">
                    <div class="tc_list_item tc_list_hover">
                        <div class="R-Dvn3zA">
                            <div class="pYreFzHC">
                                <img :src="item.logo" />
                            </div>
                            <div class="_0aDOKXj6">
                                <div class="FpwufnYn flex" @click="companyDetail(item.id)">
                                    <div  class="titles" v-html="item.name"></div>
                                    <!-- <div class="WMSbIy8X flex"> -->
                                    <div class="">招投标数：<span style="color:red">{{item.bidNum}}</span></div>
                                   <!-- </div> -->
                                </div>
                                <div class="gGdJlei">
                                   <!-- <span class="ant-tag ant-tag-green bordered">{{item.categoryCode}}</span> -->
                                   <span class="ant-tag ant-tag-green bordered" v-if="item.regStatus!=undefined">{{item.regStatus}}</span>
                                   <span class="ant-tag ant-tag-blue bordered" v-if="item.dataUnitType==0">招采单位</span>
                                   <span class="ant-tag ant-tag-blue bordered" v-if="item.dataUnitType==1">中标单位</span>
                                   <span class="ant-tag ant-tag-blue bordered" v-if="item.dataUnitType==2">建设单位</span>
                                    <span class="ant-tag  bordered" v-if="item.companyOrgType!=undefined">{{item.companyOrgType}}</span>
                                    
                                </div>

                                
                                <div class="flex fiklj9cw" >
                                    <div class="smailDiv" v-if="item.legalEntityId!=''&&item.legalEntityId!=undefined">
                                        <div >法定代表人:</div>
                                        <div>{{item.legalEntityId}}</div>
                                    </div>
                                    <div class="flex smailDiv" v-if="item.regCapitalAmount!=''&&item.regCapitalAmount!=undefined">
                                        <div>注册资本:</div>
                                        <div>{{item.regCapitalAmount}}</div>
                                    </div>
                                    <div class="flex smailDiv" v-if="item.estiblishTime!=''&&item.estiblishTime!=undefined">
                                        <div >成立时间:</div>
                                        <div>{{item.estiblishTime}}</div>
                                    </div>
                                </div>

                                <div class="flex fiklj9cw" >
                                    <div class="flex smailDiv" v-if="item.regLocation!=''&&item.regLocation!=undefined">
                                        <div >企业地址:</div>
                                        <div>{{item.regLocation}}</div>
                                    </div>
                                    <div class="flex smailDiv" v-if="item.result!=''&&item.result!=undefined">
                                        <div >联系方式:</div>
                                        <div>{{item.result}}</div>
                                    </div>
                                </div>
                         
                              <div  style=" margin: 10px 0;">
                                <div class="flex fiklj9cw jutRowsA" v-if="item.scripingpage!=''&&item.scripingpage!=undefined">
                                    <div class="flex smailDiv" >
                                         <div>{{item.scripingpage.issueTime}}</div> 
                                    </div>
                                     <div class="flex smailDiv" v-if="item.scripingpage.dataType==1">
                                        <div >中标结果 | 中标</div>
                                    </div>
                                    <div class="flex smailDiv" v-else>
                                        <div >招标公告 | 招标</div>
                                    </div>
                                    <div class="flex smailDiv" v-if="item.scripingpage.provinceCode!=''&&item.scripingpage.provinceCode!=undefined">
                                        <div>{{item.scripingpage.provinceCode}}</div>
                                    </div>
                                    <div class="flex smailDiv" style="max-width: 50%;margin-top: 11px;">
                                       <div  @click="zbDetail(item.scripingpage.id,item.scripingpage.dataType)" style="color: #0078FF;cursor: pointer;width: 100%;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{item.scripingpage.name}}</div>
                                    </div>
                                    <div class="flex smailDiv">
                                        <div>{{ item.scripingpage.enterpriseRole }}</div>
                                        <!-- <div v-if="item.scripingpage.dataType==1">中标单位</div> -->
                                        <!-- <div v-if="item.scripingpage.enterpriseRole==0"></div>
                                        <div v-else-if="item.scripingpage.enterpriseRole==1">中标单位</div>
                                        <div v-else>代理单位</div> -->
                                    </div> 
                                </div>
                              </div>

                                <div class="flex fiklj9cw" >
                                    <div class="flex" style="overflow: hidden;" v-if="item.zcProductNames!=''&&item.zcProductNames!=undefined">
                                        <div >招采产品:</div>
                                        <div class="zcPr">{{item.zcProductNames}}</div>
                                    </div>
                                    <div class="flex" style="overflow: hidden;" v-if="item.zbProductNames!=''&&item.zbProductNames!=undefined">
                                        <div >中标产品:</div>
                                        <div class="zcPr">{{item.zbProductNames}}</div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div> 
       </div>


         <!-- 弹框二维码支付 -->
      <div class="tkPay">
        <el-dialog
        :visible.sync="ewmVisible"
        width="760px"
        :before-close="handleClose">
        <div class="nav" v-if="isPay==true">
            <div class="ktText">开通产品套餐，查看更多数据</div>
            <div class="nav-top">
               <div v-if="isChao">
                
                    <el-tabs v-model="activeNameT" @tab-click="handleClickT">
                        <el-tab-pane name="1">
                            <div slot="label">
                                <div class="bzImgs"><img style="margin-right:9px;width: 49px;height: 18px;" src="../assets/images/hot.png" />单条购买</div>
                                <div class="bqqStext">标圈圈，找更多商机！</div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane name="2" >
                            <div slot="label">
                                <div class="bzImgs"><img style="margin-right:9px;width: 38px;height: 18px;" src="../assets/images/vip.png" />标准版</div>
                                <div class="bqqStext">标圈圈，找更多商机！</div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>


                    <div class="firstContent" v-if="activeNameT==2">
                        <div style="margin-bottom: 16px;font-size: 18px;">选择合适套餐，查看更多详情！</div>
                        <div class="nav-topA flex aligenCenter">
                            <div class="tao-half-row maright" @click="taocan('1')">
                                <div :class="vipFlag==1?'danTk':'danTk2'">
                                    <div class="taoTtile">基准价</div>
                                    <div style="margin: 10px 0 0 0;">
                                        <span style="color:#FF0000">¥<span style="font-size:30px;">599</span></span>
                                        <span>/3个月</span>
                                    </div>
                                    <div class="danTexts flex jutCenter">
                                        <div class="flex jutCenter danH" >每天仅需6.6元</div>
                                    </div>
                                </div>
                                <img v-if="vipFlag==1" class="tkgouImg" src="../assets/images/gou.png" />
                            </div>
                            <div class="tao-half-row maright" @click="taocan('2')">
                                <div  :class="vipFlag==2?'danTk':'danTk2'">
                                    <div class="taoTtile">用户优惠</div>
                                    <div style="margin: 10px 0 0 0;">
                                        <span style="color:#FF0000">¥<span style="font-size:30px;">999</span></span>
                                        <span>/6个月</span>
                                    </div>
                                    <div class="danTexts flex jutCenter">
                                        <div class="saleprice">原价¥1198</div>
                                    </div>
                                </div>
                                <div class="dwZhek">83折</div>
                                <img v-if="vipFlag==2" class="tkgouImg" src="../assets/images/gou.png" />
                            </div>
                            <div class="tao-half-row" @click="taocan('3')"> 
                                <div :class="vipFlag==3?'danTk':'danTk2'">
                                    <div class="taoTtile">超值推荐</div>
                                    <div style="margin: 10px 0 0 0;">
                                        <span style="color:#FF0000">¥<span style="font-size:30px;">1599</span></span>
                                        <span>/12个月</span>
                                    </div>
                                    <div class="danTexts flex jutCenter">
                                        <div class="saleprice">原价¥2396</div>
                                    </div>
                                </div>
                                <div class="dwZhek">67折</div>
                                <img v-if="vipFlag==3" class="tkgouImg" src="../assets/images/gou.png" />
                            </div> 
                        </div>

                        <div class="nav-middle">
                            <div class="flex nav-middleCon">
                                <div class="dkq" v-if="couponUserId!=''">{{quanList[0].couponInfo.couponAmount}}抵扣券</div>
                                <div class="sjPay">实付金额：<span style="color:#FF0000;margin-bottom: -6px;">￥<span style="font-size: 30px;">{{totalPrice}}</span></span></div>
                            </div>
                            <div class="flex jutCenter"><div class="sureBtn" @click="payfor">立即开通</div></div>
                        </div>
                        <div style="font-size: 12px;">注：试运营期VIP可通过<span style="cursor: pointer;color:#0078FF" @click="Sign">点击上方领取VIP会员</span>获得，我们提供多种活动给大家赠送VIP体验机会，
                            支付购买后<span><router-link to="/userCenter?actives=8" style="color:#0078FF">发票</router-link></span>可在个人中心申请。</div>
                     </div>


                   <div class="firstContent" v-else>
                        <div class="tao-half-row maright">
                            <div class="danTk">
                                <div class="taoTtile">单条标讯</div>
                                <div style="margin: 10px 0 0 0;">
                                    <span style="color:#FF0000">¥<span style="font-size:30px;">0.5</span>元</span>
                                    <span>/条</span>
                                </div>
                                <div class="danTexts flex jutCenter">
                                    <div class="flex jutCenter danH" >永久查看</div>
                                </div>
                            </div>
                            <img class="tkgouImg" src="../assets/images/gou.png" />
                        </div>

                        <div class="nav-middle">
                           
                            <div class="flex jutCenter"><div class="sureBtn" @click="payfor" style="margin-top: 10px;">立即开通</div></div>
                        </div>

                        <div style="font-size: 12px;">注：试运营期VIP可通过<span style="cursor: pointer;color:#0078FF" @click="Sign">点击上方领取VIP会员</span>获得，我们提供多种活动给大家赠送VIP体验机会，
                            支付购买后<span><router-link to="/userCenter?actives=8" style="color:#0078FF">发票</router-link></span>可在个人中心申请。</div>

                   </div>


               </div>
               <!-- <div v-else class="tabs">
                  <div class="btn active"  style="text-align: center;">
                            <div>高级VIP</div>
                            <div style="font-size: 12px;color: #999999;">更多特权，超值服务</div>
                            <div style="font-size: 30px;color: #999999;">敬请期待</div>
                            <i class="tc_purchase-order_check"></i>
                    </div>
               </div> -->
            </div>
            <div style="max-height: 320px; overflow-y: auto;">
                <div  class="nav-tabel">
                    <div class="footsCon">
                        <div class="flex aligenCenter jutCenter bqqText1">
                            <img class="iconZ" src="../assets/images/vipJx.png" />
                            <div style="margin:0 5px;">标准版会员特权</div>
                            <img class="iconZ" src="../assets/images/vipJx.png" />
                        </div>	
                        <div class="bqqText2">标圈圈，找更多商机！</div>
                        <div class="flex" style="flex-wrap: wrap;align-items: flex-start;">
                            <div class="vipHalfs" v-for="(item,index) in bqqList" :key="index">
                                <img class="vipIcons" :src="item.url" />
                                <div class="vipIconText">{{item.text}}</div>
                            </div>
                        </div>
                    </div>              
                </div>
            </div>
        </div>

        <div v-else class="navK">
            <div class="ktText">开通产品套餐，查看更多数据</div>
            <div class="paySuccess" v-if="paySuccess">
                <el-alert :title="messageTitle" type="success"  center show-icon :closable="false"></el-alert>
            </div>
            <div class="fYcolor">
                <div class="cpLeft">
                    <img :src="ewmImg" class="ewmImg" />
                    <div class="flex jutCenter">
                        <img src="../assets/images/sao.png" style="width:17px;height:17px;margin-right: 5px;" />
                        <div style="color:#999999">使用<span style="color:#1E88FF">微信</span>扫码支付</div>
                    </div>
                </div>
                <div class="cpRight">
                    <div>开通账号：{{phoneNo}}</div>
                    <div>
                        <div>开通套餐：{{orderPay.name}}</div>
                        <div>到期时间：{{orderPay.days}}</div>
                        <div v-if="orderPay.days=='永久'">实付金额：<span style="color:#FF0000">￥<span style="font-size:30px">{{orderPay.salePrice}}</span>元/条</span></div>
                        <div v-else>实付金额：<span style="color:#FF0000">￥<span style="font-size:30px">{{orderPay.salePrice}}</span>元/年</span></div>
                        <div style="color: #999999;margin-top: 6px;">发票获取：完成支付后可在【个人中心-我的发票】中申请</div>
                    </div>
                   
                </div>
            </div>
        </div>
        </el-dialog>
      </div>
 

       <!-- 分页 -->
       <div class="pagination">
        <el-pagination
            @current-change="handleCurrentChange"
            background
            :current-page="currentPage"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
       </div>
      </div>
 
      <common-foot></common-foot>

    <common-active :showTk="beiji" :showActivity="flagActivity" @change="amountChanges($event)"></common-active>

    </div>
</template>

<script>
   import CommonHeader from '@/components/CommonHeader.vue';
   import CommonFoot from '@/components/CommonFoot.vue';
   import IndexOne from '@/components/IndexOne.vue';
   import CommonActive from '@/components/CommonActive.vue';
    export default {
      components:{
       CommonHeader,
       IndexOne,
       CommonFoot,
       CommonActive
       }, 
        data() {
            return {
                activeName:'1',
                showAll:false,
                isShow:true,
                currentPage: 1,
                pageSize:10,
                total:0,
                totalA:0,
                pxvalue:'智能排序',
                pxvalue2:'智能排序',
                pxIndex:'1',
                intervalId:null,
                animate:false, //控制过渡动画是否开启

                tabsArr:[
                    {label:'全部标讯',name:'1'},
                    {label:'招标公告',name:'2'},
                    {label:'中标公告',name:'3'},
                    {label:'拟建项目',name:'4'},
                    // {label:'标讯企业',name:'5'},
                ],
                clickTabs:'1',
                gysList:[ ],
                listAll:[],
                newArr:[]  ,


                numberOne: 0,
                numberTwo:'',
                numberArea:'',
                numberThree:'',
                number4:0,
                qyClass:0,
                provinceCode:'',
                cityCode:'',
                areaCode:'',
                proviceList:[],
                cityList:[],
                isCity:false,
                areaList:[],
                isCountry:false,
                timeCode:'',
                timeList:[],
                valueYear: '',
                messageList:[
                    {areaCode:'',areaName:'全部'},
                    {areaCode:'1',areaName:'招标公告'},
                    {areaCode:'2',areaName:'中标结果'},
                    {areaCode:'3',areaName:'拟建项目'},
                ],
                messageLists:[],
                number4s:0,
                messageCode:'',
                messageCodesA:'',
                sxList1:[],
                sxvalue1: '',
                sxList2: [ ],
                sxvalue2: '',
                sxList3: [],
                sxvalue3: '',
                sxList4: [ ],
                sxvalue4: '',
                sxList5: [ ],
                sxvalue5: '',
                sxList6: [],
                sxvalue6: '',
                sxList7: [ ],
                sxvalue7: '',
                sxList8: [ ],
                sxvalue8: '',

                sxList9: [],
                sxvalue9: '',
                sxList10: [ ],
                sxvalue10: '',
                sxList11: [ ],
                sxvalue11: '',
                sxList12: [ ],
                sxvalue12: '',

                sxList77: [ ],
                sxvalue77: '',
                sxvalue77s: '',

                valueYearzdy:'',
                startTime:'',
                endTime:'',
                yearArr:[],
                pickerOptions0: {
                    disabledDate(time) {
                        return time.getTime() > Date.now() - 8.64e6;//如果没有后面的-8.64e6就是不可以选择今天的
                     }
                }, 
                aa:'',
                number5:0,
                number6:0,
                number7:0,
                number8:0,
                zbList:[],
                zbvalue:'',
                zbList1:[],
                zbvalue1:'',
                zbList2:[],
                zbvalue2:'',
                zbList3:[],
                zbvalue3:'',
                zdyTime:'',
                messClick:'',
                pxLabel:'',
                searchCon:'',
                qyflag1:'',
                qyflag2:'',
                qyflag3:'',
                
                form: {
                    price1:'',
                    price2:'',
                },

                sxvalue1s: '',
                sxvalue2s: '',
                sxvalue3s: '',
                sxvalue4s: '',
                sxvalue5s: '',
                sxvalue6s: '',
                sxvalue7s: '',
                sxvalue9s: '',
                sxvalue10s: '',

                //签到
                beiji:false,
                textHeight: null,
                status: false,
                idShowText: false,

                // sign: false,
                // state:'',
                // str3:'',
                // str4:'',
                // rlArr:[]
                phoneNo:'',
                vipEndtime:'',


                vipAuth:'',
                ewmVisible:false,
                isPay:true,
                isChao:true,

                dialogVisible:false,
                gjtext:'',  //关键词
                optionTime: [{
                    value: '0',
                    label: '0:00'
                    }, {
                    value: '1',
                    label: '1:00'
                    }, {
                    value: '2',
                    label: '2:00'
                    }, {
                    value: '3',
                    label: '3:00'
                    }, {
                    value: '4',
                    label: '4:00'
                   }, {
                    value: '5',
                    label: '5:00'
                    }, {
                    value: '6',
                    label: '6:00'
                    }, {
                    value: '7',
                    label: '7:00'
                    }, {
                    value: '8',
                    label: '8:00'
                   }, {
                    value: '9',
                    label: '9:00'
                   }, {
                    value: '10',
                    label: '10:00'
                    }, {
                    value: '11',
                    label: '11:00'
                    }, {
                    value: '12',
                    label: '12:00'
                    },
                    {
                    value: '13',
                    label: '13:00'
                    }, {
                    value: '14',
                    label: '14:00'
                   }, {
                    value: '15',
                    label: '15:00'
                    }, {
                    value: '16',
                    label: '16:00'
                    }, {
                    value: '17',
                    label: '17:00'
                    }, {
                    value: '18',
                    label: '18:00'
                   }, {
                    value: '19',
                    label: '19:00'
                   }, {
                    value: '20',
                    label: '20:00'
                    }, {
                    value: '21',
                    label: '21:00'
                    }, {
                    value: '22',
                    label: '22:00'
                    },{
                    value: '23',
                    label: '23:00'
                    }
                ],
                value: '9:00',
                timeS:'9:00',
                notice1:'1',
                notice2:'1',
                notice3:'1',
                notice4:'1',
                dyNotes:[ ],


                goodsId:'7163018607638296736',   //vip套餐id
               //zTexts:'',
                objectIds:'', //点击详情时的Id
                objectIds2:'',
                timerT: null, // 定时器
                orderPay:[],
                orderPays:[],
                ewmImg:'',
                paySuccess:false,
                totalTime:3,
                messageTitle:'',
                tempPage:'',
                isFlag:1,


                bqqList:[
					{id:1,text:'招标公告',url:'https://www.biaox.cn/webcrawler/wechat/images/zbvipIcon1.png'},
					{id:2,text:'中标公告',url:'https://www.biaox.cn/webcrawler/wechat/images/zbvipIcon2.png'},
					{id:3,text:'拟建项目',url:'https://www.biaox.cn/webcrawler/wechat/images/zbvipIcon3.png'},
					{id:4,text:'招标预告',url:'https://www.biaox.cn/webcrawler/wechat/images/zbvipIcon4.png'},
					{id:5,text:'精选项目',url:'https://www.biaox.cn/webcrawler/wechat/images/zbvipIcon5.png'},
					{id:6,text:'审批项目',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon6.png'},
					{id:7,text:'企业直采',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon7.png'},
					{id:8,text:'政府采购',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon8.png'},
					{id:9,text:'询价订单',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon9.png'},
					{id:10,text:'谈判采购',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon10.png'},
					{id:11,text:'项目分包',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon11.png'},
					{id:12,text:'采购意向',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon12.png'},
					{id:13,text:'招标订阅',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon13.png'},
					{id:14,text:'中标订阅',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon14.png'},
					{id:15,text:'拟建订阅',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon15.png'},
					{id:16,text:'邮件提醒',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon16.png'},
					{id:17,text:'系统提醒',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon17.png'},
					{id:18,text:'微信提醒',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon18.png'},
					{id:19,text:'标讯导出',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon19.png'},
					{id:20,text:'附件查看',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon20.png'},
					
					{id:21,text:'企业监控',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon21.png'},
					{id:22,text:'企业分析',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon22.png'},
					{id:23,text:'业务渠道拓展',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon23.png'},
					{id:24,text:'商机分析预测',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon24.png'},
					{id:25,text:'AI大数据报告',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon25.png'},
					{id:26,text:'项目分析报价分析',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon26.png'},
				],
                vipFlag:'3',
                quanList:[],
				totalPrice:'1599',
				couponUserId:'',
                activeNameT:'1',
                arrPro:[],
				arrPao:[],
                flagActivity:'',

            }
        },
        computed:{
            flag:function(){
                if(this.showAll == false){
                    this.isShow=true
                    return '收起'
                }
                else{
                    this.isShow=false
                    return '展开'
                }
            },
        },
        mounted() {
            let that=this
             //获取当前年份的前4年
             let now   = new Date();
            let yearn  = now.getFullYear();
            let yearArrs=[]
            yearArrs.push(yearn-4,yearn-3,yearn-2,yearn-1)
            that.yearArr=[
                {label:1,value:yearArrs[0]},
                {label:2,value:yearArrs[1]},
                {label:3,value:yearArrs[2]},
                {label:4,value:yearArrs[3]},
            ] 

            that.sxList('pub_date')  //发布时间
            that.sxList('industry_category')  //更多筛选-发布类型
            that.sxList('bidding_procurement_type')  //招采类型
            that.sxList('bidding_unit')   //招采单位
            that.sxList('budget_amount')  //预算金额
            that.sxList('agency')  
            that.sxList('bid_winning_amount')    //中标金额
            that.sxList('contact_information')  
            that.sxList('en_contact_information')
            that.sxList('enclosure')  
            that.sxList('bidding_type')     //招标类型
            that.sxList('bid_winning_type') 
            that.sxList('approval_stage') 
            that.sxList('enterprise_role')

            that.sxList('registered_capital') 
            that.sxList('establishment_period')
            that.sxList('certificate_information') 
            that.sxList('building_qualification')
         
            that.provice('')

            if(that.$route.query.keyword!=''&&that.$route.query.keyword!=null&&that.$route.query.keyword!=undefined){
                that.searchCon=that.$route.query.keyword
            }

            if(that.$route.query.code!=''&&that.$route.query.code!=null&&that.$route.query.code!=undefined){
                that.clickTabs=that.$route.query.code
                that.activeName=that.$route.query.code

                if(that.clickTabs==2){
                    that.sxList('bidding_type')
                }else if(that.clickTabs==3){
                    that.sxList('bid_winning_type')
                }else if(that.clickTabs==4){
                    that.sxList('proposed_type')
                }else{
                    that.zbList=''
                }

            }

            if(that.clickTabs==5){
                that.showQyList(that.currentPage,that.qyflag1,that.qyflag2,that.qyflag3)
            }else{
                // if(that.clickTabs==1){
                //     that.showZbList(that.currentPage,'',that.pxLabel)
                //     that.messClick=''
                // }else if(that.clickTabs==2){
                //     that.showZbList(that.currentPage,'1',that.pxLabel)
                //     that.messClick='1'
                // }else if(that.clickTabs==3){
                //     that.showZbList(that.currentPage,'2',that.pxLabel)
                //     that.messClick='2'
                // }else if(that.clickTabs==4){
                //     that.showZbList(that.currentPage,'3',that.pxLabel)
                //     that.messClick='3'
                // }
               // that.showZbList(that.currentPage,that.messClick,that.pxLabel)
            }

            let phoneNos=JSON.parse(localStorage.getItem('phoneNo'))
            that.phoneNo=phoneNos

            that.showQuan()
           
            this.$nextTick(() => {
                setTimeout(() => {
                    that.calculateText()
                }, 500)
            })
          
        },
     
        methods: {
            Sign(){
                let that=this
                that.ewmVisible=false
                that.isChao=false
                setTimeout(()=>{
                    that.amountChanges('true')
                },1000)
            },
            handleClickT(tab, event) {
                console.log(tab._props.name)
                this.activeNameT=tab._props.name
            },
             // 查询是否有优惠券
             showQuan(){
				let that = this;
                that.$axios.post('/webInterface/queryCouponUserList?couponType=3'+'&useState=1')
                    .then(function (res) {
                        //console.log(res);
                        if(res.data.resultcode==1){
                            that.quanList=res.data.couponUserList
                            if(that.quanList.length>0){
                                if(that.vipFlag==1){
                                    that.totalPrice=599-that.quanList[0].couponInfo.couponAmount
                                }else if(that.vipFlag==2){
                                    that.totalPrice=999-that.quanList[0].couponInfo.couponAmount
                                }else{
                                    that.totalPrice=1599-that.quanList[0].couponInfo.couponAmount
                                }
                                that.couponUserId=that.quanList[0].couponUserId
                            }else{
                                that.couponUserId=''
                                if(that.vipFlag==1){
                                    that.totalPrice=599
                                }else if(that.vipFlag==2){
                                    that.totalPrice=999
                                }else{
                                    that.totalPrice=1599
                                }
                            }
                        }
                    })
                    .catch(function (err) { 
                    })
			},
            del(){
               this.searchCon=''
               let urls=window.location.href
               if (urls.indexOf('&') !== -1) {
                urls= urls.split("&")[0] 
                window.history.pushState({}, 0, urls)
               }
               this.currentPage=1
               if(this.clickTabs==5){
                this.showQyList(this.currentPage,this.qyflag1,this.qyflag2,this.qyflag3)
               }else{
                this.showZbList(this.currentPage,this.messClick,this.pxLabel)
               }
            },
            changeExpend(id){
                this.proviceList = this.proviceList.map(item=>{
                    if(item.id === id){
                        item.expend = !item.expend;
                    }
                    return item;
                })
            },
             amountChange(e){
              this.beiji=e
            },
            amountChanges(e){
              this.beiji=e
              this.flagActivity='1'
            },
            detailsNot(noticeId){
                const {href}=this.$router.resolve({
                // 跳转到的页面路径
                path: '/noticeDetails',
                // 传递的参数集合
                query: {
                    noticeId: noticeId
                }
              })
              window.open(href,'_blank')
            },
            handleClick(event) {
                this.clickTabs=event._props.name
                this.currentPage=1
                this.provinceCode=''
                this.numberOne=0
                this.numberThree=''
                this.valueYear=''
                this.valueYearzdy=''
                this.timeCode=''
                 this.startTime=''
                 this.endTime=''

                 //切换菜单时更多筛选内容清空
                 this.sxvalue1='行业类型'
                 this.sxvalue1s=''
                 this.sxvalue2='招采类型'
                 this.sxvalue2s=''
                //  this.sxvalue3='招采单位'
                //  this.sxvalue3s=''
                 this.sxvalue4='预算金额'
                 this.sxvalue4s=''
                 this.sxvalue5='代理单位'
                 this.sxvalue5s=''
                 this.sxvalue6='中标金额'
                 this.sxvalue6s=''
                 this.sxvalue7='联系方式'
                 this.sxvalue7s=''
                 this.sxvalue7='联系方式'
                 this.sxvalue77s=''
                //  this.sxvalue8='附件'
                //  this.sxvalue8s=''
                 this.sxvalue9='注册资本'
                 this.sxvalue9s=''
                 this.sxvalue10='成立年限'
                 this.sxvalue10s=''
               
                if(this.clickTabs==1){
                    this.messClick=''
                    this.zbList=''
                    this.number4s=0
                    this.messageCodesA=''
                    this.showZbList(this.currentPage,'',this.pxLabel)
                }else if(this.clickTabs==2){
                    this.messClick='1'
                    this.sxList('bidding_type')
                    this.number4s=0
                    this.messageCode=''
                    this.messageCodesA=''
                    this.showZbList(this.currentPage,'1',this.pxLabel)
                }else if(this.clickTabs==3){
                    this.messClick='2'
                    this.sxList('bid_winning_type')
                    this.number4s=0
                    this.messageCode=''
                    this.messageCodesA=''
                    this.showZbList(this.currentPage,'2',this.pxLabel)
                }else if(this.clickTabs==4){
                    this.messClick='3'
                    this.sxList('proposed_type')
                    this.number4s=0
                    this.messageCode=''
                    this.messageCodesA=''
                    this.showZbList(this.currentPage,'3',this.pxLabel)
                }else if(this.clickTabs==5){
                    this.zbList=''
                    this.number4s=0
                    this.messageCode=''
                    this.messageCodesA=''
                    this.showQyList(this.currentPage,this.qyflag1,this.qyflag2,this.qyflag3)
                   
                }
            },
            calculateText() {
                // 这是默认两行数据的高度，一行的高度可以自定义 可以*3 *4达到三行或者四行显示展示和收起的效果
                let twoHeight = 22 * 2;
                this.textHeight = `${twoHeight}px`;
                //let curHeight = this.$refs.descs.clientHeight;
                let curHeight =120
                if (curHeight > twoHeight) {
                    this.status = true;
                    this.idShowText = true;
                } else {
                    this.status = false;
                    this.idShowText = false;
                }
            },
            showStaus(){
             if(this.status==true){
                this.status=false
             }else{
                this.status=true
             }
            }, 
            provice(code){
                this.$axios.post('/webInterface/queryAreaList?parentAreaCode='+code).then(res => {
                    if (res.data) {
                      // console.log(res.data) 
                        let aa= {areaCode:'',areaName:'全部'}
                     if(code==''){
                       this.proviceList=res.data.localareaList
                       this.proviceList.unshift(aa)
                    }else{
                        this.cityList=res.data.localareaList
                        this.cityList.unshift(aa)
                    } 
                    }
                }).catch(err => {
                })
            },
            provices(code){
                this.$axios.post('/webInterface/queryAreaList?parentAreaCode='+code).then(res => {
                    //console.log(res.data) 
                    if (res.data) {
                        let aa= {areaCode:'',areaName:'全部'}
                       this.areaList=res.data.localareaList
                       this.areaList.unshift(aa)
                    }
                }).catch(err => {
                })
            },
            sxList(text){
                let that=this
                that.$axios.post('/webInterface/querySysRefList?optionCode='+text).then(response => {
                  //console.log(response)
                    if (response.data) {
                           let aa= {dictionaryOptionId:'',optionName:'全部'}
                           let bb= {dictionaryOptionId:'',optionName:'不限'}
                        if(text=='industry_category'){
                            that.sxList1=response.data.sysRefListList  //行业分类
                        }else if(text=='pub_date'){
                            that.timeList=response.data.sysRefListList   //时间选择
                            that.timeList.unshift(aa)
                            that.timeCode=that.timeList[5].dictionaryOptionId
                            that.numberThree=5 

                            if(that.clickTabs==1){
                                that.showZbList(that.currentPage,'',that.pxLabel)
                                that.messClick=''
                            }else if(that.clickTabs==2){
                                that.showZbList(that.currentPage,'1',that.pxLabel)
                                that.messClick='1'
                            }else if(that.clickTabs==3){
                                that.showZbList(that.currentPage,'2',that.pxLabel)
                                that.messClick='2'
                            }else if(that.clickTabs==4){
                                that.showZbList(that.currentPage,'3',that.pxLabel)
                                that.messClick='3'
                            }


                        }else if(text=='bidding_procurement_type'){
                            that.sxList2=response.data.sysRefListList //招采分类
                        }else if(text=='bidding_unit'){
                            that.sxList3=response.data.sysRefListList   //招采单位
                        }else if(text=='budget_amount'){
                            that.sxList4=response.data.sysRefListList   //招采单位
                            // response.data.sysRefListList.forEach((item,index)=>{
                            //     let opt={}
                            //     opt.value=item.dictionaryOptionId 
                            //     opt.label=item.optionName
                            //     this.sxList4.push(opt)
                            // })
                        }else if(text=='agency'){
                            that.sxList5=response.data.sysRefListList   //招采单位
                        }else if(text=='bid_winning_amount'){
                            that.sxList6=response.data.sysRefListList   //招采单位
                        }else if(text=='contact_information'){
                            that.sxList7=response.data.sysRefListList   //中标金额
                        }else if(text=='en_contact_information'){
                            that.sxList77=response.data.sysRefListList
                        }else if(text=='enclosure'){
                            that.sxList8=response.data.sysRefListList   //招采单位
                        }else if(text=='bidding_type'){
                            that.zbList=response.data.sysRefListList   //招标类型
                           // this.zbList.unshift(aa)
                        }else if(text=='bid_winning_type'){
                            that.zbList=response.data.sysRefListList   //中标类型
                            //this.zbList.unshift(bb)
                        }else if(text=='proposed_type'){
                            that.zbList=response.data.sysRefListList   //拟建项目
                            that.zbList.unshift(bb)
                        }else if(text=='approval_stage'){
                            that.zbList2=response.data.sysRefListList   //招标类型
                            that.zbList2.unshift(aa)
                        }else if(text=='enterprise_role'){
                            that.zbList3=response.data.sysRefListList   //招标类型
                        }else if(text=='registered_capital'){
                            that.sxList9=response.data.sysRefListList   //招标类型
                        }else if(text=='establishment_period'){
                            that.sxList10=response.data.sysRefListList   //招标类型
                        }else if(text=='certificate_information'){
                            that.sxList11=response.data.sysRefListList   //招标类型
                        }else if(text=='building_qualification'){
                            that.sxList12=response.data.sysRefListList   //招标类型
                        }    
                        
                        

                    }
                }).catch(err => {
                })
            },
           //点击省
           proviceChang(index,areaCode) {
                this.numberOne = index;
                this.provinceCode=areaCode
                this.currentPage=1
                this.numberTwo = '';
                this.cityCode=''
                this.numberArea = ''
                this.areaCode=''
                this.areaList=[]
                this.isCountry=false
               this.provice(areaCode)
                if(index!=0){
                    this.isCity=true 
                    this.isCountry=false
                }else{
                    this.isCity=false
                    this.isCountry=false
                }
                if(this.clickTabs!=5){
                    this.showZbList(this.currentPage,this.messClick,this.pxLabel)
                }else{
                    this.showQyList(this.currentPage,this.qyflag1,this.qyflag2,this.qyflag3)
                }
                
            },
            //点击市
            cityChang(index,areaCode) {
                this.numberTwo = index
                this.cityCode=areaCode
                this.numberArea = ''
                this.areaCode=''
                this.provices(areaCode)
                this.currentPage=1
                if(index!=0){
                    this.isCountry=true
                }else{
                    this.isCountry=false
                }
                if(this.clickTabs!=5){
                    this.showZbList(this.currentPage,this.messClick,this.pxLabel)
                }else{
                    this.showQyList(this.currentPage,this.qyflag1,this.qyflag2,this.qyflag3)
                }
            },
            //点击区
            areaChang(index,areaCode) {
                this.numberArea = index;
                this.areaCode=areaCode
                this.currentPage=1
                if(this.clickTabs!=5){
                    this.showZbList(this.currentPage,this.messClick,this.pxLabel)
                }else{
                    this.showQyList(this.currentPage,this.qyflag1,this.qyflag2,this.qyflag3)
                }
            },
            hqYears(e,id){
                this.valueYear=e
                this.valueYearzdy=''  //自定义时间清空
                this.zdyTime=''   
                this.startTime=''   //自定义开始时间清空
                this.endTime=''    //自定义结束时间清空
                this.timeCode=id   //时间清空
                this.currentPage=1 
                this.showZbList(this.currentPage,this.messClick,this.pxLabel)
            },
            zdyYears(id){
               this.startTime=this.timeZh(this.valueYearzdy[0])  //开始时间
               this.endTime=this.timeZh(this.valueYearzdy[1])   //结束时间
               //this.zdyTime=this.startTime+'-'+this.endTime
               this.currentPage=1
               this.valueYear=''  //往年
               this.timeCode=id  //时间
               this.showZbList(this.currentPage,this.messClick,this.pxLabel)
            },
           
            timeZh(time){
                var date = new Date(time)
                var y = date.getFullYear()
                var m = date.getMonth() + 1
                m = m < 10 ? ('0' + m) : m
                var d = date.getDate()
                d = d < 10 ? ('0' + d) : d
                const times = y + '-' + m + '-' + d
                return times
            },
            timeChang(index,areaCode) {
                this.numberThree = index;
                this.timeCode=areaCode  //时间
                //this.wnTime='' 
                this.valueYear=''  //往年
                this.valueYearzdy=''  //自定义
                this.currentPage=1
                this.showZbList(this.currentPage,this.messClick,this.pxLabel)
            },
            messageChang(index,areaCode) {
                this.currentPage=1
                this.number4 = index;
                this.messageCode=areaCode
                this.messageCodesA=''
                this.number4s=0
              
                if(index==1){
                    this.sxList('bidding_type')
                }else if(index==2){
                    this.sxList('bid_winning_type')
                }else if(index==3){
                    this.sxList('proposed_type')
                }else{
                    this.zbList=''
                }

                this.showZbList(this.currentPage,this.messClick,this.pxLabel)
            },
            messageChangs(index,areaCode){
                this.currentPage=1
                this.number4s = index;
                this.messageCodesA=areaCode
                this.showZbList(this.currentPage,this.messClick,this.pxLabel)
            },
            zbChang(index,areaCode,flag){
              if(flag==1){
                this.zbvalue=areaCode   //招标类型
                this.number5=index
              }else if(flag==2){
                this.zbvalue1=areaCode   //中标类型
                this.number6=index
              }else if(flag==3){
                this.zbvalue2=areaCode  //审批阶段
                this.number7=index
              }else if(flag==4){
                this.zbvalue3=areaCode   //企业角色
                this.number8=index
              }
              this.currentPage=1
              if(this.clickTabs==5){
                this.showQyList(this.currentPage,this.qyflag1,this.qyflag2,this.qyflag3)
               }else{
                this.showZbList(this.currentPage,this.messClick,this.pxLabel)
               }
             
            },
            moreSx(){
                //console.log(this.phoneNo)
                 if(this.phoneNo!=null&&this.phoneNo!=''){

                 }else{
                    setTimeout(()=>{  //设置延迟执行
                        this.$router.push({path: '/login'});
                    },600);
                 }
            },
            sxOne(e,flag){
                console.log(e)
                if(flag==1){
                    if(e.split(",")[1]=='不限'){
                        this.sxvalue1='行业类型'
                    }else{
                        this.sxvalue1=e.split(",")[1]
                    }
                    this.sxvalue1s=e.split(",")[0]
                }else if(flag==2){
                // this.sxvalue2=e
                    if(e.split(",")[1]=='不限'){
                        this.sxvalue2='招采类型'
                    }else{
                        this.sxvalue2=e.split(",")[1]
                    }
                    this.sxvalue2s=e.split(",")[0]
                }else if(flag==3){
                    this.sxvalue3=e
                }else if(flag==4){
                    //this.sxvalue4=e
                    if(e.split(",")[1]=='不限'){
                        this.sxvalue4='预算金额'
                    }else{
                        this.sxvalue4=e.split(",")[1]
                    }
                    this.sxvalue4s=e.split(",")[0]
                }else if(flag==5){
                    //this.sxvalue5=e
                    if(e.split(",")[1]=='不限'){
                        this.sxvalue5='代理单位'
                    }else{
                        this.sxvalue5=e.split(",")[1]
                    }
                    this.sxvalue5s=e.split(",")[0]
                }else if(flag==6){
                    if(e.split(",")[1]=='不限'){
                        this.sxvalue6='中标金额'
                    }else{
                        this.sxvalue6=e.split(",")[1]
                    }
                    this.sxvalue6s=e.split(",")[0]
                }else if(flag==7){
                    //this.sxvalue7=e
                    if(e.split(",")[1]=='不限'){
                        this.sxvalue7='联系方式'
                    }else{
                        this.sxvalue7=e.split(",")[1]
                    }
                    this.sxvalue7s=e.split(",")[0]
                }else if(flag==77){
                    //this.sxvalue7=e
                    if(e.split(",")[1]=='不限'){
                        this.sxvalue77='联系方式'
                    }else{
                        this.sxvalue77=e.split(",")[1]
                    }
                    this.sxvalue77s=e.split(",")[0]
                }else if(flag==8){
                    this.sxvalue8=e
                }
                else if(flag==9){
                    //console.log(e.split(",")[0])
                    if(e.split(",")[1]=='不限'){
                        this.sxvalue9='注册资本'
                    }else{
                        this.sxvalue9=e.split(",")[1]
                    }
                    this.sxvalue9s=e.split(",")[0]
                }else if(flag==10){
                    if(e.split(",")[1]=='不限'){
                        this.sxvalue10='成立年限'
                    }else{
                        this.sxvalue10=e.split(",")[1]
                    }
                    this.sxvalue10s=e.split(",")[0]
                }else if(flag==11){
                    this.sxvalue11=e
                }else if(flag==12){
                    this.sxvalue12=e
                }
                this.currentPage=1
                if(this.clickTabs==5){
                    this.showQyList(this.currentPage,this.qyflag1,this.qyflag2,this.qyflag3)
                }else{
                    this.showZbList(this.currentPage,this.messClick,this.pxLabel)
                }
              
               
             },
            //查询招标
            showZbList(currentP,clickTabs,pxFlag){
                var that=this
                let mesId=''
                if(clickTabs==''){
                    mesId=that.messageCode
                }else{
                    mesId=clickTabs
                }
                let addressCode=''
                if(that.cityCode==''){
                    addressCode= that.provinceCode
                }else if(that.areaCode==''){
                    addressCode= that.cityCode
                }else{
                    addressCode=that.areaCode
                }
               // console.log(that.messageCodesA)
                // console.log(addressCode)
                // console.log(that.timeCode)
                //   console.log(mesId)
                // console.log(that.sxvalue1s)
                //   console.log(that.sxvalue2s)
                // console.log(that.sxvalue3)
                //   console.log(that.sxvalue4s)
                // console.log(that.sxvalue5s)


                // console.log(that.sxvalue6s)
                // console.log(that.zbvalue) 
                // console.log(that.zbvalue1)
                // console.log(that.zbvalue2) 
                // console.log(pxFlag)
                // console.log(encodeURI(that.searchCon))
                // console.log(that.valueYear)

                // console.log(that.startTime)
                // console.log(that.endTime)
                let aprams= {  
                            pageSize:that.pageSize,
                            pageNo:currentP,
                            areaCode: addressCode,  //地区
                            pubDate:that.timeCode,  //发布时间
                            dataType:mesId,  //信息类型
                            dataSubType:that.messageCodesA,   //信息类型二级
                            industryCategory:that.sxvalue1s, // 行业分类 
                            biddingProcurementType:that.sxvalue2s, //招采类型 
                            biddingUnit:that.sxvalue3,//招采单位 
                            budgetAmount:that.sxvalue4s,   //预算金额 
                            agency:that.sxvalue5s,//代理单位
                            bidWinningAmount:that.sxvalue6s, //中标金额 
                            contactInformation:that.sxvalue7s, //联系方式
                            enclosure:that.sxvalue8,   //附件
                            registeredCapital:that.sxvalue9s,  //注册资本  
                            establishmentPeriod:that.sxvalue10s, //成立年限
                            certificateInformation:that.sxvalue11, //证书信息
                            buildingQualification:that.sxvalue12,//建筑资质

                            biddingType:that.zbvalue,//招标类型
                            bidWinningType:that.zbvalue1,//中标类型
                            approvalStage:that.zbvalue2,//审批阶段
                           
                            isSearch:1,
                            isTuij:1,
                            putDateOrder:pxFlag,   //排序方式
                            searchData:encodeURI(that.searchCon),
                              //往年多传的参数
                            pubDateYear:that.valueYear,
                            //自定义时间传的参数
                            pubDateStart:that.startTime,
                            pubDateEnd:that.endTime,
                        }
                        console.log(aprams)
                that.$axios.get('/webInterface/queryScripingpageList', {  //params参数必写 , 如果没有参数传{}也可以
                        params:aprams
                    })
                    .then(function (res) {
                       console.log(res)
                       if(res.data.resultcode==1){
                        that.listAll = res.data.scripingpageList
                        that.total=res.data.totalCount
                        if(that.total>=100000){
							that.totalA='10万+'
						}else{
                            that.totalA=res.data.totalCount
                        }
                        var arr=[]
                        var arrs=[]
                        for(var i=0;i<that.listAll.length;i++){
                            arr.push(that.listAll[i].procurementType.split(','))
                            arrs.push(that.listAll[i].industryCategory.split(','))
                        }
                        that.arrPro=arr
                        that.arrPao=arrs
                      } else if(res.data.resultcode==-1){
                        if(res.data.vipAuth=='-9999'){
                            setTimeout(()=>{  //设置延迟执行
                                that.$router.push({path: '/login'});
                            },600);
                        }else{
                            // this.sxvalue1='行业类型'
                            //  this.sxvalue1s=''
                            
                            that.$message.warning(res.data.message) 
                            that.currentPage=1

                            that.sxvalue1='行业类型'
                            that.sxvalue1s=''
                            that.sxvalue2='招采类型'
                            that.sxvalue2s=''
                            //  that.sxvalue3='招采单位'
                            //  that.sxvalue3s=''
                            that.sxvalue4='预算金额'
                            that.sxvalue4s=''
                            that.sxvalue5='代理单位'
                            that.sxvalue5s=''
                            that.sxvalue6='中标金额'
                            that.sxvalue6s=''
                            that.sxvalue7='联系方式'
                            that.sxvalue7s=''
                            that.sxvalue7='联系方式'
                            that.sxvalue77s=''
                            //  that.sxvalue8='附件'
                            //  that.sxvalue8s=''
                            that.sxvalue9='注册资本'
                            that.sxvalue9s=''
                            that.sxvalue10='成立年限'
                            that.sxvalue10s=''
                            // that.listAll =[]
                            // that.total=0
                            // that.showZbList(that.currentPage,that.messClick,that.pxLabel)
                        }
                        // setTimeout(()=>{  //设置延迟执行
                        //         that.$router.push({path: '/login'});
                        //  },600);
                     } else{
                        that.$message.warning(res.data.message)
                        that.listAll =[]
                        that.total=0
                        that.currentPage=1
                        that.showZbList(that.currentPage,that.messClick,that.pxLabel)
                      }
                    })
                    .catch(function (err) {
                      // console.log(err);
                    })
            },
            //查询企业
            showQyList(currentP,flag1,flag2,flag3){
                var that=this
                let addressCode=''
                if(that.cityCode==''){
                    addressCode= that.provinceCode
                }else if(that.areaCode==''){
                    addressCode= that.cityCode
                }else{
                    addressCode=that.areaCode
                }
               // console.log(that.searchCon)
                that.$axios.get('/webInterface/queryEnCompanyList', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            pageSize:that.pageSize,
                            pageNo:currentP,
                            areaCode: addressCode,  //地区
                            enterpriseRole:that.zbvalue3,  //企业角色
                            industryCategory:that.sxvalue1s, // 行业分类 
                            registeredCapital:that.sxvalue9s,  //注册资本  
                            establishmentPeriod:that.sxvalue10s, //成立年限
                            certificateInformation:that.sxvalue11, //证书信息
                            buildingQualification:that.sxvalue12,//建筑资质
                            contactInformation:that.sxvalue77s, //联系方式

                            searchData:encodeURI(that.searchCon),
                            //bidNumOrder:flag1,  
                            registeredCapitalOrder:flag1,
                            //establishmentPeriodOrder:flag3  
                        }
                    })
                    .then(function (res) {
                       console.log(res);
                       if(res.data.resultcode==1){
                        that.gysList = res.data.enCompanyList
                        that.total=res.data.totalCount
                        if(that.total>=10000){
							that.totalA='1万+'
						}else{
                            that.totalA=res.data.totalCount
                        }
                     }else if(res.data.resultcode==-1){
                        if(res.data.vipAuth=='-9999'){
                            setTimeout(()=>{  //设置延迟执行
                                that.$router.push({path: '/login'});
                            },600);
                        }else{
                            that.currentPage=1
                            setTimeout(()=>{
                                that.$router.push({path: '/vipPackage'});
                            })

                            that.sxvalue1='行业类型'
                            that.sxvalue1s=''
                            that.sxvalue2='招采类型'
                            that.sxvalue2s=''
                            //  that.sxvalue3='招采单位'
                            //  that.sxvalue3s=''
                            that.sxvalue4='预算金额'
                            that.sxvalue4s=''
                            that.sxvalue5='代理单位'
                            that.sxvalue5s=''
                            that.sxvalue6='中标金额'
                            that.sxvalue6s=''
                            that.sxvalue7='联系方式'
                            that.sxvalue7s=''
                            that.sxvalue7='联系方式'
                            that.sxvalue77s=''
                            //  that.sxvalue8='附件'
                            //  that.sxvalue8s=''
                            that.sxvalue9='注册资本'
                            that.sxvalue9s=''
                            that.sxvalue10='成立年限'
                            that.sxvalue10s=''

                            // that.listAll =[]
                            // that.total=0
                            // that.showZbList(that.currentPage,that.messClick,that.pxLabel)
                        }
                       
                     } else{
                        that.$message.warning(res.data.message)
                        that.gysList =[]
                        that.total=0
                        that.currentPage=1
                        that.showQyList(that.currentPage,that.qyflag1,that.qyflag2,that.qyflag3)
                      }
                    })
                    .catch(function (err) {
                        console.log(err);
                    })
            },
            batch(){

            },
           //分页查询数据
            handleCurrentChange(val) {
                this.currentPage=val
               
               if(this.clickTabs==5){
                this.showQyList(this.currentPage,this.qyflag1,this.qyflag2,this.qyflag3)
               }else{
                this.showZbList(this.currentPage,this.messClick,this.pxLabel)
               }
               document.querySelector('#tabsQ').scrollIntoView({
                    behavior: "smooth"
                });
            },
           zbDetail(flag){
            let phoneNos=JSON.parse(localStorage.getItem('phoneNo'))
            var that=this
            that.objectIds=flag
            console.log(phoneNos)

            that.activeNameT='1'
            that.vipFlag=3
            that.goodsId='7163018607638296736'

            if(phoneNos!=null&&phoneNos!=''){
                    that.$axios.get('/webInterface/checkVipAuth', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            objectType:2,
                            objectId:flag
                        }
                    })
                    .then(function (res) {
                       console.log(res);
                       if(res.data.resultcode==1){
                        const {href}=that.$router.resolve({
                            // 跳转到的页面路径
                            path: '/zbDetails',
                            query: {
                                flag: flag,
                            }
                        })
                        window.open(href,'_blank')
                       }else{
                           that.ewmVisible=true
                           that.isChao=true  //不是vip  isChao=true 弹框支付
                       } 
                    })
                    .catch(function (err) { 
                    })
                }else{
                    setTimeout(()=>{  //设置延迟执行
                        this.$router.push({path: '/login'});
                    },600);
                }
                
            },
            companyDetail(flag){
               let phoneNos=JSON.parse(localStorage.getItem('phoneNo'))
                let that=this
                that.objectIds2=flag
                that.objectIds=''
                if(phoneNos!=null&&phoneNos!=''){
                that.$axios.get('/webInterface/checkVipAuth', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            objectType:1,
                            objectId:flag
                        }
                    })
                    .then(function (res) {
                       console.log(res);
                       if(res.data.resultcode==1){
                        const {href}=that.$router.resolve({
                            // 跳转到的页面路径
                            path: '/companyDetails',
                            // 传递的参数集合
                            query: {
                                flag: flag
                            }
                        })
                        window.open(href,'_blank')
                       }else{
                         //不是会员 需要开通5毛或者年费vip
                         if(res.data.vipAuth==1){
                              //调接口 微信支付的弹框  3种情况
                              that.ewmVisible=true
                              that.isChao=true //不是vip  isChao=true 弹框支付
                         }else{
                            that.ewmVisible=true
                            that.isChao=false
                         }
                       } 
                    })
                    .catch(function (err) { 
                    })
                }else{
                    setTimeout(()=>{  //设置延迟执行
                        this.$router.push({path: '/login'});
                    },600);
                }
            },

             //关闭支付弹框
             handleClose(){
               // this.goodsId='7163018607638296736'
                this.ewmVisible=false
                this.isPay=true
                clearInterval(this.timerT)
                this.timerT = null
            },
          
        //     taocan(flag){
        //         let that=this
		//    		that.vipFlag=flag
        //            if(that.quanList.length>0){
        //                 if(that.vipFlag==1){
        //                     that.totalPrice=599-that.quanList[0].couponInfo.couponAmount
        //                 }else if(that.vipFlag==2){
        //                     that.totalPrice=999-that.quanList[0].couponInfo.couponAmount
        //                 }else{
        //                     that.totalPrice=1599-that.quanList[0].couponInfo.couponAmount
        //                 }
        //                 that.couponUserId=that.quanList[0].couponUserId
        //             }else{
        //                 that.couponUserId=''
        //                 if(that.vipFlag==1){
        //                     that.totalPrice=599
        //                 }else if(that.vipFlag==2){
        //                     that.totalPrice=999
        //                 }else{
        //                     that.totalPrice=1599
        //                 }
        //             }
		//    },
           taocan(flag){
                let that=this
                that.vipFlag=flag
                if(flag==3){
					that.showQuan()
					if(that.quanList.length>0){
                        that.totalPrice=1599-that.quanList[0].couponInfo.couponAmount
						that.couponUserId=that.quanList[0].couponUserId
					}else{
						
                        that.totalPrice=1599
						that.couponUserId=''
					}
				}else{
					that.quanList=[]
					that.couponUserId=''
                    if(flag==1){
                        that.totalPrice=599
                    }else if(flag==2){
                        that.totalPrice=999
                    }
				}
		   },
            payfor(){
                let that = this;
				let goodsId=''
				let OneObjectId=''
				let twoObjectId=''
				if(that.activeNameT==1){
					goodsId='7163018607638296736'       //0.5/条
					OneObjectId=that.objectIds
				}else{
					if(that.vipFlag==1){
						goodsId='5166747643280060475'  //包日
					}else if(that.vipFlag==2){
						goodsId='5166747643280060476'   //包月
					}else if(that.vipFlag==3){
						goodsId='5166747643280060474'   //包年
					}
					if(that.couponUserId!=undefined&&that.couponUserId!=''){
						twoObjectId=that.couponUserId
					}else{
						twoObjectId=''
					}
				}
               // console.log(that.vipFlag)
                // console.log(goodsId)
                // console.log(OneObjectId)
                // console.log(twoObjectId)
               that.$axios.get('/webInterface/generateOrder', {  //params参数必写 , 如果没有参数传{}也可以
                    params: {  
                        goodsId:goodsId,  //套餐id
                        orderIds:'',
                        objectId:OneObjectId, //单条详情id
                        quantity:1,
                        freight:0,
                        payType:2,  //1是支付宝支付，2是微信支付
                        objectType:2, //2是标讯，1是企业					
                        //sourceType:"wx",
                        couponUserId:twoObjectId   //优惠券id
                    }
                })
                .then(function (res) {
                    console.log(res);
                    if(res.data.resultcode==1){
                        that.ewmVisible=true
                        that.ewmImg=res.data.reStr  //二维码
                        that.isPay=false  
                        that.orderPays=res.data.orderInfo
                       // that.orderInfoId=res.data.orderInfo.id
                        that.$axios.post('/webInterface/queryGoodsInfo?id='+goodsId)
                        .then(function (res) {
                           console.log(res);
                            if(res.data.resultcode==1){
                                 that.orderPay=res.data.goodsInfo                               
                            }
                        })
                        .catch(function (err) { 
                        })
                         //that.isFlag=1
                         that.timerT = window.setInterval(() => {
                            that.check(res.data.orderInfo.id)
                           // that.isFlag++
                        }, 3000);
                    }else{
                        this.$message.error(res.data.resultmessage)
                    }
                })
                .catch(function (err) { 
                })

            },
            check(orderId){
                var that=this
               that.$axios.post('/webInterface/queryOrderInfo?orderId='+orderId+'&flag=1')
                .then(function (res) {
                    console.log(res);
                    // let state=''
                    // if(that.isFlag==4){
                    //    state=1
                    // }

                    // res.data.resultcode=1
                    // res.data.orderInfo.state=1

                 if(res.data.resultcode=='1'&&res.data.orderInfo.state!=0){
                     that.paySuccess=true
                     //that.messageTitle='支付成功，'+that.totalTime +'秒后将跳转至详情页面'
                      that.messageTitle='支付成功，将跳转至详情页面'
                      that.ewmVisible=false
                      that.isPay=true
                      that.paySuccess=false
                      clearInterval(that.timerT)
                      that.totalTime = 3
                      that.timerT = null
                    //标讯
                    setTimeout(()=>{
                        that.$router.push({
                                path: '/zbDetails',
                                query: {
                                flag: that.objectIds, 
                            }
                        })
                      },500)


                    //  let clock = setInterval(() => {
                    //     that.totalTime--
                    //     that.messageTitle='支付成功，'+that.totalTime +'秒后将跳转至详情页面'
                    //     if (that.totalTime==0) {     			//当倒计时小于0时清除定时器
                    //         clearInterval(clock)
                    //         that.ewmVisible=false
                    //         that.isPay=true
                    //         that.paySuccess=false
                    //         clearInterval(that.timerT)
                    //         that.totalTime = 3
                    //         that.timerT = null

                    //         that.$router.push({
                    //                  path: '/zbDetails',
                    //                  query: {
                    //                  flag: that.objectIds, 
                    //               }
                    //             })
                    //     }
                    // },300)
					}else{
                        that.paySuccess=false
                        clearInterval(clock)
                        that.isPay=true
                        clearInterval(that.timerT)
                        that.totalTime = 3
                        that.timerT = null

                    }
                })
                .catch(function (err) { 
                })
            },

            pxChange(e){
                this.pxLabel=e
                this.showZbList(this.currentPage,this.messClick,this.pxLabel)
            },
            pxChange2(e){
                this.qyflag1=e
                // if(e==1||e==2){
                //     this.qyflag1=e
                // }else if(e==3){
                //     this.qyflag2=1
                // }else if(e==4){
                //     this.qyflag2=2
                // }else if(e==5){
                //     this.qyflag3=1
                // }else if(e==6){
                //     this.qyflag3=2
                // }
                this.showQyList(this.currentPage,this.qyflag1,this.qyflag2,this.qyflag3) 
            },
           searchClick(){
            this.currentPage=1
            if(this.clickTabs==5){
                this.showQyList(this.currentPage,this.qyflag1,this.qyflag2,this.qyflag3)
               }else{
                this.showZbList(this.currentPage,this.messClick,this.pxLabel)
               }
           },
           submitForm(formName) {
            //console.log(this.form.price1)
            
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            mouseOver1(){
                
            },
            
          
           
        }
    }
</script>
<style scoped lang="less">
  .topCon{
  background:url(../assets/images/banner1.png);
  width: 100%;
  height: 295px;
  background-size: 100% 100%;
  position: relative;
}
.flex{
  display: flex;
  align-items: center;
}
.topHalf{
  text-align: center;
}
.hTitle{
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    padding-top: 70px;
}
.inputClass{
    border: none;
    outline: none;
    width: 77%;
    margin-right: 15px;
    margin-left: 12px;
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: 400;
}
.searchBtn{
    letter-spacing: 2px;
    cursor: pointer;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    width: 70px;
    height: 38px;
    background: #0078FF;
    text-align: center;
    line-height: 38px;
}
.ju-tinput{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 660px;
    height: 38px;
    background: #FFFFFF;
    border: 1px solid #0078FF;
    border-radius: 2px;
    margin-top:2px
}
.shuline{
  width: 2px;
  height: 25px;
  background: #BBBBBB;
}
.selLeft{
  min-width: 14%;
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
}
.selectImg{
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.hotSearch{
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  width: 970px;
  margin: 0 auto;
}
.hotTitles{
  font-weight: 500;
  color: #fff;
}
.reLine{
  background: #fff;
  margin: 0 20px;
  width: 2px;
  height: 18px;
  background: rgba(255,255,255,0.6);
}
.itemHot{
  margin-right: 20px;
  cursor: pointer;
}
/* 小喇叭通知 */
ui,li {
  list-style: none;
  width: 100%;
  overflow: hidden; 
white-space: nowrap; 
text-overflow: ellipsis; 
}
.mobile-marquee-wrapper{
    flex: 1;
    height: 40px;
    overflow: hidden;
    position: relative;
    #news{
    overflow: hidden;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000000;
    cursor: pointer;
    white-space: nowrap;
    line-height: 40px;
    position: absolute;
    top: 0;
  }
}
 
  .halfC{
  width: 1140px;
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  border-radius: 5px;
  margin: 0 auto;
  padding: 30px 30px;
}
/deep/.el-tabs{
    .el-tabs__header{
        margin: 0 0 20px;
    }
    .el-tabs__item{
        height: 43px !important;
        font-size: 16px !important;
    }
}
/deep/.el-pagination {
    .el-pagination__total,.el-pagination__jump{
    display: inline-block;
    font-size: 15px;
    }
    .el-pager li {
    font-size: 15px;
    }
}
.open{
    display: flex;
    justify-content: center;
    margin-top: -1px;
}
.statusA{
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #0078FF;
    border-width: 30px 25px 0 25px;
    border-style: solid;
    border-color: #fff transparent blue transparent;
    position: relative;
    width: 33px;
    padding: 0 14px;
    cursor: pointer;
}
select{
    padding: 3px;
    font-size: 16px;
    padding: 3px;
    font-size: 16px;
    border: 1px solid #DCDFE6;
    color: #000;
    height: 30px;
    outline: none;
}

.el-button{
    margin-left:0 !important;
    font-size: 16px !important;
    padding:0 !important;
    width: 82px;
    height: 30px;
}
.ant-spin-nested-loading {
    position: relative;
}
.ant-list-items {
    margin: 0;
    padding: 0;
    list-style: none;
}
.tc_list_item.tc_list_hover {
    transition: background-color .2s;
}
.tc_list_item {
    position: relative;
    // border-bottom: 1px solid #F3F3F3;
    padding: 10px 0 14px 0;

}
.title{
    font-size: 18px;
    font-weight: 700;
    padding: 10px 0;
    color: #333;
    cursor: pointer;
}

.gGdJlei{
    margin-bottom: 10px;
}
.fiklj9cw {
    color: #333;
}
.fiklj9cw .smailDiv {
    line-height: 22px;
    margin: 5px 0;
    max-width: 100%;
}
.fiklj9cw .smailDiv, .fiklj9cw .smailDiv>div {
    display: inline-block;
}
.fiklj9cw .smailDiv>div:first-child {
    color: #999;
    padding-right: 10px;
}
.fiklj9cw .smailDiv:not(:last-child) {
    margin-right: 30px;
}
/* ul{
    margin: 0;
    padding:0;
} */

.blueColor{
    color: #0078FF;
    cursor: pointer;
}
a{
    color:#000
}
.pagination{
    text-align: center;
    margin-top: 28px;
}


/deep/.el-input__inner{
    color: #222222;
}
/deep/.rightM{
    height: 30px;
    line-height: 30px;
    //margin-right:10px;
    .el-input{
        width: 160px !important;
        font-size: 16px !important;
        .el-input__inner{
            height: 30px !important;
            line-height: 30px !important;
            outline: none;
            padding-left: 9px;
            padding-right: 9px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #222222;
        }
        .el-input__inner::input-placeholder{
            color:#222;
        }
        .el-input__inner::-webkit-input-placeholder { /* WebKit browsers */ 
          color: #222; 
        }
        .el-input__suffix{
            display:none
        }
    }
}

// .jtImgsx{
//     width: 11px;
//     height: 12px;
//     position: absolute;
//     right: 16px;
//     top: 9px;
// }






.ant-spin-container {
    position: relative;
    transition: opacity .3s;
  }
  .ant-list-items {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    border-bottom: 1px solid #F3F3F3;
    padding: 10px 0;
  }
  .ant-list-items:last-child{
    border-bottom:none
  }
  .tc_list_item.tc_list_hover {
   // padding: 0 20px;
    transition: background-color .2s
  }
 
  .tc_list_item:not(:last-child):after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    border-bottom: 1px solid #eee;
  }
  .R-Dvn3zA{
    padding-top: 10px;
    display: flex;
  }
  .pYreFzHC {
    padding-right: 20px;
    // width: 80px;
    // height: 160px;
  }
  .pYreFzHC img {
    // border: 1px solid #eee;
    width: 60px;
    height: 60px;
    border-radius: 4px;
  }
  ._0aDOKXj6 {
    position: relative;
   width: calc(100% - 80px);
  }
  .FpwufnYn {
    display: flex;
    margin-bottom: 10px;
    cursor: pointer;
    justify-content: space-between;
  }
  .FpwufnYn a {
    font-size: 16px;
    font-family: PingFangSC-Medium,PingFang SC;
    font-weight: 700;
    color: #333;

}
.WMSbIy8X {
    position: absolute;
    top: 0;
    right: 0;
    height: 24px;
    color: #000;
    font-weight: 700;
}
.jutRowsA{
    height: 46px;
    background: #F5F7FA;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
        // margin: 10px 0;
}
.noticTitles{
    // line-height:46px;
    margin-right: 20px;
}
.noticTitles:last-child{
    margin-right: 0;
}
.huiColor{
     color:#999999;
}
.titles{
    font-size: 18px;
    font-weight: 700;
    color: #333;
    cursor: pointer;
}









 /* tab切换 */
 .chooseArea{
  font-size: 16px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #222222;
}
.areaCode,.timeCode{
  flex-wrap: wrap;
  cursor: pointer;
  width: 1050px;
  height:100%
}
.areaCode .item,.timeCode .item{
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-right: 8px;
  padding: 0 12px;
  margin-bottom: 10px;
}
.selected{
  background: #EFF7FF;
  border-radius: 4px;
  font-weight: 400;
  color: #0078FF; 
 
}
.tabCon{
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #222222;
  margin-top: 20px;
}
.conRows{
  border-bottom: 1px solid #F3F3F3;
  padding: 20px 0;
}
input[name="color"]::-webkit-input-placeholder { /* WebKit browsers */
color: red;
font-size:15px;
}
select{
    border: none;
    outline: none;
    margin-right: 22px;
    font-size:16px
}
/deep/.el-cascader{
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    .el-input{
        width: 108px !important;
        font-size: 16px !important;
    .el-input__inner{
        height: 30px !important;
        line-height: 30px !important;
        border: none;
        outline: none;
        padding-left: 0;
        padding-right: 15px;
        text-align: center;
    }
    .el-input__inner::input-placeholder{
            color:#222;
        }
        .el-input__inner::-webkit-input-placeholder { /* WebKit browsers */ 
          color: #222; 
        }
    .el-input__suffix{
        display:none
    }
  }
}
/deep/.faTime{
.selectitem{
    .el-input{
        width: 100px !important;
        font-size: 16px !important;
        .el-input__inner{
            height: 30px !important;
            line-height: 30px !important;
            border: none;
            outline: none;
            padding-left: 8px;
            padding-right: 5px;
        }
        .el-input__inner::input-placeholder{
            color:#222;
        }
        .el-input__inner::-webkit-input-placeholder { /* WebKit browsers */ 
          color: #222; 
        }
        .el-input__suffix{
            display:none
        }
    }
}
    }
    
/deep/.aShaixuan{
    
.el-select{
    height: 30px;
    line-height: 30px;
    .el-input{
        width: 110px !important;
        font-size: 16px !important;
        .el-input__inner{
            height: 30px !important;
            line-height: 30px !important;
            border: none;
            outline: none;
            padding-left: 0;
            padding-right: 15px;
            text-align: center;
        }
        .el-input__inner::input-placeholder{
            color:#222;
        }
        .el-input__inner::-webkit-input-placeholder { /* WebKit browsers */ 
          color: #222; 
        }
        .el-input__suffix{
            display:none
        }
    }
} 
}
/deep/ .el-date-editor {
    height: 33px;
    line-height: 30px;
    border: none !important;
    outline: none !important;
    padding-left: 10px !important;
    width: 210px;
    padding-right: 12px;
    margin-bottom:10px;
     .el-range-separator{
        display: none;
     }
     .el-input__icon{
       display: none;
    } 
    .el-range-input{
      width: 90px !important; 
      font-size: 16px !important;
      text-align: left;
      }
      .el-range-input::input-placeholder{
        color:#222;
     }
    .el-range-input::-webkit-input-placeholder { /* WebKit browsers */ 
        color: #222; 
     }
    }
    .selectitem{
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin-bottom: 10px;
        padding:0;
        width:75px
    }
    /deep/.selected{
        .el-range-input{
            background: none;
        border-radius: 4px;
        font-weight: 400;
        color: #0078FF;
      }
    }
    .jtImg{
        width: 11px;height: 12px;position: absolute;right: 132px;top: 9px;
    }
    .jtImgs{
        width: 11px;height: 12px;position: absolute;right:8px;top: 9px;
    }
    .el-popper{
        margin-top:0
    }
    .jtImgsxs{
        width: 11px;height: 12px;position: absolute;right:8px;top: 9px;
    }
    .jtImgsxsa{
        width: 11px;height: 12px;position: absolute;right:10px;top: 9px;
    }
    .jtImgsx{
        width: 11px;height: 12px;position: absolute;right: 3px;top: 9px;
    }
    /deep/.selectitem {
       .el-input{
        width: 76px !important;
        .el-input__inner{
            padding-right: 10px !important;
            padding-left: 11px;
            background: none;
            color: #0078FF;
        }
       }
    }
  
ul{
    padding:0;
    margin:0
}
    .newsList {
  width: 90%;
  height: 26px;
  overflow: hidden;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;

   /* background-color: red; */
}
.anim {
  transition: all 2s;
  margin-top: -30px;
}
#con1 li {
  list-style: none;
  cursor: pointer;
  color:#000;
}
.zcPr{
    max-width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.ant-popover-content{
    width: 150px;
    position: absolute;
    top: 48px;
    left: 126px;
    z-index: 999999;
    .ant-popover-inner {
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    
        .tc_select_number-range {
            padding: 12px;
            /deep/.el-form{
                .el-form-item{
                    .el-form-item__content{
                            display:flex
                        }
                }
            }
            
        }
    
}

}
.statusText{
    overflow: hidden;
    display: block;
}
.openClose{
    width: 62px;
    margin-top: 5px;
    color:#0078FF;
    cursor: pointer;
    font-size: 16px;
}
.jtImgsxsb{
    width: 12px;height: 12px;
}
.closeImg{
    width: 15px;height: 15px;
    cursor: pointer;
}





// 展开收起
.video-info {
        display: flex;
        // width: 100%;
        flex-direction: column;
        background-color: #fff;
        padding: 30rpx;
        
        position: relative;
        .info {
            display: flex;
            flex-direction: column;
            .tips {
                width: 126rpx;
                height: 40rpx;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%);
                
                position: absolute;
                bottom: 27rpx;
                right: 30rpx;
            }
        }
 
    }
    // 收起 按钮
    .hidebtn {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        color: #0078FF;
        font-size: 28rpx;
    }
    // 展开 文字
    .hide {
        word-break: break-word; //换行模式
        overflow: hidden;
        text-overflow: ellipsis; //修剪文字，超过2行显示省略号
        display: -webkit-box;
        -webkit-line-clamp: 2; //此处为上限行数
        -webkit-box-orient: vertical;
    }
    .sxTexts{
        width: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    }
    /deep/.tkPay{
    .el-dialog{
        border-radius: 5px;
           // max-height: 670px;

    .el-dialog__header{
        background-image: url(../assets/images/tcTops.png);
        width:100%;
        height:140px;
        padding:0;
        .el-dialog__close{
            color: #fff;
        }
    }
    .el-dialog__body{
       border-radius: 5px;
       padding: 0;
       padding-bottom: 30px;
       .nav{
        .nav-top{
            margin: -50px 30px 20px 30px;
            background: #fff;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
            border-radius: 10px;
        }
        .nav-tabel{
            margin: 10px 30px 10px 30px;
        }
       }
       .navK{
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.08);
        border-radius: 10px;
        margin: -50px 30px 0 30px;
         padding: 30px;
        .fYcolor{
            background: #FFFCF6;
            border-radius: 5px;
            padding: 20px 30px;
            display:flex;
            .cpLeft{
                width: 160px;
                height: 200px;
                background: #FFFFFF;
                border-radius: 5px;
                margin-right: 60px;
                .ewmImg{
                    width:160px;
                    height:160px;
                    margin-bottom:7px;
                }
            }
            .cpRight{
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #222222;
                line-height: 30px;
                margin-top: 20px;
            }
        }
       }
    }
  }
}

.tcTabelImg{
    width:100%;
    height:757px
}
.kaiTong{
    background-image: url(../assets/images/kaiTong.png);
    width:200px;
    height:46px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #222222;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.ktText{
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 468px;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
}
.tabs{
    .btn{
        position: relative;
        flex: 1;
        margin: 0 10px;
        border: 1px solid #CCCCCC;
        border-radius: 5px;
        padding: 19px 15px;
        cursor: pointer;
        transition: border-color .2s;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #222222;
        .tc_purchase-order_check {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 30px;
            height: 30px;
        }
    }
    .active{
        border-color: #432107;
        background-color: #fff;
        .tc_purchase-order_check{
            background-image: url(../assets/images/gou.png);
           background-size: 100% 100%;
        }
    }
}
.rows{
    justify-content: space-between;
}
.paySuccess{
        position: absolute;
        top: 77px;
        left: 50%;
        transform: translateX(-50%);
    }







    /deep/.nav-top{
 
 .el-tabs--top{
     width: 100%;
     .el-tabs__header{
         margin: 0 !important;
     }
     .el-tabs__nav-wrap::after{
         background-color: #fff;
     }
     .el-tabs__nav-scroll{
         .el-tabs__nav{
             width: 100%;
             .el-tabs__active-bar{
                 left: 16%;
                 width: 120px !important;
             }
             .el-tabs__item{
                 height: 60px !important;
                 line-height: 20px !important;
                 font-size: 24px;
                 width: 50%;
                 text-align: center;
                 padding: 12px 0 0 0;
                 background: #F2F4F7;
                 border-radius: 5px 5px 0 0;
                 .bzImgs{
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     font-size: 18px;
                 } 
                 .bqqStext{
                     font-size: 12px;
                     font-family: Source Han Sans CN;
                     font-weight: 400;
                     color: #999999;
                 }
             } 
             .el-tabs__item.is-active{
                    background: #fff;
                    color:#303133
                } 
         }
     }
 }
 .firstContent{
     box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.04);
     border-radius: 5px;
     padding: 20px 30px;
 }
}


.topA{
margin-top: -80px;
 background: #FFFFFF;
 border-radius: 5px;
}
.tao-half-row{
 width: 100%;
 position: relative;
 font-size: 12px;
 cursor: pointer;
 .taoTtile{
     font-size: 18px;
     font-family: Source Han Sans CN;
     font-weight: bold;
     color: #222222;
 }
}
.danTk2{
 background: #FFFFFF;
 border: 1px solid #E6E6E6;
 border-radius: 10px;
 text-align: center;
 padding: 20px 0;
}
.maright{
 margin-right: 20px;
}
.saleprice{
 height: 26px;
 line-height: 26px;
 text-decoration: line-through;
}
.danTk{
 background: #EDF5FF;
 border: 1px solid #0078FF;
 border-radius: 5px;
 text-align: center;
 padding: 20px 0;
}
.danTops{
 height: 24px;
 background: linear-gradient(0deg, #0078FF 0%, #0052F0 100%);
 border-radius: 5px 5px 0px 0px;
 margin-top: -2px;
 line-height: 24px;
}
.tq1Img{
 width:137px;
 height:12px;
}
.danTexts{
 width: 100%;
 font-size: 12px;
 font-family: PingFang SC;
 font-weight: 500;
}
.dlTexts{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 80%;
 }
 .dwZhek{
   position: absolute;
   right: -10px;
   top:-11px;
   width: 33px;
   height: 18px;
   background: #FF0000;
   border-radius: 11px 0px 11px 0px;
   color:#ffffff;
   font-size: 12px;
   font-family: PingFang SC;
   font-weight: 500;
   color: #FEFEFE;
   line-height: 18px;
   text-align: center;
 }
 .danH{
     height:26px;line-height:26px;color:#999999
 }
.tkgouImg{
 width: 30px;
 height: 30px;
 position: absolute;
 right: 0;
 bottom: 0;
}
.sureBtn{
     font-size: 18px;
     font-family: Source Han Sans CN;
     font-weight: 400;
     color: #FFFFFF;
     width: 200px;
     height: 46px;
     background: #0078FF;
     border-radius: 6px;
     line-height: 46px;   
     cursor: pointer;
 }
.nav-middle{
 width: 100%;
 background: #FFFFFF;
 border: 2px dashed #EEEEEE;
 border-radius: 5px;
 padding: 10px 0 20px 0;
 text-align: center;
 margin:20px 0 12px 0;
     .nav-middleCon{
         align-items: flex-end;
         font-size: 14px;
         justify-content: center;
         margin-bottom: 18px;
         .sjPay{
             display: flex;
            align-items: flex-end;
         }
     }
 }
 .footsCon{
        padding: 30px;
        border: 2px solid #0078FF; 
        border-radius: 5px;
    }
   .bqqText1{
		font-size: 18px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #222222;
	}
    .iconZ{
        width: 10px;
        height: 10px;
    }
	.bqqText2{
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #999999;
		text-align: center;
		margin: 4px 0;
	}
	.vipHalfs{
        text-align: center;
        width: calc(100%/7);
        margin: 18px 0 0 0;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #222222;
        img{
            width: 50px;
            height: 50px;
        }
	}
	.vipIconText{
		width: 72px;
		margin: 0 auto;
	}
    .dkq{
        width: 130px;
        height: 30px;
        background: #FF0000;
        border-radius: 4px;
        line-height: 30px;
        text-align: center;
        font-size: 18px;
        font-family: Source Han Sans CN;
        color: #FFE477;
        margin-right: 30px;
    }
</style>