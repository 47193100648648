<template>
    <div>
        <common-header  @change="amountChange($event)" :message="beiji"></common-header>   
         <div class="top">
            <div class="topCon flex">
                <img style="width:140px;height:140px;margin-right: 30px;" class="bordR" v-if="isShowTx" :src="userInfos.headPic" />
                <img style="width:140px;height:140px;margin-right: 30px;" class="bordR" v-else src="../assets/images/user1.png" />
                <div>
                    <div class="cTitle flex alignCenter">
                        <span style="margin-right:18px" v-if="userInfos.realName!=''&&userInfos.realName!='undefined'">{{userInfos.realName}}</span>
                        <div v-else class="flex alignCenter">
                            <span v-if="userInfos.nickname!=''&&userInfos.nickname!=null" style="margin-right:18px">{{userInfos.nickname}}</span>
                        </div>
                         <div class="flex alignCenter" v-if="level.userMemberRank!=''&&level.userMemberRank!=undefined">
                            <img v-if="level.userMemberRank==1" class="vipImage" src="../assets/images/vip.png" />
                            <img v-if="level.userMemberRankStr==2" class="svipImage" src="../assets/images/svip.png" />
                            <!-- <span>{{level.userMemberRankStr}}</span> -->
                         </div>
                    </div>
                    <div class="flex">
                        <div style="margin-right: 20px;">账号：{{ userInfos.phoneNo }}</div>
                        <div v-if="level.userMemberRank!=0">会员到期：{{level.memberEndTime}}</div>
                    </div>
                </div>
            </div>
         </div>
        <div class="container flex jutCenter" id="tabsQ">
            <div class="left" style="width: 200px;height: inherit;background: #fff;">
              <el-row class="tac" style="height: 100%;">
                    <el-col :span="12">
                        <el-menu
                        :default-active="activeIndex"
                        class="el-menu-vertical-demo"
                        @select="handleSelect">
                        <el-menu-item index="1">
                            <!-- <i class="el-icon-user"></i> -->
                            <img class="vicon1 show" src="../assets/images/micon4.png" />
                            <img class="vicon2 none"  src="../assets/images/micon44.png" />
                            <span slot="title">个人中心</span>
                        </el-menu-item>
                        <el-menu-item index="2">
                            <!-- <i class="el-icon-time"></i> -->
                            <img class="vicon1 show" src="../assets/images/micon5.png" />
                            <img class="vicon2 none"  src="../assets/images/micon55.png" />
                            <span slot="title">浏览历史</span>
                        </el-menu-item>
                        <el-menu-item index="3">
                            <!-- <i class="el-icon-star-off"></i> -->
                            <img class="vicon1 show" src="../assets/images/micon6.png" />
                            <img class="vicon2 none"  src="../assets/images/micon66.png" />
                            <span slot="title">我的收藏</span>
                        </el-menu-item>
                         <el-menu-item index="4">
                            <!-- <i class="el-icon-coin"></i> -->
                            <img class="vicon1 show" src="../assets/images/micon7s.png" />
                            <img class="vicon2 none"  src="../assets/images/micon77s.png" />
                            <span slot="title">推广活动</span>
                        </el-menu-item> 
                        <!-- <el-menu-item index="5">
                                <img class="vicon1 show" src="../assets/images/micon8.png" />
                                <img class="vicon2 none"  src="../assets/images/micon88.png" />
                            <span slot="title">导出记录</span>
                        </el-menu-item>-->
                        <el-menu-item index="6">
                                <img class="vicon1 show" src="../assets/images/micon9.png" />
                                <img class="vicon2 none"  src="../assets/images/micon99.png" />
                            <span slot="title">消息中心</span>
                        </el-menu-item>
                        <el-menu-item index="7">
                                <img class="vicon1 show" src="../assets/images/micon10.png" />
                                <img class="vicon2 none"  src="../assets/images/micon101.png" />
                            <span slot="title">我的订单</span>
                        </el-menu-item>
                        
                         <el-menu-item index="8">
                                <img class="vicon1 show" src="../assets/images/cicon33.png" />
                                <img class="vicon2 none"  src="../assets/images/cicon333.png" />
                            <span slot="title">我的发票</span>
                        </el-menu-item>
                        <!--  <el-menu-item index="9">
                                <img class="vicon1 show" src="../assets/images/micon1.png" />
                                <img class="vicon2 none"  src="../assets/images/micon11.png" />
                            <span slot="title">我的监控</span>
                        </el-menu-item>
                        <el-menu-item index="10">
                                <img class="vicon1 show" src="../assets/images/micon2.png" />
                                <img class="vicon2 none"  src="../assets/images/aicon3.png" />
                            <span slot="title">我的跟进</span>
                        </el-menu-item>
                        <el-menu-item index="11">
                                <img class="vicon1 show" src="../assets/images/cicon1.png" />
                                <img class="vicon2 none"  src="../assets/images/cicon11.png" />
                            <span slot="title">企业中心</span>
                        </el-menu-item>
                        <el-menu-item index="12">
                                <img class="vicon1 show" src="../assets/images/micon3.png" />
                                <img class="vicon2 none"  src="../assets/images/micon33.png" />
                            <span slot="title">我的反馈</span>
                        </el-menu-item> -->

                       <!-- <el-menu-item index="13">
                                <img class="vicon1 show" src="../assets/images/micon13.png" />
                                <img class="vicon2 none"  src="../assets/images/micon13s.png" />
                            <span slot="title">我的订阅</span>
                        </el-menu-item>  -->

                        <el-menu-item index="14">
                                <img class="vicon1 show" src="../assets/images/micon7.png" />
                                <img class="vicon2 none"  src="../assets/images/micon77.png" />
                            <span slot="title">我的优惠券</span>
                        </el-menu-item>

                        <el-menu-item index="15">
                                <img class="vicon1 show" src="../assets/images/micon13.png" />
                                <img class="vicon2 none"  src="../assets/images/micon13s.png" />
                            <span slot="title">意见反馈</span>
                        </el-menu-item>

                        </el-menu>
                    </el-col>
               </el-row>
            </div>
            <div class="rightCon">
                <!-- 个人中心 -->
                <div v-if="activeIndex==1">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="个人信息" name="first">
                            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                                <!-- <el-form-item label="用户名：" prop="user">
                                    <el-input  type="text" v-model="ruleForm.user" ></el-input>
                                </el-form-item> -->
                                <!-- <el-form-item label="手机号：" prop="phone">
                                    <el-input type="number" disabled v-model="phoneNo" maxlength=""></el-input>
                                </el-form-item> -->
                                <el-form-item label="昵称：" prop="nickname">
                                    <el-input  type="text" v-model="ruleForm.nickname" ></el-input>
                                </el-form-item>
                                <el-form-item label="性别：" prop="sex">
                                    <el-radio-group v-model="ruleForm.sex">
                                    <el-radio label="0">男</el-radio>
                                    <el-radio label="1">女</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="真实姓名：" prop="realName">
                                    <el-input  type="text" v-model="ruleForm.realName" ></el-input>
                                </el-form-item>
                                <el-form-item label="企业名称：" prop="company">
                                    <el-input  type="text" v-model="ruleForm.company" ></el-input>
                                </el-form-item>
                                <el-form-item label="职位：" prop="position">
                                    <el-input  type="text" v-model="ruleForm.position" ></el-input>
                                </el-form-item>
                                <el-form-item label="邮箱绑定：" prop="email">
                                    <el-input  type="text" v-model="ruleForm.email" ></el-input>
                                </el-form-item>

                                <el-form-item style="margin-top: 14px;">
                                    <el-button style="padding: 12px 30px;" @click="resetForm('ruleForm','1')">重置</el-button>
                                    <el-button  style="margin-left: 24px;padding: 12px 30px;" type="primary" @click="submitForm('ruleForm','1')">保存</el-button>
                                 </el-form-item>
                            </el-form>  

                        </el-tab-pane>
                        <el-tab-pane label="头像修改" name="second">
                            <div class="flex">
                                <div>
                                    <div>当前头像：</div>
                                    <!-- <img src="../assets/images/user.png" class="xgHeader" /> -->
                                    <img class="xgHeader bordR" v-if="isShowTx" :src="userInfos.headPic" />
                                    <img class="xgHeader bordR" v-else src="../assets/images/user1.png" />
                                </div>
                                <div style="margin-left:60px">
                                    <div>修改头像：</div>
                                    <el-upload
                                    class="avatar-uploader"
                                    accept=".jpg, .jpeg, .png"
                                    :action="imgUrl"
                                    :show-file-list="false"
                                    :on-success="handleAvatarSuccess"
                                    :before-upload="beforeAvatarUpload">
                                    <img v-if="imageUrl" :src="imageUrl" class="xgHeader" style="border-radius:50%;" />
                                    <img src="../assets/images/xgHeader.png" v-else class="xgHeader" /> 
                                    </el-upload>
                                    <div style="margin-bottom: 18px;font-size: 14px;color: #666;">(支持上传2M以下jpg、jpeg、png格式图片)</div>

                                <el-button  style="margin-left: 24px;padding: 12px 30px;" type="primary" @click="submitForm('ruleForm2','2')">保存</el-button>



                                </div>
                            </div>

                            
                        </el-tab-pane>
                        <el-tab-pane label="密码重置" name="third">

                            <el-form :model="ruleForm3" status-icon :rules="rules3" ref="ruleForm3" label-width="100px" class="demo-ruleForm">
                                     <el-form-item label="手机号" prop="user">
                                        <el-input placeholder="请输入手机号" v-model.number="ruleForm3.user" maxlength="11"></el-input>
                                    </el-form-item>
                                    <el-form-item prop="checkCode11" label="验证码">
                                        <el-input type="number" class="check-code-box" 
                                        maxlength="6" v-model="ruleForm3.checkCode11" 
                                        placeholder="请输入验证码">
                                        <!-- <el-input 
                                            class="check-code-box"  
                                            size="small" maxlength="6" :min="0"
                                            v-model.number="ruleForm3.checkCode11" 
                                            placeholder="请输入验证码">
                                        </el-input> -->
                                         <el-button :disabled="codeCd" size="small" slot="append" @click="handleCaptcha('ruleForm3')">
                                                获取验证码
                                               <span v-if="codeCd">({{long}})</span>
                                         </el-button>
                                        </el-input>  
                                    </el-form-item>
                               
                                   <el-form-item label="新密码" prop="password">
                                        <el-input type="password" placeholder="请输入新密码" v-model="ruleForm3.password"
                                        auto-complete="new-password" autocomplete="off"></el-input>
                                    </el-form-item>

                                    <el-form-item label="确认密码" prop="checkPass">
                                        <el-input type="password" placeholder="请再次输入密码" v-model="ruleForm3.checkPass" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item class="btnsA">
                                        <el-button style="padding: 12px 30px;" @click="resetForm('ruleForm3','3')">重置</el-button>
                                         <el-button  style="margin-left: 24px;padding: 12px 30px;" type="primary" @click="submitForm('ruleForm3','3')">保存</el-button>
                                    </el-form-item>
                            </el-form>



                        </el-tab-pane>
                    </el-tabs>
                </div>

                <!-- 浏览历史 -->
                <div v-else-if="activeIndex==2" class="aa">
                    <el-tabs v-model="activeName2" @tab-click="handleClicks" key="table1">
                        <el-tab-pane label="全部" name="3" >
                            <div class="ant-spin-container" >
                                <div class="ant-list-items" v-for="(item,index) in allList" :key="index">
                                    <div class="tc_list_item tc_list_hover">
                                      <div v-if="item.objectType==1">
                                        <div class="R-Dvn3zA">
                                            <div class="pYreFzHC">
                                                <img :src="item.enCompany.logo" />
                                            </div>
                                            <div class="_0aDOKXj6">
                                                <div class="FpwufnYn flex" @click="companyDetail(item.enCompany.id)">
                                                    <div  class="titles" v-if="item.enCompany.name!=''&&item.enCompany.name!=undefined">{{item.enCompany.name}}</div>
                                                </div>
                                                 <div class="gGdJlei">
                                                <span class="ant-tag ant-tag-blue bordered" v-if="item.enCompany.categoryCode!=undefined&&item.enCompany.categoryCode!=''">{{item.enCompany.categoryCode}}</span>
                                                <span class="ant-tag  bordered" v-if="item.enCompany.regStatus!=undefined&&item.enCompany.regStatus!=''">{{item.enCompany.regStatus}}</span>
                                                </div> 
                                            </div>
                                            <div class="timeA" v-if="item.visitTime!=''&&item.visitTime!=undefined">{{ item.visitTime }}</div>
                                        </div>
                                      </div>
                                      <div v-else>
                                        <div class="R-Dvn3zA">
                                            <div class="_0aDOKXj6s">
                                                <div class="FpwufnYn flex" @click="zbDetail(item.scripingpage.id)" v-if="item.scripingpage!=''&&item.scripingpage!=undefined&&item.scripingpage!=null">
                                                    <div class="titles" v-if="item.scripingpage.name!=''&&item.scripingpage.name!=undefined">{{item.scripingpage.name}}</div>
                                                </div>
                                            </div>
                                            <div class="timeA" v-if="item.visitTime!=''&&item.visitTime!=undefined">{{ item.visitTime }}</div>
                                        </div>  
                                      </div>  
                                    </div>
                                </div>
                            </div> 
                        </el-tab-pane>
                        <!-- <el-tab-pane label="企业" name="1">
                            <div class="ant-spin-container">
                                <div class="ant-list-items" v-for="(item,index) in gysList" :key="index">
                                    <div class="tc_list_item tc_list_hover">
                                        <div class="R-Dvn3zA">
                                            <div class="pYreFzHC">
                                                <img :src="item.enCompany.logo" />
                                            </div>
                                            <div class="_0aDOKXj6">
                                                <div class="FpwufnYn flex" @click="companyDetail(item.enCompany.id)">
                                                    <div  class="titles" v-if="item.enCompany.name!=''&&item.enCompany.name!=undefined">{{item.enCompany.name}}</div>
                                                </div>
                                                 <div class="gGdJlei">
                                                <span class="ant-tag ant-tag-blue bordered" v-if="item.enCompany.categoryCode!=undefined&&item.enCompany.categoryCode!=''">{{item.enCompany.categoryCode}}</span>
                                                <span class="ant-tag  bordered" v-if="item.enCompany.regStatus!=undefined&&item.enCompany.regStatus!=''">{{item.enCompany.regStatus}}</span>
                                                </div> 
                                            </div>
                                            <div class="timeA" v-if="item.visitTime!=''&&item.visitTime!=undefined">{{ item.visitTime }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </el-tab-pane>
                        <el-tab-pane label="标讯" name="2">
                            <div class="ant-spin-container">
                                <div class="ant-list-items" v-for="(item,index) in bxList" :key="index">
                                    <div class="tc_list_item tc_list_hover">
                                        <div class="R-Dvn3zA">
                                            <div class="_0aDOKXj6s">
                                                <div class="FpwufnYn flex" @click="zbDetail(item.scripingpage.id)" v-if="item.scripingpage!=''&&item.scripingpage!=undefined&&item.scripingpage!=null">
                                                    <div class="titles" v-if="item.scripingpage.name!=''&&item.scripingpage.name!=undefined">{{item.scripingpage.name}}</div>
                                                </div>
                                            </div>
                                            <div class="timeA" v-if="item.visitTime!=''&&item.visitTime!=undefined">{{ item.visitTime }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </el-tab-pane> -->
                    </el-tabs>

                    <div class="clears">
                        <el-button type="primary" size="small" @click="clear">清空记录</el-button>
                    </div>

                    <!-- 分页 -->
                    <div>
                        <el-pagination style="margin-top: 30px;text-align: center;"
                            @current-change="handleCurrentChange"
                            background
                            :current-page="currentPage"
                            :page-size="pageSize"
                            layout="total, prev, pager, next, jumper"
                            :total="total">
                            </el-pagination>
                    </div>

                </div>
                <!-- dyTable我的收藏 -->
                <div v-else-if="activeIndex==3" class="bb">
                    <el-tabs v-model="activeName3" @tab-click="handleClicks3" key="table2" > 
                        <el-tab-pane label="全部" name="3" >
                             <el-table
                                :data="tableData3" border :key="Math.random()"
                                style="width: 100%">
                                <el-table-column
                                prop="dataIdx"
                                label="序号"
                                width="80">
                                </el-table-column>
                                <el-table-column 
                                label="名称">
                                <template slot-scope="scope">
                                    <div style="cursor: pointer;" @click="zbDetail(scope.row.scripingpage.id)">{{scope.row.objectName}}</div>
                                </template>
                                </el-table-column>
                                <el-table-column
                                fixed="right"
                                label="操作"
                                width="120">
                                <template slot-scope="scope">
                                    <el-button 
                                    @click.native.prevent="deleteRows(scope.$index, tableData3,'')"
                                    type="text">
                                    删除
                                    </el-button>
                                </template>
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>     
                        <!-- <el-tab-pane label="企业" name="1">
                            <el-table
                                :data="tableData" border :key="Math.random()"
                                style="width: 100%">
                                <el-table-column
                                prop="dataIdx"
                                label="序号"
                                width="80">
                                </el-table-column>
                                <el-table-column
                                prop="name"
                                label="名称">
                                </el-table-column>
                                <el-table-column
                                fixed="right"
                                label="操作"
                                width="120">
                                <template slot-scope="scope">
                                    <el-button
                                    @click.native.prevent="deleteRow(scope.$index, tableData,'1',)"
                                    type="text"
                                    size="small">
                                    删除
                                    </el-button>
                                </template>
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>
                         <el-tab-pane label="标讯" name="2">
                            <el-table
                                :data="tableData2" border :key="Math.random()"
                                style="width: 100%">
                                <el-table-column
                                prop="dataIdx"
                                label="序号"
                                width="80">
                                </el-table-column>
                                <el-table-column
                                prop="name" 
                                label="名称">
                                </el-table-column>
                                <el-table-column
                                fixed="right"
                                label="操作"
                                width="120">
                                <template slot-scope="scope">
                                    <el-button
                                    @click.native.prevent="deleteRow(scope.$index, tableData2,'2')"
                                    type="text"
                                    size="small">
                                    删除
                                    </el-button>
                                </template>
                                </el-table-column>
                            </el-table>
                        </el-tab-pane> -->
                    </el-tabs>
                    <div>
                        <el-pagination style="margin-top: 30px;text-align: center;"
                            @current-change="handleCurrentChange2"
                            background
                            :current-page="currentPage2"
                            :page-size="pageSize"
                            layout="total, prev, pager, next, jumper"
                            :total="total2">
                            </el-pagination>
                    </div>

                </div>
                <!-- 推广活动 -->
                <div v-else-if="activeIndex==4">
                    <el-tabs v-model="activeName4" @tab-click="handleClicks4" key="table3">
                        <el-tab-pane label="邀请活动" name="1">
                            <div class="flex alignCenter mbottom">
                                <div style="width: 85px;">邀请链接：</div>
                                <div class="yqlj">{{yqLj}}</div>
                                <div class="copyText" @click="copy(yqLj)">复制</div>
                            </div>
                            <div class="flex alignCenter mbottom">
                                <div style="width: 85px;">推广Id：</div>
                                <div class="yqlj">{{userInfos.sn}}</div>
                                <div class="copyText" @click="copy(userInfos.sn)">复制</div>
                            </div>
                            <div class="yqrules">
                                <div>1.推广成功获得3天VIP。(用户注册登录)</div> 
                                <div>2.推广活跃用户获得7天VIP。(用户注册登录并连续签到3天)</div>
                                <!-- <div>3.推广活动初期不限制推广人数量。(活动截止日期2023年3月1日)</div> -->
                            </div>
                        </el-tab-pane>
                        
                        <el-tab-pane label="推广记录" name="3">
                            <div class="tgJlIcon"> 
                            <el-table 
                                :data="tableData33" border :key="Math.random()"
                                style="width: 100%">
                                 <el-table-column
                                type="index"
                                label="序号"
                                width="80">
                                </el-table-column>
                                <el-table-column
                                label="头像"
                                width="140">
                                   <template slot-scope="scope">
                                        <img v-if="scope.row.headPic!=''&&scope.row.headPic!=undefined"  :src="scope.row.headPic" style="width:40px;height:40px;border-radius: 50%;" />  
                                        <img  v-else src="../assets/images/user.png"  />
                                    </template>
                                </el-table-column>
                                <el-table-column
                                prop="realName"
                                label="推广人姓名"
                                width="160">
                                </el-table-column>
                                <el-table-column
                                prop="phoneNo"
                                label="手机号码"
                                width="170">
                                </el-table-column>
                                <el-table-column
                                prop="state"
                                label="推广状态"
                                width="170">
                                </el-table-column>
                                <el-table-column
                                prop="regeditDatetime"
                                label="推广时间">
                                </el-table-column>
                            </el-table>
                            <div>
                                <el-pagination style="margin-top: 30px;text-align: center;"
                                    @current-change="handleCurrentChange44"
                                    background4 :current-page="currentPage44"
                                    :page-size="pageSize"
                                    layout="total, prev, pager, next, jumper"
                                    :total="total44">
                                    </el-pagination>
                            </div>

                            </div>
                        </el-tab-pane>
                        
                        <el-tab-pane label="会员记录" name="4">
                            <div class="tgJlIcon">
                            <el-table :key="Math.random()"
                                :data="tableData4" border
                                style="width: 100%">
                                <el-table-column
                                label="会员时长"
                                width="180">
                                    <template slot-scope="scopes">
                                        <div class="flex alignCenter">
                                           <!-- <img v-if="level.userMemberRank=='1'" class="vipImage" src="../assets/images/hy.png" />
                                           <img v-else class="vipImage" src="../assets/images/a2.png" /> -->
                                           <div style="margin-top: 4px;">VIP会员{{scopes.row.memberDuration}}天</div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                prop="memberStartTime"
                                label="会员生效时间"
                                width="180">
                                </el-table-column>
                                <el-table-column
                                prop="memberEndTime"
                                label="会员到期时间"
                                width="180">
                                </el-table-column>
                                <el-table-column
                                prop="createTime"
                                label="获取时间"
                                width="180">
                                </el-table-column>
                                <el-table-column
                                prop="userComboTypeTxt" 
                                label="获取途径">
                                </el-table-column>
                            </el-table>
 
                            <div>
                                <el-pagination style="margin-top: 30px;text-align: center;"
                                    @current-change="handleCurrentChange4"
                                    background4 :current-page="currentPage4"
                                    :page-size="pageSize"
                                    layout="total, prev, pager, next, jumper"
                                    :total="total4">
                                    </el-pagination>
                            </div> 

                            </div>

                    
                        </el-tab-pane>
                    </el-tabs>

                </div>
 
                <!-- 消息中心 -->
                <div v-else-if="activeIndex==6" class="aa">
                   <div v-if="listAllMess.length>0">
                    <el-tabs v-model="activeName6" @tab-click="handleClicks6" key="table6" class="table6Content">
                        <el-tab-pane label="全部" name="1">
                            <div class="list-itemMessage" v-for="(item,index) in listAllMess" :key="item.id">
                                    <div class="half-Content flex aligenCenter" >
                                        <!-- <checkbox-group @change="changeitem(item)" checked class="checkbox" v-if="isBianji">
                                            <checkbox color="#0078FF" style="transform:scale(0.7);border-radius: 50%;"
                                            :value="item.id" :checked="item.checked" />
                                        </checkbox-group> -->
                                      <div style="display: flex;width: 100%;"  @click.stop="messageDetail(item.id)">
                                        <div class="leftRadius" v-if="item.readState==0"></div>
                                        <div class="rightR">
                                            <div class="flex jutSpace">
                                                <div v-if="item.subscribeName!=''&&item.subscribeName!=undefined" class="subTitle">{{item.subscribeName}}</div>
                                                <div class="delOneMessage" @click.stop="dleMessage(item.id)">删除</div>
                                            </div>
                                            <div class="times">{{item.sendTime}}</div>
                                            <div v-for="(items,indexs) in item.messageList" :key="indexs" class="flex aligenCenter sChildren">
                                                <div>{{indexs+1}}.</div>
                                                <div class="sRight">{{items}}</div>
                                            </div>
                                        </div>
                                      </div>
                                    </div>	
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                   </div>
                   <div v-else class="kongYhq">
                            <img class="kongImg" src="../assets/images/kong.png" style="margin-top: 30px;" />
                            <div>暂无消息</div>
                        </div> 
                    <div class="clears">
                        <el-button type="primary" size="small" @click="clearMessage">清空记录</el-button>
                    </div>
                     <!-- 分页 -->
                     <div v-if="total6>0">
                        <el-pagination style="margin-top: 30px;text-align: center;"
                            @current-change="handleCurrentChange6"
                            background
                            :current-page="currentPage6"
                            :page-size="pageSize"
                            layout="total, prev, pager, next, jumper"
                            :total="total6">
                            </el-pagination>
                    </div>
                </div>
                <!-- 我的订单 -->
                <div v-else-if="activeIndex==7" class="bb">
                    <el-tabs v-model="activeName7" @tab-click="handleClicks7" key="table7" class="table7Content">
                        <el-tab-pane label="全部" name="1">
                            <el-table 
                                :data="tableData77" border :key="Math.random()"
                                style="width: 100%">
                                <!-- prop="name" -->
                                <el-table-column
                                label="我的订单"
                                width="280">
                                <template slot-scope="scope">
                                    <div class="blue">{{scope.row.name}}</div>
                                    <div  class="hui">编号：{{ scope.row.id }}</div>
                                    <div v-if="scope.row.objectType!=-1&&scope.row.objectType!=undefined&&scope.row.objectType!=''"
                                     @click="titleTz(scope.row.objectId,scope.row.objectType)"  class="hui">
                                        标题：<span :title="scope.row.objectName" style="cursor: pointer;color: #409EFF">{{scope.row.objectName}}</span>
                                    </div>
                                    <div  class="hui" v-else>状态：{{scope.row.stateStr}}</div> 
                                </template>
                                </el-table-column>
                                <el-table-column
                                prop="days"
                                label="时长(天)"
                                width="120">
                                </el-table-column>
                                <el-table-column
                                prop="price"
                                label="支付金额(元)"
                                width="120">
                                </el-table-column>
                                <el-table-column
                                label="支付状态"
                                width="120">
                                 <template slot-scope="scope">
                                    <el-button v-if="scope.row.isPay==0" type="text" size="small" style="color:#FF0000">
                                    未支付
                                    </el-button>
                                    <el-button v-else type="text" size="small">
                                    已支付
                                    </el-button>
                                 </template>
                                </el-table-column>
                                <el-table-column
                                prop="createTime"
                                width="170"
                                label="下单时间">
                                </el-table-column>
                                <el-table-column
                                fixed="right"
                                label="操作">
                                <!-- state=0 and isPay=0 订单状态有效且未支付时才可以继续支付-->
                                 <template slot-scope="scope">
                                    <el-button  v-if="scope.row.state==0&&scope.row.isPay==0"
                                    @click.native.prevent="payRows(scope.$index,tableData77)"
                                    type="text"
                                    size="small">
                                     支付
                                    </el-button>
                                </template>
                                </el-table-column>

                            </el-table>
                        </el-tab-pane>
                        <!-- <el-tab-pane label="VIP购买订单" name="2">
                        </el-tab-pane>
                        <el-tab-pane label="赠送订单" name="3">
                        </el-tab-pane> -->
                    </el-tabs>

                    <div class="clears">
                        <div class="flex" >
                             <div class="ju-tinput">
                                <input type="text" placeholder="请输入订单号" class="inputClass" v-model="searchCon">
                                <img v-if="searchCon!=''&&searchCon!=null" src="../assets/images/guanbi.png" class="closeImg"  @click="del" />
                                <div class="searchBtn" @click="searchClick">搜索</div>
                             </div>
                             <div>
                                <el-select class="zfSelect" v-model="zfValue" placeholder="支付状态" clearable  @change="zfChange($event)">
                                    <el-option
                                    v-for="item in zfoptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                               </el-select>
                             </div>

                         </div>
                    </div>

                    <div>
                        <el-pagination style="margin-top: 30px;text-align: center;"
                            @current-change="handleCurrentChange7"
                            backgroundhandleCurrentChange7
                            :current-page="currentPage7"
                            :page-size="pageSize"
                            layout="total, prev, pager, next, jumper"
                            :total="total7">
                            </el-pagination>
                    </div>

                </div> 
                 <!-- 我的发票 -->
                <div v-else-if="activeIndex==8" style="position: relative;" class="table8Class">
                    <el-tabs v-model="activeName8" @tab-click="handleClicks8" key="table8">
                      <el-tab-pane label="可开发票" name="1">
                        <el-table  :data="tableData88" border :key="Math.random()"
                            @selection-change="handleSelectionChange">
                            <el-table-column
                            type="selection"
                            width="55">
                            </el-table-column>
                            <el-table-column
                            prop="name"
                            label="产品名称"
                            width="170">
                            </el-table-column>
                            <el-table-column
                            prop="id"
                            label="订单编号"
                            width="190">
                            </el-table-column>
                            <el-table-column
                            prop="price"
                            label="支付金额(元)"
                            width="140">
                            </el-table-column>
                            <el-table-column
                            prop="createTime"
                            label="下单时间"
                            width="200">
                            </el-table-column>
                            <el-table-column
                                fixed="right"
                                label="操作">
                                <template slot-scope="scope">
                                    <el-button
                                    @click.native.prevent="appFor(scope.row.id)"
                                    type="text"
                                    size="medium">
                                    申请发票
                                    </el-button>
                                </template>
                            </el-table-column>

                        </el-table>
                       </el-tab-pane>
                       <el-tab-pane label="开票记录" name="2">
                        <el-table  :data="tableData882" border :key="Math.random()" class="fpButton">
                            <el-table-column
                            prop="invoiceRecordId"
                            label="发票申请编号"
                            width="180">
                            </el-table-column>
                            <el-table-column
                            prop="invoiceTypeStr"
                            label="发票类型"
                            width="120">
                            </el-table-column>
                            <el-table-column
                            prop="unitTypeStr"
                            label="抬头类型"
                            width="100">
                            </el-table-column>
                            <el-table-column
                            prop="unitName"
                            label="发票抬头"
                            width="160">
                            </el-table-column>
                            <el-table-column
                            prop="invoiceAmount"
                            label="发票金额"
                            width="100">
                            </el-table-column>
                            <el-table-column
                            prop="createTime"
                            label="申请日期"
                            width="100">
                            </el-table-column>
                            <el-table-column
                            prop="invoiceRecordStateStr"
                            label="审核状态"
                            width="120">
                            </el-table-column>
                            <el-table-column
                                fixed="right"
                                width="200"
                                label="操作">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.invoiceRecordState==2">发票生成中</div>
                                    <div v-else>
                                        <el-button v-if="scope.row.invoiceRecordState==1" 
                                        @click.native.prevent="look(scope.row.exportFilePath)"
                                        type="text"
                                        size="middle">
                                        查看
                                        </el-button>
                                        <el-button
                                        @click.native.prevent="lsApply('2',scope.row.invoiceRecordId)"
                                        type="text"
                                        size="middle">
                                        申请重开
                                        </el-button>
                                        <el-button v-if="scope.row.invoiceRecordState==1"
                                        @click.native.prevent="lsApply('5',scope.row.invoiceRecordId)"
                                        type="text"
                                        size="middle">
                                        重新发送
                                        </el-button>
                                    </div>
                                </template>
                            </el-table-column>

                        </el-table>
                       </el-tab-pane>

                    </el-tabs>

                    <div class="clears">
                        <el-button type="text" @click="open">开票说明</el-button>
                        <el-button type="primary" size="small" v-if="activeName8==1" style="padding: 7px 11px;font-size: 14px;" @click="appFor('')">申请发票</el-button>
                        <!-- <el-button type="primary" size="small" @click="Apply" style="padding: 7px 11px;font-size: 14px;">申请发票</el-button> -->
                    </div>

                    <div v-if="activeName8==1">
                        <el-pagination style="margin-top: 30px;text-align: center;"
                            @current-change="handleCurrentChange8"
                            background
                            :current-page="currentPage8"
                            :page-size="pageSize"
                            layout="total, prev, pager, next, jumper"
                            :total="total8">
                            </el-pagination>
                    </div>

                    <div v-else>
                        <el-pagination style="margin-top: 30px;text-align: center;"
                            @current-change="handleCurrentChange82"
                            background
                            :current-page="currentPage82"
                            :page-size="pageSize"
                            layout="total, prev, pager, next, jumper"
                            :total="total82">
                            </el-pagination>
                    </div>

                </div>

                <!-- <div v-else-if="activeIndex==13" class="dyTable bb dys">
                    <el-tabs v-model="activeName13" @tab-click="handleClicks13">
                        <el-tab-pane label="全部" name="1" >
                             <el-table
                                :data="tableData13" border :key="Math.random()"
                                style="width: 100%">
                                <el-table-column
                                prop="subscribeName"
                                label="订阅名称"
                                width="200">
                                <template slot-scope="scope">
                                   <div @click="openDy(scope.row.subscribeId)" :title="scope.row.subscribeName">{{scope.row.subscribeName}}</div> 
                                </template>
                                </el-table-column>
                                <el-table-column 
                                label="订阅关键字"   width="180">
                                <template slot-scope="scope">
                                   <div  :title="scope.row.keywords">{{scope.row.keywords}}</div> 
                                </template>
                                </el-table-column>
                                <el-table-column 
                                label="推送频率" 
                                  width="120">
                                 <template slot-scope="scope">
                                     <div v-if="scope.row.pushFrequency==1">每日推送</div>
                                 </template>
                                </el-table-column>
                                <el-table-column 
                                label="推送时间"
                                prop="pushTimeStr"  width="120">
                                </el-table-column>
                                <el-table-column 
                                label="推送方式"
                                prop="noticeStr"  width="160">
                                </el-table-column>
                                <el-table-column
                                fixed="right"
                                label="操作">
                                <template slot-scope="scope">
                                    <el-button 
                                    @click.native.prevent="showOneDy(scope.$index,scope.row.subscribeId)"
                                    type="text" style="margin-right:10px">
                                    编辑
                                    </el-button>
                                    <el-button
                                    @click.native.prevent="deleteDy(scope.row.subscribeId)"
                                    type="text" >
                                    删除
                                    </el-button>
                                </template>
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>  
                    </el-tabs>

                    <div class="clears">
                        <div class="flex" >
                            <el-button size="small" @click="addDytk()">新增订阅</el-button>
                            <el-button size="small" type="primary" @click="deleteDy('')">全部删除</el-button>
                         </div>
                    </div> 

                </div> -->
                <!-- 我的优惠券 -->
                <div v-else-if="activeIndex==14" class="aa">
                    <el-tabs v-model="activeName14"  key="table14">
                        <el-tab-pane label="全部" name="1" >
                      
                        <div v-if="quanList!=''"  class="flex yhqRows">
                        <div v-for="(item,index) in quanList" :key="index">
                            <!-- 优惠券正常 -->
                            <div class="copunA" v-if="item.useState==1">
                                <img class="quanBj" src="../assets/images/quan1.png" />
                                <div class="yhqBj" style="cursor: pointer;">
                                <div class="flex jutBetween aligenCenter">
                                    <div class="dkquan1">
                                    <div>￥<span style="font-size: 35px;">{{item.couponInfo.couponAmount}}</span></div>
                                    <div>抵扣券</div>
                                    </div> 
                                    <div class="dkquan2">
                                    <div class="qNames2">{{item.couponInfo.couponName}}</div>
                                    <div>该券可用于购买VIP</div>
						            <div>满{{item.couponInfo.limitAmount}}元可使用</div>
                          
                                    <!-- <div class="flex">
                                        <div >适用于：</div>
                                        <div>购买年费会员使用</div>
                                    </div>
                                    <div class="flex">
                                        <div >有效期：</div>
                                        <div style="max-width: 172px;">活动到期失效，最终解释权归标圈圈所有！</div>
                                    </div> -->
                                    </div>
                                    <div class="dkquan3" @click="navgiter()">
                                      <router-link style="color: #DF0000;" to="/vipPackage">立即使用</router-link>
                                    </div>
                                </div>
                                </div>
                            </div>
                            
                            <!-- 优惠券已过期 --> 
                            <div class="copunA" v-if="item.useState==-3">
                            <img class="quanBj" src="../assets/images/quan2.png" />
                            <div class="yhqBj ">
                                    <div class="flex jutBetween aligenCenter">
                                        <div class="dkquan1">
                                        <div>￥<span style="font-size: 35px;">{{item.couponInfo.couponAmount}}</span></div>
                                        <div>抵扣券</div>
                                    </div>
                                    <div class="dkquan2">
                                        <div class="qNames2">{{item.couponInfo.couponName}}</div>
                                        <div>该券可用于购买VIP</div>
						                <div>满{{item.couponInfo.limitAmount}}元可使用</div>
                                    </div>
                                        <div class="dkquan3s">立即使用</div>
                                    </div>
                                </div>  
                                <img class="gqImg" src="../assets/images/quangq.png" />
                            </div>
            
                            <!-- 优惠券已使用 -->
                            <div class="copunA" v-if="item.useState==-1||item.useState==-2">
                                <img class="quanBj" src="../assets/images/quan2.png" />
                                <div class="yhqBj ">
                                    <div class="flex jutBetween aligenCenter" >
                                        <div class="dkquan1">
                                        <div>￥<span style="font-size: 35px;">{{item.couponInfo.couponAmount}}</span></div>
                                        <div>抵扣券</div>
                                        </div>
                                        <div class="dkquan2">
                                        <div class="qNames2">{{item.couponInfo.couponName}}</div>
                                        <div>该券可用于购买VIP</div>
						                 <div>满{{item.couponInfo.limitAmount}}元可使用</div>
                                        </div>
                                        <div class="dkquan3s">立即使用</div>
                                    </div>
                                    </div>  
                                <img class="gqImg" src="../assets/images/quansy.png" />
                            </div>
            
                        </div>
                        </div> 
                        <div v-else class="kongYhq">
                            <img class="kongImg" src="../assets/images/kong.png" />
                            <div>暂无优惠券</div>
                        </div> 
                       
                        </el-tab-pane>
                    </el-tabs>

                    <div class="clears">
                        <el-button type="text" @click="yhqRules">优惠券使用规则</el-button>
                    </div>

                </div> 
                <!-- 意见反馈 -->
                <div v-else-if="activeIndex==15">
                    <el-tabs v-model="activeName15"  key="table15">
                        <el-tab-pane label="意见反馈" name="1" >
                          <div class="flex yjfkContentAll alignCenter">
                              <div class="yjLeft flex">
                                <img class="fkIcon" src="../assets/images/bt.png" />
                                <div>反馈类型：</div>
                              </div>
                              <div class="yjRight">
                                <el-tabs v-model="activeName16" type="card" @tab-click="handleClick16">
                                    <el-tab-pane :label="item.optionName" :name="item.dictionaryOptionId" v-for="(item,index) in fkList" :key="index"></el-tab-pane>
                                </el-tabs>
                              </div>
                          </div>

                          <div class="flex mt22">
                            <div class="yjLeft flex">
                                <img class="fkIcon" src="../assets/images/bt.png" />
                                <div>反馈内容：</div>
                              </div>
                              <div class="yjRight riTextarea">
                                <textarea class="textareaClass" v-model="fkContent" placeholder="请输入反馈的问题或建议" maxlength="200"></textarea>
                              </div>
                          </div>

                          <div class="flex mt22">
                            <div class="yjLeft flex">
                                <img class="fkIcon" src="../assets/images/bt.png" />
                                <div>联系方式：</div>
                              </div>
                              <div class="yjRight ">
                                <input type="text" maxlength="11" class="inputClassA" v-model="fkNumber" 
                                placeholder="请输入手机号" pattern="[0-9]*" @keyup="fkNumber=fkNumber.replace(/[^\d]/g,'').replace(/^0{1,}/g,'')" />
                              </div>
                          </div>

                          <div class="flex mt22">
                            <div class="yjLeft flex">
                                <img class="fkIcon" src="" />
                                <div>问题截图：</div>
                            </div>
                            <div class="yjRight">
                                <el-upload ref="uploadfiles" 
                                :action="imgUrl" :on-success="imgUpload" list-type="picture-card"
                                :on-preview="handlePreview"  :limit="limit" :before-upload="beforeAvatarUpload"
                                :on-remove="handleRemove"  :on-change="handleLimit"
                                :on-exceed="handleExceed" accept="image/jpeg,image/png"  :class="{disabled:uploadDisabled}">
                                    <i class="el-icon-plus"></i>
                                    <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，单张图片大小不超过5M
                                    </div>
                                </el-upload>
                                <el-dialog :visible.sync="dialogVisibles" append-to-body class="aac" >
                                    <img width="100%" :src="dialogImageUrl" alt="">
                                </el-dialog>

                            </div>
                           </div>

                            <div class="btnTj" @click="submitFk()">提交</div>
                       
                        </el-tab-pane>
                    </el-tabs>
                </div> 


            </div>  
        </div>
        
        <!-- 发票弹框 -->
        <div>
            <el-dialog
                title="发票信息"  @close="closeFpDialog"
                :visible.sync="dialogVisible"
                width="680px">
                <div class="flex ">
                    <div class="fpTitle mtop">发票类型:</div>
                    <div class="fpttC">
                        <!-- <div class="dzfp">电子普通发票</div>
                        <div class="fpsText">电子发票是税务局认可的有效凭证，其法律效力、基本用途及使用规定同纸质发票。</div> -->
                        <el-tabs v-model="activeNamefp2" type="card" @tab-click="handleClickfp2">
                            <el-tab-pane label="电子普通发票" name="1">
                                <div class="fpsText mtop">电子发票是税务局认可的有效凭证，其法律效力、基本用途及使用规定同纸质发票。</div>
                            </el-tab-pane>
                            <!-- <el-tab-pane label="增值税专用发票" name="2"></el-tab-pane> -->
                        </el-tabs>
                    </div>
                </div>
                <div class="flex mtBot">
                    <div class="fpTitle mtop">抬头类型:</div>
                    <div class="fpttC">
                        <el-tabs v-model="activeNamefp" type="card" @tab-click="handleClickfp">
                            <el-tab-pane label="个人" name="0"></el-tab-pane>
                            <el-tab-pane label="单位" name="1"></el-tab-pane>
                        </el-tabs>
                        
                    </div>
                </div>
                <div class="flex mtBot" >
                    <div class="fpTitle mtop">发票抬头:</div>
                    <el-input
                        placeholder="请输入发票抬头"
                        v-model="inputFp1" @blur="changFp('1')"
                        clearable>
                    </el-input>
                 </div>

                <div class="flex mtBot" v-if="activeNamefp==1">
                        <div class="fpTitle mtop">单位税号:</div>
                        <el-input 
                            placeholder="请输入单位税号"
                            v-model="inputFp4" @blur="changFp('4')"
                            clearable>
                        </el-input>
                 </div>

                 <div class="flex mtBot" v-if="activeNamefp==1">
                        <div class="fpTitle mtop">更多内容:</div>
                        <el-input 
                            placeholder="填写备注地址等（非必填）"
                            v-model="inputFp5" @blur="changFp('5')"
                            clearable>
                        </el-input>
                 </div>

                <div class="flex mtBot">
                        <div class="fpTitle mtop">收票手机:</div>
                        <el-input 
                            placeholder="请输入收票人手机号" maxlength="11"  @keyup="inputFp2=inputFp2.replace(/[^\d]/g,'').replace(/^0{1,}/g,'')"
                            v-model="inputFp2" @blur="changFp('2')" type="text" pattern="[0-9]*" clearable>
                        </el-input>
                 </div>
                 <div class="flex mtBot">
                    <div class="fpTitle mtop">收票邮箱:</div>
                        <el-input 
                            placeholder="请输入收票人邮箱"
                            v-model="inputFp3" @blur="changFp('3')"
                            clearable>
                        </el-input>
                    </div>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="closeFpDialog">取 消</el-button>
                    <el-button type="primary" @click="submitFpOrder">确 定</el-button>
                </span>
            </el-dialog>
        </div>

        <el-dialog title="电子发票" :visible.sync="dialogTableVisibleURL" width="850px">
            <div>
                <img style="width:810px;height:515px" :src="fpUrls">
            </div>
        </el-dialog>
         <!-- 弹框二维码支付 -->
       <div class="tkPay">
        <el-dialog
        :visible.sync="ewmVisible"
        width="760px"
        :before-close="handleClose">
        <div  class="navK">
            <div class="ktText">开通产品套餐，查看更多数据</div>
            <div class="paySuccess" v-if="paySuccess">
                <el-alert :title="messageTitle" type="success"  center show-icon :closable="false"></el-alert>
            </div>
            <div class="fYcolor">
                <div class="cpLeft">
                    <img :src="ewmImg" class="ewmImg" />
                    <div class="flex jutCenter">
                        <img src="../assets/images/sao.png" style="width:17px;height:17px;margin-right: 5px;" />
                        <div style="color:#999999">使用<span style="color:#1E88FF">微信</span>扫码支付</div>
                    </div>
                </div>
                <div class="cpRight">
                    <div>开通账号：{{phoneNo}}</div>
                    <div>
                        <div>开通套餐：{{orderPay.name}}</div>
                        <div>到期时间：{{orderPay.days}}</div>
                        <div v-if="orderPay.days=='永久'">实付金额：<span style="color:#FF0000">￥<span style="font-size:30px">{{orderPays.price}}</span>元/条</span></div>
                        <div v-else>实付金额：<span style="color:#FF0000">￥<span style="font-size:30px">{{orderPays.price}}</span>元/年</span></div>
                        <div style="color: #999999;margin-top: 6px;">发票获取：完成支付后可在【个人中心-我的发票】中申请</div>
                    </div>
                    <!-- <div v-else>
                        <div>开通套餐：VIP套餐</div>
                        <div>到期时间：{{vipEndtime}}</div>
                        <div>实付金额：<span style="color:#FF0000">￥<span style="font-size:30px">{{orderPay.price}}</span>元/年</span></div>
                    </div> -->
                    
                </div>
            </div>
        </div>
        </el-dialog>
      </div> 

      <!-- 编辑订阅弹框 -->
        <div class="addDy">
            <el-dialog
            title="我的订阅"
            :visible.sync="dialogVisibleDy"   @close="closeDy"
            width="30%">
            <div class="dy-form-item flex">
                <div class="gjTitle xtWidth">
                    <img style="width:4px;height: 4px;margin-bottom: 7px;" src="../assets/images/redIcon.png" />
                    订阅名称:</div>
                <el-input
                    type="text"
                    maxlength="50"
                    placeholder="请输入订阅名称"
                    v-model="subscribeName">
                </el-input>
            </div>
            <div class="dy-form-item flex">
                <div class="gjTitle xtWidth">
                    <img style="width:4px;height: 4px;margin-bottom: 7px;" src="../assets/images/redIcon.png" />
                    关键词:</div>
                <el-input
                    type="text"
                    maxlength="50"
                    placeholder="请输入订阅关键词(多个关键词请用英文,隔开)"
                    v-model="keyWords">
                </el-input>
            </div>
            <div class="dy-form-item flex">
                <div class="gjTitle xtWidth">
                    <!-- <img style="width:4px;height: 4px;margin-bottom: 7px;" src="../assets/images/redIcon.png" /> -->
                    推送频率:</div>
                <el-tabs v-model="activeNamePl" type="card" @tab-click="handleClick" class="tsPl">
                    <el-tab-pane label="每日推送" name="1"></el-tab-pane>
                </el-tabs>
            </div>

            <div class="dy-form-item flex">
                <div class="gjTitle xtWidth">时间设置:</div>
                    <el-select v-model="timeS" placeholder="请选择推送时间" @change="chooseTime($event)" style="width:76%">
                        <el-option
                        v-for="item in optionTime"
                        :key="item.value"
                        :label="item.label"
                        :value="item.label">
                        </el-option>
                    </el-select>
            </div>
            
            <!-- <div class="gjTitles">订阅条件</div> -->
            <!-- <div class="ant-row">
                <div class="gjTitles flex" style="align-items: flex-start;">
                    <div class="xtWidth">系统通知：</div>
                    <div>
                      <div>
                        <el-radio v-model="notice1" label="1">是</el-radio>
                        <el-radio v-model="notice1" label="0">否</el-radio>
                      </div>
                      <div class="tjHui">可在网站、APP个人中心-消息通知下查看</div>
                    </div>
                </div>
            </div> -->
              <div class="ant-row">
                  <div class="gjTitles flex" style="align-items: flex-start;">
                    <div class="xtWidth">微信通知：</div>
                    <div>
                      <div>
                        <el-radio v-model="notice2" label="1">是</el-radio>
                        <el-radio v-model="notice2" label="0">否</el-radio>
                      </div>
 
                      <div class="bangding" v-if="wechatStatus==1">订阅消息将推送至 绑定微信 
                        <!-- <span @click="jiebang('1')" style="color: #409EFF;cursor: pointer;" >解绑</span> -->
                      </div>
 
                       <div class=" bangding" v-else>
                            <div>请先
                            <span style="color: #409EFF;cursor: pointer;" @mouseover="mouseOver" @mouseleave="mouseLeave">绑定微信</span>
                            绑定后订阅信息将推送至微信
                            </div>
                            <div ref="wechatEwm" class="ewmImg" style="display:none">
                                <img src="../assets/images/gzhEwm.jpg" style="width:100px;height:100px" />
                            </div>
                       </div>
                    </div>
                  </div> 
            </div>

            <div class="ant-row">
                <div class="gjTitles flex" style="align-items: flex-start;">
                    <div class="xtWidth">邮件通知：</div>
                    <div style="width: 75%;">
                      <div>
                        <el-radio v-model="notice3" label="1">是</el-radio>
                        <el-radio v-model="notice3" label="0">否</el-radio>
                      </div>
                      <div class="bangding" v-if="emailA!=null&&emailA.length>0">订阅消息将推送至 绑定邮箱
                         <span @click="jiebang('2')" style="color: #409EFF;cursor: pointer;">解绑</span>
                      </div>
                      <div v-else style="margin-top:10px">
                        <div v-if="notice3!=0">
                            <el-input
                            type="text"
                            maxlength="50" 
                            placeholder="请输入邮箱号"
                            v-model="emailCon">
                        </el-input>
                        </div>
                      </div>
                    </div>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDy()" style="margin-right: 20px;">取 消</el-button>
                <el-button type="primary" @click="addDys()" :disabled="keyWords==''&&keyWords.length<=0||subscribeName==''">确 定</el-button>
            </span>
            </el-dialog>
        </div> 
 
      <common-foot></common-foot>

      <common-active :showTk="beiji" :showActivity="flagActivity" @change="amountChanges($event)"></common-active>

    </div>
</template>

<script>
   import CommonHeader from '@/components/CommonHeader.vue';
   import CommonFoot from '@/components/CommonFoot.vue';
   import CommonActive from '@/components/CommonActive.vue';
  
    export default {
        inject:['reload'],
        components:{
        CommonHeader,
        CommonFoot,
        CommonActive
        }, 
        data() {
            let  checkEmail = (rule, value, callback) => {
                let regex = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
                if(value===""){
                    return callback(new Error('请输入邮箱'));
                }else if(!regex.test(value)){
                    return callback(new Error('请输入正确的邮箱'));
                } else {
                     callback();
                }
            };
            let checkAge = (rule, value, callback) => {
                 var reg = /^1[3-9]\d{9}$/;
                  if (value === "") {
                    return callback(new Error("请输入手机号码"));
                  } else if (!Number.isInteger(value)) {
                    return callback(new Error("手机号码必须是数字"));
                  } else if (value.toString().length !== 11) {
                    return callback(new Error("手机号码必须是11位数字"));
                  } else if (!reg.test(value)) {
                    return callback(new Error("请输入有效的手机号码"));
                  } else {
                    callback();
                  }
            };
            let checkCodes=(rule, value, callback) => {
                if (value === '') {
                callback(new Error('请输入验证码'));
                }else{
                    callback();
                }
            }
            var validatePass1 = (rule, value, callback) => {
                if (value === '') {
                callback(new Error('请输入密码'));
                }else if (value.length<6) {
                callback(new Error('密码长度最少6位数'));
                } else {
                if (this.ruleForm3.checkPass !== '') {
                    this.$refs.ruleForm3.validateField('checkPass');
                }
                callback();
                }
            };
            var validatePass2 = (rule, value, callback) => {
                if (value === '') {
                callback(new Error('请再次输入密码'));
                } else if (value !== this.ruleForm3.password) {
                callback(new Error('两次输入密码不一致!'));
                } else {
                callback();
                }
            };
            return {
                dialogVisibleDy:false,
                activeNamePl:'1',
               activeIndex:'1',
               activeName:'first',
               activeName2:'3',
               activeName3:'3',
               activeName4:'1',
               activeName6:'1',
               activeName7:'1',
               activeName8:'1',
               activeName14:'1',
               activeName15:'1',
               activeName16:'',
               currentPage: 1,
               pageSize:8,
               total:0,
               currentPage2: 1,
               total2:0,
               currentPage4: 1,
               total4:0,
               currentPage44: 1,
               total44:0,
               currentPage6: 1,
               total6:0,
               currentPage7: 1,
               total7:0,
               currentPage8: 1,
               total8:0,
               currentPage82: 1,
               total82:0,

               ruleForm: {
                nickname: '',
                sex:'',
                realName: '',
                company:'',
                position:'',
                email:''
               },
               rules: {
                    user: [
                        { trigger: 'blur' }
                    ],
                    name: [
                        {trigger: 'blur' }
                    ],
                    company: [
                        {trigger: 'blur' }
                    ],
                    position: [
                        {trigger: 'blur' }
                    ],
                    email: [
                        {validator: checkEmail,trigger: 'blur' }
                        //{trigger: 'blur' }
                    ]
                },
                imageUrl: '',
                ruleForm2:{},
                codeCd: false,
                // CD长度
                long: 60,
                ruleForm3: {
                    password: '',
                    checkPass: '',
                    user: '',
                    checkCode11: ''
                },
                rules3: {
                    password: [
                        { validator: validatePass1, trigger: 'blur' }
                    ],
                    checkPass: [
                        { validator: validatePass2, trigger: 'blur' }
                    ],
                    user: [
                        { validator: checkAge, trigger: 'blur' }
                    ],
                    checkCode11: [
                        { validator:checkCodes, trigger: 'blur' }
                    ]
                },
                phoneNo:'',
                fileList: [],
                resultsrc: '',
                resvisible: false,
                //imgUrl:'http://192.168.1.8:8080/admin/specImgInfo/uploadNew',
                //imgUrl:'http://192.168.2.26:8073/specImgInfo/uploadNew',
                //imgUrl:'http://192.168.2.26:8073/specImgInfo/uploadNew',
                imgUrl:'https://www.biaox.cn/webcrawler/specImgInfo/uploadNew',
                headerId:'',
                gysList:[],
                bxList:[],
                tableData: [],
                tableData2:[],
                scTabs:'',
                lisTabs:'',
                lisTab:'first',
                userInfos:{},
                isShowTx:'',
                level:[],
                scTabs4:'1',
                tableData3:[],
                tableData4:[],
                hylist:[],
                yqLj:'',
                beiji:false,
                allHistory:[],
                strUrl:'',
                str:'',
                index1:'',
                allList:[],
                isShow:'',
                tableData33:[],
                tableData77:[],
                tableData88:[],
                tableData882:[],
                multipleSelection:[],
                dialogVisible: false,
                dialogVisibles: false,
                activeNamefp:'0',
                activeNamefp2:'1',
                inputFp1:'',  //发票抬头
                inputFp2:'',   //税号
                inputFp3:'',   //手机号
                inputFp4:'',    //邮箱
                inputFp5:'',   

                ewmVisible:false,
                isChao:true,
                orderPay:[],
                orderPays:[],
                ewmImg:'',
                paySuccess:false,
                totalTime:3,
                messageTitle:'',
                vipEndtime:'',
                zfoptions:[{
                        value: '0',
                        label: '未支付'
                        }, {
                        value: '1',
                        label: '已支付'
                        }, 
                ],
                zfValue:'',
                searchCon:'',
                activeName13:'1',
                tableData13:[],


                subscribeName:'',  //订阅名称
                keyWordsA:[],
                keyWords:'',  //关键词
                optionTime: [{
                    value: '0',
                    label: '0:00'
                    }, {
                    value: '1',
                    label: '1:00'
                    }, {
                    value: '2',
                    label: '2:00'
                    }, {
                    value: '3',
                    label: '3:00'
                    }, {
                    value: '4',
                    label: '4:00'
                   }, {
                    value: '5',
                    label: '5:00'
                    }, {
                    value: '6',
                    label: '6:00'
                    }, {
                    value: '7',
                    label: '7:00'
                    }, {
                    value: '8',
                    label: '8:00'
                   }, {
                    value: '9',
                    label: '9:00'
                   }, {
                    value: '10',
                    label: '10:00'
                    }, {
                    value: '11',
                    label: '11:00'
                    }, {
                    value: '12',
                    label: '12:00'
                    },
                    {
                    value: '13',
                    label: '13:00'
                    }, {
                    value: '14',
                    label: '14:00'
                   }, {
                    value: '15',
                    label: '15:00'
                    }, {
                    value: '16',
                    label: '16:00'
                    }, {
                    value: '17',
                    label: '17:00'
                    }, {
                    value: '18',
                    label: '18:00'
                   }, {
                    value: '19',
                    label: '19:00'
                   }, {
                    value: '20',
                    label: '20:00'
                    }, {
                    value: '21',
                    label: '21:00'
                    }, {
                    value: '22',
                    label: '22:00'
                    },{
                    value: '23',
                    label: '23:00'
                    }
                ],
                timeS:'9:00',
                notice1:'0',
                notice2:'1',
                notice3:'1',
                subscribeId:'',
                opFlag:'',
                emailA:'',
                emailCon:'',
                userInfos:[],
                wechatStatus:'',
                timerT:null,
                timerTs:null,
                quanList:[
                    // {id:1,useState:'1',couponAmount:'100',couponName:'标圈圈会员抵扣券',},
                    // {id:2,useState:'-1',couponAmount:'100',couponName:'标圈圈会员抵扣券',},
                    // {id:3,useState:'-3',couponAmount:'100',couponName:'标圈圈会员抵扣券',},
                ],
                fkList:[],
                add_ObjDetails:[], //反馈图片数组
                limit:6,
                dialogImageUrl: '',
				dialogVisible: false,
                uploadDisabled:false,
                fkContent:'',
                fkNumber:'',
                listAllMess:[],
                objId:'',
                objIds:'',
                invoiceRecordId:'',   //申请重开id
                dialogTableVisibleURL:false,
                fpUrls:'',
                flagActivity:'', 
            };
        },
        mounted() {
            var that=this
            let now   = new Date();
            var y = now.getFullYear();
            var m = now.getMonth() + 1;
            var d = now.getDate();
            m = m < 10 ? "0" + m : m; //月小于10，加0
            d = d < 10 ? "0" + d : d; //day小于10，加0
            this.vipEndtime=y+1 + "-" + m + "-" + d
        // 判断登录状态
            let phoneNo = JSON.parse(localStorage.getItem('phoneNo'));
            that.phoneNo=phoneNo

            console.log(that.$route.query.actives)
            if(that.$route.query.actives!=''&&that.$route.query.actives!=undefined){
                that.activeIndex = that.$route.query.actives
                if(that.activeIndex==13){
                    that.showDyall()
                }else if(that.activeIndex==6){
                  that.showMessage(that.currentPage6)
                }else if(that.activeIndex==8){
                  that.getOrderByUser('1')
               }
            }else{
                that.activeIndex = 1
            }

            let urls=window.location.href
            let index = urls.indexOf('#');//获取“=”首次出现的位置
            let o = urls.substring( index + 1 );
            let indexs = o.indexOf("?")
            let resolve = o.substring(0, indexs);
            let indexss = resolve.indexOf("/");
            that.strUrl = resolve.substring(indexss + 1,resolve.length);

            that.paySuccess=false
            that.isPay=true
            clearInterval(that.timerT)
            that.totalTime = 3
            that.timerT = null

            that.queryInfos()
            //that.querInfo()
            that.fkLists()
           // that.getOrderByUser()
        },
        watch: {
            '$route': function (to, from) {
            let that=this
            that.activeIndex = that.$route.query.actives
               if(that.activeIndex==6){
                  that.showMessage(that.currentPage6)
                }
            }
        },
        methods: {
            messageDetail(id){
                let that=this
                that.$axios.post('/webInterface/upSysSubscribe?id='+id)
                    .then(function (res) {
                       console.log(res);
                       if(res.data.resultcode==1){
                        for(var i=0;i<that.listAllMess.length;i++){
                            if(that.listAllMess[i].id==id){
                                that.listAllMess[i].readState=1
                            }
                        }
                        const {href}=that.$router.resolve({
                            // 跳转到的页面路径
                            path: '/subscribe',
                            // 传递的参数集合
                            // query: {
                            //     subscribeId: ''
                            // }
                        })
                        window.open(href,'_blank')

                        //that.$router.push({path: '/subscribe'})
                       }
                    })
                    .catch(function (err) { 
                    })  
            },
            dleMessage(id){
                let that=this
                that.$axios.post('/webInterface/upSysSubscribe?id='+id+'&readState=-1')
                    .then(function (res) {
                       console.log(res);
                       if(res.data.resultcode==1){
                        that.$message({
                            message: '删除成功!',
                            type: 'success'
                        });
                        console.log(that.listAllMess.length)
                        console.log(that.currentPage6)
                        if(that.listAllMess.length==1){
                            that.currentPage6=that.currentPage6-1
                        }
                        console.log(that.currentPage6)
                        that.showMessage(that.currentPage6)
                       }
                    })
                    .catch(function (err) { 
                    })  
            },
            showMessage(currentP){
                let that=this
                that.$axios.get('/webInterface/querySysSubscribeList', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            objectType:'',
                            pageSize:that.pageSize,
                            pageNo:currentP, 
                        }
                    })
                    .then(function (res) {
                       console.log(res);
                       if(res.data.resultcode==1){
                        that.listAllMess=res.data.sysSmsSendlogList
                        that.total6=res.data.totalCount
                        // if(currentP==1){
                        //     that.listAllMess=[
                        //         {id:1,subscribeName:'订阅1',sendTime:'2023-8-14',messageList:['订阅11','订阅22']},
                        //         {id:2,subscribeName:'订阅2',sendTime:'2023-8-14',messageList:['订阅11','订阅22']},
                        //         {id:3,subscribeName:'订阅3',sendTime:'2023-8-14',messageList:['订阅11','订阅22']},
                        //         {id:4,subscribeName:'订阅4',sendTime:'2023-8-14',messageList:['订阅11','订阅22']},
                        //         {id:5,subscribeName:'订阅5',sendTime:'2023-8-14',messageList:['订阅11','订阅22']},
                        //         {id:6,subscribeName:'订阅6',sendTime:'2023-8-14',messageList:['订阅11','订阅22']},
                        //         {id:7,subscribeName:'订阅7',sendTime:'2023-8-14',messageList:['订阅11','订阅22']},
                        //         {id:8,subscribeName:'订阅8',sendTime:'2023-8-14',messageList:['订阅11','订阅22']},
                        //     ]
                        // }else{
                        //     that.listAllMess=[
                        //         {id:9,subscribeName:'订阅9',sendTime:'2023-8-14',messageList:['订阅11','订阅22']},
                        //     ]
                        // }
                        // that.total6= 9
                       }
                    })
                    .catch(function (err) { 
                    })
            },
           
            //提交反馈
            submitFk(){
                let that=this
                let ids=[]
				for(var i=0;i<that.add_ObjDetails.length;i++){
					  ids.push(that.add_ObjDetails[i].imgid)
				}
				ids=ids.toString()
				let myreg=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                if(that.activeName16==''){
                    that.$message.warning('请选择反馈类型');
				}else if(that.fkContent==''){
                    that.$message.warning('请输入反馈内容');
				}else if(that.fkNumber==""){
                    that.$message.warning('请输入手机号');
				}else if(!myreg.test(that.fkNumber)){
                    that.$message.warning('请输入正确的手机号');
				}else{
                    that.$axios.get('/webInterface/upFeedbackInfo', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            content:encodeURI(that.fkContent),
                            feedbackType:that.activeName16,
                            linkPhone:that.fkNumber,
                            imgIds:ids
                        }
                    })
                    .then(function (res) {
                       console.log(res);
                       if(res.data.resultcode==1){
                        that.$message({
                            message: '提交成功',
                            type: 'success'
                         });
                         that.activeName16=''
                         that.fkContent=''
                         that.fkNumber=''
                         that.add_ObjDetails=[]
                         ids=''
                         that.$refs.uploadfiles.clearFiles()
                         that.uploadDisabled = false
                       }else{
                        that.$message({
                            message: res.data.resultmessage,
                            type: 'wraning'
                         });
                       }
                    })
                    .catch(function (err) { 
                    })
                }
            },
           // 删除图片
            handleRemove(file, fileList) {
               this.add_ObjDetails.filter((item,index) => {
                    if (item.imgname == file.name) {
                    this.add_ObjDetails.splice(index, 1);
                    }
                });
                if(fileList.length>=6){
                    this.uploadDisabled = true
                }else{
                    this.uploadDisabled = false
                }
            },
            handleLimit(file,fileList){
                if(fileList.length>=6){
                        this.uploadDisabled = true;
                    }
            },
            //预览图片时
            handlePreview(file) {
                this.dialogImageUrl = file.url
                this.dialogVisibles = true
            },
            //上传图片
            imgUpload(res, file) {
                // console.log(res.fileUpload.fullFileUrl)
                // console.log(res.fileUpload.id)
              
                this.add_ObjDetails.push({
                    imgname:res.fileUpload.fileName,
                    imgid:res.fileUpload.id,
                    imgurl:res.fileUpload.fullFileUrl,
                });
            },
            handleExceed(files,fileList){
                this.$message.warning(`当前限制选择6个图片，本次选择了${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            fkLists(){
                let that=this
                that.$axios.post('/webInterface/querySysRefList?optionCode=feedback_type')
                .then(function (res) {
                   //console.log(res);
                    if(res.data.resultcode==1){
                        that.fkList=res.data.sysRefListList
                        for(var i=0;i<that.fkList.length;i++){
                            if(i==that.fkList.length-1){
                                that.activeName16=that.fkList[i].dictionaryOptionId
                            }
                        }
                    }
                })
                .catch(function (err) {
                    console.log(err);
                })
            },
            handleClick16(e){
                this.activeName16=e._props.name
            },
            yhqRules(){
                this.$alert('1、优惠券是什么<br/>优惠券可在购买天眼查VIP相关商品时抵扣相应金额，具体抵扣金额以页面显示为准。<br/>2、优惠券领取方式 <br/>平台会不定期在天眼查客户端、电脑端等推送营销活动，用户可通过各活动入口领取并 使用优惠券，请您及时关注活动动态。<br/> 3、优惠券使用规则 <br/>优惠券仅限购买天眼查部分商品使用，具体使用范围以券页面显示为准。 每张优惠券仅限使用一次，每个订单只可使用一张优惠券，优惠券不可拆分使用。 订单支付完成后优惠券自动核销，核销的优惠券不予退还。 优惠券与线上其他活动优惠不可叠加使用。 优惠券不得提现，不支持出售、赠予他人，或为他人付款。 优惠券只能在有效期内使用，具体使用期限以券页面显示为准。<br/> 4、其他说明 <br/>用户不得以不正当手段（作弊、机器注册、虚假手机号、网络攻击等）领取或使用优惠 券。如遇其他不可抗力因素需暂停相关优惠券使用的，优惠券恢复使用后天眼查将另行发布 公告通知相关事宜。 平台会不定期对优惠券使用规则进行调整，请您及时关注。', '优惠券使用规则', {
                    dangerouslyUseHTMLString: true,
                    customClass: 'msgBox' 
                    });
            },
            open() {
                this.$alert('<div>1.开票内容为：【*研发和技术服务*技术服务费】或【*研发和技术服务*咨询服务费】。</div><div>2.申请开票成功后，电子普票即时交付至所留邮箱，纸质普票5-7个工作日内开具，纸质 专票需10-15个工作日内开具。</div><div>3.发票信息提交后无法修改，如发票信息有误或更改发票种类，请联系客服。</div><div>4.每笔订单支持换票重开一次，非我方引起的发票问题，换票来回邮费请自理。</div>', '开票说明', {
                confirmButtonText: '确定',
                dangerouslyUseHTMLString: true,
                callback: action => {
                }
                });
            },
            //优惠券
            showQuan(){
                let that=this
                that.$axios.post('/webInterface/queryCouponUserList?couponType=3')
                .then(function (res) {
                   console.log(res);
                    if(res.data.resultcode==1){
                        that.quanList=res.data.couponUserList
                    }
                })
                .catch(function (err) {
                    console.log(err);
                })
            },
            //可开发票订单
            getOrderByUser(currentP){
                let that=this
                that.$axios.get('/webInterface/queryOrderList', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            mine:'1',  
                            pageSize:that.pageSize,
                            pageNo:currentP, 
                            invoiceFlag:'1',
                            startDate:'',
                            endDate:''
                        }
                    })
                    .then(function (res) {
                     console.log(res);
                     if(res.data.resultcode==1){
                        that.tableData88=res.data.orderInfoList
                        that.total8=res.data.totalCount
                     } 
                    })
                    .catch(function (err) {
                        console.log(err);
                        that.$message.error('发送失败')
                    })
            },
            //开票记录
            getOrderByUser2(currentP){
                let that=this
                that.$axios.post('/webInterface/operateInvoiceRecord?type=4'+'&pageSize='+that.pageSize+'&pageNo='+currentP)
                    .then(function (res) {
                     console.log(res);
                     if(res.data.resultcode==1){
                        that.tableData882=res.data.bidInvoiceRecordList
                        that.total82=res.data.totalCount
                     }
                    })
                    .catch(function (err) {
                        console.log(err);
                        that.$message.error('发送失败')
                    })
            },
            //申请开票
            appFor(e){
              if(e==''){
                if(this.objId==''){
                    this.$message.warning('请选择要开票的订单')
                    return
                }else{
                    this.objIds=this.objId
                }
              }else{
                 this.objIds=e 
              }
            //   console.log(this.objId)
            //   console.log(this.objIds)
                this.dialogVisible=true
                this.activeNamefp='0'  //抬头类型
                this.inputFp1=''  //发票抬头
                this.inputFp4=''  //税号
                this.inputFp2='' //手机号码 
                this.inputFp3='' //邮箱
                this.inputFp5=''  //更多内容
                this.invoiceRecordId='' 
            },
            //关闭发票提交弹框
            closeFpDialog(){
                this.dialogVisible=false
                this.objId=''
                this.objIds=''
            },
            //提交发票订单
            submitFpOrder(){
                // console.log(this.objIds)
                // console.log(this.activeNamefp)  //抬头类型
                // console.log(this.inputFp1)  //发票抬头
                // console.log(this.inputFp2)  //手机号
                // console.log(this.inputFp3)   //邮箱
                // console.log(this.inputFp4)   //税号
                // console.log(this.inputFp5)   //更多内容

               let that=this
               let rege=/^[1][3,4,5,6.7,8,9][0-9]{9}$/   
			   let regex =/^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/
			  
			  if(that.inputFp1==''){
                 that.$message('请输入发票抬头')
				 return
			  }else if(that.inputFp2==''){
                that.$message('请输入手机号码')
				 return
			  }else if(!rege.test(that.inputFp2)){
                 that.$message('手机号码输入有误')
				  return
			  }else if(that.inputFp3==''){
                that.$message('请输入邮箱')
			  	return
			  }else if(!regex.test(that.inputFp3)){
                that.$message('邮箱输入有误')
			  	return
			  }

             if(that.activeNamefp==1){
                    //单位  inputFp4
                    if(that.inputFp4==''){
                        that.$message('请输入单位税号')
                        return
                    }
              }
            
              if(that.objIds!=''){
                   that.addFps('1')
                }else{
                that.addFps('3')
                }
            },
            addFps(flags){
                let that=this
                // console.log(flags)
                // console.log(that.activeNamefp)
                // console.log(that.inputFp1)
                // console.log(that.inputFp4)
                // console.log(that.inputFp2)
                // console.log(that.inputFp3)
                // console.log(that.objIds)
                // console.log(that.inputFp5)
                // console.log(that.invoiceRecordId)

               that.$axios.get('/webInterface/operateInvoiceRecord', {  //params参数必写 , 如果没有参数传{}也可以
                    params: {  
                      type:flags,
                      invoiceId:'',  //抬头id
                      invoiceType:1,              //发票类型
                      unitType:that.activeNamefp,    //抬头类型
                      unitName:that.inputFp1,    //发票抬头
                      unitCreditCode:that.inputFp4,     //税号
					  invoicePhoneNo:that.inputFp2, //手机号码 
					  invoiceEmail:that.inputFp3,  //邮箱
					  orderIds:that.objIds,   //开票订单id    修改为3
					  invoiceRecordNote:that.inputFp5,  //更多内容
					  invoiceRecordId:that.invoiceRecordId   //订单编号 
                    }
                })
                .then(function (res) {
                    console.log(res)
                    if(res.data.resultcode==1){
                        that.$message.success('发票申请成功')
                        that.dialogVisible=false
                        let ars=that.objIds.split(",")
                        console.log(that.tableData88.length)
                        console.log(ars.length)
                        if(flags==1){
                            if(that.tableData88.length==ars.length){
                                that.currentPage8=that.currentPage8-1
                            }
                            console.log(that.currentPage8)
                          that.getOrderByUser(that.currentPage8)
                        }else{
                        //     if(that.tableData882.length==1){
                        //     that.currentPage82=that.currentPage82-1
                        //   }
                          that.getOrderByUser2(that.currentPage82)
                        }
                       
                      
                    }else{
                        that.$message.error(res.data.resultmessage)
                    } 
                })
                .catch(function (err) {
                    console.log(err);
                    that.$message.error('发送失败')
                })
              
            },
            //查看已开发票
            look(parthUrl){
                this.dialogTableVisibleURL=true
                this.fpUrls=parthUrl
            },
            //2申请重开，5重新发送
            lsApply(flag,invoiceRecordId){
				let that=this
				if(flag==2){
                    that.invoiceRecordId=invoiceRecordId
					//that.addFps('3')
                    that.$axios.post('/webInterface/operateInvoiceRecord?type=4'+'&invoiceRecordId='+invoiceRecordId)
                    .then(function (res) {
                     console.log(res);
                     if(res.data.resultcode==1){
                        that.dialogVisible=true
                        that.activeNamefp=res.data.bidInvoiceRecordList[0].unitType   //抬头类型
                        that.inputFp1=res.data.bidInvoiceRecordList[0].unitName,    //发票抬头
                        that.inputFp4=res.data.bidInvoiceRecordList[0].unitCreditCode   //税号
                        that.inputFp2=res.data.bidInvoiceRecordList[0].invoicePhoneNo //手机号码 
                        that.inputFp3=res.data.bidInvoiceRecordList[0].invoiceEmail //邮箱
                        that.inputFp5= res.data.bidInvoiceRecordList[0].invoiceRecordNote  //更多内容
                     }
                    })
                    .catch(function (err) {
                        console.log(err);
                    })
                   
				}else{
					//重新发送
                    that.$axios.post('/webInterface/operateInvoiceRecord?type=5'+'&invoiceRecordId='+invoiceRecordId)
                    .then(function (res) {
                     console.log(res);
                     if(res.data.resultcode==1){
                        that.$message.success('发送成功!')
                     }
                    })
                    .catch(function (err) {
                        console.log(err);
                    })

				}
			},
            amountChange(e){
              this.beiji=e
            },
            async amountChanges(e){
              this.beiji=e
              this.flagActivity='1'
                let that=this
                    setTimeout(function(){
                    if(that.$route.query.str!=''&&that.$route.query.str!=undefined){
                        that.str = that.$route.query.str
                    }
                   
                    if(that.beiji==false){
                        if(that.str==1){
                            that.activeIndex=4
                        }
                    }
                },1000)
                
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.showHistory(this.lisTabs,this.currentPage)
            },
            handleCurrentChange2(val) {
                this.currentPage2=val
                this.showShoucang(this.scTabs,this.currentPage2)
            },
            handleCurrentChange4(val) {
                this.currentPage4=val
                this.showHy(this.currentPage4)
            },
            handleCurrentChange44(val) {
                this.currentPage44=val
                this.showTg(this.currentPage44)
            },
            handleCurrentChange6(val) {
                this.currentPage6=val
                this.showMessage(this.currentPage6)
                document.querySelector('#tabsQ').scrollIntoView({
                    behavior: "smooth"
                });
            },
            handleCurrentChange7(val) {
                this.currentPage7=val
                this.orders(this.currentPage7)
            },
            handleCurrentChange8(val) {
                this.currentPage8=val
                this.getOrderByUser(this.currentPage8)
            },
            handleCurrentChange82(val) {
                this.currentPage82=val
                this.getOrderByUser2(this.currentPage82)
            },
            // deleteRow(index, rows,flag) {
            //    var that=this
            //     that.$axios.get('/webInterface/upBookmark', {  //params参数必写 , 如果没有参数传{}也可以
            //         params: {  
            //             objectId:rows[index].id,
            //             objectType:flag
            //         }
            //     })
            //     .then(function (res) {
            //       console.log(res);
            //         if(res.data.resultcode==1){
            //             that.$message({
            //                 message: '删除成功',
            //                 type: 'success'
            //             });
            //            if(that.scTabs==1){
            //             if(that.tableData.length==1){
            //                 that.currentPage2=that.currentPage2-1
            //             }
            //            }else{
            //             if(that.tableData2.length==1){
            //                 that.currentPage2=that.currentPage2-1
            //             }
            //            }
                       
            //             that.handleCurrentChange2(that.currentPage2)
            //             that.showShoucang(that.scTabs,that.currentPage2)
            //         }
                 
            //     })
            //     .catch(function (err) {
            //         console.log(err);
            //     })
            // },
            deleteRows(index, rows,flag) {
               var that=this
                that.$axios.get('/webInterface/upBookmark', {  //params参数必写 , 如果没有参数传{}也可以
                    params: {  
                        bookmarkId:rows[index].bookmarkId,
                    }
                })
                .then(function (res) {
                console.log(res);
                    if(res.data.resultcode==1){
                        that.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                  
                        if(that.tableData3.length==1){
                            that.currentPage2=that.currentPage2-1
                        }
                       
                        that.handleCurrentChange2(that.currentPage2)
                        that.showShoucang(that.scTabs,that.currentPage2)
                    }
                 
                })
                .catch(function (err) {
                    console.log(err);
                })
            },
             //关闭支付弹框
             handleClose(){
                this.ewmVisible=false
                clearInterval(this.timerT)
                this.timerT = null
            },
            //弹框支付
            payRows(index,rows){
                let that=this
              console.log(rows[index])
              that.$axios.get('/webInterface/generateOrder', {  //params参数必写 , 如果没有参数传{}也可以
                    params: {  
                        orderIds:rows[index].id,
                        payType:2,
                    }
                })
                .then(function (res) {
                    console.log(res);
                     if(res.data.resultcode==1){
                        that.ewmVisible=true
                        that.orderPays=res.data.orderInfo
                        that.ewmImg=res.data.reStr  //二维码
                        that.$axios.get('/webInterface/queryOrderInfo?orderId='+rows[index].id)
                        .then(function (res) {
                            console.log(res);
                            if(res.data.resultcode==1){
                                that.orderPay=res.data.orderInfoList[0].goodsInfoList[0]
                            }
                        })
                        .catch(function (err) { 
                        })
                        that.timerT = setInterval(() => {
                            //  定时器中执行的代码
                                that.check(res.data.orderInfo.id)
                         }, 3000)
                    }
                })
                .catch(function (err) { 
                })
            },
            //点击支付
            check(orderId){
                var that=this
               that.$axios.post('/webInterface/queryOrderInfo?orderId='+orderId+'&flag=1')
                .then(function (res) {
                    console.log(res);
                    // res.data.resultcode=1
                    // res.data.orderInfo.state=1
                 if(res.data.resultcode=='1'&& res.data.orderInfo.state!=0){
                     that.paySuccess=true
                     that.messageTitle='支付成功，已开通年费VIP'

                        setTimeout(function(){
                            that.ewmVisible=false
                            clearInterval(that.timerT)
                            that.timerT = null
                            that.paySuccess=false
                            that.orders(that.currentPage7)
                        },2500)

					}else{
                        that.paySuccess=false
                       // clearInterval(that.timerT)
                       // that.timerT = null
                    }
                })
                .catch(function (err) { 
                })
            },

            // querInfo(){
            // var that=this
            //   that.$axios.post('/webInterface/queryUserOther')
            //     .then(function (res) {
            //        console.log(res);
            //         if(res.data.resultcode==1){ 
            //         that.level=res.data.userOther
            //         }
            //     })
            //     .catch(function (err) {
            //         console.log(err);
            //     })
			// },
            queryInfos(){
                var that=this
                that.$axios.post('/webInterface/queryUserInfo')
                    .then(function (res) {
                       console.log(res)
                     if(res.data.resultcode==1){
                        that.level=res.data.userOther
                        that.userInfos=res.data.userInfo
                        that.wechatStatus=res.data.status
                        that.emailA=that.userInfos.email
                        if(that.wechatStatus==1){ 
                            clearInterval(that.timerTs)
                        }
                        
                        //that.yqLj='http://192.168.2.20:8080/#/register?type='+that.userInfos.sn
                        that.yqLj='https://www.biaox.cn/#/register?type='+that.userInfos.sn
                        
                        if(that.userInfos.headPic!=''&&that.userInfos.headPic!=undefined){
                            that.isShowTx=true
                        }else{
                            that.isShowTx=false
                        }
                        if(res.data.userInfo.positionName==undefined){
                            res.data.userInfo.positionName=''
                        }
                        if(res.data.userInfo.departmentId==undefined){
                            res.data.userInfo.departmentId=''
                        }
                        if(res.data.userInfo.realName==undefined){
                            res.data.userInfo.realName=''
                        }
                        if(res.data.userInfo.nickname=='undefined'){
                            res.data.userInfo.nickname=''
                        }
                       
                        let sexs=''
                        if(res.data.userInfo.sex!='undefined'&&res.data.userInfo.sex!=undefined){
                            sexs=res.data.userInfo.sex
                        }

                        that.ruleForm={
                                nickname: res.data.userInfo.nickname,  //昵称
                                realName:res.data.userInfo.realName, //真是姓名
                                company:res.data.userInfo.departmentId, //公司
                                position:res.data.userInfo.positionName,
                                email:res.data.userInfo.email,
                                sex:sexs
                        }
                    }else if(res.data.resultcode==-9999){
                        that.$message.error('登录信息失效，请重新登录')
                        setTimeout(()=>{  //设置延迟执行
                            that.$router.push({path: '/login'});
                       },1500);
                     } 
                    })
                    .catch(function (err) {
                        console.log(err);
                    })
            },
            //切换左边菜单栏
            handleSelect(key) {
               // console.log(key);
                this.activeIndex=key
                this.currentPage=1
                if(this.activeIndex==2){
                    this.showHistory(this.lisTabs,'1')
                }else if(this.activeIndex==3){
                    this.showShoucang(this.scTabs,'1')
                }else if(this.activeIndex==4){
                    //this.showTg()
                }else if(this.activeIndex==6){
                    this.showMessage(this.currentPage6)
                }else if(this.activeIndex==7){
                    this.orders(this.currentPage7)
                }else if(this.activeIndex==8){
                    this.getOrderByUser(this.currentPage8)
                }else if(this.activeIndex==13){
                    this.showDyall()
                }else if(this.activeIndex==14){
                    this.showQuan()
                }
            },
            clearMessage(){
                let that=this
                 that.$axios.post('/webInterface/upSysSubscribe?readState=-1'+'&cleanAll=1'+'&id=')
                    .then(function (res) {
                     console.log(res);
                     if(res.data.resultcode==1){
                        that.$message({
                            message: '清除成功',
                            type: 'success'
                            });
                            if(that.listAllMess.length==1){
                                that.currentPage6=that.currentPage6-1
                            }
                            that.showMessage(that.currentPage6) 
                           // that.listAllMess=''
                     }else{
                        that.$message({
                            message: '清除失败,请重试',
                            type: 'error'
                            }); 
                     }
                    })
                    .catch(function (err) {
                        console.log(err);
                    })
            },
            clear(){
                var that=this
                that.$axios.post('/webInterface/cleanVisit?objectType='+that.lisTabs)
                    .then(function (res) {
                     console.log(res);
                     if(res.data.resultcode==1){
                     if(that.lisTabs==1){
                        that.gysList=''
                        that.total=0
                        that.$message({
                            message: '清除成功',
                            type: 'success'
                            }); 
                     }else if(that.lisTabs==2){
                        that.bxList=''
                        that.total=0
                        that.$message({
                            message: '清除成功',
                            type: 'success'
                            }); 
                     }else{
                        that.allList=''
                        that.total=0
                        that.$message({
                            message: '清除成功',
                            type: 'success'
                            }); 
                       }
                     }
                    })
                    .catch(function (err) {
                        console.log(err);
                    })
            },
            showHistory(flag,currentP){
                var that=this
                that.$axios.post('/webInterface/queryVisitList?objectType='+flag+'&pageSize='+that.pageSize+'&pageNo='+currentP)
                    .then(function (res) {
                    //console.log(res)
                     if(flag==1){
                       that.gysList=res.data.visitRecordList
                       that.total=res.data.totalCount
                     }else if(flag==2){
                       that.bxList=res.data.visitRecordList
                        that.total=res.data.totalCount
                     }else {
                       that.allList=res.data.visitRecordList
                        that.total=res.data.totalCount
                     }
                    })
                    .catch(function (err) {
                        console.log(err);
                    })
            },
            showShoucang(flag,currentP){
                console.log(flag)
                var that=this
                that.$axios.post('/webInterface/queryBookmarkList?objectType='+flag+'&pageSize='+that.pageSize+'&pageNo='+currentP)
                    .then(function (res) {
                     if(flag==1){
                       that.tableData=res.data.enCompanyList
                       that.total2=res.data.totalCount
                     }else if(flag==2){
                        that.tableData2=res.data.scripingpageList
                        that.total2=res.data.totalCount
                     }else{
                        console.log(res);
                        that.tableData3=res.data.bookmarkInfoList
                    //     let list=res.data.bookmarkInfoList
                    //     let arr = []
                    //     let aa=''
                    //     list.forEach((item,index)=>{
                    //         console.log(item.objectType)
                    //         if(item.objectType==1){
                    //              aa=1
                    //         }else{
                    //             aa=2
                    //         }
                    //         arr.push(Object.assign({},item,{isShow:aa}))
                    //     })
                    //    that.tableData3=arr
                        that.total2=res.data.totalCount
                     }
                    })
                    .catch(function (err) {
                        console.log(err);
                    })
            },
            showTg(currentP){
                var that=this
                that.$axios.post('/webInterface/queryUserLevel?pageSize=10'+'&pageNo='+currentP)
                    .then(function (res) {
                     console.log(res);
                     if(res.data.resultcode==1){
                        that.tableData33=res.data.userInfoList
                        that.total44=res.data.totalCount
                      }
                    })
                    .catch(function (err) {
                        console.log(err);
                    })
            },
            showHy(currentP){
                var that=this
                that.$axios.post('/webInterface/queryUserMemberRecord?pageSize='+that.pageSize+'&pageNo='+currentP)
                    .then(function (res) {
                    // console.log(res);
                     if(res.data.resultcode==1){
                        that.tableData4=res.data.userMemberRecordList
                        that.total4=res.data.totalCount
                      }
                    })
                    .catch(function (err) {
                        console.log(err);
                    })
                    
            },
            handleClick(event) {
                this.lisTab=event._props.name
            },
            handleClicks(event) {
                var that=this
                if(event._props.name=='3'){
                    that.lisTabs=''
                }else{
                    that.lisTabs=event._props.name
                }
                that.currentPage=1
                that.showHistory(that.lisTabs,that.currentPage)
            },
            handleClicks3(event) {
                var that=this
                if(event._props.name=='3'){
                    that.scTabs=''
                }else{
                    that.scTabs=event._props.name
                }
               that.currentPage2=1
              that.showShoucang(that.scTabs,that.currentPage2)
            },
            handleClicks4(event) {
                var that=this
                that.scTabs4=event._props.name
              if(that.scTabs4==3){
                this.showTg(this.currentPage44)
              }if(that.scTabs4==4){
                that.showHy(this.currentPage4)
              }
            },
            handleClicks6(event){

            },
            handleClicks7(event) {
                var that=this
               // that.scTabs4=event._props.name
               //console.log(event._props.name)
            },
            handleClicks8(event) {
                var that=this
               // that.scTabs4=event._props.name
               console.log(that.activeName8)
               if(that.activeName8==1){
                that.getOrderByUser(that.currentPage8)
               }else{
                that.getOrderByUser2(that.currentPage82)
               }
              // console.log(event._props.name)
            },
            handleClicks13(event) {
            },
            handleClickfp(tab, event) {
                console.log(tab, event);
            },
            handleClickfp2(tab, event) {
                console.log(tab, event);
            },
            handleClickfp3(tab, event) {
                console.log(tab, event);
            },
            handleClickfp4(tab, event) {
                console.log(tab, event);
            },
            //选中要开票的订单
            handleSelectionChange(val) {
                this.multipleSelection = val;
                let resultArr = [];
				this.multipleSelection.forEach(item => {
					resultArr.push(item.id)
				});
				this.objId=resultArr.toString()

            },
            
            // 获取
            handleCaptcha (form) {
                var that=this
                that.$refs[form].validateField('user', async (valid) => {
                if (!valid) {
                // 获取验证码
                that.$axios.get('/webInterface/getCode', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            phoneNo:that.ruleForm3.user,
                            flag:2,
                        }
                    })
                    .then(function (res) {
                     console.log(res);
                     if(res.data.resultcode==1){
                        that.$message.success(res.data.resultmessage)
                        that.codeCd = true
                        const timer = setInterval(() => {
                            that.long--
                            if (that.long <= 0) {
                                that.long = 60
                                that.codeCd = false
                            clearInterval(timer)
                            }
                        }, 1000)
                     }else{
                        that.$message.error(res.data.resultmessage)
                     }  
                    })
                    .catch(function (err) {
                        console.log(err);
                        that.$message.error('发送失败')
                    })
                } else {
                console.log('error submit!!')
                return false
                }
            })
            },
            submitForm(formName,flag) {
                var that=this
                if(flag==1){
                    //修改个人信息
                  this.$refs[formName].validate((valid) => {
                  if (valid) {
                    //console.log(that.ruleForm)
                    that.$axios.post('/webInterface/upUserInfo?nickname='+that.ruleForm.nickname+'&realName='+that.ruleForm.realName+'&departmentId='
                    +that.ruleForm.company+'&positionName='+that.ruleForm.position+'&email='+that.ruleForm.email+'&sex='+that.ruleForm.sex)
                    .then(function (res) {
                     console.log(res.data);
                     if(res.data.resultcode==1){
                        that.$message.success('保存成功')
                     }else{
                        that.$message.error(res.data.resultmessage)
                     }
                    })
                    .catch(function (err) {
                        console.log(err);
                        that.$message.error('保存失败，请重试')
                    })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                    }); 
                }else if(flag==2){
                    //修改头像
                        that.$axios.post('/webInterface/upUserInfo?flag=1'+'&imgId='+that.headerId)
                        .then(function (res) {
                        console.log(res.data);
                        if(res.data.resultcode==1){
                            that.$message.success('头像修改成功')
                            //that.activeIndex = that.$route.query.actives
                            //that.activeIndex = 1
                            that.$router.push({
                            // 跳转到的页面路径
                            path: '/userCenter',
                            query: {
                                actives:1
                            }
                        })
                           // that.$router.go(0)
                        }else{
                            that.$message.error(res.data.resultmessage)
                        }
                        })
                        .catch(function (err) {
                            console.log(err);
                            that.$message.error('头像修改失败，请重试')
                        })
                }else{
                    //修改密码
                    that.$refs[formName].validate((valid) => {
                    if (valid) {
                        that.$axios.post('/webInterface/changeMyPassword?phoneNo='+that.ruleForm3.user+'&checkCode='+that.ruleForm3.checkCode11+'&password='+that.ruleForm3.password)
                        .then(function (res) {
                        console.log(res.data);
                        if(res.data.resultcode==1){
                            that.$message.success('密码修改成功')
                            that.phoneNo=''
				           localStorage.clear();
                            setTimeout(()=>{  //设置延迟执行
                                that.$router.push({path: '/login'});
                        },1500);
                        }else{
                            that.$message.error(res.data.resultmessage)
                        }
                        })
                        .catch(function (err) {
                            console.log(err);
                            that.$message.error('密码修改失败，请重试')
                        })

                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                    });

                }
               
            },
            resetForm(formName,flag) {
                let that=this
                //.log(that.$refs[formName])
               // that.$refs[formName].resetFields();
               if(flag==1){
                that.ruleForm={
                        name: '',
                        company:'',
                        position:'',
                        email:''
                    }
               }else{
                that.ruleForm3={
                    password: '',
                    checkPass: '',
                    user: '',
                    checkCode11: ''
                   }
               }
                
            },
            handleAvatarSuccess(res, file) {
                var that=this
                console.log(res)
                that.imageUrl = URL.createObjectURL(file.raw);
                that.headerId=res.fileUpload.id
            },
            beforeAvatarUpload(file) {
                const suffix = file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/jpeg'
                if (!suffix) {
                  this.$message.error('上传图片只能是jpg、jpeg、png格式!');
                  return false
                }

                //  const isJPG = file.type === 'image/jpeg';
                // const isPNG = file.type === 'image/png';
                 const isLt2M = file.size / 1024 / 1024 < 2;
                // if (!isJPG&&!isPNG) {
                //   this.$message.error('上传图片只能是jpg、jpeg、png格式!');
                //   return false
                // }
                if (!isLt2M) {
                 this.$message.error('上传图片大小不能超过 2MB!');
                }
                //return isJPG||isPNG&&isLt2M;
                 return suffix&&isLt2M ;
            },
            //跳转到订阅详情
            openDy(subscribeId){
                const {href}=this.$router.resolve({
                    // 跳转到的页面路径
                    path: '/subscribe',
                    // 传递的参数集合
                    query: {
                        subscribeId: subscribeId
                    }
                })
                window.open(href,'_blank')
            },
            zbDetail(flag){
                console.log(flag)
              const {href}=this.$router.resolve({
                // 跳转到的页面路径
                path: '/zbDetails',
                // 传递的参数集合
                query: {
                    flag: flag,
                }
              })
              window.open(href,'_blank')
            },
            companyDetail(flag){
                const {href}=this.$router.resolve({
                // 跳转到的页面路径
                path: '/companyDetails',
                // 传递的参数集合
                query: {
                    flag: flag
                }
              })
              window.open(href,'_blank')
            },
            // DetailShoucang(row,index){
            //   console.log(row)
            // },
            copy(val){
                var cInput = document.createElement("input");
                cInput.value = val;
                document.body.appendChild(cInput);
                cInput.select(); // 选取文本框内容
                document.execCommand("copy");
                this.$message({
                    type: "success",
                    message: "复制成功"
                });
                // 复制成功后再将构造的标签 移除
                document.body.removeChild(cInput);

            },
            changFp(flag){
              //  console.log(this.inputFp)
            },
            //查询订单
            orders(currentP){
                var that=this
                that.$axios.get('/webInterface/queryOrderList',{  //params参数必写 , 如果没有参数传{}也可以
                    params: {  
                        mine:'1',  //套餐id
                        pageSize:that.pageSize,
                        pageNo:currentP, //单条详情id
                        searchData:that.searchCon,
                        isPay:that.zfValue,
                        //wxType:2
                       
                    }
                })
                .then(function (res) {
                    console.log(res)
                    if(res.data.resultcode==1){
                        that.tableData77=res.data.orderInfoList
                        that.total7=res.data.totalCount
                    }
                })
                .catch(function (err) { 
                })
            },
            del(){
               this.searchCon=''
               this.currentPage7=1
               this.orders(this.currentPage7)
            },
            zfChange(e){
                //this.isPays=e
                this.currentPage7=1
                this.orders(this.currentPage7)
            },
            searchClick(){
                this.currentPage7=1
                this.orders(this.currentPage7)
           },
           titleTz(flag,objType){
            let that=this
            //跳转标讯详情
            if(objType==2){
                const {href}=that.$router.resolve({
                // 跳转到的页面路径
                path: '/zbDetails',
                query: {
                    flag: flag,
                }
              })
              window.open(href,'_blank')
            }else{
                const {href}=this.$router.resolve({
                    // 跳转到的页面路径
                    path: '/companyDetails',
                    // 传递的参数集合
                    query: {
                        flag: flag
                    }
                })
                window.open(href,'_blank')
            }
           
           },
           chooseTime(e){
                this.timeS=e
            },
           //编辑订阅条件
        //    editDy(index,table){
        //       this.dialogVisibleDy=true
        //       //反显点击的订阅条件内容
        //       this.subscribeName=''  //订阅名称
        //       this.keyWords=''  //关键词
        //       this.timeS=''  //订阅时间
        //       this.notice1=1  //是否系统通知 1是  2否
        //       this.notice2=1  //是否微信通知
        //       this.notice3=1  //是否邮件通知
        //    },
           //删除订阅
           deleteDy(subscribeId){
            let that=this
            if(subscribeId==''){
                that.$confirm('确定删除全部订阅吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                }).then(() => {
                    console.log()
                    that.$axios.get('/webInterface/opScripingSubscribe', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            opFlag:5
                        }
                    })
                    .then(function (res) {
                        console.log(res);
                        if(res.data.resultcode==1){
                            that.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                            that.subscribeId=''
                            that.showDyall()
                        } 
                    })
                    .catch(function (err) {
                    })
                }).catch(() => {
                })
            }else{
            that.$confirm('确定删除该条订阅吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                }).then(() => {
                    console.log()
                    that.$axios.get('/webInterface/opScripingSubscribe', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            subscribeId:subscribeId,
                            opFlag:2
                        }
                    })
                    .then(function (res) {
                        console.log(res);
                        if(res.data.resultcode==1){
                            that.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                            that.subscribeId=''
                            that.showDyall()
                        } 
                    })
                    .catch(function (err) {
                    })
                }).catch(() => {
                })
              }
           },
           addDytk(){
                let that=this
                if(that.wechatStatus==0){   //未绑定实时刷新是否已绑定
                    that.timerTs = window.setInterval(() => {
                        that.queryInfos()
                    }, 3000);
                }
                // console.log(that.level.userMemberRankStr)
                // console.log(that.tableData13.length) 
                if(that.level.userMemberRank=='0'&&that.tableData13.length==1){
                    that.$message.warning('需要VIP权限')
                }else if(that.level.userMemberRank=='1'&&that.tableData13.length==10){
                    that.$message.warning('需要高级VIP权限')
                }else{
                    that.dialogVisibleDy =true
                    that.opFlag='1'
                    that.keyWords=''
                    that.subscribeName=''
                    that.activeNamePl='1' //推送频率
                    that.timeS='09:00'
                    that.notice1='0'
                    that.notice2='1'                                
                    that.notice3='1' 
                    that.subscribeId=''
                }
           },
           closeDy(){
                let that=this
                that.dialogVisibleDy = false
                clearInterval(that.timerTs)
            },
             //查询单个订阅详情
             showOneDy(index,subscribeId){
                let that=this
                that.subscribeId=subscribeId
                that.dialogVisibleDy=true
                that.opFlag='3'
                that.queryInfos()
                that.$axios.get('/webInterface/opScripingSubscribe', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            subscribeId:subscribeId,
                            opFlag:4
                        }
                    })
                    .then(function (res) {
                        console.log(res);
                        if(res.data.resultcode==1){
                            //that.activeName=res.data.scripingSubscribe.subscribeId 
                            that.keyWords=res.data.scripingSubscribe.keywords 
                            that.subscribeName=res.data.scripingSubscribe.subscribeName
                            that.notice1=res.data.scripingSubscribe.sysNotice
                            that.notice2=res.data.scripingSubscribe.wechatNotice
                            that.notice3=res.data.scripingSubscribe.emailNotice
                            that.timeS=res.data.scripingSubscribe.pushTimeStr
                        } 
                    })
                    .catch(function (err) {
                        // console.log(err);
                    })
            },

            showDyall(){
                let that=this
                //查询全部订阅
                that.$axios.post('/webInterface/queryScripingSubscribeList').then(res => {
                    console.log(res) 
                    if(res.data.resultcode==1){
                        that.tableData13=res.data.scripingSubscribeList
                    }else if(res.data.resultcode=='-9999'){
                        setTimeout(()=>{  //设置延迟执行
                            that.$router.push({path: '/login'});
                        },600);
                    }
                }).catch(err => {
                    //console.log('请求失败')
                }) 
            },

            addDys(){
                let that=this
                let opFlags=''
                if(that.opFlag==1){
                    opFlags=1
                }else{
                    opFlags=3
                }
                // console.log(that.subscribeId)
                // console.log(that.subscribeName)
                // console.log(that.keyWords)
                // console.log(that.timeS)
                // console.log(opFlags)
                let regex = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
              if(that.emailA!=''&&!regex.test(that.emailA)){
                        that.$message({
                            message: '请输入正确的邮箱',
                            type: 'warning'
                        })
                    return
                }
                if(that.emailCon!=''&&!regex.test(that.emailCon)){
                        that.$message({
                            message: '请输入正确的邮箱',
                            type: 'warning'
                        });
                    return
                } 

                 if(that.notice2==1&&that.wechatStatus==0){
                    that.$message({
                        message: '未绑定微信，无法推送订阅消息',
                        type: 'warning'
                    });
                    return 
                }else if(that.notice3==1&&that.emailA==''&&that.emailCon==''){
                      that.$message({
                            message: '未绑定邮箱，无法推送订阅消息',
                            type: 'warning'
                        });
                        return 
                }else{
                     //更新邮箱
                     let content=''
                     if(that.emailCon==''){
                        content=that.emailA
                     }else{
                        content=that.emailCon
                     }
                    that.$axios.post('/webInterface/upUserInfo?email='+content)
                    .then(function (res) {
                        if(res.data.resultcode==1){
                        }
                    })
                    .catch(function (err) {
                        console.log(err);
                    })

                that.$axios.get('/webInterface/opScripingSubscribe', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            subscribeId:that.subscribeId,
                            subscribeName:that.subscribeName,
                            keywords:that.keyWords,
                            pushFrequency:'1', //推送频率
                            pushTime:that.timeS,
                            sysNotice:that.notice1,
                            wechatNotice:that.notice2,                                  
                            emailNotice:that.notice3,  
                            opFlag:opFlags           
                        }
                    })
                    .then(function (res) {
                       console.log(res);
                       if(res.data.resultcode==1){
                            that.$message({
                                message: '修改订阅成功',
                                type: 'success'
                                });
                                that.dialogVisibleDy = false
                                that.showDyall()
                        }else{
                            that.$message.warning(res.data.resultmessage)
                        }
                    })
                    .catch(function (err) {
                       // console.log(err);
                    })
                }
            },
            //解绑
            jiebang(flag){
              let that=this
              if(flag==1){
                 that.$axios.post('/webInterface/unbidWx')
                    .then(function (res) {
                         console.log(res)
                     if(res.data.resultcode==1){
                        that.wechatStatus=0
                        that.queryInfos()
                        that.timerTs = window.setInterval(() => {
                            that.queryInfos()
                        }, 3000);
                      }
                    })
                    .catch(function (err) {
                        console.log(err);
                    })
              }else{
                   //解绑邮箱
                    that.$axios.post('/webInterface/upUserInfo?email=')
                    .then(function (res) {
                     if(res.data.resultcode==1){
                        that.emailCon=''
                        that.emailA=''
                        that.notice3=0
                        that.queryInfos()
                      }
                    })
                    .catch(function (err) {
                    })
              }
            },
               // 移入
           mouseOver() {
              if(this.wechatStatus!=1){
                 this.$refs.wechatEwm.style='display:show'
              }
               
            },
            // 移出
            mouseLeave() {
                if(this.wechatStatus!=1){
                this.$refs.wechatEwm.style='display:none'
                }
            },



        }
    }
</script>
<style scoped lang="less">
.top{
    background:url('../assets/images/userTop.png');
    width:100%;
    height:200px
}
.topCon{
    width: 1200px;
    margin: 0 auto;
    align-items: center;
    height: 100%;
    color:#FFFFFF;
    font-size: 16px;
}
.container{
    margin-top:15px;
}
.cTitle{
    font-size: 30px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #FFFFFF;
margin-bottom:10px
}
  .rightCon{
    width: 915px;
    background: #FFFFFF;
   // box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 8%);
    border-radius: 5px;
    margin-left: 15px;
    padding: 12px 30px 25px 30px;
    min-height:360px
  }
  .el-col-12 {
    width: 100%;
}
/deep/.el-form-item__content{
    width: 260px;
    font-size: 16px;
}

// 上传头像
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }



  .xgHeader{
    width:207px;
    height:207px;
    margin: 20px 0px 0 0;
  }
  .saveHeader{
    width: 102px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    border-radius: 5px;
    font-size:16px;
    text-align: center;
    line-height: 40px;
    margin-top: 10px;
  }
  /deep/.el-tabs__header{
    margin: 0 0 30px;
   
  }
 .aa{
    position: relative;
 }
 .clears{
    position:absolute;
    top:0;
    right:20px
 }
.bordR{
    border-radius:50%;
}

//   企业
.ant-spin-container {
    position: relative;
    transition: opacity .3s;
  }
  .ant-list-items {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    border-bottom: 1px solid #F3F3F3;
    padding: 5px 0 10px 0;
  }
  .ant-list-items:last-child{
    border-bottom:none
  }
  .tc_list_item.tc_list_hover {
   // padding: 0 20px;
    transition: background-color .2s
  }
 
  .tc_list_item:not(:last-child):after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    border-bottom: 1px solid #eee;
  }
  .R-Dvn3zA{
    padding: 10px 0 8px 0;
    display: flex;
    align-items: center;
  }
  .pYreFzHC {
    padding-right: 20px;
  }
  .pYreFzHC img {
    border: 1px solid #eee;
    width: 60px;
    height: 60px;
    border-radius: 4px;
  }
  ._0aDOKXj6 {
    position: relative;
    width: calc(100% - 235px);
    margin-right: 20px;
  }
  ._0aDOKXj6s {
    position: relative;
    width: calc(100% - 220px);
    margin-right: 44px;
  }
  .FpwufnYn {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
  }
  .FpwufnYn a {
    font-size: 16px;
    font-family: PingFangSC-Medium,PingFang SC;
    font-weight: 700;
    color: #333;

}
.WMSbIy8X {
    position: absolute;
    top: 0;
    right: 0;
    height: 24px;
    color: #000;
    font-weight: 700;
}
.jutRowsA{
    height: 46px;
    background: #F5F7FA;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
        margin-top: 10px;
}
.noticTitles{
    // line-height:46px;
    margin-right: 20px;
}
.noticTitles:last-child{
    margin-right: 0;
}
.huiColor{
     color:#999999;
}
.titles{
    font-size: 18px;
    font-weight: 700;
    color: #333;
    cursor: pointer;
}
.timeA{
    font-size: 17px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;

    // max-width: 100px;
    // overflow: hidden;
    // white-space: nowrap;
}
/deep/.el-tabs__item{
    height: 44px;
    font-size: 16px;
}
/deep/.el-menu{
    border-right:none;
}
/deep/ .el-menu-item {
    font-size: 15px;
}
/deep/.el-input--small .el-input__inner {
    height: 40px;
    line-height: 40px;
}
.el-input--small .el-input__icon {
    line-height: 40px;
}
.yqlj{
    // width: 290px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    border-radius: 2px;
    margin:0 10px;
    line-height: 36px;
    padding: 0 10px;
}
.copyText{
    width: 51px;
    height: 36px;
    background: #0078FF;
    border-radius: 2px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    display:flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.yqrules{
    width: 855px;
    background: #F2F4F7;
    border-radius: 5px 5px 0px 0px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #222222;
    padding: 30px;
    line-height: 24px;
}
.mbottom{
    margin-bottom:18px
}
.kongH{
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    text-align:center
}
.kongImg{
    width: 290px;
    height:280px;
}
/deep/.el-table td.el-table__cell:first-child, .el-table th.el-table__cell.is-leaf{
    text-align: center;
}
/deep/.el-table--border th.el-table__cell, .el-table__fixed-right-patch {
    text-align: center;
}
.half{
    background: #F2F4F7;
    border-radius: 14px;
    font-size: 15px;
    margin-bottom: 15px;
    padding: 15px 10px;
}
// .vipImage{
// 	width: 21px;
//     height: 21px;
//     margin-right: 8px;
//     margin-top: 2px;
// }

.vipImage{
   width: 47px;
    height: 22px;
    margin: 0 4px 0 0;
}
.svipImage{
    width: 62px;
    height: 22px;
    margin: 0 4px 0 0;
}

	.hui{
		color:#777
	}
	.aVip{
		font-size: 16px;
		    border-bottom: 1px solid #fff;
		    padding-bottom: 8px;
		    margin-bottom: 8px;
	}
    .middContent{
		  margin-top: 4px;
	}
    .vipImages{
        width: 36px;
        height:36px;
        margin-right:7px;
    }
    /deep/.el-table{
        .cell {
         line-height: 23px;
       } 
    }
    // /deep/.table8Class{
    //     .el-table .el-table__cell{
    //         padding:10px 0;
    //         text-align: center;
    //     }
    // }
    /deep/.el-table--border .el-table__cell:first-child .cell {
    display: flex;
    justify-content: center;
    }
    /deep/.el-table td.el-table__cell div{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
    }
    /deep/.tgJlIcon{
        .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
            text-align: center;
            border-bottom: 1px solid #EBEEF5;
        }
    }
    // .show{
    //     display:show
    // }
    .none{
        display:none
    }
    /deep/.el-menu-item img{
        width: 18px;
        height: 18px;
        margin-right: 6px;
        text-align: center;
        vertical-align: middle;
        margin-left: 5px;
    }
    /deep/.el-menu-item.is-active .vicon1 {
        display:none;
    }
    /deep/.el-menu-item.is-active .vicon2 {
        display: inline-block;
    }
    .fpTitle{
        // width: 70px;
        // height: 16px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #222222;
        margin-right: 20px
    }
    .dzfp{
       padding:10px 0;
        background: #0078FF;
        border-radius: 2px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        width: 220px;
        text-align: center;
        margin-bottom: 8px;
    }
    .fpsText{
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
    }
    .mtop{
        margin-top:10px
    }
    .mtBot{
        margin:20px 0
    }
    /deep/.el-dialog__header{
        padding: 20px 20px 20px;
    background: #F2F4F7;
    border-radius: 5px 5px 0px 0px;
    }
    /deep/.fpttC{
        .el-tabs{
            .el-tabs__header{
               margin:0;
               border-bottom: none;
               .el-tabs__nav{
                border:none;
                .el-tabs__item{
                    color: #999;
                    padding: 0; 
                    box-sizing: border-box;
                    line-height: 40px;
                    width: 220px;
                    text-align: center;
                    margin-right: 15px;
                    border: 1px solid #DEDEDE;
                    border-radius: 2px;
                }
                .is-active{
                    color:#fff;
                    background: #409EFF;
                    border: 1px solid #409EFF;
                  }
               }
            }
        }
    }
    /deep/.mtBot{
        .el-input{
            width:455px;
        }
    }
    /deep/.fpButton{
        .el-button{
            padding:4px;
            margin-left: 0; 
        }
    }
    /deep/.table7Content{
        .el-table__row{
         .el-table__cell{
            .cell{
                text-align:center
            }
         }
         .el-table__cell:first-child{
            .cell{
                text-align:left;
                display:block;
                line-height: 19px;
                padding: 5px 10px;
            }
         }
        }
        .blue{
            color: #409EFF;
            font-size: 15px;
        }
        .hui{
            font-size: 13px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
        }
    }
    /deep/.table8Class{
        .el-table__row{
         .el-table__cell{
            padding:10px 0;
            .cell{
                text-align:center
            }
         }
         .el-table__cell:nth-child(2){
            .cell{
                text-align:left;
                display:block;
            }
         }
        }
    }
    /deep/.tkPay{
    .el-dialog{
        border-radius: 5px;
    .el-dialog__header{
        background-image: url(../assets/images/tcTops.png);
        width:100%;
        height:140px;
        padding:0;
        .el-dialog__close{
            color: #fff;
        }
    }
    .el-dialog__body{
       border-radius: 5px;
       padding: 0;
       padding-bottom: 30px;
       .nav{
        .nav-top{
            padding: 30px 30px 20px 20px;
            margin: -50px 15px 20px 15px;
            background: #fff;
           // box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 8%);
            box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.08);
            border-radius: 10px;
        }
        .nav-tabel{
            margin: 20px 15px 0 15px;
            max-height: 300px;
            overflow: auto;
        }
       }
       .navK{
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.08);
        border-radius: 10px;
        margin: -50px 30px 0 30px;
        padding: 30px;
        .fYcolor{
            background: #F6FAFF;
            border-radius: 5px;
            padding: 20px 30px;
            display:flex;
            .cpLeft{
                width: 160px;
                height: 200px;
                background: #FFFFFF;
                border-radius: 5px;
                margin-right: 60px;
                .ewmImg{
                    width:160px;
                    height:160px;
                    margin-bottom:7px;
                }
            }
            .cpRight{
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #222222;
                line-height: 30px;
                margin-top: 20px;
            }
        }
       }
    }
  }
}
.ktText{
    // font-size: 36px;
    // font-family: Source Han Sans CN;
    // font-weight: bold;
    // color: #B28054;
    // position: absolute;
    // top: 20px;
    // left: 94px;


    position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: 468px;
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;
}

// 我的订单-搜索
.inputClass{
    border: none;
    outline: none;
    width: 160px;
    margin-left: 10px;
    font-size: 13px;
    font-family: Source Han Sans CN;
    font-weight: 400;
}
.searchBtn{
    cursor: pointer;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    width: 50px;
    height: 30px;
    background: #0078FF;
    text-align: center;
    line-height: 32px;
}
.ju-tinput{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 260px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #0078FF;
    border-radius: 2px
}
/deep/.bb{
    position: relative;
    .el-table__body{
        .el-table__row{
            .el-table__cell:first-child{
                padding: 4px 0;
            }
            .el-table__cell:last-child{
                padding: 4px 0;
                text-align: center;
            }
        }
    }
    .zfSelect{
    width:110px;
    .el-input{
            margin-left: 20px;
            .el-input__inner{
                height: 32px;
                line-height: 32px;
            }
            .el-input__icon{
                line-height: 32px;
            }
        }
    }
}
.paySuccess{
    position: absolute;
    top: 77px;
    left: 50%;
    transform: translateX(-50%);
}
// /deep/.dyTable{
//     .el-table{
//         .cell{
//             text-align: center;
//         }
//     } 
//     .el-table td{
//         .el-table__cell:first-child{
//             text-align: left;
//         }  
//     }
//     .el-table--border {
//         .el-table__cell:first-child {
//             .cell{
//                 text-align: left;
//             }
//         }
        
//     }
//     .el-table_4_column_24{
//         text-align: center;
//     }    
// }

/deep/.addDy{
      .el-dialog__body{
        padding:30px 20px 0 20px;
        .dy-form-item{
            .el-select{
                .el-input{
                 width: 100%;
                }
            }
        .el-input{
            width: 76%;
            .el-input__inner{
                border-radius: 2px;
                font-size: 15px;
           }
        }
        .tsPl{
            width:76%;
        .el-tabs__header{
            margin:0;
            border-bottom:none;
            .el-tabs__nav{
                border:none;
                width:100%;
            }
            .el-tabs__item{
                border-bottom: none;
                border-left: none;
                border:1px solid #E4E7ED;
                margin-right: 10px;
                width:100%;
                font-size: 15px;
                text-align: center;
                border-radius: 3px;
            }
            .el-tabs__item.is-active{
                background: #409EFF;
                color:#fff;
                width:100%
            }
        }
    }
       }
      }

      .el-dialog__footer{
            text-align:center;
            padding:10px 20px 25px;
            .el-button{
                padding: 12px 28px;
                border-radius: 5px;
            }
        }
    }
    .sxTexts{
    width: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    }
    .gjTitle{
        padding: 0 0 8px;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #222222;
        width: 88px;
    }
    .gjTitles{
        padding: 0 0 20px;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #222222;
    }
    .el-radio{
        font-size:15px
    }
    .dy-form-item{
        margin:0 0 24px;
    }
    /deep/.el-dialog__header {
        padding: 15px;
        border-bottom: none;
        margin: 0;
       background: #F2F4F7;
    }
    .tjHui{
        font-size: 14px;
       color: #999;    
    }
    /deep/.tc_card_body{
        text-align: center;
        .body_half{
            margin: 15px 0;
            border-bottom: 1px solid #F3F3F3;
            padding-bottom: 15px;
            .blue{
                color: #0078FF;
            }
            .half_rows{
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                padding: 15px 8px;
                background: #F5F7FA;
                border-radius: 5px;
                margin: 10px 0;
            }
        }
        .body_half:nth-child(2){
             border-bottom: none;
            padding-bottom: 4px;
            margin:15px 0 10px 15px
         }
        .el-button{
           color:#000;
           padding: 6px 10px; 
        }
    }
    .ljaddBtn{
        background: #0078FF;
        border-radius: 2px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: hsl(0, 0%, 100%);
        font-size: 15px;
        padding: 5px 10px;
        cursor: pointer
    }
    .xtWidth{
        width:88px
    }
    .paySuccess{
        position: absolute;
        top: 77px;
        left: 50%;
        transform: translateX(-50%);
    }
    /deep/.el-table__fixed-right::before, .el-table__fixed::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height:0;
    }
    /deep/.dyTable{
        .el-table__row{
            .el-table__cell:nth-child(3),.el-table__cell:nth-child(4){
               text-align:center
            }
        }
    }  
    /deep/.dys{
        .el-table--border{
            .el-table__cell:first-child{
                .cell{
                    // width: 100%;
                    // white-space: pre-wrap;
                    // line-height: 18px;
                    // text-align: left; 
                    // justify-content: flex-start;
                    cursor: pointer;
                    justify-content: flex-start;
                }
            }
        }  
    }
    .bangding{
    font-size: 14px;
    color: #999;
    position: relative;
    margin-top: 5px;
}
.ewmImgS{
     position: absolute;
    top: 22px;
    left: 0;
    background: #fff;
    padding: 8px;
    z-index: 999;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
}

.yhqRows{
    flex-wrap: wrap;
    justify-content: space-between;
}
.copunA{
    width: 422px;
    height: 126px;
    margin-bottom: 20px;
    position: relative;
	}
	.quanBj{
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
	}
	.yhqBj{
        position: absolute;
        z-index: 1;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
        margin: 15px 14px;
	}
	.halfBtn{
		line-height: 40px;
		height: 40px;
		background: linear-gradient(90deg, #0078FF, #0052F0);
		border-radius: 20px;
		font-size: 18px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
	}
	.btnTexts{
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #1F9561;
		margin-top: 6px;
	}
	.lqText{
		color: #fff;
		text-decoration: none;
	}
	.repadding{
		   padding: 15px;
	}
	.relative{
		position: relative;
		 
	}
	.dkquan1{
        color: #fff;
        text-align: center;
        font-size: 16px;
	}
	.dkquan2{
		width: 232px;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
        text-align: left;
        margin: 0 30px;
	}
	.dkquan3{
		width: 20px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #DF0000;
	}
	.dkquan3s{
        width: 20px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
	}
	.gqImg{
		position: absolute;
        top: 18px;
        right: 10px;
        width: 90px;
        height: 90px;
        z-index: 99;
	}
   .qNames2{
    font-size: 20px;
    color: rgb(34, 34, 34);
    margin-bottom: 5px;
   }
   .kongImg{
    width:290px;
    height: 280px;
   }
    .kongYhq{
        text-align: center;
        margin: 0 auto;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        margin-bottom: 20px;
    } 
    /deep/.yjRight{
        .el-tabs__header {
            margin: 0 0 2px;
            .el-tabs__item{
                font-size: 14px;
                height: 36px;
                border-radius: 2px;
                line-height: 36px;
                padding: 0 10px;
            }
            .el-tabs__item:nth-child(2){
                padding-left:10px
            }
            .el-tabs__item:last-child{
               padding-right:10px
            }
        }
    }
    .fkIcon{
        width: 4px;
        height: 4px;
    }
    .yjLeft{
        width: 96px;
       font-size: 15px;
    }
    .mt22{       
       margin-top: 22px;
    }
    .riTextarea{
        width: 415px;
    height: 180px;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    border-radius: 2px;
    padding: 10px;
    }
    .textareaClass{
        border: none;
        width: 100%;
        height: 94%;
        outline: none;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        resize: none
    }
    .inputClassA{
        border: none;
        outline: none;
        width: 415px;
        background: #FFFFFF;
        border: 1px solid #DEDEDE;
        border-radius: 2px;
        padding: 12px 10px;
        font-size: 14px;
    }
    .btnTj{
        width: 440px;
        height: 42px;
        background: #409EFF;
        border-radius: 5px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        line-height: 42px;
        margin-top: 30px;
        margin-left: 90px;
    }
    /deep/.disabled{
        .el-upload--picture-card {
          display: none!important;
       }
    } 
    /deep/.aac{
       .el-dialog__header{
        padding: 0 0 20px 0;
        background: #fff;
       }
    }
    /deep/.table6Content {
        .el-tabs__header {
            margin: 0 0 10px;
        }
        .list-itemMessage{
            padding: 15px 0;
            border-bottom: 1px solid #F3F3F3;
            cursor: pointer;
            .leftRadius{
                width: 4px;
                height: 4px;
                background: #E60012;
                border-radius: 50%;
                margin-top: 7px;
                margin-right: 4px;
            }
            .rightR{
                //line-height: 26px;
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #222222;
                width: 96%;
                .times{
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #999999;
                    margin: 6px 0;
                }
                .sRight{
                    overflow:hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -o-text-overflow:ellipsis;
                    max-width: 95%;
                }
                .sChildren{
                    line-height: 27px;
                    font-size: 15px;
                }
            }     
        }
    }
    .delOneMessage{
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #0078FF;
    }
    .subTitle{
        max-width: 85%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

</style>