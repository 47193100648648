<template>
    <div>
        <common-header  @change="amountChange($event)" :message="beiji"></common-header>   
     
        <div class="container ">
           <div class="title">《网站声明》</div>
           <div style="max-height: 100%;overflow-y: auto;">
            <div style="text-indent:2em">特别注意：请使用本网站前仔细阅读以下条款，当你开始使用本网站即视为您已仔细阅读本条款，同意接受本服务条款的所有规范并愿受其拘束，本服务条款对您及标圈圈均具有法律效力。</div>
            <div style="text-indent:2em;font-weight: bold;">版权声明</div>
            <div style="text-indent:2em">未经标圈圈的明确书面许可，任何人不得复制或在非标圈圈所属的服务器上做镜像。本站所有页面的版式、图片版权均为本站所有，未经授权，不得用于除标圈圈之外的任何站点。</div>
            <div style="text-indent:2em;font-weight: bold;">网站使用</div>
            <div style="text-indent:2em">未经标圈圈的明确书面许可，任何人不得复制或在非标圈圈所属的服务器上做镜像。本站所有页面的版式、图片版权均为本站所有，未经授权，不得用于除标圈圈之外的任何站点。</div>
            <div style="text-indent:2em;font-weight: bold;">软件使用</div>
            <div style="text-indent:2em">如果您从本站下载软件，一切版权属于原作者，在使用软件时要遵守该软件携带的软件许可协议中的所有许可条款。在您阅读并接受软件许可协议的各项条款之前不得下载或安装这一软件。</div>
            <div style="text-indent:2em;font-weight: bold;">免责申明</div>
            <div style="text-indent:2em">标圈圈在建设过程中引用了因特网上的一些信息资源并对有明确来源的信息注明了出处，版权归原作者及原网站所有，如果您对本站信息资源版权的归属问题存有异议，请您联系我们，我们会立即做出答复并及时解决。如果您认为本站有侵犯您权益的行为，请通知我们，我们一定根据实际情况及时处理。 本站中所提供的信息资源均按照原样提供，不附加任何形式的保证，包括适销性、适合于特定目的或不侵犯知识产权的保证。此外，不保证本站所提供信息材料的绝对准确性和完整性。可能在不通知注册用户的情况下随时变更本站的内容或在这些内容中介绍的产品和价格。
            本站可能（但在法律上不负任何责任）会监控或审查用户在本站上发送或邮寄的信息或相互之间单独交流的任何领域，包括但不限于交谈室、公告牌或其他用户论坛以及任何交流内容。标圈圈对有关任何这类交流的内容不承担任何责任，无论它们是否会因版权法引起诽谤、隐私、淫秽或其它方面的问题。保留删除包含被视为侮辱、诽谤、淫秽或其它不良内容的消息的权利。
            用户明确同意网站服务的使用由用户个人承担风险。标圈圈不作任何类型的担保，不担保服务一定能满足用户的要求，也不担保服务一定不会受中断，对服务的安全性，出错发生都不作担保。</div>
            <div style="text-indent:2em;font-weight: bold;">服务条款的修改</div>
            <div style="text-indent:2em">标圈圈可能随时修改这些条款。您应经常访问本页面以了解当前的条款，因为这些条款与您密切相关。这些条款的某些条文也可能被本站中某些页面上明确指定的法律通告或条款所取代。</div>
            </div>
        </div> 
 
      <common-foot></common-foot>
      <common-active :showTk="beiji" :showActivity="flagActivity" @change="amountChanges($event)"></common-active>

    </div>
</template>

<script>
   import CommonHeader from '@/components/CommonHeader.vue';
   import CommonFoot from '@/components/CommonFoot.vue';
   import CommonActive from '@/components/CommonActive.vue';
  
    export default {
       inject:['reload'],
       components:{
       CommonHeader,
       CommonFoot,
       CommonActive
       }, 
        data() {  
            return {
                beiji:false,
                flagActivity:'',
            };
        },
       
        methods: {
            amountChange(e){
              this.beiji=e
            },
            async amountChanges(e){
              this.beiji=e
              this.flagActivity='1'
                let that=this
                    setTimeout(function(){
                    if(that.$route.query.str!=''&&that.$route.query.str!=undefined){
                        that.str = that.$route.query.str
                    }
                   
                    if(that.beiji==false){
                        if(that.str==1){
                            that.activeIndex=4
                        }
                    }
                },1000)
                
            },
          
        }
    }
</script>
<style scoped lang="less">
.container{
    width: 1160px;
    background: #FFFFFF;
    border-radius: 5px;
    margin: 0 auto;
    padding: 17px 20px;
    margin-top: 20px;
}
.title{
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    margin-bottom: 6px;
}

    
</style>