import { render, staticRenderFns } from "./vipPackage.vue?vue&type=template&id=9233dd68&scoped=true"
import script from "./vipPackage.vue?vue&type=script&lang=js"
export * from "./vipPackage.vue?vue&type=script&lang=js"
import style0 from "./vipPackage.vue?vue&type=style&index=0&id=9233dd68&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9233dd68",
  null
  
)

export default component.exports