import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index'
import login from '@/views/login'
import register from '@/views/register'
import zbDetails from '@/views/zbDetails'
import companyDetails from '@/views/companyDetails.vue'
import forgetPwd from '@/views/forgetPwd.vue';
import userCenter from '@/views/userCenter.vue';
import noticeDetails from '@/views/noticeDetails.vue';
import list from '@/views/list.vue';
import vipPackage from '@/views/vipPackage.vue';
import subscribe from '@/views/subscribe.vue';
import h5 from '@/views/h5.vue'
import agreement from '@/views/xieyi.vue'
import pcDeclaration from '@/views/xieyi1.vue'
import aboutUs from '@/views/xieyi2.vue'
Vue.use(VueRouter)


const originalPush = VueRouter.prototype.push
    //修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
        return originalPush.call(this, location).catch(err => err)
    }

//1.创建路由组件
//2.将路由与组件进行映射
//3.创建router实例

const routes = [

    {
        path: '/',
        component: index,
        // children: [
        //     {
        //         path: '/index1',
        //         component: () => import('@/components/view/index1.vue')
        //     }, {
        //         path: '/index2',
        //         component: () => import('@/components/view/index2.vue')
        //     },
        //     {
        //         path: '/index3',
        //         component: () => import('@/components/view/index3.vue')
        //     },
        // ]
    },
    {
        path: '/login',
        component: login, 
    },
    {
        path: '/register',
        component: register, 
    },
    {
        path: '/zbDetails',
        component: zbDetails, 
    },
    {
        path: '/companyDetails',
        component: companyDetails, 
    },
    { 
         path: '/forgetPwd',
        component: forgetPwd, 
    },
    {
        path: '/userCenter',
        component: userCenter, 
    },
    {  
        path: '/noticeDetails',
        component: noticeDetails, 
    },
    {  
        path: '/list',
        component: list, 
    },
    {  
        path: '/vipPackage',
        component: vipPackage, 
    },
    {  
        path: '/subscribe',
        component: subscribe, 
    }
    ,
    {  
        path: '/h5',
        component: h5
    },
    {  
        path: '/agreement',
        component: agreement
    },
    {  
        path: '/pcDeclaration',
        component: pcDeclaration
    },
    {  
        path: '/aboutUs',
        component: aboutUs
    }
    
    //主路由
    // {
    //     path:'/',
    //     component:Main,
    //     redirect:'/home',
    //     children:[
    //         //子路由
    //         { path: 'home',name:'home',component: Home },  //首页
    //         { path: 'user', name:'user',component: User },  //用户管理
    //         {path:'mall',name:'mall',component:Mall},       //商品管理
    //         {path:'page1',name:'page1',component:PageOne},   //页面1
    //         {path:'page2',name:'page2',component:PageTwo},
    //     ]
    // },
   
]

const router = new VueRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    //history: VueRouter.createWebHashHistory(),
    //mode: 'history',
    routes, // `routes: routes` 的缩写
})

export default router



