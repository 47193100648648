<template>
    <div >
      <common-header></common-header>   
      
      <div class="background">
        <img :src="imgSrc" width="100%" height="100%" alt/>
        <div class="content">
            <div class="leftCon">
                <div class="leftBj">
                     <div class="flex alignCenter biaoqq">
                        <img style="width:46px;height:46px;margin-right: 8px;border-radius: 50%;" src="../assets/images/logobqq1.png" />
                        <span>标圈圈</span>
                    </div>
                    <div style="margin:10px 0 0 20px">
                        <div class="flex alignCenter ltext">
                        <div class="cicle"></div>
                        <div>一站式标讯服务平台</div>
                        </div>
                        <div class="flex alignCenter ltext">
                            <div class="cicle"></div>
                            <div>精准查询招标采购信息</div>
                        </div>
                        <div class="flex alignCenter ltext">
                            <div class="cicle"></div>
                            <div>全流程招标信息服务</div>
                        </div>
                        <div class="flex alignCenter ltext">
                            <div class="cicle"></div>
                            <div>全网招采情报分析</div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="rightCon">
                <div class="rightBj">
                    <div>
                         <!-- <el-tabs v-model="activeName" @tab-click="handleClick"> 
                            <el-tab-pane label="密码登录" name="one"> -->
                                <div class="register">用户注册</div>
                                <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                                <el-form-item label="手机号"  prop="user">
                                    <el-input placeholder="请输入手机号" v-model.number="ruleForm.user" maxlength="11"></el-input>
                                </el-form-item>
                               <el-form-item prop="checkCode" label="验证码">
                                    <el-input  type="number"
                                        class="check-code-box"
                                        size="small" v-model="ruleForm.checkCode"  maxlength="6"
                                        placeholder="请输入验证码">
                                        <el-button :disabled="codeCd" size="small"  slot="append" @click="handleCaptcha('ruleForm')" class="yzmBtn">
                                            <!-- 获取验证码 -->
                                             {{getcode}}
                                            <!-- <span v-if="codeCd">({{long}})</span> -->
                                        </el-button>
                                    </el-input>
                                </el-form-item> 

                                <el-form-item label="密码" prop="pass">
                                    <el-input type="password" placeholder="请输入密码" 
                                    auto-complete="new-password" v-model="ruleForm.pass" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item label="确认密码" prop="checkPass">
                                    <el-input type="password" placeholder="请再次确认密码" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item label="推荐码" prop="yqCode">
                                    <el-input type="text" placeholder="请输入推荐码(选填)" v-model="ruleForm.yqCode" autocomplete="off"></el-input>
                                </el-form-item>

                                
                                <el-form-item class="btnsA" style="margin-bottom: 10px !important;">
                                    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                                </el-form-item>
                                </el-form>
                                <div class="flex jutSpace btnw">
                                        <div class="adl">
                                            已有账号, <router-link to="/login" style="color: #0078FF;">去登录</router-link>
                                        </div>
                                 </div>
                            <!-- </el-tab-pane>
                          </el-tabs>        -->
 
                    </div>
                </div>
            </div>

        </div>
    </div>
     
    </div>
</template>
<script>
 import CommonHeader from '@/components/CommonHeader.vue';
 export default { 
    components:{
       CommonHeader
       }, 
        data() {
       var checkPhone = (rule, value, callback) => {
        var reg = /^1[3-9]\d{9}$/;
           if (value === "") {
           return callback(new Error("请输入手机号码"));
            } else if (!Number.isInteger(value)) {
            return callback(new Error("手机号码必须是数字"));
            } else if (value.toString().length !== 11) {
            return callback(new Error("手机号码必须是11位数字"));
            } else if (!reg.test(value)) {
            return callback(new Error("请输入有效的手机号码"));
            } else {
            callback();
        }
      };
      let checkCodes=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入验证码'));
        }else{
            callback();
        }
     }
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else if (value.length<6) {
          callback(new Error('密码长度最少6位数'));
        }else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
    //   var checkyqCode=(rule, value, callback) => {
    //     if (value === '') {
    //       callback(new Error('请输入邀请码'));
    //     } else {
    //       callback();
    //     }
    //   };


            return {
                imgSrc: require("../assets/images/login_bj.png"),
                activeName: 'one',
                codeCd: false,
                getcode: '获取验证码',
                dingshiqi:null,
				getcodes:60,

                ruleForm: {
                pass: '',
                checkPass: '',
                user: '',
                checkCode:'',
                yqCode:''
                },
                rules: {
                pass: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                checkPass: [
                    { validator: validatePass2, trigger: 'blur' }
                ],
                user: [
                    { validator: checkPhone, trigger: 'blur' }
                ],
                checkCode: [
                    { validator:checkCodes, trigger: 'blur' }
                ],
                // yqCode:[
                //     { validator:checkyqCode, trigger: 'blur' }
                //  ]
                }

            };
        },
        mounted(){
            var that=this
         // console.log(window.location.href.indexOf('type')!=-1)
          if(window.location.href.indexOf('type')!=-1){
             let urls=window.location.href
              let index = urls.indexOf('?');//获取“=”首次出现的位置
              let o = urls.substring( index + 1 );
              let indexs = o.indexOf('=');
              let oo=o.substring( indexs + 1 )
              that.ruleForm.yqCode=oo
          }
        },
        methods: {
            handleClick(tab, event) {
                console.log(tab, event);
            },

            // 获取手机验证码
            handleCaptcha (form) {
                var that=this
                that.$refs[form].validateField('user', async (valid) => {
                if (!valid) {
                    // that.codeCd = true
                    // var  getcode=that.getcode
					// 	if(getcode=="获取验证码"){	
					// 		that.dingshiqi = setInterval(() => {
					// 		  if (that.getcodes > 0) {
					// 			that.getcode=that.getcodes-- +'S'; 
					// 		  } else {
					// 		   that.disabled=false
					// 		   that.getcode="获取验证码"
					// 		   that.getcodes=60
					// 		   clearInterval(that.dingshiqi);
					// 		  }
					// 		}, 1000); // 每隔一秒减少一秒倒计时
                    //     }

                // 获取验证码  ?phoneNo='+that.ruleForm.user+'&flag=1'
                  that.$axios.post('/webInterface/getCode?phoneNo='+that.ruleForm.user+'&flag=1')
                    .then(function (res) {
                     console.log(res);
                     if(res.data.resultcode==1){
                        that.codeCd = true
                        that.$message.success(res.data.resultmessage)
                        var  getcode=that.getcode
						if(getcode=="获取验证码"){	
							that.dingshiqi = setInterval(() => {
							  if (that.getcodes > 0) {
								that.getcode=that.getcodes-- +'S'; 
							  } else {
							   that.disabled=false
							   that.getcode="获取验证码"
							   that.getcodes=60
							   clearInterval(that.dingshiqi);
							  }
							}, 1000); // 每隔一秒减少一秒倒计时
                        }
                     }else{
                        that.$message.error(res.data.resultmessage)
                        that.codeCd = false
                     }  
                    })
                    .catch(function (err) {
                        console.log(err);
                        that.$message.error('发送失败')
                    })
                } else {
                console.log('error submit!!')
                return false
                }
            })
            },
          

            submitForm(formName) {
                var that=this
                that.$refs[formName].validate((valid) => {
                if (valid) {
                    that.$axios.get('/webInterface/register', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            phoneNo:that.ruleForm.user,
                            checkcode:that.ruleForm.checkCode,
                            password:that.ruleForm.pass,
                            source:2,
                            inviteCode:that.ruleForm.yqCode,
                            registerSource:'PC',
                            wxType:''
                        }
                    })
                    .then(function (res) {
                     console.log(res.data);
                     if(res.data.resultcode==1){
                        that.$message.success(res.data.resultmessage)
                        setTimeout(()=>{  //设置延迟执行
                           // that.$router.push({path: '/login'});
                            that.$axios.post('/j_spring_security_check?j_username='+that.ruleForm.user+'&j_password='+that.ruleForm.pass
                            +'&ajaxTag=ajaxTag'+'&phoneToken=phoneToken'+'&wxType=5'+'&deviceMac=1'+'&deviceImei=1'+'&deviceName=1'
                             )
                            .then(function (res) {
                            console.log(res);
                            if(res.data.success == "true" && res.data.userInfo!=null){
                                localStorage.setItem("phoneNo", JSON.stringify(res.data.userInfo.phoneNo));
                                that.$store.state.phoneNo = res.data.userInfo.phoneNo
                                setTimeout(()=>{  //设置延迟执行
                                    that.$router.push({path: '/'});
                                },500);
                            }else{
                                that.$message.error(res.data.errorInfo)
                            }

                            })
                            .catch(function (err) {
                                console.log(err);
                                that.$message.error('登录失败，请重试')
                            })


                       },1500);
                     }else{
                        that.$message.error(res.data.resultmessage)
                     }
                    })
                    .catch(function (err) {
                        console.log(err);
                        that.$message.error('注册失败，请重试')
                    })

                } else {
                    console.log('error submit!!');
                    return false;
                }
                });
             },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },

        }
    }
</script>
<style scoped lang="less">
.background {
    width: 100%;
    height: 100%;
    z-index: -1;
    position: fixed;
    top: 74px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    min-height: 980px;
    overflow: hidden;
    }
 .content{
    display: flex;
    position: absolute;
    left: 60%;
    top: 55%;
    transform: translate(-50%,-50%);
    width: 90%;
    height: 95%;
 }
 .leftCon{
    background: url('../assets/images/login-left.png');
    width: 35%;
    height: 60%;
    background-size: 100% 100%;
 }
 .rightCon{
    background: url('../assets/images/login_right.png');
    width: 37.5%;
    height: 60%;
    background-size: 100% 100%;
    position: relative;
 }
 .cicle{
    width: 8px;
    height: 8px;
    background: #F5F7FA;
    opacity: 0.4;
    margin-right: 20px;
 }
 .ltext{
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 44px;
 }
 .leftBj{
    margin: 40px;
    .biaoqq{
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 44px;
    }
 }
 .rightBj{
    position: absolute;
    width: 404px;
    top: 50%;
    left: 48%;
    transform: translate(-50%, -50%);
 }
 /deep/.el-tabs__item{
    font-size: 24px;
    line-height: 20px;
 }
 /deep/.el-tabs__header{
    margin: 0 0 40px;
 }
 /deep/.el-form-item{
    margin-bottom:20px;
 }
 /deep/ .el-form-item__label{
          width: 85px !important;
          font-size: 17px;
        }
 /deep/ .el-form-item__content{
            margin-left: 90px !important;
            line-height: 48px !important;
        }
/deep/.el-input__inner{
    font-size: 17px;
    height: 45px;
    line-height: 45px;
}
    .btn{
        text-align: center;
       padding: 0 10px;
    }
    .btns{
        width: 100%;
        
        background: #0078FF;
        border-radius: 30px;
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        margin-top: 50px;
    }
    .btnw{
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #B9C3D1;
        cursor: pointer;
    }
    .adl{
        color: #B9C3D1;
       font-size: 16px;
    }

    .register{
        color:#409EFF;
        font-size:24px;
        margin-bottom: 30px;
    }
    /deep/.el-button{
        // width:100%;
    }
    /deep/.btnsA{
      .el-button{
        width:100%;
        font-size: 16px;
        letter-spacing: 3px;
     }
    }

    .yzmBtn.is-disabled{
        background-color: #F5F7FA;
    }
</style>