<template>
    <div>
        <div class="positionA" :style="{'top':height5}">
			 <img class="img1" :style="{'height':height5,'top':height6}" src="../assets/h5/img1s.png" /> 
			 <div class="half-con" v-if="isNew">
				<div class="relative middle" >
				  <div class="top-middleCon">
					  <!-- <div class="con1Texts flex jutCenter">
						  <div style="width: 230px;">{{company}}</div>
					  </div> -->
					  <div class="con2Text">
						<span class="reds">标圈圈</span>为您推荐<span class="reds">精选标讯</span>
					  </div>
					  <div class="flex con1Text jutBetween">
						  <div class="flex aligenCenter">
							  <img class="iconImg" src="../assets/h5/img21.png" />
							  <div>及时</div>
						  </div>
						  <div class="flex aligenCenter">
							  <img class="iconImg" src="../assets/h5/img21.png" />
							  <div>精准</div>
						  </div>
						  <div class="flex aligenCenter">
							  <img class="iconImg" src="../assets/h5/img21.png" />
							  <div>省心</div>
						  </div>
					  </div>

					  <div class="">
						<div class="halfR flex aligenCenter" v-for="(item,index) in listAll" :key="index"  @click="navgite()">
							<div class="numImg">{{ index+1 }}</div>
							<div class="halfCon" :style="{'width':widthC}">
							  <span class="halfConT" v-html="item.name" :title="item.name"  :style="{'width':widthC}"></span>
							</div>
						</div>
					  </div>

					  <div class="half-quan">
						  <div class="yhqBj flex jutBetween aligenCenter">
							  <div class="dkquan1">
								  <div>￥<span style="font-size: 24px;">300</span></div>
								  <div>满减券</div>
							  </div>
							  <div class="dkquan2">
								  <div style="font-size: 14px;color: #222222;margin-bottom: 8px;" >点击下方按钮即可领取</div>
								  <div>该优惠券用于购买VIP</div>
								  <div>满1599元可使用</div>
							  </div>
							  <div class="dkquan3" @click="navgite()">立即使用</div>
						  </div>
					  </div>
					  <div class="halfBtn">
						  <div @click="navgite()">点击进入小程序领取</div>
					  </div>
					  <div class="btnTexts">
						  <span style="color:#989898">优惠券在</span>标圈圈小程序个人中心优惠券栏目查看
					  </div>
				  </div>
                </div> 

				 <div style="text-align: center;background: #fff;margin-top: 20px;">
				 <img class="ewmImg" :src="xcxUrl"  @touchstart="touchStart()" @touchend="touchEnd()" />
				<div class="bcText">长按保存图片，微信扫码进入小程序</div>
				<div class="moreText">更多功能详情与企业方案，欢迎咨询</div>
			    </div> 
			 </div> 
			 <div class="half-cons" v-else>
				<div class="relative middle2">
				  <div class="top-middleCon2">
					  <div class="con1Texts flex jutCenter">
						  <div style="width: 230px;">{{company}}</div>
					  </div>
					  <div class="con1Text">您的<span style="color:#DF0000">标讯信息</span>已送达</div>
					  <div class="flex con1Text jutBetween">
						  <div class="flex aligenCenter">
							  <img class="iconImg" src="../assets/h5/img21.png" />
							  <div>及时</div>
						  </div>
						  <div class="flex aligenCenter">
							  <img class="iconImg" src="../assets/h5/img21.png" />
							  <div>精准</div>
						  </div>
						  <div class="flex aligenCenter">
							  <img class="iconImg" src="../assets/h5/img21.png" />
							  <div>省心</div>
						  </div>
					  </div>
					  <div class="half-quan">
						  <div class="yhqBj flex jutBetween aligenCenter">
							  <div class="dkquan1">
								  <div>￥<span style="font-size: 24px;">300</span></div>
								  <div>抵扣券</div>
							  </div>
							  <div class="dkquan2">
								  <div style="font-size: 14px;color: #222222;margin-bottom: 8px;" >点击下方按钮即可领取</div>
								  <div>该优惠券用于购买VIP</div>
								  <div>满1599元可使用</div>
							  </div>
							  <div class="dkquan3" @click="navgite()">立即使用</div>
						  </div>
					  </div>
					  <div class="halfBtn">
						  <div @click="navgite()">点击进入小程序领取</div>
					  </div>
					  <div class="btnTexts">
						  <text style="color:#989898">优惠券可在</text>标圈圈小程序个人中心优惠券栏目查看
					  </div>
				  </div>
                </div>

				<div style="text-align: center;background: #fff;margin-top: 20px;">
				<img class="ewmImg" :src="xcxUrl"  @touchstart="touchStart()" @touchend="touchEnd()" />
				<div class="bcText">长按保存图片，微信扫码进入小程序</div>
				<div class="moreText">更多功能详情与企业方案，欢迎咨询</div>
			</div>

			</div>

			<!-- style="height:278px" style="height:827px"  style="height:325px" -->
			<img class="img3" :style="{'height':height1}" src="../assets/h5/img3.png" />
			<img class="img3" :style="{'height':height2}" src="../assets/h5/img4.png" />
			<img class="img3" :style="{'height':height3}" src="../assets/h5/img5.png" />
			<img class="img6" :style="{'height':height4}" src="../assets/h5/img6.png" />
			<div style="text-align: center;background: #fff;margin-top: -5px;">
				<img class="ewmImg" :src="xcxUrl"  @touchstart="touchStart()" @touchend="touchEnd()" />
				<div class="bcText">长按保存图片，微信扫码进入小程序</div>
				<div class="moreText">更多功能详情与企业方案，欢迎咨询</div>
				<div class="bottomText">
					<div>Copyright ©2023 上海标讯科技有限公司 版权所有</div>
				</div>
			</div>





			
			<!-- <div class="foot flex jutBetween aligenCenter">
				<div>
                    <a href="tel:4000-000-000" style="color: #000;">
                        <img class="phone" src="../assets/h5/img7.png" />
                        <div>电话</div>
                    </a>
				</div>
				<div class="flex aligenCenter jutBetween" style="width: 85%;">
					<div class="btn btn1 flex aligenCenter jutCenter" @click="navgite()">微信小程序</div>
					<div class="btn btn2 flex aligenCenter jutCenter">微信公众号</div>
				</div>
			</div> -->
            </div> 
	</div>
</template>

<script>
    export default {
        data() {
            return {
                company:'',
                xcxUrl:'',
                xcxScheme:'',
				height1:'',
				height2:'',
				height3:'',
				height4:'',
				height5:'',
				height6:'',
				heightTop:'',
				widthTop:'',
				width1:'',
				listAll:[],
				widthC:'',
				userId:'',
				isNew:''
            }
        },
        mounted() {
           let that=this
		//    console.log(document.documentElement.clientWidth)
		//    console.log(document.documentElement.clientWidth*0.81)
		    that.width1=document.documentElement.clientWidth+'px'
            that.height1=document.documentElement.clientWidth*0.8+'px'
			that.height2=document.documentElement.clientWidth*0.712+'px'
			that.height3=document.documentElement.clientWidth*2.17+'px'
			that.height4=document.documentElement.clientWidth*0.83+'px'
			that.height5=document.documentElement.clientWidth*0.72+'px'
			that.height6='-'+(document.documentElement.clientWidth*0.72)+'px'
			that.heightTop=(document.documentElement.clientWidth-12)*1.42+'px'

			that.widthC=document.documentElement.clientWidth-150+'px'
           //let urls= 'http://192.168.2.16:8081/#/h5?sn=eMrUja'
		   //let urls='http://www.lobodi.cn/#/h5?sn=eMrUja'

            let urls=window.location.href
			let index = urls.indexOf('?');//获取“=”首次出现的位置
			let o = urls.substring( index + 1 );
			let indexs = o.split("=")[1]
			that.$axios.get('/webInterface/queryEnCompanyMobilePhone', {  //params参数必写 , 如果没有参数传{}也可以
					params: {  
						shortLinkChannel:indexs
					}
			    })
			.then(function (res) {
				console.log(res);
				if(res.data.resultcode==1){
					that.xcxUrl=res.data.str1
					if(res.data.enCompanyMobilePhone!=''&&res.data.enCompanyMobilePhone!=undefined){
						that.company=res.data.enCompanyMobilePhone.cname
					}
					that.xcxScheme=res.data.bidShortLink.xcxScheme
					that.userId=res.data.userId
					if(that.userId!=''&&that.userId!=undefined){
						that.isNew=true

						that.$axios.get('/webInterface/queryScripingpageList', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            pageSize:3,
                            pageNo:1,
							extraFlag:1,
							userId:that.userId,
							pubDate:5562977985774673643,
							}
						})
						.then(function (res) {
						console.log(res);
						if(res.data.resultcode==1){
						   that.listAll = res.data.scripingpageList
						}
						})
						.catch(function (err) {
						})
					}else{
                        that.isNew=false
					}
				} else{
				that.$message.warning(res.data.message)
				}
			})
			.catch(function (err) {
				// console.log(err);
			})

    

        },
        destroyed () {
          
        },
        methods: {
          navgite(){
            window.location.href = this.xcxScheme
          },
           touchEnd(){
            //手指离开
            clearTimeout(this.Loop);
        },
        touchStart(){
            //手指触摸
            clearTimeout(this.Loop); //再次清空定时器，防止重复注册定时器
            this.Loop = setTimeout(() => {
                this.downImg()
            },1000)
        },
         // 下载
        downImg() {
            if(!window.plus) return;
                plus.gallery.save(this.imgUrl, function () {
                    console.log('保存成功');
                },function(){
                    console.log('保存失败');
            });
        }
           
        }
    }
</script>
<style scoped lang="less">
 .img1{
    width: 100%;
    background-size: cover;
    z-index: -1;
    position: absolute;
}	
.positionA{
      position: absolute;
}
.half-con{
	padding: 12px 6px;
	border-bottom: 12px solid #EEEEEE;
       background: #fff;
}
.half-cons{
	padding: 12px 15px;
	border-bottom: 12px solid #EEEEEE;
       background: #fff;
}
.con-bj{
	position: absolute;
	height: 363px;
	width: 100%;
	box-shadow: 0px 1px 3px 0px #E6EFFF;
}
.middle{
	// height: 363px;
	background-image:url(../assets/h5/bqqTopbj1.png);
	width: 100%;
	background-size: 100% 100%;
	text-align: center;
    margin-top: -42px;
}
.middle2{
	background:url(../assets/h5/img2.png);
	width: 100%;
	background-size: 100% 100%;
	text-align: center;
    margin-top: -42px;
}
.top-middleCon{
	padding: 35px 30px;
}
.top-middleCon2{
	padding: 35px 27px;
}
.con1Texts{
	font-size: 28px;
	font-family: Alibaba PuHuiTi;
	font-weight: 800;
	color: #0078FF;
	text-align: center;
}
.con1Text{
	font-size: 18px;
	font-family: PingFang SC;
	font-weight: bold;
	color: #222222;
	margin-bottom: 16px;
}
.iconImg{
	width: 15px;
	height:15px;
	margin-right:5px;
}
.half-quan{
	//width: 312px;
	//height:94px;
	background-size: 100% 100%;
	margin: 18px auto;
}
.yhqBj{
	background: url(../assets/h5/img22.png);
	background-size: 100% 100%;
	height: 100%;
	position: relative;
	font-size: 12px;
	padding: 10px 15px 10px 10px;
}
.halfBtn{
	line-height: 40px;
	height: 40px;
	background: linear-gradient(90deg, #0078FF, #0052F0);
	border-radius: 20px;
	font-size: 18px;
	font-family: PingFang SC;
	font-weight: 500;
	color: #FFFFFF;
	margin: 0 3%;
}
.btnTexts{
	font-size: 12px;
	font-family: PingFang SC;
	font-weight: 500;
	color: #1F9561;
	margin-top: 8px;
}
.lqText{
	color: #fff;
	text-decoration: none;
}
.img3{
	width: 100%;
	padding: 20px 0;
	border-bottom: 12px solid #EEEEEE;
     background:#fff;
         margin-top: -2px;
}
.img6{
	width: 100%;
	padding: 20px 0 10px 0;
    background: #fff;
    margin-top: -3px
}
.bottomText{
	height: 32px;
	background: #EEEEEE;
	line-height: 32px;
	font-size: 12px;
	font-family: PingFang SC;
	font-weight: 500;
	color: #888888;
}
.dkquan1{
	color:#fff;
}
.dkquan2{
	width: 156px;
	font-size: 11px;
	font-family: PingFang SC;
	font-weight: 500;
	color: #999999;
	text-align: left;
}
.dkquan3{
	width: 15px;
	font-size: 13px;
	font-family: Source Han Sans CN;
	font-weight: 400;
	color: #DF0000;
}
.bcText{
	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 500;
	color: #222222;
}
.moreText{
	font-size: 11px;
	font-family: PingFang SC;
	font-weight: 500;
	color: #666666;
	padding-bottom: 14px;
	margin-top: 5px;
}
.foot{
	height: 48px;
	background: #FFFFFF;
	box-shadow: 0px 1px 8px 0px rgba(8,1,3,0.2);
	font-size: 10px;
	font-family: PingFang SC;
	font-weight: 500;
	color: #222222;
   padding: 4px 20px;
}
.phone{
	width: 18px;
	height:18px;
}
.btn{
	font-size: 15px;
	font-family: PingFang SC;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 24px;
	width: 48%;
	height: 40px;
}
.btn1{
	background: linear-gradient(90deg, #0078FF, #0052F0);
	border-radius: 5px;
}
.btn2{
	background: linear-gradient(90deg, #FD9D2D, #FE821E);
	border-radius: 5px;
}
.ewmImg{
	width: 110px;
    height: 110px;
    margin-bottom: 9px;
}
.flex{
		display:flex;
	}
	.aligenCenter{
		align-items: center;
	}
	.jutCenter{
		justify-content: center;
	}
	.jutBetween{
	    justify-content: space-between;
	}
	.con2Text{
		font-size: 23px;
		font-family: Alibaba PuHuiTi;
		font-weight: 800;
		color:#222222; 
        margin-bottom: 13px;
	}
	.reds{
		color: #DF0000;
	}
	.halfR{
		font-size: 15px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #222222;
		justify-content: center;
		margin-top: 9px;
	}
	.numImg{
		background-image: url(../assets/h5/bqqNum.png);
		width: 25px;
		height: 28px;
		background-size: 100% 100%;
		color: #fff;
		font-family: PingFang SC;
		font-weight: 800;
		margin-right:15px;
	} 
	.halfCon{
		// white-space: nowrap;
		// overflow: hidden;
		// text-overflow: ellipsis;
		//width: 50%;
		padding-bottom: 6px;
		border-bottom: 1px solid #D1DDEB;
		text-align: left;		
	}
	.halfConT{
		overflow:hidden;
		text-overflow:ellipsis; 
		display:-webkit-box;  
		-webkit-box-orient:vertical;  
		-webkit-line-clamp:2;
	}
</style>