<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
import CommonHeader from '@/components/CommonHeader.vue'

export default {
  name: 'App',
  components: {
    CommonHeader
  },
  provide(){
    return {
      reload:this.reload
    }
  },
  data(){
    return{
      isRouterAlive:true
    }
  },
  
  methods:{
    reload(){
      this.isRouterAlive=false
      this.$nextTick(()=>{
        this.isRouterAlive=true
      })
    }
  }
}
</script>

<style>
body,html{
  padding: 0 !important;
  width: 100% !important;
  margin: 0 !important;
  background-color: #F5F7FA;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
  background-color: #F5F7FA;
}
.flex{
  display: flex;
}
.alignCenter{
  align-items: center;
}
.jutSpace{
  justify-content: space-between;
}
.jutCenter{
  justify-content: center;
}
a{
  text-decoration: none;
}
.aligenCenter{
  align-items: center;
}

.msgBox{
    width: 40% !important;
    .el-message-box__content{
    font-size: 16px;
  }
  }
  .msgBoxA{
  width: 58% !important;
    max-height: 90%;
    overflow-y: auto !important;
    .el-message-box__content{
    font-size: 16px;
  }
}



.ant-tag{
    /* color: #666;
    box-sizing: border-box;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum","tnum";
    display: inline-block;
    height: auto;
    margin: 0 8px 0 0;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    background: #f6f6f6;
    border: 1px solid #d9d9d9;
    cursor: default;
    opacity: 1;
    transition: all .3s cubic-bezier(.78,.14,.15,.86);
    border-radius: 2px; */

    color: #666;
    box-sizing: border-box;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum","tnum";
    display: inline-block;
    height: auto;
    margin: 0 8px 0 0;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    background: #f6f6f6;
    border: 1px solid #d9d9d9;
    cursor: default;
    opacity: 1;
    transition: all .3s cubic-bezier(.78,.14,.15,.86);
    border-radius: 2px;
    font-family: none;
}
.ant-tag-orange {
    background: #fffaf0;
    border-color: #fffaf0;
    color: #f9ad14;
}
.ant-tag-orange.bordered {
    border-color: #f9ad14 !important;
}
.ant-tag.bordered {
    border-color: #d9d9d9;
}
.ant-tag-blue{
    background: #fffaf0;
    border-color: #fffaf0;
    color: #0078FF; 
}
.ant-tag-blue.bordered {
    border-color: #0078FF !important;
    background-color: #EDF5FF !important;
}
.ant-tag-green{
    /* background: #fffaf0;
    border-color: #fffaf0;
    color: #0078FF;  */
    background: #EDFFF0;
	    border-color: #13C261;
	    color: #13C261 !important; 
}
.ant-tag-green.bordered{
   border-color: #13C261 !important;
    background-color: #EDFFF0 !important;
}

.ant-tag-green{
    border-color: #fffaf0;
    color: #30AF40; 
}
.ant-tag-green.bordered {
    border-color: #30AF40 !important;
    background-color: #EAF7EC !important;
}

.wzDialog{
  .el-dialog{
    /* height: 75% !important; */
   
    .el-dialog__header{
       display:none !important;
    }
    .el-dialog__body{
      /* height: 85% !important; */
      max-height: 400px;
      overflow: auto;
      padding: 25px 20px 10px 20px !important;      
      font-size: 16px !important;
    }
  }
}
.wzDialog2{
  .el-dialog{
    max-height: 75% !important;
    .el-dialog__header{
       display:none !important;
    }
    .el-dialog__body{
      max-height: 85% !important;
      padding: 25px 20px 10px 20px !important;      
      font-size: 16px !important;
    }
  }
}


input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
 
}
 
input::-webkit-outer-spin-button{
  -webkit-appearance: none !important;
 
}
 
input[type="number"]{
  -moz-appearance: textfield;
 
}
</style>
