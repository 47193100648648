<template>
    <div>
        <common-header  @change="amountChange($event)" :message="beiji"></common-header>   
     
        <div class="container ">
           <div class="title">《关于我们》</div>
           <div style="max-height: 100%;overflow-y: auto;">
            <div style="text-indent:2em">上海标讯科技有限公司是专业的智能标讯SaaS服务平台，通过运用大数据、区块链和AI技术，实时更新全国全行业政府企业招标采购信息，提供搜索、订阅、分析、下载等一站式标讯服务。</div>
            <div style="text-indent:2em">上海标讯科技有限公司帮助用户轻松解决信息搜集难题，从根本上解除由传统的投标人四处寻找、搜集信息的低效行为，转变为主动获取及时、完整、精准的招投标商业讯息。</div>
            <div style="text-indent:2em">上海标讯科技有限公司以先进的网络技术，让采购单位及供应商可以快速、便捷地从海量复杂的信息中通过自主设定的项目关键词查询对应的招标信息，有效降低采购成本，也为广大供应商创造一个“公平、公开、公正”的市场环境。</div>
            <div style="text-indent:2em">准确的项目信息、每日数万条更新、广泛的信息覆盖面、丰富的信息量、个性化按需精准推送，上海标讯科技有限公司基于数据智能的算法与运用，有效解决企业经营发展过程中的痛点，使招标采购商机更加广布、信息交互更加迅捷、招标操作更加规范、采购过程更加透明，从而进一步发挥招投标机制优化资源配置的作用，为政府部门和企业的数据分析、市场分析提供强有力支撑，赋能千行百业，助推社会经济高质量发展。</div>
           </div>
        </div> 
 
      <common-foot></common-foot>
      <common-active :showTk="beiji" :showActivity="flagActivity" @change="amountChanges($event)"></common-active>

    </div>
</template>

<script>
   import CommonHeader from '@/components/CommonHeader.vue';
   import CommonFoot from '@/components/CommonFoot.vue';
   import CommonActive from '@/components/CommonActive.vue';
  
    export default {
       inject:['reload'],
       components:{
       CommonHeader,
       CommonFoot,
       CommonActive
       }, 
        data() {  
            return {
                beiji:false,
                flagActivity:'',
            };
        },
       
        methods: {
            amountChange(e){
              this.beiji=e
            },
            async amountChanges(e){
              this.beiji=e
              this.flagActivity='1'
                let that=this
                    setTimeout(function(){
                    if(that.$route.query.str!=''&&that.$route.query.str!=undefined){
                        that.str = that.$route.query.str
                    }
                   
                    if(that.beiji==false){
                        if(that.str==1){
                            that.activeIndex=4
                        }
                    }
                },1000)
                
            },
          
        }
    }
</script>
<style scoped lang="less">
.container{
    width: 1160px;
    background: #FFFFFF;
    border-radius: 5px;
    margin: 0 auto;
    padding: 17px 20px;
    margin-top: 20px;
    min-height: 500px;
}
.title{
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    margin-bottom: 6px;
}

    
</style>