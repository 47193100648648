<template>
    <div>
        <common-header @change="amountChange($event)" :message="beiji"></common-header>   
        <div class="topCon" :style="{'height':height1}"></div>
        <div class="halfCon">
          <div class="tops ">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane name="first">
                    <div slot="label">
                        <div class="bzImgs"><img style="margin-right:9px" src="../assets/images/vip.png" />标准版</div>
                        <div class="bqqStext">标圈圈，找更多商机！</div>
                    </div>
                    
                </el-tab-pane>
                <el-tab-pane name="second" >
                    <div slot="label">
                        <div class="bzImgs"><img style="margin-right:9px" src="../assets/images/svip.png" />高级版</div>
                        <div class="bqqStext">标圈圈，找更多商机！</div>
                    </div>
                </el-tab-pane>
            </el-tabs>

            <div class="firstContent" >
                        <div>选择合适套餐，查看更多详情！</div>
                        <div class="nav-top flex aligenCenter">
                            <div class="tao-half-row maright" @click="taocan('1')">
                                <div :class="vipFlag==1?'danTk':'danTk2'">
                                    <div>基准价</div>
                                    <div style="margin: 20px 0 10px 0;">
                                        <span style="color:#FF0000">¥<span style="font-size:30px;">599</span></span>
                                        <span>/3个月</span>
                                    </div>
                                    <div class="danTexts flex jutCenter">
                                        <div class="flex jutCenter danH" >每天仅需6.6元</div>
                                    </div>
                                </div>
                                <img v-if="vipFlag==1" class="tkgouImg" src="../assets/images/gou.png" />
                            </div>
                            <div class="tao-half-row maright" @click="taocan('2')">
                                <div  :class="vipFlag==2?'danTk':'danTk2'">
                                    <div>用户优惠</div>
                                    <div style="margin: 20px 0 10px 0;">
                                        <span style="color:#FF0000">¥<span style="font-size:30px;">999</span></span>
                                        <span>/6个月</span>
                                    </div>
                                    <div class="danTexts flex jutCenter">
                                        <div class="saleprice">原价¥1198</div>
                                    </div>
                                </div>
                                <div class="dwZhek">83折</div>
                                <img v-if="vipFlag==2" class="tkgouImg" src="../assets/images/gou.png" />
                            </div>
                            <div class="tao-half-row" @click="taocan('3')"> 
                                <div :class="vipFlag==3?'danTk':'danTk2'">
                                    <div>超值推荐</div>
                                    <div style="margin: 20px 0 10px 0;">
                                        <span style="color:#FF0000">¥<span style="font-size:30px;">1599</span></span>
                                        <span>/12个月</span>
                                    </div>
                                    <div class="danTexts flex jutCenter">
                                        <div class="saleprice">原价¥2396</div>
                                    </div>
                                </div>
                                <div class="dwZhek">67折</div>
                                <img v-if="vipFlag==3" class="tkgouImg" src="../assets/images/gou.png" />
                            </div> 
                        </div>

                        <div class="nav-middle">
                            <div class="flex nav-middleCon">
                                <div class="dkq" v-if="couponUserId!=''">{{quanList[0].couponInfo.couponAmount}}抵扣券</div>
                                <div class="sjPay">实付金额：<span style="color:#FF0000;margin-bottom: -10px;">￥<span style="font-size: 48px;">{{totalPrice}}</span></span></div>
                            </div>
                            <div class="flex jutCenter"><div class="sureBtn" @click="kaitong">确认套餐并支付</div></div>
                        </div>
                        <div style="font-size: 12px;">注：试运营期VIP可通过<span style="cursor: pointer;color:#0078FF" @click="Sign">点击上方领取VIP会员</span>获得，我们提供多种活动给大家赠送VIP体验机会，
                            支付购买后<span><router-link to="/userCenter?actives=8" style="color:#0078FF">发票</router-link></span>可在个人中心申请。</div>
            </div>
           
          </div>
          <div class="bottom">
            <div class="footsCon">
                <div class="flex aligenCenter jutCenter bqqText1">
                    <img class="iconZ" src="../assets/images/vipJx.png" />
                    <div style="margin:0 5px;">标准版会员特权</div>
                    <img class="iconZ" src="../assets/images/vipJx.png" />
                </div>	
                <div class="bqqText2">标圈圈，找更多商机！</div>
                <div class="flex" style="flex-wrap: wrap;align-items: flex-start;">
                    <div class="vipHalfs" v-for="(item,index) in bqqList" :key="index">
                        <img class="vipIcons" :src="item.url" />
                        <div class="vipIconText">{{item.text}}</div>
                    </div>
                </div>
            </div>   
          </div>
       </div>

       <!-- 弹框支付 -->
       <el-dialog
        :visible.sync="ewmVisible"
        width="760px"
        :before-close="handleClose">
        <div  class="nav">
            <div class="ktText">开通产品套餐，查看更多数据</div>
            <div class="paySuccess" v-if="paySuccess">
                <el-alert :title="messageTitle" type="success"  center show-icon :closable="false"></el-alert>
            </div>
            <div class="fYcolor">
                <div class="cpLeft">
                    <img :src="ewmImg" class="ewmImg" />
                    <div class="flex jutCenter">
                        <img src="../assets/images/sao.png" style="width:17px;height:17px;margin-right: 5px;" />
                        <div style="color:#999999">使用<span style="color:#1E88FF">微信</span>扫码支付</div>
                    </div>
                </div>
                <div class="cpRight">
                    <div>
                        <div>开通账号：{{phoneNo}}</div>
                        <div>开通套餐：{{orderPay.name}}</div>
                        <div>到期时间：{{orderPay.days}}</div>
                        <div v-if="orderPay.days=='永久'">实付金额：<span style="color:#FF0000">￥<span style="font-size:30px">{{orderPay.salePrice}}</span>元/条</span></div>
                        <div v-else>实付金额：<span style="color:#FF0000">￥<span style="font-size:30px">{{orderPay.salePrice}}</span>元/年</span></div>
                        <div style="color: #999999;margin-top: 6px;">发票获取：完成支付后可在【个人中心-我的发票】中申请</div>
                    </div>
                </div>
            </div>
        </div>
        </el-dialog>
 
      <common-foot></common-foot>

     <common-active :showTk="beijis" :showActivity="flagActivity" @change="amountChanges($event)"></common-active>

    </div>
</template>

<script>
   import CommonHeader from '@/components/CommonHeader.vue';
   import CommonFoot from '@/components/CommonFoot.vue';
   import CommonActive from '@/components/CommonActive.vue';
    export default {
      components:{
       CommonHeader,
       CommonFoot,
       CommonActive
       }, 
        data() {
            return {
                //签到
                beiji:false,
               // activeNameTop:'1',
                phoneNo:'',
                vipAuth:'',
                level:[],
                beijis:false,
                ewmVisible:false,
                ewmImg:'',

                objectIds:'', //点击详情时的Id
                objectIds2:'', //点击详情时的公司Id
                timerT: null, // 定时器
                orderPay:[],
                orderPays:[],
                ewmImg:'',
                paySuccess:false,
                totalTime:3,
                messageTitle:'',
                height1:'',
                activeName: 'first',
                bqqList:[
					{id:1,text:'招标公告',url:'https://www.biaox.cn/webcrawler/wechat/images/zbvipIcon1.png'},
					{id:2,text:'中标公告',url:'https://www.biaox.cn/webcrawler/wechat/images/zbvipIcon2.png'},
					{id:3,text:'拟建项目',url:'https://www.biaox.cn/webcrawler/wechat/images/zbvipIcon3.png'},
					{id:4,text:'招标预告',url:'https://www.biaox.cn/webcrawler/wechat/images/zbvipIcon4.png'},
					{id:5,text:'精选项目',url:'https://www.biaox.cn/webcrawler/wechat/images/zbvipIcon5.png'},
					{id:6,text:'审批项目',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon6.png'},
					{id:7,text:'企业直采',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon7.png'},
					{id:8,text:'政府采购',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon8.png'},
					{id:9,text:'询价订单',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon9.png'},
					{id:10,text:'谈判采购',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon10.png'},
					{id:11,text:'项目分包',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon11.png'},
					{id:12,text:'采购意向',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon12.png'},
					{id:13,text:'招标订阅',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon13.png'},
					{id:14,text:'中标订阅',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon14.png'},
					{id:15,text:'拟建订阅',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon15.png'},
					{id:16,text:'邮件提醒',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon16.png'},
					{id:17,text:'系统提醒',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon17.png'},
					{id:18,text:'微信提醒',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon18.png'},
					{id:19,text:'标讯导出',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon19.png'},
					{id:20,text:'附件查看',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon20.png'},
					
					{id:21,text:'企业监控',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon21.png'},
					{id:22,text:'企业分析',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon22.png'},
					{id:23,text:'业务渠道拓展',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon23.png'},
					{id:24,text:'商机分析预测',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon24.png'},
					{id:25,text:'AI大数据报告',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon25.png'},
					{id:26,text:'项目分析报价分析',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon26.png'},
				],
                vipFlag:'3',
                quanList:[],
				totalPrice:'1599',
				couponUserId:'',
                flagActivity:'',
            }
        },
        mounted(){
            this.phoneNo=JSON.parse(localStorage.getItem('phoneNo'))
            this.height1=document.documentElement.clientWidth/6.5+'px'
            this.showQuan()
            this.queryInfos()
        },
        methods: {
            Sign(){
                let that=this
                that.ewmVisible=false
                that.isChao=false
                setTimeout(()=>{
                    that.amountChanges('true')
                },1000)
            },
            queryInfos(){
                var that=this
                that.$axios.post('/webInterface/queryUserInfo')
                    .then(function (res) {
                     console.log(res)
                     if(res.data.resultcode==1){
                       
                    }else if(res.data.resultcode==-9999){
                        that.$message.error('登录信息失效，请重新登录')
                        setTimeout(()=>{  //设置延迟执行
                            that.$router.push({path: '/login'});
                       },1500);
                     } 
                    })
                    .catch(function (err) {
                        console.log(err);
                    })
            },
            showQuan(){
				let that = this;
                that.$axios.post('/webInterface/queryCouponUserList?couponType=3'+'&useState=1')
                    .then(function (res) {
                        console.log(res);
                        if(res.data.resultcode==1){
                            that.quanList=res.data.couponUserList
                            if(that.quanList.length>0){
                                if(that.vipFlag==1){
                                    that.totalPrice=599-that.quanList[0].couponInfo.couponAmount
                                }else if(that.vipFlag==2){
                                    that.totalPrice=999-that.quanList[0].couponInfo.couponAmount
                                }else{
                                    that.totalPrice=1599-that.quanList[0].couponInfo.couponAmount
                                }
                                that.couponUserId=that.quanList[0].couponUserId
                            }else{
                                that.couponUserId=''
                                if(that.vipFlag==1){
                                    that.totalPrice=599
                                }else if(that.vipFlag==2){
                                    that.totalPrice=999
                                }else{
                                    that.totalPrice=1599
                                }

                            }
                        }
                    })
                    .catch(function (err) { 
                    })
			},
            handleClick(tab, event) {
                console.log(tab._props.name)
                if(tab._props.name=='second'){
                    this.$message('敬请期待...')
                }
            },
            
            amountChange(e){
              this.beijis=e
            },
            amountChanges(e){
              this.beijis=e
              this.flagActivity='1'
            },
            Sign(){
                //传值给子组件
                let that=this
                that.amountChanges('true')
            },
            handleClose(){
                this.ewmVisible=false
            },

            taocan(flag){
                let that=this
                that.vipFlag=flag
                if(flag==3){
					that.showQuan()
					if(that.quanList.length>0){
						// if(that.vipFlag==1){
						// 	that.totalPrice=599-that.quanList[0].couponInfo.couponAmount
						// }else if(that.vipFlag==2){
						// 	that.totalPrice=999-that.quanList[0].couponInfo.couponAmount
						// }else{
						// 	that.totalPrice=1599-that.quanList[0].couponInfo.couponAmount
						// }
                        that.totalPrice=1599-that.quanList[0].couponInfo.couponAmount
						that.couponUserId=that.quanList[0].couponUserId
					}else{
						// if(that.vipFlag==1){
						// 	that.totalPrice=599
						// }else if(that.vipFlag==2){
						// 	that.totalPrice=999
						// }else{
						// 	that.totalPrice=1599
						// }
                        that.totalPrice=1599
						that.couponUserId=''
					}
				}else{
					that.quanList=[]
					that.couponUserId=''
                    if(flag==1){
                        that.totalPrice=599
                    }else if(flag==2){
                        that.totalPrice=999
                    }
				}
		   },
            //关闭支付弹框
            handleClose(){
                this.ewmVisible=false
                clearInterval(this.timerT)
                this.timerT = null
            },
            //开通支付
            kaitong(){
                let that=this
                let twoObjectId=''
                if(this.phoneNo!=null&&this.phoneNo!=''){
                    that.ewmVisible=true
                    let goodsId=''
                    if(that.vipFlag==1){
                        goodsId='5166747643280060475'  //包日
                    }else if(that.vipFlag==2){
                        goodsId='5166747643280060476'   //包月
                    }else if(that.vipFlag==3){
                        goodsId='5166747643280060474'   //包年
                    }
                    
                    if(that.couponUserId!=undefined){
                        twoObjectId=that.couponUserId
                    }else{
                        twoObjectId=''
                    }
                    
                    that.$axios.get('/webInterface/generateOrder', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            goodsId:goodsId,  //套餐id
                            orderIds:'',
                            objectId:'', //单条详情id
                            quantity:1,
                            quantity:'1',
                            freight:0,
                            payType:2,  //1是支付宝支付，2是微信支付
                            objectType:'',
                            //sourceType:"wx",
                            couponUserId:twoObjectId  //优惠券Id
                        }
                    })
                    .then(function (res) {
                        console.log(res)
                        if(res.data.resultcode==1){
                            that.ewmVisible=true
                            that.ewmImg=res.data.reStr  //二维码
                            that.orderPays=res.data.orderInfo
                            that.$axios.post('/webInterface/queryGoodsInfo?id='+goodsId+'&couponUserId='+twoObjectId)
                            .then(function (res) {
                                console.log(res)
                                if(res.data.resultcode==1){
                                    that.orderPay=res.data.goodsInfo                               
                                }
                            })
                            .catch(function (err) { 
                            })
                            that.timerT = setInterval(() => {
                                //  定时器中执行的代码
                               that.check(res.data.orderInfo.id)
                            }, 3000)
                        }
                    })
                    .catch(function (err) { 
                    })
                }else{
                    setTimeout(()=>{  //设置延迟执行
                        this.$router.push({path: '/login'});
                    },600);
                }    
                
            },
            check(orderId){
                var that=this
               that.$axios.post('/webInterface/queryOrderInfo?orderId='+orderId+'&flag=1')
                .then(function (res) {
                    console.log(res);
                    // res.data.resultcode=1
                    // res.data.orderInfo.state=1
                 if(res.data.resultcode=='1'&& res.data.orderInfo.state!=0){
                     that.paySuccess=true
                     that.messageTitle='支付成功，已开通年费VIP'

                    setTimeout(function(){
                        that.ewmVisible=false
                        clearInterval(that.timerT)  
                        that.timerT = null
                        that.paySuccess=false
                    },2500)

					}else{
                        that.paySuccess=false
                        that.quanList=[]
                        //clearInterval(that.timerT)
                        //that.timerT = null
                    }
                })
                .catch(function (err) { 
                })
            },

        }
    }
</script>
<style scoped lang="less">
  .topCon{
  background:url(../assets/images/vipBanner.png);
  width: 100%;
  background-size: 100% 100%;
}
.flex{
  display: flex;
  align-items: center;
}
.halfCon{
    width: 1200px;
    margin: 0 auto;
}
/deep/.tops{
    margin-top: -80px;
    background: #FFFFFF;
    border-radius: 5px;
    .el-tabs--top{
        width: 100%;
        .el-tabs__nav-wrap::after{
            background-color: #fff;
        }
        .el-tabs__nav-scroll{
            .el-tabs__nav{
                width: 100%;
                .el-tabs__active-bar{
                    left: 25%;
                    width: 120px !important;
                    transform: translateX(-50%) !important;
                }
                .el-tabs__item{
                    height: 92px;
                    line-height: 28px;
                    font-size: 24px;
                    width: 50%;
                    text-align: center;
                    padding: 20px 0 0 0; 
                    background: #F2F4F7;
                    .bzImgs{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 24px;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                    } 
                    .bqqStext{
                        font-size: 18px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #999999;
                    }  
                    }
                    .el-tabs__item.is-active{
                    background: #fff;
                    color:#303133
                } 
            }
        }
    }
}
.tImg{
    width: 585px;
    height:540px;
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.08);
    border-radius: 3px;
}
.tImgs{
    width:1200px;
    height:1581px;
    // box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.08);
    border-radius: 3px;
    margin-top: 30px;
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.08);
}
.top-left{
    background-image:url(../assets/images/vip11.png);
    width: 585px;
    height:540px;
    //box-shadow: 0 3px 8px 0 rgb(0 0 0 / 8%);
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.08);
}
.kaiButton{
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    display:flex;
    justify-content: center;
    align-items: center;
    width:100%;
}
.btns{
    width: 400px;
    height: 60px;
    background: linear-gradient(90deg, #FFA347, #FF864A);
    border-radius: 6px;
    display:flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
/deep/ .el-dialog{
        border-radius: 5px;
    .el-dialog__header{
        background-image: url(../assets/images/tcTops.png);
        width:100%;
        height:140px;
        padding:0;
        .el-dialog__close{
            color: #fff;
        }
    }
    .el-dialog__body{
       border-radius: 5px;
       padding: 0;
       padding-bottom: 20px;
       .nav{
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.08);
        border-radius: 10px;
        margin: -50px 15px 0 15px;
        padding: 30px;
        .ktText{
            position: absolute;
            top: 20px;
            left: 94px;
            font-size: 36px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #FFFEFE;
        }
        .fYcolor{
            background: #F6FAFF;
            border-radius: 5px;
            padding: 15px;
            display:flex;
            .cpLeft{
                width: 160px;
                height: 200px;
                background: #FFFFFF;
                border-radius: 5px;
                margin-right: 60px;
                .ewmImg{
                    width: 160px;
                    height: 160px;
                    margin-bottom: 7px;
                }
            }
            .cpRight{
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #222222;
                line-height: 30px;
                margin-top: 20px;
            }
        }
       }
    }
}
// .ktText{
//     font-size: 36px;
//     font-family: Source Han Sans CN;
//     font-weight: bold;
//     color: #B28054;
//     position: absolute;
//     top: 20px;
//     left: 94px;
// }
.firstContent{
    padding: 20px 30px;
}


.bottom{
    border-radius: 5px;
    margin: 30px 0;
    padding: 30px;
    background: #FFFFFF;
}
.footsCon{
    padding: 30px;
    border: 2px solid #0078FF; 
}
.bqqText1{
		font-size: 30px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #222222;
	}
	.bqqText2{
		font-size: 18px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #999999;
		text-align: center;
		margin: 4px 0;
	}
	.vipHalfs{
		text-align: center;
		font-size: 18px;
		width: calc(100%/7);
		margin: 18px 0 0 0;
	}
	.vipIconText{
		width: 72px;
		margin: 0 auto;
	}

    .nav-middle{
        width: 100%;
        background: #FFFFFF;
        border: 2px dashed #EEEEEE;
        border-radius: 5px;
        padding:40px 0;
        text-align: center;
        margin: 25px 0;
        .nav-middleCon{
            align-items: flex-end;
            font-size: 18px;
            justify-content: center;
            .sjPay{
                display: flex;
               align-items: flex-end;
            }
        }
    }
    .nav-top{      
      margin-top: 25px;
    }
    .sureBtn{
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        width: 400px;
        height: 60px;
        background: #0078FF;
        border-radius: 6px;
        line-height: 60px;
       margin-top: 32px;
       cursor: pointer;
    }
    .dkq{
        width: 130px;
        height: 30px;
        background: #FF0000;
        border-radius: 4px;
        line-height: 30px;
        text-align: center;
        font-size: 18px;
        font-family: Source Han Sans CN;
        color: #FFE477;
        margin-right: 30px;
    }

    .tao-half-row{
	width: 100%;
	position: relative;
    font-size: 18px;
    cursor: pointer;
}
.tq2Img{
	width:63px;
	height:12px;
}
.tq3Img{
	width:47px;
	height:12px;
}
.danTk2{
	background: #FFFFFF;
	border: 1px solid #E6E6E6;
	border-radius: 10px;
    text-align: center;
    padding: 30px 0;
}
.danTops2{
	height: 24px;
	background: #E6E6E6;
	border-radius: 5px 5px 0px 0px;
	margin-top: -2px;
	line-height: 24px;
}
.maright{
	margin-right: 30px;
}
.saleprice{
	height: 26px;
	line-height: 26px;
	text-decoration: line-through;
}
.danTk{
	background: #EDF5FF;
	border: 1px solid #0078FF;
	border-radius: 5px;
    text-align: center;
    padding: 30px 0;
}
.danTops{
	height: 24px;
	background: linear-gradient(0deg, #0078FF 0%, #0052F0 100%);
	border-radius: 5px 5px 0px 0px;
	margin-top: -2px;
	line-height: 24px;
}
.tq1Img{
	width:137px;
	height:12px;
}
.danTexts{
	width: 100%;
	font-size: 12px;
	font-family: PingFang SC;
	font-weight: 500;
	margin-bottom:8px;
}
.danbtns{
	width: 70px;
	height: 26px;
	background: linear-gradient(135deg, #F5DCB3, #E5BA71);
	border-radius: 13px;
	line-height: 26px;
	text-decoration: line-through;
}
.dlTexts{
		 overflow: hidden;
		 white-space: nowrap;
		 text-overflow: ellipsis;
		 max-width: 80%;
	}
    .dwZhek{
	  position: absolute;
	  right: -10px;
	  top:-11px;
	  width: 33px;
	  height: 18px;
	  background: #FF0000;
	  border-radius: 11px 0px 11px 0px;
	  color:#ffffff;
	  font-size: 12px;
	  font-family: PingFang SC;
	  font-weight: 500;
	  color: #FEFEFE;
	  line-height: 18px;
      text-align: center;
	}
	// .danbtnsA{
	// 	height: 26px;
	//     line-height:26px;
	// 	width: 70px;
	// 	background: linear-gradient(135deg, #F5DCB3, #E5BA71);
	// 	border-radius: 13px;
	// }
	.danH{
		height:26px;line-height:26px;color:#999999
	}
	.iconZ{
		width: 12px;
		height: 12px;
	}
    
  .tkgouImg{
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
</style>