<template>
    <div>
        <common-header @change="amountChange($event)" :message="beiji"></common-header>   
        
     <div class="halfC" style="margin-top: 15px;">
        <div  v-if="isFlag" >
         <div v-if="isFlags">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <div class="flex jutSpace jnNas">
                    <div class="flex alignCenter">数据截止：<span>{{nowTime}}</span>
                      <span style="margin-left:20px;color:#409EFF;cursor: pointer;" @click="showAll = !showAll">修改条件</span>
                    </div>
                    <div class="flex alignCenter jnTextdy" >
                        <!-- <div class="flex alignCenter" style="margin-right: 15px;cursor: pointer;" @click="showAll = !showAll">
                        <img src="../assets/images/dyIcon1.png" style="margin-right: 3px;" />修改条件
                        </div> -->
                        <div class="flex alignCenter" style="margin-right: 15px;cursor: pointer;" @click="addDytk('1')">
                        <img src="../assets/images/dyIcon1.png" style="margin-right: 3px;" />修改订阅
                        </div>
                        <div class="flex alignCenter" style="margin-right: 15px;cursor: pointer;" @click="delDy">
                        <img src="../assets/images/dyIcon2.png" style="margin-right: 3px;" />删除订阅
                        </div>
                        <!-- <div class="flex alignCenter" style="margin-right: 15px;cursor: pointer;" @click="addDytk('2')">
                        <img src="../assets/images/dyIcon3.png" style="margin-right: 3px;" />新增订阅
                        </div> -->
                    </div>
                </div>
                <el-tab-pane :label="item.subscribeName" :name="item.subscribeId" v-for="(item,index) in tabsArr" :key="index" >
                    <div v-if="showAll" class="isOpenClass">
                        <!-- 关键词 -->
                        <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px" >
                            <div style="color: #999999;width: 90px;line-height: 30px;">关键词：</div>
                            <div class="flex timeCode">
                            <div class="flex" v-if="keyWordsA!=''&&keyWordsA.length>0" style="flex-wrap: wrap;">
                                <div class="tc_tag-input_roots" v-for="(item,index) in keyWordsA" :key="index">
                                        <span>{{item}}</span>
                                        <img src="../assets/images/guanbi1.png" @click="deletes(item,index)" style="width: 11px;height: 12px;margin-left: 4px;margin-top: 3px;" />
                                        <!-- <span style="width:14px;height: 14px;margin-left:3px" @click="deletes(item,index)">x</span> -->
                                </div>
                            </div>
                            <div class="flex" v-if="keyWordsA.length<10">
                                <div class="ju-tinput">
                                    <input type="text" placeholder="请输入关键词" class="inputClass" v-model="addContent">
                                    <img v-if="addContent!=''&&addContent!=null" src="../assets/images/guanbi.png" class="closeImg"  @click="del" />
                                    <div class="searchBtn" @click="addClick">添加</div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <!-- 搜索范围 -->  
                        <!-- <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px" >
                            <div style="color: #999999;width: 90px;line-height: 30px;">搜索范围：</div>
                            <div class="flex timeCode" >
                                <div v-for="(item, index) in fwList" :key="index"  >
                                    <div @click="fwChang(index,item.id,item.name)" class="item"  :class="{ selected: item.id == fwCode}">{{item.name}}</div>
                                </div>
                            </div>
                        </div> -->

                        <!-- 选择地区--省 -->
                        <div class="flex chooseArea" style="align-items: flex-start;margin-top: 20px;">
                            <div style="color: #999999;width: 90px;line-height: 30px;">选择地区：</div>
                            <!-- 省 -->
                            <div class="flex areaCode " :style="{ 'max-height': status ? textHeight : '' }"
                                                :class="{ statusText: status }" ref="descs">
                                    <div v-for="(item, index) in proviceList" :key="index" style="display: inline-block;">
                                        <div  @click="proviceChang(index,item.areaCode,item.areaName,'2')"   class="item" :class="{ selected: item.areaCode == provinceCode}">
                                            {{item.areaName}}
                                        </div>
                                    </div>
                            </div>
                    
                            <div
                                v-if="idShowText"
                                @click="showStaus" ref="clickOpen"
                                :class="{ openSpan: status }"
                                class="openClose">{{ status ? "展开" : "收起" }}
                                <img v-if="status==true" class="jtImgsxsb" src="../assets/images/xiajt.png"  />
                                <img v-else class="jtImgsxsb" src="../assets/images/shangjt1.png"  />
                            </div> 
                        </div>
                        <!-- 市 -->
                        <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px" v-if="isCity"> 
                        <div style="color: #999999;width: 90px;line-height: 30px;" >城市：</div>
                            <div class="flex areaCode" style=" margin-left:0;">
                                <div v-for="(item, index) in cityList" :key="item.id"  >
                                    <div @click="cityChang(index,item.areaCode,item.areaName,'2')" class="item"  :class="{ selected: index == numberTwo}">{{item.areaName}}</div>
                                </div>
                            </div> 
                        </div>
                        <!-- 县 -->
                        <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px" v-if="isCountry"> 
                            <div style="color: #999999;width: 90px;line-height: 30px;" >区县：</div>
                            <div class="flex areaCode" style=" margin-left:0;">
                                <div v-for="(item, index) in areaList" :key="item.id"  >
                                    <div @click="areaChang(index,item.areaCode,item.areaName,'2')" class="item"  :class="{ selected: index == numberArea}">{{item.areaName}}</div>
                                </div>
                            </div> 
                        </div>     
            
            <div > 
            <!-- 发布时间 -->
               <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px"  >
                <div style="color: #999999;width: 90px;line-height: 30px;">选择时间：</div>
                <div class="flex timeCode" >
                    <div v-for="(item, index) in timeList" :key="index"  >
                        <div v-if="item.optionCode==5" style="margin-right: 13px;"> 
                            <div style="position: relative;" class="faTime">
                                <el-select class="selectitem" v-model="valueYear" placeholder="往年" @change="hqYears($event,item.dictionaryOptionId)" 
                                :class="{selected: valueYear!==''&&valueYearzdy==''}">
                                    <el-option
                                    v-for="(item, index) in yearArr" :key="index"
                                    :label="item.value"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                                <img class="jtImgsxsa" src="../assets/images/jt.png"  />
                            </div> 
                        </div> 
                        <div v-else-if="item.optionCode==4">
                            <div style="position: relative;">
                                <el-date-picker 
                                v-model="valueYearzdy"
                                type="daterange"
                                range-separator=""
                                :class="{selected: valueYear==''&&valueYearzdy!==''}"
                                :picker-options="pickerOptions0"
                                @change="zdyYears(item.dictionaryOptionId)"
                                start-placeholder="自定义"
                                end-placeholder="">
                                </el-date-picker>
                            <img :class="valueYearzdy==''?'jtImg':'jtImgs'" src="../assets/images/jt.png"  />
                            </div> 
                        </div>

                        <div @click="timeChang(index,item.dictionaryOptionId)" class="item"  v-else
                        :class="{ selected: index == numberThree&&valueYear==''&&valueYearzdy==''}">
                        {{item.optionName}}</div>
                    </div>
                    
                </div>
                </div> 
            </div>
            

            <!-- 信息类型 -->  
                <!-- <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px" >
                    <div style="color: #999999;width: 90px;line-height: 30px;">信息类型：</div>
                    <div class="flex timeCode" >
                        <div v-for="(item, index) in messageList" :key="index"  >
                            <div @click="messageChang(index,item.areaCode,item.areaName)" class="item"  :class="{ selected: item.areaCode==messageCode}">{{item.areaName}}</div>
                        </div>
                    </div>
                </div> -->


                <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px"  >
                    <div style="color: #999999;width: 90px;line-height: 30px;">信息类型：</div>
                   <div>
                    <div class="flex timeCode" >
                        <div v-for="(item, index) in messageList" :key="index"  >
                            <div @click="messageChang(index,item.areaCode,item.areaName)" class="item"  :class="{ selected: index == number4}">{{item.areaName}}</div>
                        </div>
                    </div>
                     <div class="flex timeCode" style="margin-top: 20px;" v-if="number4!=''">
                        <div v-for="(item, index) in zbList" :key="index"  >
                            <div @click="messageChangs(index,item.dictionaryOptionId,item.optionName)" class="item"  :class="{ selected: index == number4s}">
                                {{item.optionName}}</div>
                        </div>
                     </div>

                   </div>
                </div>

       

            <!-- 更多筛选 -->
               <div class="flex chooseArea" style="align-items: center;margin-top:20px">
                <div style="color: #999999;width: 90px;line-height: 30px;">更多筛选：</div>
                <div class="flex timeCode aShaixuan" >
                    <div style="position: relative;margin-right: 12px;">
                        <div v-if="phoneNo!=''&&phoneNo!=null" >
                        <el-select v-model="sxvalue1" placeholder="行业类型" @change="sxOne($event,'1')"  @focus="moreSx()">
                            <el-option
                            v-for="(item, index) in sxList1" :key="index"
                                :label="item.optionName"
                                :value="item.dictionaryOptionId+','+item.optionName">
                            </el-option>
                        </el-select>
                        <img class="jtImgsx" src="../assets/images/jt.png"  />
                        </div>
                        <div v-else class="sxTexts" @focus="moreSx()">
                            <span >行业类型</span>
                            <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                        </div> 
                    </div>

                    <div style="position: relative;margin-right: 12px;" >
                        <div v-if="phoneNo!=''&&phoneNo!=null" >
                            <el-select v-model="sxvalue2" placeholder="招采类型" @change="sxOne($event,'2')" @focus="moreSx()">
                            <el-option
                            v-for="(item, index) in sxList2" :key="index"
                                :label="item.optionName"
                                :value="item.dictionaryOptionId+','+item.optionName">
                            </el-option>
                        </el-select>
                        <img class="jtImgsx" src="../assets/images/jt.png"  />
                        </div>
                        <div v-else class="sxTexts" @focus="moreSx()">
                            <span >招采类型</span>
                            <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                        </div> 
                    </div>

                
                    <div style="position: relative;margin-right: 12px;" >
                        <div v-if="phoneNo!=''&&phoneNo!=null" >
                            <el-select v-model="sxvalue3" placeholder="预算金额" @change="sxOne($event,'3')" @focus="moreSx()">
                            <div  v-for="(item, index) in sxList4" :key="index">
                                <div v-if="item.optionName=='自定义'"></div>
                                <el-option  v-else
                                @mouseover="mouseOver1(item.optionName)"
                                :label="item.optionName"
                                :value="item.dictionaryOptionId+','+item.optionName">
                                </el-option>
                            </div>
                            </el-select> 
                            <img class="jtImgsx" src="../assets/images/jt.png"  />
                        </div>
                        <div v-else  class="sxTexts" @focus="moreSx()">
                            <span >预算金额</span>
                            <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                        </div> 
                    </div>    

                    <div style="position: relative;margin-right: 12px;" >
                        <div v-if="phoneNo!=''&&phoneNo!=null" >
                            <el-select v-model="sxvalue4" placeholder="中标金额" @change="sxOne($event,'4')" @focus="moreSx()">
                                <div  v-for="(item, index) in sxList6" :key="index">
                                    <div v-if="item.optionName=='自定义'"></div>
                                    <el-option  v-else
                                    :label="item.optionName"
                                    :value="item.dictionaryOptionId+','+item.optionName">
                                    </el-option> 
                                </div>
                            </el-select>
                            <img class="jtImgsx" src="../assets/images/jt.png"  />
                        </div>
                        <div v-else  class="sxTexts" @focus="moreSx()">
                            <span >中标金额</span>
                            <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                        </div> 
                    </div>

                     <!-- <div style="position: relative;margin-right: 12px;" v-if="messageCode!=3">
                        <el-select v-model="sxvalue5" placeholder="招采单位" @change="sxOne($event,'5')">
                        <el-option
                        v-for="(item, index) in sxList3" :key="index"
                            :label="item.optionName"
                            :value="item.dictionaryOptionId+','+item.optionName">
                        </el-option>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                    </div> -->


                    <div style="position: relative;margin-right: 12px;" >
                        <div v-if="phoneNo!=''&&phoneNo!=null" >
                            <el-select v-model="sxvalue6" placeholder="代理单位" @change="sxOne($event,'6')" @focus="moreSx()">
                            <el-option
                            v-for="(item, index) in sxList5" :key="index"
                                :label="item.optionName"
                                :value="item.dictionaryOptionId+','+item.optionName">
                            </el-option>
                            </el-select> 
                            <img class="jtImgsx" src="../assets/images/jt.png"  />
                        </div>
                        <div v-else  class="sxTexts" @focus="moreSx()">
                            <span >代理单位</span>
                            <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                        </div> 
                    </div>


                <!-- 联系方式 -->
                    <div style="position: relative;margin-right: 12px;" class="phoneClass" >
                        <div v-if="phoneNo!=''&&phoneNo!=null" >
                            <el-select v-model="sxvalue7" placeholder="联系方式" @change="sxOne($event,'7')" @focus="moreSx()">
                            <el-option
                            v-for="(item, index) in sxList7" :key="index"
                                :label="item.optionName"
                                :value="item.dictionaryOptionId+','+item.optionName">
                            </el-option>
                        </el-select>
                        <img class="jtImgsx" src="../assets/images/jt.png"  />
                        </div>
                        <div v-else  class="sxTexts" @focus="moreSx()">
                            <span >联系方式</span>
                            <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                        </div> 
                    </div>

                    <!-- <div style="position: relative;margin-right: 12px;" >
                        <el-select v-model="sxvalue8" placeholder="附件" @change="sxOne($event,'8')" @focus="moreSx()">
                        <el-option
                        v-for="(item, index) in sxList8" :key="index"
                            :label="item.optionName"
                            :value="item.dictionaryOptionId+','+item.optionName">
                        </el-option>
                        </el-select>
                        <img class="jtImgsx" src="../assets/images/jt.png"  />
                    </div>  -->
                </div>
                </div>

                </div>
                </el-tab-pane>  

                <div class="half-bottom flex jutSpace">
                    <div class="flex" style="flex-wrap:wrap;margin-right: 20px;">
                        <!-- v-if="keyWordsA!=''||fwName!=''||cityName!=''&&cityName!='全部'
                        ||xxName!=''&&xxName!='全部'" -->
                        <div style="height: 35px;">已选条件：</div>
                        <div class="flex tc_tag-input_root" v-if="keyWordsA!=''">关键词：
                            <div  v-for="(item,index) in keyWordsA" :key="index" class="flex">
                                {{item}}
                                <div v-if="keyWordsA.length>1&&keyWordsA.length-1!=index">,</div>
                            </div>
                        </div>
                        <div class="flex tc_tag-input_root" v-if="fwName!=''">搜索范围:<div>{{fwName}}</div></div>
                        <div class="flex tc_tag-input_root" v-if="cityName!=''&&cityName!='全部'">省份地区：
                            <div >{{cityName}}</div>
                            <div v-if="cityName1!=''">/{{cityName1}}</div>
                            <div v-if="cityName2!=''">/{{cityName2}}</div>
                        </div>
                        <div class="flex tc_tag-input_root" v-if="xxName!=''&&xxName!='全部'">信息类型：
                            <div>{{xxName}}</div>
                            <div v-if="xxName2!=''&&xxName2!='不限'">/{{xxName2}}</div>
                        </div>
                        <div v-if="sxvalue1!=''&&sxvalue1!='行业类型'&&sxvalue1!=undefined" class="flex tc_tag-input_root">行业类型：{{sxvalue1}}</div>
                        <div v-if="sxvalue2!=''&&sxvalue2!='招采类型'" class="flex tc_tag-input_root">招采类型：{{sxvalue2}}</div>
                        <div v-if="sxvalue3!=''&&sxvalue3!='预算金额'" class="flex tc_tag-input_root">预算金额：{{sxvalue3}}</div>
                        <div v-if="sxvalue4!=''&&sxvalue4!='中标金额'" class="flex tc_tag-input_root">中标金额：{{sxvalue4}}</div>
                        <div v-if="sxvalue6!=''&&sxvalue6!='代理单位'" class="flex tc_tag-input_root">代理单位：{{sxvalue6}}</div>
                        <div v-if="sxvalue7!=''&&sxvalue7!='联系方式'" class="flex tc_tag-input_root">联系方式：{{sxvalue7}}</div>
                    </div>
                    <div class="flex" v-if="showAll">
                        <el-button type="info" style="margin-right:10px" @click="showAll=false">取消</el-button>
                        <el-button type="primary" @click="sure()">确定</el-button>
                    </div>
                </div>
            </el-tabs>
         </div> 
          
         <div v-else style="padding-bottom:20px">
            <el-tabs v-model="activeNameT" @tab-click="handleClickT">
                <el-tab-pane label="我的订阅" name="1">
                    <div class="kong">
                        <img class="kongImg" src="../assets/images/kong.png" />
                        <div style="margin: 6px 0 19px 0;">暂无订阅记录</div>
                        <el-button type="primary" @click="addDytk">立即添加</el-button>
                    </div>
                </el-tab-pane>
            </el-tabs>
            
         </div>
       </div>
       <div v-else></div>
    </div>

      <!-- <div class="open">
        <div @click="showAll = !showAll" class="statusA">
            <div style="position: absolute;top: -28px;">{{flag}}</div>
        </div>
      </div> -->
      <div class="tabCon halfC" style="margin-top: 15px;" id="tabsQ" v-if="tabsArr.length>0">
         <div class="flex jutSpace" style="border-bottom: 1px solid #F3F3F3;padding-bottom: 20px;">
           <div class="tabLeft" v-if="messageCode==1">
              标圈圈已帮您筛选出<span style="color: red;">{{totalA}}</span>条招标信息
           </div>
           <div class="tabLeft" v-else-if="messageCode==2">
              标圈圈已帮您筛选出<span style="color: red;">{{totalA}}</span>条中标信息
           </div>
           <div class="tabLeft" v-else-if="messageCode==3">
              标圈圈已帮您筛选出<span style="color: red;">{{totalA}}</span>条拟建信息
           </div>
           <div class="tabLeft" v-else>
              标圈圈已帮您筛选出<span style="color: red;">{{totalA}}</span>条招投标信息
           </div>
           <div class="tabRight flex">
                 <div style="position: relative;" >
                     <el-select class="rightM" v-model="pxvalue"  @change="pxChange($event)">
                        <el-option value="" label="智能排序"></el-option>
                        <el-option value="1" label="发布时间从早到晚"></el-option>
                        <el-option value="2" label="发布时间从晚到早"></el-option>
                    </el-select>
                    <img class="jtImgsxs" src="../assets/images/jt.png"  />
                </div>
           </div>
         </div>
         <div >
         <div class="contentAll">
           <div class="ant-spin-nested-loading">
            <ul class="ant-list-items" v-for="(item,index) in listAll" :key="index">
                 <!-- 中标公告 -->
                 <div class="items-half" >
                    <div class="tc_list_item tc_list_hover">
                        <div class="title" @click="zbDetail(item.id)">
                             <div v-html="item.name"></div> 
                        </div>
                        <div class="ant-content">
                            <div class="gGdJlei">
                                <span class="ant-tag ant-tag-orange bordered" v-if="item.dataType==1">{{item.dataTypeStr }} 
                                    <span v-if="item.dataSubTypeStr!=''&&item.dataSubTypeStr!=undefined">| {{item.dataSubTypeStr}}</span>
                                </span>
                                <span class="ant-tag ant-tag-green bordered" v-else-if="item.dataType==2">{{item.dataTypeStr }} 
                                   <span v-if="item.dataSubTypeStr!=''&&item.dataSubTypeStr!=undefined">| {{item.dataSubTypeStr}}</span>
                                </span>
                                <span class="ant-tag ant-tag-blue bordered" v-else>{{item.dataTypeStr }} 
                                    <span v-if="item.dataSubTypeStr!=''&&item.dataSubTypeStr!=undefined">| {{item.dataSubTypeStr}}</span>
                                </span>

                                <span class="ant-tag bordered" v-if="item.provinceCode!=''&&item.provinceCode!=undefined">
                                    <span v-if="item.provinceCode!=''&&item.provinceCode!=undefined">{{ item.provinceCode }}</span>
                                    <span v-if="item.cityCode!=''&&item.cityCode!=undefined"> | {{ item.cityCode }} </span>
                                    <span v-if="item.countyCode!=''&&item.countyCode!=undefined"> | {{ item.countyCode }} </span>
                                </span>  

                                <span  v-if="item.industryCategory!=''&&item.industryCategory!=undefined">
                                    <span class="ant-tag bordered"
                                    v-for="(items,indexs) in arrPao[index]" :key="indexs">
                                        {{ items }} 
                                    </span>
                                </span>
                                <span  v-if="item.procurementType!=''&&item.procurementType!=undefined">
                                    <span class="ant-tag bordered"
                                    v-for="(items,indexs) in arrPro[index]" :key="indexs">
                                        {{ items }} 
                                    </span>
                                </span>
                            </div>   
                         
                        
                            <div class="fiklj9cw" v-if="item.dataType==1">
                                <div class="smailDiv" v-if="item.bidCode!=''&&item.bidCode!=undefined">
                                    <div>项目编号:</div>
                                    <div>{{item.bidCode}}</div>
                                </div>
                                <div class="smailDiv" v-if="item.bidDeptName!=''&&item.bidDeptName!=undefined">
                                    <div>招采单位:</div>
                                    <div >{{ item.bidDeptName }}</div> 
                                    <!-- <div v-if="item.bidDeptId!=''" @click="companyDetail(item.bidDeptId)" class="blueColor">{{ item.bidDeptName }}</div> 
                                        <div v-else >{{ item.bidDeptName }}</div>  -->
                                </div>
                                <div class="smailDiv" v-if="item.agentDeptName!=''&&item.agentDeptName!=undefined">
                                    <div>代理单位:</div>
                                    <div >{{ item.agentDeptName }}</div>
                                    <!-- <div v-if="item.agentDeptId!=''" @click="companyDetail(item.agentDeptId)" class="blueColor">{{ item.agentDeptName }}</div> 
                                        <div v-else >{{ item.agentDeptName }}</div>  -->
                                </div>                            
                               
                                <div v-if="item.pageWinPackageList!=''&&item.pageWinPackageList!=undefined">
                                  <div class="fiklj9cw" v-for="(items,indexs) in item.pageWinPackageList" :key="indexs">
                                     <div class="smailDiv">
                                        <div>中标单位:</div>
                                        <div>{{ items.windeptname }}</div> 
                                        <!-- <div v-if="items.windeptId!=''" @click="companyDetail(items.windeptId)" class="blueColor">{{ items.windeptname }}</div> 
                                         <div v-else >{{ items.windeptname }}</div>  -->
                                    </div>
                                    <div class="smailDiv">
                                        <div>中标金额:</div>
                                        <div>{{items.winamountStr}}</div>
                                    </div> 
                                  </div>
                                </div> 
                                <div class="fiklj9cw">
                                    <div class="smailDiv" v-if="item.agentDeptPhone!=''&&item.agentDeptPhone!=undefined">
                                        <div>联系方式:</div>
                                        <div>{{item.agentDeptPhone}}</div>
                                    </div>
                                    <div class="smailDiv" v-if="item.issueTimeStr!=''&&item.issueTimeStr!=undefined">
                                        <div>发布时间:</div>
                                        <div>{{item.issueTimeStr}}</div>
                                    </div>
                                </div> 

                            </div>

                            <div class="fiklj9cw" v-else-if="item.dataType==2">
                                <div class="smailDiv" v-if="item.bidCode!=''&&item.bidCode!=undefined">
                                    <div>项目编号:</div>
                                    <div>{{item.bidCode}}</div>
                                </div>
                                <div class="smailDiv" v-if="item.bidDeptName!=''&&item.bidDeptName!=undefined">
                                    <div>招采单位:</div>
                                    <div >{{ item.bidDeptName }}</div> 
                                    <!-- <div v-if="item.bidDeptId!=''" @click="companyDetail(item.bidDeptId)" class="blueColor">{{ item.bidDeptName }}</div> 
                                        <div v-else >{{ item.bidDeptName }}</div>  -->
                                </div>
                                <div class="smailDiv" v-if="item.agentDeptName!=''&&item.agentDeptName!=undefined">
                                    <div>代理单位:</div>
                                    <div >{{ item.bidDeptName }}</div> 
                                    <!-- <div v-if="item.agentDeptId!=''" @click="companyDetail(item.agentDeptId)" class="blueColor">{{ item.agentDeptName }}</div> 
                                        <div v-else >{{ item.agentDeptName }}</div>  -->
                                </div>
                                <div class="fiklj9cw">
                                    <div class="smailDiv" v-if="item.issueTimeStr!=''&&item.issueTimeStr!=undefined">
                                        <div>发布时间:</div>
                                        <div>{{item.issueTimeStr}}</div>
                                    </div>
                                    <div class="smailDiv" v-if="item.openTimeStr!=''&&item.openTimeStr!=undefined">
                                        <div>审批时间:</div>
                                        <div>{{item.openTimeStr}}</div>
                                    </div>
                                </div> 
                            </div> 

                            <div class="fiklj9cw" v-else>
                                    <div class="smailDiv" v-if="item.bidCode!=''&&item.bidCode!=undefined">
                                        <div>项目编号:</div>
                                        <div>{{item.bidCode}}</div>
                                    </div>
                                    <div class="smailDiv" v-if="item.bidDeptName!=''&&item.bidDeptName!=undefined">
                                        <div>招采单位:</div>
                                        <div >{{ item.bidDeptName }}</div> 
                                    </div>

                                <div class="fiklj9cw">
                                    <div class="smailDiv" v-if="item.budgetAmountStr!=''&&item.budgetAmountStr!=undefined">
                                        <div>预算金额:</div>
                                        <div>{{item.budgetAmountStr}}</div>
                                    </div>
                                    <div class="smailDiv" v-if="item.agentDeptPhone!=''&&item.agentDeptPhone!=undefined">
                                        <div>联系方式:</div>
                                        <div>{{item.agentDeptPhone}}</div>
                                    </div> 
                                </div>
                                <div class="fiklj9cw">
                                    <div class="smailDiv" v-if="item.issueTimeStr!=''&&item.issueTimeStr!=undefined">
                                        <div>发布时间:</div>
                                        <div>{{item.issueTimeStr}}</div>
                                    </div>
                                    <div class="smailDiv" v-if="item.deadTimeStr!=''&&item.deadTimeStr!=undefined">
                                        <div v-if="item.dataSubType==101">预计采购时间:</div>
                                        <div v-else>投标截止时间:</div>
                                        <div>{{item.deadTimeStr}}</div>
                                    </div>
                                </div> 
                            </div>

                        </div>
                    </div>
                </div> 
              
            </ul>  
           </div>
         </div>
       </div>

  
       <!-- 分页 -->
       <div class="pagination">
        <el-pagination
            @current-change="handleCurrentChange"
            background
            :current-page="currentPage"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
       </div>
      </div>

   

  <!-- 弹框添加订阅 -->
       <div class="addDy">
            <el-dialog
            title="我的订阅"
            :visible.sync="dialogVisible"   @close="closeDy"
            width="65%">
            <!-- <div class="dy-form-item flex">
                <div class="gjTitle xtWidth">
                    <img style="width:4px;height: 4px;margin-bottom: 7px;" src="../assets/images/redIcon.png" />
                    订阅名称:</div>
                <el-input
                    type="text"
                    maxlength="50"
                    placeholder="请输入订阅名称"
                    v-model="subscribeName">
                </el-input>
            </div> -->
            <div class="dy-form-item flex">
                <div class="gjTitle xtWidth">
                    <!-- <img style="width:4px;height: 4px;margin-bottom: 7px;" src="../assets/images/redIcon.png" /> -->
                    关键词:</div>
                    
                    <div class="flex timeCode">
                        <div class="flex" v-if="keyWordsAs!=''&&keyWordsAs.length>0" style="flex-wrap: wrap;">
                            <div class="tc_tag-input_roots" v-for="(item,index) in keyWordsAs" :key="index">
                                <span>{{item}}</span>
                                <img src="../assets/images/guanbi1.png" @click="deletess(item,index)" style="width: 11px;height: 12px;margin-left: 4px;margin-top: 3px;" />
                            </div>
                        </div>
                        <div class="flex" v-if="keyWordsAs.length<10">
                            <div class="ju-tinput">
                                <input type="text" placeholder="请输入关键词" class="inputClass" v-model="addContents">
                                <img v-if="addContents!=''&&addContents!=null" src="../assets/images/guanbi.png" class="closeImg"  @click="dels" />
                                <div class="searchBtn" @click="addClicks">添加</div>
                            </div>
                        </div>
                    </div>

                <!-- <el-input
                    type="text"
                    maxlength="50"
                    placeholder="请输入订阅关键词(多个关键词请用英文,隔开)"
                    v-model="keyWords">
                </el-input> -->
            </div>
             
            <div style="margin-bottom:15px"> 
                    <!-- 选择地区--省 -->
                    <div class="flex chooseArea" style="align-items: flex-start;">
                        <div class="gjTitle xtWidth">    
                            <!-- <img style="width:4px;height: 4px;margin-bottom: 7px;" src="../assets/images/redIcon.png" /> -->
                            选择地区:
                        </div>
                            <!-- 省 -->
                            <div class="flex areaCode " :style="{ 'max-height': statusA ? textHeight : '' }"
                                                :class="{ statusText: statusA }" ref="descs">
                                    <div v-for="(item, index) in proviceList" :key="index" style="display: inline-block;">
                                        <div  @click="dyproviceChang(index,item.areaCode,item.areaName)"   class="item" :class="{ selected: item.areaCode == dyprovinceCode}">
                                            {{item.areaName}}
                                        </div>
                                    </div>
                            </div>
                    
                            <div
                                v-if="idShowTextA"
                                @click="showStausA"
                                :class="{ openSpan: statusA }"
                                class="openClose">{{ statusA ? "展开" : "收起" }}
                                <img v-if="statusA==true" class="jtImgsxsb" src="../assets/images/xiajt.png"  />
                                <img v-else class="jtImgsxsb" src="../assets/images/shangjt1.png"  />
                            </div> 
                        </div>
                        <!-- 市 -->
                        <div class="flex chooseArea" style="align-items: flex-start;margin-top:8px" v-if="dyisCity"> 
                        <div style="line-height: 30px;width: 88px;" >城市：</div>
                            <div class="flex areaCode" style=" margin-left:0;">
                                <div v-for="(item, index) in cityList" :key="item.id"  >
                                    <div @click="dycityChang(index,item.areaCode,item.areaName)" class="item"  :class="{ selected: index == dynumberTwo}">{{item.areaName}}</div>
                                </div>
                            </div> 
                        </div>
                        <!-- 县 -->
                        <div class="flex chooseArea" style="align-items: flex-start;margin-top:8px" v-if="dyisCountry"> 
                            <div style="line-height: 30px;width: 88px;" >区县：</div>
                            <div class="flex areaCode" style=" margin-left:0;">
                                <div v-for="(item, index) in areaList" :key="item.id"  >
                                    <div @click="dyareaChang(index,item.areaCode,item.areaName)" class="item"  :class="{ selected: index == dynumberArea}">{{item.areaName}}</div>
                                </div>
                            </div> 
                        </div>     
            </div>

            <div class="flex" style="margin-bottom:15px">
                <div class="flex chooseArea" style="align-items: flex-start;"  >
                    <div class="gjTitle xtWidth">
                        信息类型:</div>
                   <div>
                    <div class="flex timeCode" >
                        <div v-for="(item, index) in messageList" :key="index"  >
                            <div @click="dymessageChang(index,item.areaCode,item.areaName)" class="item"  :class="{ selected: index == dynumber4}">{{item.areaName}}</div>
                        </div>
                    </div>
                    <div class="flex timeCode" style="margin-top: 8px;" v-if="dynumber4!=''">
                        <div v-for="(item, index) in zbList" :key="index"  >
                            <div @click="dymessageChangs(index,item.dictionaryOptionId,item.optionName)" class="item"  :class="{ selected: index == dynumber4s}">
                                {{item.optionName}}</div>
                        </div>
                     </div> 

                   </div>
                </div>
            </div>
            <!-- <div class="dy-form-item flex">
                <div class="gjTitle xtWidth">
                    推送频率:</div>
                <el-tabs v-model="activeNamePl" type="card" @tab-click="handleClick" class="tsPl">
                    <el-tab-pane label="每日推送" name="1"></el-tab-pane>
                </el-tabs>
            </div> -->

            <!-- <div class="dy-form-item flex">
                <div class="gjTitle xtWidth">时间设置:</div>
                    <el-select v-model="timeS" placeholder="请选择推送时间" @change="chooseTime($event)" style="width:76%">
                        <el-option
                        v-for="item in optionTime"
                        :key="item.value"
                        :label="item.label"
                        :value="item.label">
                        </el-option>
                    </el-select>
            </div> -->
            
            <!-- <div class="gjTitles">订阅条件</div> -->
            <!-- <div class="ant-row">
                <div class="gjTitles flex" style="align-items: flex-start;">
                    <div class="xtWidth">系统通知：</div>
                    <div>
                      <div>
                        <el-radio v-model="notice1" label="1">是</el-radio>
                        <el-radio v-model="notice1" label="0">否</el-radio>
                      </div>
                      <div class="tjHui">可在网站、APP个人中心-消息通知下查看</div>
                    </div>
                </div>
            </div> -->
            <div class="ant-row">
                  <div class="gjTitles flex" style="align-items: flex-start;">
                    <div class="xtWidth">微信通知：</div>
                    <div>
                      <div>
                        <el-radio v-model="notice2" label="1">是</el-radio>
                        <el-radio v-model="notice2" label="0">否</el-radio>
                      </div>
 
                      <div class="bangding" v-if="wechatStatus==1">订阅消息将推送至 绑定微信 
                        <!-- <span @click="jiebang('1')" style="color: #409EFF;cursor: pointer;" >解绑</span> -->
                      </div> 
 
                       <div class=" bangding"  v-else>
                            <div>请先
                            <span style="color: #409EFF;cursor: pointer;" @mouseover="mouseOver" @mouseleave="mouseLeave">绑定微信</span>
                            绑定后订阅信息将推送至微信
                            </div>
                            <div ref="wechatEwm" class="ewmImg" style="display:none">
                                <img src="../assets/images/gzhEwm.jpg" style="width:100px;height:100px" />
                            </div>
                       </div>
                    </div>
                  </div> 
            </div>

            <div class="ant-row">
                <div class="gjTitles flex" style="align-items: flex-start;">
                    <div class="xtWidth">邮件通知：</div>
                    <div style="width: 75%;">
                      <div>
                        <el-radio v-model="notice3" label="1">是</el-radio>
                        <el-radio v-model="notice3" label="0">否</el-radio>
                      </div>
                      <div class="bangding" v-if="emailA!=null&&emailA.length>0">订阅消息将推送至绑定邮箱
                         <span @click="jiebang('2')" style="color: #409EFF;cursor: pointer;">解绑</span>
                      </div>
                      <div v-else style="margin-top:10px">
                         <div v-if="notice3!=0">
                            <el-input
                            type="text"
                            maxlength="50" 
                            placeholder="请输入邮箱号"
                            v-model="emailCon">
                        </el-input>
                        </div>
                      </div>
                    </div>
                </div>
            </div>

            <!-- :disabled="keyWords==''&&keyWords.length<=0" -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDy()" style="margin-right: 20px;">取 消</el-button>
                <el-button type="primary" @click="addDys()" >确 定</el-button>
            </span>
            </el-dialog>
        </div>



         <!-- 弹框二维码支付 -->
      <div class="tkPay">
        <el-dialog
        :visible.sync="ewmVisible"
        width="760px"
        :before-close="handleClose">
        <div class="nav" v-if="isPay==true">
            <div class="ktText">开通产品套餐，查看更多数据</div>
            <div class="nav-top">
               <div v-if="isChao">
                    <el-tabs v-model="activeNameT" @tab-click="handleClickT">
                        <el-tab-pane name="1">
                            <div slot="label">
                                <div class="bzImgs"><img style="margin-right:9px;width: 49px;height: 18px;" src="../assets/images/hot.png" />单条购买</div>
                                <div class="bqqStext">标圈圈，找更多商机！</div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane name="2" >
                            <div slot="label">
                                <div class="bzImgs"><img style="margin-right:9px;width: 38px;height: 18px;" src="../assets/images/vip.png" />标准版</div>
                                <div class="bqqStext">标圈圈，找更多商机！</div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>


                    <div class="firstContent" v-if="activeNameT==2">
                        <div style="margin-bottom: 16px;font-size: 18px;">选择合适套餐，查看更多详情！</div>
                        <div class="nav-topA flex aligenCenter">
                            <div class="tao-half-row maright" @click="taocan('1')">
                                <div :class="vipFlag==1?'danTk':'danTk2'">
                                    <div class="taoTtile">基准价</div>
                                    <div style="margin: 10px 0 0 0;">
                                        <span style="color:#FF0000">¥<span style="font-size:30px;">599</span></span>
                                        <span>/3个月</span>
                                    </div>
                                    <div class="danTexts flex jutCenter">
                                        <div class="flex jutCenter danH" >每天仅需6.6元</div>
                                    </div>
                                </div>
                                <img v-if="vipFlag==1" class="tkgouImg" src="../assets/images/gou.png" />
                            </div>
                            <div class="tao-half-row maright" @click="taocan('2')">
                                <div  :class="vipFlag==2?'danTk':'danTk2'">
                                    <div class="taoTtile">用户优惠</div>
                                    <div style="margin: 10px 0 0 0;">
                                        <span style="color:#FF0000">¥<span style="font-size:30px;">999</span></span>
                                        <span>/6个月</span>
                                    </div>
                                    <div class="danTexts flex jutCenter">
                                        <div class="saleprice">原价¥1198</div>
                                    </div>
                                </div>
                                <div class="dwZhek">83折</div>
                                <img v-if="vipFlag==2" class="tkgouImg" src="../assets/images/gou.png" />
                            </div>
                            <div class="tao-half-row" @click="taocan('3')"> 
                                <div :class="vipFlag==3?'danTk':'danTk2'">
                                    <div class="taoTtile">超值推荐</div>
                                    <div style="margin: 10px 0 0 0;">
                                        <span style="color:#FF0000">¥<span style="font-size:30px;">1599</span></span>
                                        <span>/12个月</span>
                                    </div>
                                    <div class="danTexts flex jutCenter">
                                        <div class="saleprice">原价¥2396</div>
                                    </div>
                                </div>
                                <div class="dwZhek">67折</div>
                                <img v-if="vipFlag==3" class="tkgouImg" src="../assets/images/gou.png" />
                            </div> 
                        </div>

                        <div class="nav-middle">
                            <div class="flex nav-middleCon">
                                <div class="dkq" v-if="couponUserId!=''">{{quanList[0].couponInfo.couponAmount}}抵扣券</div>
                                <div class="sjPay">实付金额：<span style="color:#FF0000;margin-bottom: -6px;">￥<span style="font-size: 30px;">{{totalPrice}}</span></span></div>
                            </div>
                            <div class="flex jutCenter"><div class="sureBtn" @click="payfor">立即开通</div></div>
                        </div>
                        <div style="font-size: 12px;">注：试运营期VIP可通过<span style="cursor: pointer;color:#0078FF" @click="Sign">点击上方领取VIP会员</span>获得，我们提供多种活动给大家赠送VIP体验机会，
                            支付购买后<span><router-link to="/userCenter?actives=8" style="color:#0078FF">发票</router-link></span>可在个人中心申请。</div>
                    </div>


                   <div class="firstContent" v-else>
                        <div class="tao-half-row maright">
                            <div class="danTk">
                                <div class="taoTtile">单条标讯</div>
                                <div style="margin: 10px 0 0 0;">
                                    <span style="color:#FF0000">¥<span style="font-size:30px;">0.5</span>元</span>
                                    <span>/条</span>
                                </div>
                                <div class="danTexts flex jutCenter">
                                    <div class="flex jutCenter danH" >永久查看</div>
                                </div>
                            </div>
                            <img class="tkgouImg" src="../assets/images/gou.png" />
                        </div>

                        <div class="nav-middle">
                            <div class="flex jutCenter"><div class="sureBtn" @click="payfor" style="margin-top: 10px;">立即开通</div></div>
                        </div>
                        <div style="font-size: 12px;">注：试运营期VIP可通过<span style="cursor: pointer;color:#0078FF" @click="Sign">点击上方领取VIP会员</span>获得，我们提供多种活动给大家赠送VIP体验机会，
                            支付购买后<span><router-link to="/userCenter?actives=8" style="color:#0078FF">发票</router-link></span>可在个人中心申请。</div>
                   </div>
               </div>
            </div>

            <div  class="nav-tabel">
                <div class="footsCon">
                    <div class="flex aligenCenter jutCenter bqqText1">
                        <img class="iconZ" src="../assets/images/vipJx.png" />
                        <div style="margin:0 5px;">标准版会员特权</div>
                        <img class="iconZ" src="../assets/images/vipJx.png" />
                    </div>	
                    <div class="bqqText2">标圈圈，找更多商机！</div>
                    <div class="flex" style="flex-wrap: wrap;align-items: flex-start;">
                        <div class="vipHalfs" v-for="(item,index) in bqqList" :key="index">
                            <img class="vipIcons" :src="item.url" />
                            <div class="vipIconText">{{item.text}}</div>
                        </div>
                    </div>
                </div>              
            </div>
            
        </div>

        <div v-else class="navK">
            <div class="ktText">开通产品套餐，查看更多数据</div>
            <div class="paySuccess" v-if="paySuccess">
                <el-alert :title="messageTitle" type="success"  center show-icon :closable="false"></el-alert>
            </div>
            <div class="fYcolor">
                <div class="cpLeft">
                    <img :src="ewmImg" class="ewmImg" />
                    <div class="flex jutCenter">
                        <img src="../assets/images/sao.png" style="width:17px;height:17px;margin-right: 5px;" />
                        <div style="color:#999999">使用<span style="color:#1E88FF">微信</span>扫码支付</div>
                    </div>
                </div>
                <div class="cpRight">
                    <div>开通账号：{{phoneNo}}</div>
                    <div>
                        <div>开通套餐：{{orderPay.name}}</div>
                        <div>到期时间：{{orderPay.days}}</div>
                        <div v-if="orderPay.days=='永久'">实付金额：<span style="color:#FF0000">￥<span style="font-size:30px">{{orderPay.salePrice}}</span>元/条</span></div>
                        <div v-else>实付金额：<span style="color:#FF0000">￥<span style="font-size:30px">{{orderPay.salePrice}}</span>元/年</span></div>
                        <div style="color: #999999;margin-top: 6px;">发票获取：完成支付后可在【个人中心-我的发票】中申请</div>
                    </div>           
                </div>
            </div>
        </div>
        </el-dialog>
      </div>
      
 
      <common-foot></common-foot>

    <common-active :showTk="beiji" :showActivity="flagActivity" @change="amountChanges($event)"></common-active>

    </div>
</template>

<script>
   import CommonHeader from '@/components/CommonHeader.vue';
   import CommonFoot from '@/components/CommonFoot.vue';
   import IndexOne from '@/components/IndexOne.vue';
   import CommonActive from '@/components/CommonActive.vue';
    export default {
      components:{
       CommonHeader,
       IndexOne,
       CommonFoot,
       CommonActive
       }, 
        data() {
            return {
                activeNameT:'1',
                activeNamePl:'1',
                activeName:'1',
                activeNames:'1',
                showAll:true,
                isShow:true,
                currentPage: 1,
                pageSize:10,
                total:0,
                pxvalue:'',
                pxIndex:'1',
                intervalId:null,
                animate:false, //控制过渡动画是否开启


                tabsArr:[],
                clickTabs:'1',
                gysList:[ ],
                listAll:[],
                newArr:[]  ,


                numberOne: 0,
                numberTwo:'',
                numberArea:'',
                numberThree:'',
                number4:0,
                qyClass:0,
                provinceCode:'',
                cityCode:'',
                areaCode:'',
                proviceList:[],
                cityList:[],
                isCity:false,
                areaList:[],
                isCountry:false,
                timeCode:'',
                timeList:[],
                valueYear: '',
                messageList:[
                    {areaCode:'',areaName:'全部'},
                    {areaCode:'1',areaName:'招标公告'},
                    {areaCode:'2',areaName:'中标结果'},
                    {areaCode:'3',areaName:'拟建项目'},
                ],
                messageCode:'',
                sxList1:[],
                sxvalue1: '',
                sxList2: [ ],
                sxvalue2: '',
                sxList3: [],
                sxvalue3: '',
                sxList4: [ ],
                sxvalue4: '',
                sxList5: [ ],
                sxvalue5: '',
                sxList6: [],
                sxvalue6: '',
                sxList7: [ ],
                sxvalue7: '',
                sxList8: [ ],
                sxvalue8: '',

                valueYearzdy:'',
                startTime:'',
                endTime:'',
                yearArr:[],
                pickerOptions0: {
                    disabledDate(time) {
                        return time.getTime() > Date.now() - 8.64e6;//如果没有后面的-8.64e6就是不可以选择今天的
                     }
                }, 
                aa:'',
              
                zbList:[],
                zbvalue:'', //招标类型
                zbName:'',
                zbList1:[],  //中标类型
                zbvalue1:'', 
                zbName1:'',
                zbList2:[],  //审批阶段
                zbvalue2:'',
                zbName2:'',
             
                zdyTime:'',
                pxLabel:'',
               
                form: {
                    price1:'',
                    price2:'',
                },

                sxvalue1s: '',
                sxvalue2s: '',
                sxvalue3s: '',
                sxvalue4s: '',
                sxvalue5s: '',
                sxvalue6s: '',
                sxvalue7s: '',
                sxvalue8s: '',

                //签到
                beiji:false,
                textHeight: null,
                status: false,
                idShowText: false,
                statusA: false,
                idShowTextA: false,

                phoneNo:'',
                nowTime:'',

                goodsId:'7163018607638296736',   //vip套餐id
                objectIds:'', //点击详情时的Id
                objectIds2:'', //点击详情时的公司Id
                timerT: null, // 定时器
                timerTs: null, // 定时器
                orderPay:[],
                orderPays:[],
                ewmImg:'',
                paySuccess:false,
                totalTime:3,
                messageTitle:'',
                tempPage:'',

                addContent:'',
                addContents:'',
                fwList:[
                    {id:'',name:'全文'},{id:2,name:'标题'},{id:3,name:'附件'},{id:4,name:'项目编号'},
                    {id:5,name:'招采单位'},{id:6,name:'代理单位'},{id:7,name:'中标单位'}
                ],
                fwName:'',
                fwCode:'',
                zbList2:[
                    {id:1,dictionaryOptionId:'',optionName:'全部'},{id:2,dictionaryOptionId:'22',optionName:'未审批'},
                    {id:3,dictionaryOptionId:'33',optionName:'审批中'},{id:4,dictionaryOptionId:'44',optionName:'审批通过'},
                    {id:5,dictionaryOptionId:'55',optionName:'审批未通过'},{id:6,dictionaryOptionId:'66',optionName:'撤销'}
                ],
               
                number66:0,
                spCode:'',
                cityName:'',
                cityName1:'',
                cityName2:'',
                dycityName:'',
                dycityName1:'',
                dycityName2:'',
                xxName:'',
                xxName2:'',

                number1:'1', //搜索范围
                dialogVisible:false,
                keyWordsA:[],
                keyWordsAs:[],
                keyWords:'',  //关键词
                optionTime: [{
                    value: '0',
                    label: '0:00'
                    }, {
                    value: '1',
                    label: '1:00'
                    }, {
                    value: '2',
                    label: '2:00'
                    }, {
                    value: '3',
                    label: '3:00'
                    }, {
                    value: '4',
                    label: '4:00'
                   }, {
                    value: '5',
                    label: '5:00'
                    }, {
                    value: '6',
                    label: '6:00'
                    }, {
                    value: '7',
                    label: '7:00'
                    }, {
                    value: '8',
                    label: '8:00'
                   }, {
                    value: '9',
                    label: '9:00'
                   }, {
                    value: '10',
                    label: '10:00'
                    }, {
                    value: '11',
                    label: '11:00'
                    }, {
                    value: '12',
                    label: '12:00'
                    },
                    {
                    value: '13',
                    label: '13:00'
                    }, {
                    value: '14',
                    label: '14:00'
                   }, {
                    value: '15',
                    label: '15:00'
                    }, {
                    value: '16',
                    label: '16:00'
                    }, {
                    value: '17',
                    label: '17:00'
                    }, {
                    value: '18',
                    label: '18:00'
                   }, {
                    value: '19',
                    label: '19:00'
                   }, {
                    value: '20',
                    label: '20:00'
                    }, {
                    value: '21',
                    label: '21:00'
                    }, {
                    value: '22',
                    label: '22:00'
                    },{
                    value: '23',
                    label: '23:00'
                    }
                ],
                timeS:'9:00',
                notice1:'0',
                notice2:'1',
                notice3:'1',
                //notice4:'0',
                isFlag:false,
                countryCode:'',

                subscribeId:'',  //订阅id
                subscribeName:'',  //订阅名称
                onFlag:'',
                isFlags:'',

                vipAuth:'',
                ewmVisible:false,
                isPay:true,
                isChao:true,
                level:'',
                emailA:'',
                emailCon:'',
                userInfos:[],
                wechatStatus:'',

                messageLists:[],
                number4s:0,
                messageName:'',
                messageCodesA:'',

                bqqList:[
					{id:1,text:'招标公告',url:'https://www.biaox.cn/webcrawler/wechat/images/zbvipIcon1.png'},
					{id:2,text:'中标公告',url:'https://www.biaox.cn/webcrawler/wechat/images/zbvipIcon2.png'},
					{id:3,text:'拟建项目',url:'https://www.biaox.cn/webcrawler/wechat/images/zbvipIcon3.png'},
					{id:4,text:'招标预告',url:'https://www.biaox.cn/webcrawler/wechat/images/zbvipIcon4.png'},
					{id:5,text:'精选项目',url:'https://www.biaox.cn/webcrawler/wechat/images/zbvipIcon5.png'},
					{id:6,text:'审批项目',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon6.png'},
					{id:7,text:'企业直采',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon7.png'},
					{id:8,text:'政府采购',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon8.png'},
					{id:9,text:'询价订单',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon9.png'},
					{id:10,text:'谈判采购',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon10.png'},
					{id:11,text:'项目分包',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon11.png'},
					{id:12,text:'采购意向',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon12.png'},
					{id:13,text:'招标订阅',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon13.png'},
					{id:14,text:'中标订阅',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon14.png'},
					{id:15,text:'拟建订阅',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon15.png'},
					{id:16,text:'邮件提醒',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon16.png'},
					{id:17,text:'系统提醒',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon17.png'},
					{id:18,text:'微信提醒',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon18.png'},
					{id:19,text:'标讯导出',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon19.png'},
					{id:20,text:'附件查看',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon20.png'},
					
					{id:21,text:'企业监控',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon21.png'},
					{id:22,text:'企业分析',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon22.png'},
					{id:23,text:'业务渠道拓展',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon23.png'},
					{id:24,text:'商机分析预测',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon24.png'},
					{id:25,text:'AI大数据报告',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon25.png'},
					{id:26,text:'项目分析报价分析',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon26.png'},
				],
                vipFlag:'3',
                quanList:[],
				couponUserId:'',
                activeNameT:'1',
                totalPrice:'1599',
                totalA:0,

                dynumberOne: 0,
                dynumberTwo:'',
                dynumberArea:'',
                dynumberThree:'',
                dyprovinceCode:'',
                dycityCode:'',
                dyareaCode:'',
                dyisCity:false,
                dyisCountry:false,

                dynumber4:0,
                dynumber4s:0,
                dymessageName:'',
                dymessageCodesA:'',

                arrPro:[],
				arrPao:[],
                flagActivity:'',
            }
        },
        // computed:{
        //     flag:function(){
        //         if(this.showAll == false){
        //             this.isShow=true
        //             return '收起'
        //         }
        //         else{
        //             this.isShow=false
        //             return '展开'
        //         }
        //     },
        // },
        mounted() {
            let that=this
             //获取当前年份的前4年
             let now   = new Date();
            let yearn  = now.getFullYear();
            let yearArrs=[]
            yearArrs.push(yearn-4,yearn-3,yearn-2,yearn-1)
            that.yearArr=[
                {label:1,value:yearArrs[0]},
                {label:2,value:yearArrs[1]},
                {label:3,value:yearArrs[2]},
                {label:4,value:yearArrs[3]},
            ] 
            　　let yy = new Date().getFullYear();
            　　let mm = new Date().getMonth()+1;
            　　let dd = new Date().getDate();
            　　let hh = new Date().getHours();
            　　let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
                that.nowTime = yy+'-'+mm+'-'+dd+' '+hh+':'+mf;

            that.sxList('pub_date')  //发布时间
            that.sxList('industry_category')  //行业分类
            that.sxList('bidding_procurement_type')  //招采类型
            that.sxList('bidding_unit')   //招采单位
            that.sxList('budget_amount')  //预算金额
            that.sxList('agency')   //代理单位
            that.sxList('bid_winning_amount')    //中标金额
            that.sxList('contact_information')   //联系方式
            that.sxList('en_contact_information') //联系方式
            that.sxList('enclosure')   //附件
            that.sxList('bidding_type')     //招标类型
            that.sxList('bid_winning_type')  //中标类型
            that.sxList('approval_stage')   //审批阶段
            
            that.provice('')
            
            if(that.$route.query.subscribeId!=''&&that.$route.query.subscribeId!=null&&that.$route.query.subscribeId!=undefined){
                that.subscribeId=that.$route.query.subscribeId
            }

            let phoneNos=JSON.parse(localStorage.getItem('phoneNo'))
            that.phoneNo=phoneNos
           
            //展开收起
            that.$nextTick(() => {
                setTimeout(() => {
                    that.calculateText()
                    that.showStaus()
                }, 500)
            })
          

            //显示订阅
            that.showDyall()
            that.querInfo()
            that.queryInfos()

            // setInterval(()=>{
            //   this.handleClicks()
            // },1000)
          // this.$refs.clickOpen.trigger('click')
        },
     
        methods: {
            // handleClicks(){
            //     this.$refs.clickOpen.click()
            // },
            Sign(){
                let that=this
                that.ewmVisible=false
                that.isChao=false
                setTimeout(()=>{
                    that.amountChanges('true')
                },1000)
            },
             // 移入
           mouseOver() {
             this.$refs.wechatEwm.style='display:show'
            },
            // 移出
            mouseLeave() {
              this.$refs.wechatEwm.style='display:none'
            },
            //解绑
            jiebang(flag){
              let that=this
              if(flag==1){
                 that.$axios.post('/webInterface/unbidWx')
                    .then(function (res) {
                        // console.log(res)
                     if(res.data.resultcode==1){
                        that.wechatStatus=0
                        that.queryInfos()
                         that.timerTs = window.setInterval(() => {
                            that.queryInfos()
                        }, 3000);

                      }
                    })
                    .catch(function (err) {
                        console.log(err);
                    })
              }else{
                   //解绑邮箱
                    that.$axios.post('/webInterface/upUserInfo?email=')
                    .then(function (res) {
                     if(res.data.resultcode==1){
                        that.emailCon=''
                        that.emailA=''
                        that.notice3=1
                        that.queryInfos()
                      }
                    })
                    .catch(function (err) {
                    })
              }
            },
            queryInfos(){
                var that=this
                that.$axios.post('/webInterface/queryUserInfo')
                    .then(function (res) {
                    // console.log(res)
                     if(res.data.resultcode==1){
                        that.userInfos=res.data.userInfo
                        that.wechatStatus=res.data.status
                        if(that.userInfos.email!=''&&that.userInfos.email!=undefined){
                            that.emailA=that.userInfos.email
                        }
                       
                        if(that.wechatStatus==1){ 
                            clearInterval(that.timerTs)
                        }
                    }
                    })
                    .catch(function (err) {
                        console.log(err);
                    })
            },
            querInfo(){
              var that=this
              that.$axios.post('/webInterface/queryUserOther')
                .then(function (res) {
                    //console.log(res)
                    if(res.data.resultcode==1){ 
                      that.level=res.data.userOther
                    }
                })
                .catch(function (err) {
                    console.log(err);
                })
            },
            showDyall(){
                let that=this
                //查询全部订阅
                that.$axios.post('/webInterface/queryScripingSubscribeList').then(res => {
                        console.log(res) 
                        if(res.data.resultcode==1){
                            that.tabsArr=res.data.scripingSubscribeList
                            that.isFlag=true
                            if(that.tabsArr.length>0){
                                that.isFlags=true
                                that.tabsArr[0].subscribeName='我的订阅'
                            }else{
                                that.isFlags=false
                                that.tabsArr[0].subscribeName=''
                            }
                          
                            if(that.subscribeId==''){
                                that.activeName=that.tabsArr[0].subscribeId
                                that.keyWords=that.tabsArr[0].keywords
                                that.subscribeName=that.tabsArr[0].subscribeName
                                if(that.tabsArr[0].sysNotice!=undefined){
                                    that.notice1=that.tabsArr[0].sysNotice
                                }
                                if(that.tabsArr[0].wechatNotice!=undefined){
                                    that.notice2=that.tabsArr[0].wechatNotice
                                }
                                if(that.tabsArr[0].emailNotice!=undefined){
                                    that.notice3=that.tabsArr[0].emailNotice
                                }
                            }else{
                                that.activeName=that.subscribeId 
                                for(var i=0;i<that.tabsArr.length;i++){
                                    if(that.tabsArr[i].subscribeId==that.subscribeId){
                                        that.keyWords=that.tabsArr[i].keywords
                                        that.subscribeName=that.tabsArr[i].subscribeName
                                        if(that.tabsArr[i].sysNotice!=undefined){
                                            that.notice1=that.tabsArr[i].sysNotice
                                        }
                                        if(that.tabsArr[i].wechatNotice!=undefined){
                                            that.notice2=that.tabsArr[i].wechatNotice
                                        }
                                        if(that.tabsArr[i].emailNotice!=undefined){
                                            that.notice3=that.tabsArr[i].emailNotice
                                        }
                                    }
                                 }
                            }
                            that.showOneD()
                        }else if(res.data.resultcode=='-9999'){
                            setTimeout(()=>{  //设置延迟执行
                                that.$router.push({
                                    path: '/login',
                                    query:{
                                        isFlagA:1 
                                    }
                                });
                            },600);
                        }
                    }).catch(err => {
                        //console.log('请求失败')
                    }) 
            },
            showOneD(){
                let that=this
                that.$axios.get('/webInterface/opScripingSubscribe', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            subscribeId:that.activeName,
                            opFlag:4
                        }
                    })
                    .then(function (res) {
                        //console.log(res);
                        if(res.data.resultcode==1){ 
                            // that.keyWords=res.data.scripingSubscribe.keywords
                            // that.keyWordsA=that.keyWords.split(',') 
                            // that.notice1=res.data.scripingSubscribe.sysNotice
                            // that.notice2=res.data.scripingSubscribe.wechatNotice
                            // that.notice3=res.data.scripingSubscribe.emailNotice
                            // that.timeS=res.data.scripingSubscribe.pushTimeStr

                            let dzAddress=[]
                            if(res.data.scripingSubscribe.qryAreaCode!=undefined){
                                dzAddress=res.data.scripingSubscribe.qryAreaCode.split(',')
                                that.provinceCode=dzAddress[0]  //省
                                for(var i=0;i<that.proviceList.length;i++){
                                    if(that.proviceList[i].areaCode==that.provinceCode){
                                        that.cityName=that.proviceList[i].areaName
                                        that.proviceChang(i,that.proviceList[i].areaCode,that.proviceList[i].areaName,'1')
                                    }
                                }
                            }else{
                                that.cityName=''
                                that.cityName1=''
                                that.cityName2=''
                            }
                            
                            setTimeout(()=>{
                                if(dzAddress[1]!=undefined&&dzAddress[1]!=''){
                                    that.cityCode=dzAddress[1]  //市
                                    for(var i=0;i<that.cityList.length;i++){
                                        if(that.cityList[i].areaCode==that.cityCode){
                                            that.cityName1=that.cityList[i].areaName
                                            that.numberTwo=i
                                            that.cityChang(i,that.cityList[i].areaCode,that.cityList[i].areaName,'1')
                                            
                                        }
                                    }
                                }
                            },400)

                            setTimeout(()=>{
                                if(dzAddress[2]!=undefined){
                                    that.areaCode=dzAddress[2]  //县
                                    for(var i=0;i<that.areaList.length;i++){
                                        if(that.areaList[i].areaCode==that.areaCode){
                                            that.cityName2=that.areaList[i].areaName
                                            that.numberArea=i
                                            that.areaChang(i,that.areaList[i].areaCode,that.areaList[i].areaName,'1')
                                        }
                                    }
                                }
                            },700)
                          
                            if(res.data.scripingSubscribe.qryDataType!=undefined){
                                that.messageCode=res.data.scripingSubscribe.qryDataType  //信息类型
                                for(var i=0;i<that.messageList.length;i++){
                                    if(that.messageList[i].areaCode==that.messageCode){
                                        that.xxName=that.messageList[i].areaName
                                       that.messageChang(i,that.messageList[i].areaCode,that.messageList[i].areaName,'1')
                                        if(res.data.scripingSubscribe.qryDataSubType==undefined){
                                            that.number4=''
                                            that.xxName2=''
                                        }
                                        } 
                                }
                            }else{
                                that.xxName=''
                            }

                            setTimeout(()=>{
                                if(res.data.scripingSubscribe.qryDataSubType!=undefined){
                                    that.messageCodesA=res.data.scripingSubscribe.qryDataSubType  //信息类型
                                    for(var i=0;i<that.zbList.length;i++){
                                        if(that.zbList[i].dictionaryOptionId==that.messageCodesA){
                                            that.xxName2=that.zbList[i].optionName
                                            that.messageChangs(i,that.zbList[i].dictionaryOptionId,that.zbList[i].optionName,'1')
                                        }
                                    }
                                }else{
                                    that.number4=''
                                    that.xxName2=''
                                }
                            },600)
                            
                            if(res.data.scripingSubscribe.qryIndustryCategory!='undefined'){   //行业类型
                                that.sxvalue1=res.data.scripingSubscribe.qryIndustryCategory
                                for(var i=0;i<that.sxList1.length;i++){
                                    if(that.sxList1[i].dictionaryOptionId==that.sxvalue1){
                                        that.sxvalue1s=that.sxList1[i].dictionaryOptionId
                                        that.sxvalue1=that.sxList1[i].optionName
                                        if(that.sxvalue1=='不限'){
                                            that.sxvalue1='行业类型'
                                        }
                                    }
                                }
                            }else{
                                that.sxvalue1='' 
                            }

                          if(res.data.scripingSubscribe.qryBiddingProcurementType!=undefined){  //招采类型
                            that.sxvalue2=res.data.scripingSubscribe.qryBiddingProcurementType
                            for(var i=0;i<that.sxList2.length;i++){
                                if(that.sxList2[i].dictionaryOptionId==that.sxvalue2){
                                    that.sxvalue2s=that.sxList2[i].dictionaryOptionId
                                    that.sxvalue2=that.sxList2[i].optionName
                                    if(that.sxvalue2=='不限'){
                                        that.sxvalue2='招采类型'
                                    }
                                }
                            }
                          }
                          if(res.data.scripingSubscribe.qryBudgetAmount!=undefined){  //预算金额
                            that.sxvalue3=res.data.scripingSubscribe.qryBudgetAmount
                            for(var i=0;i<that.sxList4.length;i++){
                                if(that.sxList4[i].dictionaryOptionId==that.sxvalue3){
                                    that.sxvalue3s=that.sxList4[i].dictionaryOptionId
                                    that.sxvalue3=that.sxList4[i].optionName
                                    if(that.sxvalue3=='不限'){
                                        that.sxvalue3='预算金额'
                                    }
                                }
                            }
                          }
                            if(res.data.scripingSubscribe.qryBidWinningAmount!=undefined){  //中标金额
                                that.sxvalue4=res.data.scripingSubscribe.qryBidWinningAmount
                                for(var i=0;i<that.sxList6.length;i++){
                                    if(that.sxList6[i].dictionaryOptionId==that.sxvalue4){
                                        that.sxvalue4s=that.sxList6[i].dictionaryOptionId
                                        that.sxvalue4=that.sxList6[i].optionName
                                        if(that.sxvalue4=='不限'){
                                            that.sxvalue4='中标金额'
                                        }
                                    }
                                }
                            }
                            // that.sxvalue5=res.data.scripingSubscribe.qryAgency
                            //  for(var i=0;i<this.sxList3.length;i++){
                            //     if(this.sxList3[i].dictionaryOptionId==this.sxvalue5){
                            //         this.sxvalue5=this.sxList3[i].optionName
                            //     }
                            // }
                            if(res.data.scripingSubscribe.qryAgency!=undefined){   //代理单位
                                that.sxvalue6=res.data.scripingSubscribe.qryAgency
                                for(var i=0;i<that.sxList5.length;i++){
                                    if(that.sxList5[i].dictionaryOptionId==that.sxvalue6){
                                        that.sxvalue6s=that.sxList5[i].dictionaryOptionId
                                        that.sxvalue6=that.sxList5[i].optionName
                                        if(that.sxvalue6=='不限'){
                                            that.sxvalue6='代理单位'
                                        }
                                    }
                                } 
                            } 

                           if(res.data.scripingSubscribe.qryContactInformation!=undefined){  //联系方式
                            that.sxvalue7=res.data.scripingSubscribe.qryContactInformation
                            for(var i=0;i<that.sxList7.length;i++){
                                if(that.sxList7[i].dictionaryOptionId==that.sxvalue7){
                                    that.sxvalue7s=that.sxList7[i].dictionaryOptionId
                                    that.sxvalue7=that.sxList7[i].optionName
                                    if(that.sxvalue7=='不限'){
                                        that.sxvalue7='联系方式'
                                    }
                                }
                            }
                           }
                           if(that.keyWords!=''){
                            that.keyWordsA=that.keyWords.split(',')
                            that.keyWordsAs=that.keyWords.split(',')
                           }else{
                            that.keyWordsA=[]
                            that.keyWordsAs=[]
                           }
                      
                           setTimeout(()=>{
                            that.showTiaojian(that.activeName,that.keyWordsA.toString(),'1')
                           },800)

                        } 
                    })
                    .catch(function (err) {
                        // console.log(err);
                    })
            },
            showTiaojian(activeName,keyWords,currentP){
                let that=this
                let addressCode=''
                if(that.cityCode==''){
                    addressCode= that.provinceCode
                }else if(that.areaCode==''){
                    addressCode=that.cityCode
                }else{
                    addressCode=that.areaCode
                }
           
                // console.log(activeName)
                // console.log(keyWords)
                // console.log(addressCode)
                // console.log(that.timeCode)
                // console.log(that.messageCode)
                // console.log(that.messageCodesA)
                // console.log(that.sxvalue1s)
                // console.log(that.sxvalue2s)
                // console.log(that.sxvalue3s)
                // console.log(that.zbvalue)
                // console.log(that.zbvalue1)
                // console.log(that.zbvalue2)
                // console.log(that.pxvalue)
                that.$axios.get('/webInterface/queryScripingpageList', {  //params参数必写 , 如果没有参数传{}也可以
                    params: {  
                        subscribeId:activeName,
                        subscribeKeywords:keyWords,
                        pageSize:that.pageSize,
                        pageNo:currentP,

                        areaCode: addressCode,  //地区
                        pubDate:that.timeCode,  //发布时间
                        dataType:that.messageCode,  //信息类型
                        dataSubType:that.messageCodesA,   //信息类型二级
                        industryCategory:that.sxvalue1s, // 行业分类  industry_category
                        biddingProcurementType:that.sxvalue2s, //招采类型  bidding_procurement_type
                        budgetAmount:that.sxvalue3s,//预算金额    budget_amount
                        bidWinningAmount:that.sxvalue4s,   //中标金额  bid_winning_amount
                        biddingUnit:that.sxvalue5s, //招采单位   bidding_unit
                        agency:that.sxvalue6s,  //代理单位
                        contactInformation:that.sxvalue7s, //联系方式  contact_information
                        enclosure:that.sxvalue8s,   //附件  enclosure
                        biddingType:that.zbvalue,//招标类型     bidding_type
                        bidWinningType:that.zbvalue1,//中标类型   bid_winning_type
                        approvalStage:that.zbvalue2,//审批阶段    approval_stage
                        putDateOrder:that.pxvalue,   //排序方式
                            
                       pubDateYear:that.valueYear,//往年多传的参数
                      
                        pubDateStart:that.startTime,   // 自定义时间传的参数
                        pubDateEnd:that.endTime,

                    }
                })
                .then(function (res) {
                   // console.log(res);
                    if(res.data.resultcode==1){
                        that.listAll = res.data.scripingpageList
                       
                        that.total=res.data.totalCount
                        if(that.total>=100000){
							that.totalA='10万+'
						}else{
                            that.totalA=res.data.totalCount
                        }

                        var arr=[]
                        var arrs=[]
                        for(var i=0;i<that.listAll.length;i++){
                            arr.push(that.listAll[i].procurementType.split(','))
                            arrs.push(that.listAll[i].industryCategory.split(','))
                        }
                        that.arrPro=arr
                        that.arrPao=arrs


                     } else if(res.data.resultcode==-1){
                        if(res.data.vipAuth=='-9999'){
                            setTimeout(()=>{  //设置延迟执行
                                that.$router.push({path: '/login'});
                            },600);
                        }else{
                            that.$message.warning(res.data.message) 
                            that.currentPage=1
                        }
                     } else{
                        that.$message.warning(res.data.message)
                        that.listAll =[]
                        that.total=0
                        that.currentPage=1
                      }
                })
                .catch(function (err) {
                    // console.log(err);
                })


              
                //招标类型  
                // this.zbvalue='8201573246259855558'
                // this.zbvalue1='8009884658366447528'
                // this.zbvalue2='44'
                
                //搜索范围
                // this.fwCode='2'
                // for(var i=0;i<this.fwList.length;i++){
                //     if(this.fwList[i].id==this.fwCode){
                //         this.fwName=this.fwList[i].name
                //     }
                // }
                //省市区
                //this.countryCode='320000,320100,320106'
                // if(this.countryCode.split(',')[0]!=undefined&&this.countryCode.split(',')[0]!=''){
                //     this.provinceCode=this.countryCode.split(',')[0]
                // }
                // if(this.countryCode.split(',')[1]!=undefined&&this.countryCode.split(',')[1]!=''){
                //     this.provice(this.provinceCode)
                //     this.cityCode=this.countryCode.split(',')[1]
                //     this.isCity=true 
                // }
                // if(this.countryCode.split(',')[2]!=undefined&&this.countryCode.split(',')[2]!=''){
                //     this.provices(this.cityCode)
                //     this.areaCode=this.countryCode.split(',')[2]
                //     this.isCountry=true
                // }
            },
            del(){
               this.addContent=''
            },
            //添加关键词
            addClick(){
               let that=this
               if(that.addContent==''){
                  that.$message.warning('关键词不能为空')
               }else{
                that.addContent=that.addContent.replace(/\s*/g,"")
				   if(that.keyWordsA.length>=10){
                      that.$message.warning('最多只能添加10个关键字')
				   }else{
					   if(that.addContent.length<2){
                        that.$message.warning('关键字至少输入2个字')
					   }else{
                        console.log(that.keyWordsA)
                        that.keyWordsA.push(that.addContent)
                         that.addContent=''
					   }
				   }
                   that.showTiaojian(that.activeName,that.keyWordsA.toString(),that.currentPage)
               }
               
            },
            //删除关键词
            deletes(item,index){
                this.keyWordsA.splice(index,1)
                 //this.keyWords=this.keyWordsA.toString()
                 this.showTiaojian(this.activeName,this.keyWordsA.toString(),this.currentPage)
            },
            dels(){
               this.addContents=''
            },
             //添加关键词
            addClicks(){
               let that=this
               if(that.addContents==''){
                  that.$message.warning('关键词不能为空')
               }else{
                 that.addContents=that.addContents.replace(/\s*/g,"")
				   if(that.keyWordsAs.length>=10){
                      that.$message.warning('最多只能添加10个关键字')
				   }else{
					   if(that.addContents.length<2){
                        that.$message.warning('关键字至少输入2个字')
					   }else{
                        that.keyWordsAs.push(that.addContents)
                        that.keyWordsAs=that.keyWordsAs.filter(item=>item)
                        that.addContents=''
					   }
				   }
               }
               
            },
            //删除关键词
            deletess(item,index){
                this.keyWordsAs.splice(index,1)
            },
            // changeExpend(id){
            //     this.proviceList = this.proviceList.map(item=>{
            //         if(item.id === id){
            //             item.expend = !item.expend;
            //         }
            //         return item;
            //     })
            // },
             amountChange(e){
              this.beiji=e
            },
            amountChanges(e){
              this.beiji=e
              this.flagActivity='1'
            },
            //删除订阅closeDy
            delDy(){
             let that=this
             that.$confirm('确定删除该条订阅吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                }).then(() => {
                    that.$axios.get('/webInterface/opScripingSubscribe', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            subscribeId:that.activeName,
                            opFlag:2
                        }
                    })
                    .then(function (res) {
                        console.log(res);
                        if(res.data.resultcode==1){
                            that.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                            that.subscribeId=''
                            that.numberOne=0
                            that.provinceCode=''
                            that.cityCode=''
                            that.areaCode=''
                            that.messageCode=''
                            that.messageCodesA=''
                            that.number4=''
                            that.number4s=''
                            that.addContents=''
                            that.keyWordsAs=[]

                            that.dynumberOne=0
                            that.dynumberTwo=''
                            that.dynumberArea=''
                            that.dynumberThree=''
                            that.dyprovinceCode=''
                            that.dycityCode=''
                            that.dyareaCode=''
                            that.dymessageCode=''
                            that.dymessageCodesA=''
                            that.dynumber4=''
                            that.dynumber4s=''
                            that.dyisCity=false
                            that.dyisCountry=false

                            that.startTime=''
                            that.endTime=''
                            that.valueYear=''
                            that.valueYearzdy=''  //自定义时间清空
                            that.zdyTime=''   

                            that.showDyall()
                        } 
                    })
                    .catch(function (err) {
                    })
                }).catch(() => {
                });

            },
            addDytk(flag){
                let that=this
                 // console.log(that.wechatStatus)  //是否绑定微信 1已绑定 0未绑定
                  if(that.wechatStatus==0){   //未绑定实时刷新是否已绑定
                    that.timerTs = window.setInterval(() => {
                        that.queryInfos()
                    }, 3000);
                  }

                if(flag==1){
                    that.dialogVisible =true
                    that.onFlag=1
                    that.$nextTick(() => {
                        setTimeout(() => {
                            that.calculateTextA()
                            that.showStausA()
                        }, 500)
                    })

                    that.$axios.get('/webInterface/opScripingSubscribe', {  //params参数必写 , 如果没有参数传{}也可以
                    params: {  
                        subscribeId:that.activeName,
                        opFlag:4
                        }
                    })
                    .then(function (res) {
                        console.log(res);
                        if(res.data.resultcode==1){
                            that.subscribeName=res.data.scripingSubscribe.subscribeName
                            that.keyWords=res.data.scripingSubscribe.keywords
                            that.timeS=res.data.scripingSubscribe.pushTimeStr
                            that.notice1=res.data.scripingSubscribe.sysNotice
                            that.notice2=res.data.scripingSubscribe.wechatNotice
                            that.notice3=res.data.scripingSubscribe.emailNotice
                            
                            that.keyWordsAs=res.data.scripingSubscribe.keywords.split(",")

                            if(res.data.scripingSubscribe.qryDataType!=undefined){
                                that.dymessageCode=res.data.scripingSubscribe.qryDataType  //信息类型
                                for(var i=0;i<that.messageList.length;i++){
                                    if(that.messageList[i].areaCode==that.dymessageCode){
                                        that.dynumber4=i
                                    } 
                                }
                            }else{
                                that.dynumber4=''
                            }

                            setTimeout(()=>{
                                if(res.data.scripingSubscribe.qryDataSubType!=undefined){
                                    that.dymessageCodesA=res.data.scripingSubscribe.qryDataSubType  //信息类型
                                    for(var i=0;i<that.zbList.length;i++){
                                        if(that.zbList[i].dictionaryOptionId==that.dymessageCodesA){
                                            that.dynumber4s=i
                                        }
                                    }
                                }else{
                                    that.dynumber4s=''
                                }
                            },200)
                            let dzAddress=[]
                            if(res.data.scripingSubscribe.qryAreaCode!=undefined){
                                dzAddress=res.data.scripingSubscribe.qryAreaCode.split(',')
                                that.dyprovinceCode=dzAddress[0]
                                for(var i=0;i<that.proviceList.length;i++){
                                    if(that.proviceList[i].areaCode==that.dyprovinceCode){
                                        that.dyproviceChang(i,that.proviceList[i].areaCode,that.proviceList[i].areaName,'1')
                                    }
                                }

                            }

                            setTimeout(()=>{
                                if(dzAddress[1]!=undefined&&dzAddress[1]!=''){
                                    that.dycityCode=dzAddress[1]  
                                    that.dyisCity=true
                                    for(var i=0;i<that.cityList.length;i++){
                                        if(that.cityList[i].areaCode==that.dycityCode){
                                            that.dynumberTwo = i
                                            that.dycityChang(i,that.cityList[i].areaCode,that.cityList[i].areaName,'1')
                                        }
                                    }
                                }
                            },200)

                            setTimeout(()=>{
                                if(dzAddress[2]!=undefined){
                                    that.dyareaCode=dzAddress[2]  
                                    that.dyisCountry=true
                                    for(var i=0;i<that.areaList.length;i++){
                                        if(that.areaList[i].areaCode==that.dyareaCode){
                                            that.dynumberArea = i
                                            that.dyareaChang(i,that.areaList[i].areaCode,that.areaList[i].areaName,'1')
                                        }
                                    }
                                }else{
                                    that.dyisCountry=false
                                }
                            },500)
                        } 
                    }).catch(function (err) {
                    }) 
                }else{
                     if(that.level.userMemberRankStr=='VIP'&&that.tabsArr.length==10){
                        that.$message.warning('需要高级VIP权限')
                    }else if(that.level.userMemberRankStr=='普通用户'&&that.tabsArr.length==1){
                        that.$message.warning('需要VIP权限')
                    }else{
                        that.showDyall()
                        setTimeout(()=>{
                            if(that.tabsArr.length==0){
                                // that.dialogVisible=true
                                // that.onFlag=2
                                // that.keyWords=''
                                // that.gjname=''
                                // that.gjtext=''
                                // that.timeS='9:00'
                                // that.notice1='0'
                                // that.notice2='1'                                
                                // that.notice3='1'

                                that.dialogVisible =true
                                that.onFlag=2
                                that.keyWords=''
                                that.keyWordsAs=[]
                                that.subscribeName=''
                                that.activeNamePl='1' //推送频率
                                that.timeS='9:00'
                                that.notice1='0'
                                that.notice2='1'                                
                                that.notice3='1'
                            }
                        },500)

                        
                   }
                } 
               
                
            },
            closeDy(){
                let that=this
                that.dialogVisible = false
                that.provice(that.provinceCode)
                that.provices(that.cityCode)

                // this.numberOne = index;
                // this.provinceCode=areaCode
                // this.cityName=cityName
                // this.currentPage=1
                // this.numberTwo = '';
                // this.cityCode=''
                // this.numberArea = ''
                // this.areaCode=''
                // this.cityName1=''


                // that.dyprovinceCode=that.provinceCode
                // that.provice(that.provinceCode)

                // that.dynumberTwo=that.numberTwo
                // that.dycityCode=that.cityCode
                // that.dyareaCode=that.areaCode
                // that.dynumberArea=that.numberArea
                clearInterval(that.timerTs)
            },
            chooseTime(e){
                this.timeS=e
            },
            //新增订阅
            addDys(){
                let that=this
                let opFlag=''
                let subscribeId=''

                that.provinceCode=that.dyprovinceCode
                that.cityCode=that.dycityCode
                that.areaCode=that.dyareaCode
                that.messageCode=that.dymessageCode
                that.messageCodesA=that.dymessageCodesA
                that.cityName=that.dycityName
                that.cityName1=that.dycityName1
                that.cityName2=that.dycityName2
                
                if(that.onFlag==1){
                    opFlag=3   //修改订阅
                    subscribeId=that.activeName
                }else{
                    opFlag=1   //新增订阅
                    subscribeId=''
                }
               let regex = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
                if(that.emailA!=''&&!regex.test(that.emailA)){
                        that.$message({
                            message: '请输入正确的邮箱',
                            type: 'warning'
                        })
                        return
                }
                if(that.emailCon!=''&&!regex.test(that.emailCon)){
                        that.$message({
                            message: '请输入正确的邮箱',
                            type: 'warning'
                        });
                        return
                } 

                 if(that.notice2==1&&that.wechatStatus==0){
                    that.$message({
                        message: '未绑定微信，无法推送订阅消息',
                        type: 'warning'
                    });
                    return 
                }else if(that.notice3==1&&that.emailA==''&&that.emailCon==''){
                      that.$message({
                            message: '未绑定邮箱，无法推送订阅消息',
                            type: 'warning'
                        });
                        return 
                }else{
                     //更新邮箱
                     let content=''
                      if(that.emailCon==''){
                        content=that.emailA
                     }else{
                        content=that.emailCon
                     }
                     let addressCode=''
                    if(that.cityCode==''){
                        addressCode= that.provinceCode
                    }else if(that.areaCode==''){
                        addressCode= that.provinceCode+','+that.cityCode
                    }else{
                        addressCode=that.provinceCode+','+that.cityCode+','+that.areaCode
                    }

                    that.$axios.post('/webInterface/upUserInfo?email='+content)
                    .then(function (res) {
                        // console.log(res);
                        if(res.data.resultcode==1){
                        }
                    })
                    .catch(function (err) {
                        console.log(err);
                    })

                    //  console.log(subscribeId)
                    //  console.log(that.keyWordsAs.toString())
                    //  console.log(opFlag)
                    //  console.log(that.timeS)
                    //  console.log(addressCode)
                    //  console.log(that.messageCode)
                    //  console.log(that.messageCodesA)

                     that.$axios.get('/webInterface/opScripingSubscribe', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            subscribeId:subscribeId,
                            subscribeName:that.keyWordsAs.toString(),
                            keywords:that.keyWordsAs.toString(),
                            pushFrequency:'1', //推送频率
                            pushTime:that.timeS,
                             sysNotice:that.notice1,
                             wechatNotice:that.notice2,                                  
                             emailNotice:that.notice3, 
                            opFlag:opFlag,   //1新增 3修改 
                            
                            qryAreaCode:addressCode,              //地区
                            qryDataType:that.messageCode,         //信息类型
                            qryDataSubType:that.messageCodesA,   //信息类型二级分类                                   
                        }
                    })
                    .then(function (res) {
                       console.log(res);
                       if(res.data.resultcode==1){
                           that.dialogVisible = false
                           if(opFlag==3){
                                that.$message({
                                    message: '修改订阅成功',
                                    type: 'success'
                                });
                           }else{
                                that.$message({
                                    message: '创建订阅成功',
                                    type: 'success'
                                });
                           }
                           clearInterval(that.timerTs)
                           that.queryInfos()  //刷新用户信息
                           that.showDyall()
                        }else{
                            that.$message.warning(res.data.resultmessage)
                        }
                    })
                    .catch(function (err) {
                       // console.log(err);
                    })
              }
            },
            //确定选中的订阅条件
            sure(){
                let that=this
                let addressCode=''
                if(that.cityCode==''){
                    addressCode= that.provinceCode
                }else if(that.areaCode==''){
                    addressCode= that.provinceCode+','+that.cityCode
                }else{
                    addressCode=that.provinceCode+','+that.cityCode+','+that.areaCode
                }
                // console.log(that.activeName)
                // console.log(that.subscribeName)
                // console.log(that.keyWordsA.toString())
               that.$axios.get('/webInterface/opScripingSubscribe', {  //params参数必写 , 如果没有参数传{}也可以
                params: {  
                    subscribeId:that.activeName,
                    subscribeName:that.keyWordsA.toString(),  //方案名称
                    keywords:that.keyWordsA.toString(),
                    pushFrequency:'1', //推送频率
                    pushTime:that.timeS,
                    sysNotice:that.notice1, 
                    wechatNotice:that.notice2,                                  
                    emailNotice:that.notice3, 
                    qryAreaCode:addressCode,              //地区
                    qryDataType:that.messageCode,         //信息类型
                    qryDataSubType:that.messageCodesA,   //信息类型二级分类
                    qryIndustryCategory:that.sxvalue1s,        //行业类型                        
                    qryBiddingProcurementType:that.sxvalue2s,  //招采类型                        
                    qryBudgetAmount:that.sxvalue3s,         //预算金额                            
                    qryAgency:that.sxvalue6s,               //代理单位                           
                    qryBidWinningAmount:that.sxvalue4s,    //中标金额                             
                    qryContactInformation:that.sxvalue7s, //联系方式
                    opFlag:3            
                }
            })
            .then(function (res) {
                //console.log(res);
                if(res.data.resultcode==1){
                    that.$message({
                    message: '数据更新成功',
                    type: 'success'
                    });
                    that.showAll=false
                }else{
                    that.$message.error(res.data.resultmessage)
                }
            })
            .catch(function (err) {
                // console.log(err);
            })
            },
            //搜索范围
            fwChang(index,areaCode,fwName){
                this.currentPage=1
                //this.number1 = index;
                this.fwCode=areaCode  
                this.fwName=fwName
            },
            handleClickT(event){},
            handleClick(event) {
                this.subscribeId=event._props.name
                this.showDyall()
                 // console.log(that.activeName)
                // console.log(that.subscribeName)
                // console.log(that.keyWordsA.toString())
                // console.log(that.timeS)
                // console.log(that.notice1)
                // console.log(that.notice2)
                // console.log(that.notice3)
                //  console.log(addressCode)
                // console.log(that.sxvalue1s)
                //  console.log(that.sxvalue2s)
                //  console.log(that.sxvalue3s)
                //  console.log(that.sxvalue6s)
                //  console.log(that.sxvalue4s)
                //  console.log(that.sxvalue7s)

                let urls=window.location.href

                if (urls.indexOf('?') !== -1) {
                    urls= urls.split("?")[0] 
                    window.history.pushState({}, 0, urls)
                }
                 
                 this.provinceCode=''
                 this.valueYear=''
                 this.valueYearzdy=''
                 this.timeCode=''
                 this.valueYear=''
                 this.startTime=''
                 this.endTime=''
                 this.messageCode=''
                 this.sxvalue1=''
                 this.sxvalue1s=''
                 this.sxvalue2=''
                 this.sxvalue2s=''
                 this.sxvalue3=''
                 this.sxvalue3s=''
                 this.sxvalue4=''
                 this.sxvalue4s=''
                 this.sxvalue6=''
                 this.sxvalue6s=''
                 this.sxvalue7=''
                 this.sxvalue7s=''
            },
            handleClicks(){

            },
            calculateText() {
                // 这是默认两行数据的高度，一行的高度可以自定义 可以*3 *4达到三行或者四行显示展示和收起的效果
                let twoHeight = 22 * 2;
                this.textHeight = `${twoHeight}px`;
                //let curHeight = this.$refs.descs.clientHeight;
                let curHeight =120
                if (curHeight > twoHeight) {
                    this.status = true;
                    this.idShowText = true;
                } else {
                    this.status = false;
                    this.idShowText = false;
                }
            },
            showStaus(){
             if(this.status==true){
                this.status=false
             }else{
                this.status=true
             }
            },
            calculateTextA() {
                // 这是默认两行数据的高度，一行的高度可以自定义 可以*3 *4达到三行或者四行显示展示和收起的效果
                let twoHeight = 22 * 2;
                this.textHeight = `${twoHeight}px`;
                //let curHeight = this.$refs.descs.clientHeight;
                let curHeight =120
                if (curHeight > twoHeight) {
                    this.statusA = true;
                    this.idShowTextA = true;
                } else {
                    this.statusA = false;
                    this.idShowTextA = false;
                }
            },
            showStausA(){
                console.log(this.statusA)
             if(this.statusA==true){
                this.statusA=false
             }else{
                this.statusA=true
             }
            }, 
            handleClickT(tab, event) {
                this.activeNameT=tab._props.name
                this.showQuan()
            },
             // 查询是否有优惠券  
             showQuan(){
				let that = this;
                that.$axios.post('/webInterface/queryCouponUserList?couponType=3'+'&useState=1')
                    .then(function (res) {
                        //console.log(res);
                        if(res.data.resultcode==1){
                            that.quanList=res.data.couponUserList
                            if(that.quanList.length>0){
                                if(that.vipFlag==1){
                                    that.totalPrice=599-that.quanList[0].couponInfo.couponAmount
                                }else if(that.vipFlag==2){
                                    that.totalPrice=999-that.quanList[0].couponInfo.couponAmount
                                }else{
                                    that.totalPrice=1599-that.quanList[0].couponInfo.couponAmount
                                }
                                that.couponUserId=that.quanList[0].couponUserId
                            }else{
                                that.couponUserId=''
                                if(that.vipFlag==1){
                                    that.totalPrice=599
                                }else if(that.vipFlag==2){
                                    that.totalPrice=999
                                }else{
                                    that.totalPrice=1599
                                }

                            }
                        }
                    })
                    .catch(function (err) { 
                    })
			},
            //查询省和市
            provice(code){
                this.$axios.post('/webInterface/queryAreaList?parentAreaCode='+code).then(res => {
                    if (res.data) {
                     let aa= {areaCode:'',areaName:'全部'}
                     if(code==''){
                       this.proviceList=res.data.localareaList
                       this.proviceList.unshift(aa)

                       for(var i=0;i<this.proviceList.length;i++){
                        if(this.proviceList[i].areaCode==this.countryCode.split(',')[0]){
                            this.cityName=this.proviceList[i].areaName
                        }
                       }

                    }else{
                        this.cityList=res.data.localareaList
                        this.cityList.unshift(aa)

                        for(var i=0;i<this.cityList.length;i++){
                        if(this.cityList[i].areaCode==this.countryCode.split(',')[1]){
                            this.cityName1=this.cityList[i].areaName
                        }
                    }
                    } 
                  }
                }).catch(err => {
                })
            },
            provices(code){
                this.$axios.post('/webInterface/queryAreaList?parentAreaCode='+code).then(res => {
                    if (res.data) {
                        let aa= {areaCode:'',areaName:'全部'}
                       this.areaList=res.data.localareaList
                       this.areaList.unshift(aa)

                       for(var i=0;i<this.areaList.length;i++){
                        if(this.areaList[i].areaCode==this.countryCode.split(',')[2]){
                            this.cityName2=this.areaList[i].areaName
                        }
                    }

                    }
                }).catch(err => {
                })
            },
            sxList(text){
                let that=this
                that.$axios.post('/webInterface/querySysRefList?optionCode='+text).then(response => {
                   // console.log(response)
                    if (response.data) {
                           let aa= {dictionaryOptionId:'',optionName:'全部'}
                           let bb= {dictionaryOptionId:'',optionName:'不限'}
                        if(text=='industry_category'){
                            that.sxList1=response.data.sysRefListList   //行业分类
                        }else if(text=='pub_date'){
                            that.timeList=response.data.sysRefListList   //发布时间
                            that.timeList.unshift(aa)
                            that.timeCode=this.timeList[5].dictionaryOptionId
                            that.numberThree=5 
                        }else if(text=='bidding_procurement_type'){
                            that.sxList2=response.data.sysRefListList  //招采类型
                        }else if(text=='bidding_unit'){
                            that.sxList3=response.data.sysRefListList   //招采单位
                        }else if(text=='budget_amount'){
                            that.sxList4=response.data.sysRefListList   //预算金额 
                        }else if(text=='agency'){
                            that.sxList5=response.data.sysRefListList   //代理单位
                        }else if(text=='bid_winning_amount'){       
                            that.sxList6=response.data.sysRefListList   //中标金额
                        }else if(text=='contact_information'){
                            that.sxList7=response.data.sysRefListList   //联系方式
                        }else if(text=='bidding_type'){
                            that.zbList=response.data.sysRefListList   //招标类型 
                        }else if(text=='bid_winning_type'){
                            that.zbList=response.data.sysRefListList   //中标类型
                        }else if(text=='proposed_type'){
                            that.zbList=response.data.sysRefListList   //拟建项目
                            that.zbList.unshift(bb)
                        }else if(text=='approval_stage'){
                            that.zbList2=response.data.sysRefListList   //审批阶段
                            that.zbList2.unshift(aa)
                            // for(var i=0;i<this.zbList2.length;i++){
                            //     if(this.zbList2[i].dictionaryOptionId==this.zbvalue2){
                            //         this.zbName=this.zbList2[i].optionName
                            //     }
                            // }
                        }else if(text=='enclosure'){
                            that.sxList8=response.data.sysRefListList   //附件
                            // for(var i=0;i<this.sxList8.length;i++){
                            //     if(this.sxList8[i].dictionaryOptionId==this.sxvalue8){
                            //         this.sxvalue8=this.sxList8[i].optionName
                            //     }
                            // }
                        }
                        
                    }
                }).catch(err => {
                })
            },
           //点击省
            proviceChang(index,areaCode,cityName,flag) {
                this.numberOne = index;
                this.provinceCode=areaCode
                this.cityName=cityName
                this.currentPage=1
                this.numberTwo = '';
                this.cityCode=''
                this.numberArea = ''
                this.areaCode=''
                this.cityName1=''
                this.provice(areaCode)
                this.areaList=[]
                this.isCountry=false
                if(index!=0){
                    this.isCity=true 
                }else{
                    this.isCity=false
                    this.isCountry=false
                }
                if(flag!=1){
                    this.showTiaojian(this.activeName,this.keyWordsA.toString(),this.currentPage)
                }
            },
            dyproviceChang(index,areaCode,cityName) {
                this.dynumberOne= index;
                this.dyprovinceCode=areaCode
                this.dycityName=cityName
                this.currentPage=1
                this.dynumberTwo = '';
                this.dyareaCode=''
                this.dycityCode=''
                this.dynumberArea = ''
                this.dycityName1=''
               this.provice(areaCode)
            //    console.log(this.numberTwo)
            //    console.log(this.cityCode)
            //    console.log(this.cityName1)
            //    console.log(this.numberArea)
               //this.areaList=[]
               this.dyisCountry=false
                if(index!=0){
                    this.dyisCity=true 
                }else{
                    this.dyisCity=false
                    this.dyisCountry=false
                }
            },
            //点击市
            cityChang(index,areaCode,cityName1,flag) {
                this.numberTwo = index;
                this.cityCode=areaCode
                this.cityName1=cityName1
                this.numberArea = ''
                this.areaCode=''
                this.cityName2=''
                this.provices(areaCode)
                this.currentPage=1
                console.log(this.cityCode)
                if(index!=0){
                    this.isCountry=true
                }else{
                    this.isCountry=false
                }
                if(flag!=1){
                    this.showTiaojian(this.activeName,this.keyWordsA.toString(),this.currentPage)
                }
            },
            dycityChang(index,areaCode,cityName1) {
                this.dynumberTwo = index;
                this.dycityCode=areaCode
                this.dycityName1=cityName1
                this.dynumberArea = ''
                this.dyareaCode=''
                this.dycityName2=''
                this.provices(areaCode)
                if(index!=0){
                    this.dyisCountry=true
                }else{
                    this.dyisCountry=false
                }
            },
            areaChang(index,areaCode,cityName2,flag) {
                this.numberArea = index;
                this.areaCode=areaCode
                this.cityName2=cityName2
                this.currentPage=1
                console.log(this.areaCode)
                if(flag!=1){
                    this.showTiaojian(this.activeName,this.keyWordsA.toString(),this.currentPage)
                }
            },
            dyareaChang(index,areaCode,cityName2) {
                this.dynumberArea = index;
                this.dyareaCode=areaCode
                this.dycityName2=cityName2
            },
            hqYears(e,id){
                this.valueYear=e
                this.valueYearzdy=''  //自定义时间清空
                this.zdyTime=''   
                this.startTime=''   //自定义开始时间清空
                this.endTime=''    //自定义结束时间清空
                this.timeCode=id   //时间清空
                this.currentPage=1
               this.showTiaojian(this.activeName,this.keyWordsA.toString(),this.currentPage)
            },
            zdyYears(id){
               this.startTime=this.timeZh(this.valueYearzdy[0])  //开始时间
               this.endTime=this.timeZh(this.valueYearzdy[1])   //结束时间
               //this.zdyTime=this.startTime+'-'+this.endTime
               this.currentPage=1
               this.valueYear=''  //往年
               this.timeCode=id  //时间
               this.showTiaojian(this.activeName,this.keyWordsA.toString(),this.currentPage)
            },
           
            timeZh(time){
                var date = new Date(time)
                var y = date.getFullYear()
                var m = date.getMonth() + 1
                m = m < 10 ? ('0' + m) : m
                var d = date.getDate()
                d = d < 10 ? ('0' + d) : d
                const times = y + '-' + m + '-' + d
                return times
            },
            timeChang(index,areaCode) {
                this.numberThree = index;
                this.timeCode=areaCode  //时间
                //this.wnTime='' 
                this.valueYear=''  //往年
                this.valueYearzdy=''  //自定义
                this.currentPage=1
                this.showTiaojian(this.activeName,this.keyWordsA.toString(),this.currentPage)
            },
            messageChang(index,areaCode,xxName,flag) {
                this.currentPage=1
                this.number4 = index;
                this.messageCode=areaCode
                this.messageCodesA=''
                this.number4s=0
                
                this.xxName=xxName
                this.xxName2=''
                if(index==1){
                    this.sxList('bidding_type')
                }else if(index==2){
                    this.sxList('bid_winning_type')
                }else if(index==3){
                    this.sxList('proposed_type')
                }
                if(flag!=1){
                    this.showTiaojian(this.activeName,this.keyWordsA.toString(),this.currentPage)
                }
            },
            dymessageChang(index,areaCode,xxName) {
                this.currentPage=1
                this.dynumber4 = index;
                this.dymessageCode=areaCode
                this.dymessageCodesA=''
                this.dynumber4s=0
                
                // this.xxName=xxName
                // this.xxName2=''
                if(index==1){
                    this.sxList('bidding_type')
                }else if(index==2){
                    this.sxList('bid_winning_type')
                }else if(index==3){
                    this.sxList('proposed_type')
                }
            },
            messageChangs(index,areaCode,xxName,flag){
                this.currentPage=1
                this.number4s = index
                this.xxName2=xxName
                this.messageCodesA=areaCode
                if(flag!=1){
                    this.showTiaojian(this.activeName,this.keyWordsA.toString(),this.currentPage)
                }
            },
            dymessageChangs(index,areaCode,xxName){
                this.currentPage=1
                this.dynumber4s = index
                //this.xxName2=xxName
                this.dymessageCodesA=areaCode
            },
           
            spChang(index,areaCode){
                this.currentPage=1
                this.number66 = index;
                this.spCode=areaCode  //审批阶段
            },
            zbChang(index,areaCode,flag,name){
              if(flag==1){
                this.zbvalue=areaCode   //招标类型
                this.zbName=name
                this.number5=index
              }else if(flag==2){
                this.zbvalue1=areaCode   //中标类型
                this.zbName1=name
                this.number6=index
              }else if(flag==3){
                this.zbvalue2=areaCode  //审批阶段
                this.zbName2=name
                this.number7=index
              }
              this.currentPage=1
              this.showTiaojian(this.activeName,this.keyWordsA.toString(),this.currentPage)
            },
            moreSx(){
                 let that=this
                 if(this.phoneNo!=null&&this.phoneNo!=''){
                   
                 }else{
                    setTimeout(()=>{  //设置延迟执行
                        that.$router.push({path: '/login'});
                    },600);
                 }
            },
            sxOne(e,flag){
                let that=this
                if(that.level.userMemberRankStr=='普通用户'){
                    that.$message.warning('需要VIP权限')
                    if(flag==1){
                        that.sxvalue1='行业类型'
                        that.sxvalue1s=''
                    }else if(flag==2){
                        that.sxvalue2='招采类型'
                        that.sxvalue2s=''
                    }else if(flag==3){
                        that.sxvalue3='预算金额'
                        that.sxvalue3s=''
                    }else if(flag==4){
                        that.sxvalue4='中标金额'
                        that.sxvalue4s=''
                     }else if(flag==5){
                        that.sxvalue5='招采单位'
                        that.sxvalue5s=''
                    }else if(flag==6){
                        that.sxvalue6='代理单位'
                        that.sxvalue6s=''
                    }else if(flag==7){
                        that.sxvalue7='联系方式'
                        that.sxvalue7s=''
                    }else if(flag==8){
                         that.sxvalue8='附件'
                        that.sxvalue8s=''
                    } 
                }else{
                if(flag==1){
                    if(e.split(",")[1]=='不限'){
                        that.sxvalue1='行业类型'
                    }else{
                        that.sxvalue1=e.split(",")[1]
                    }
                    that.sxvalue1s=e.split(",")[0]
                }else if(flag==2){
                    if(e.split(",")[1]=='不限'){
                        that.sxvalue2='招采类型'
                    }else{
                        that.sxvalue2=e.split(",")[1]
                    }
                    that.sxvalue2s=e.split(",")[0]
                }else if(flag==3){
                    if(e.split(",")[1]=='不限'){
                        that.sxvalue3='预算金额'
                    }else{
                        that.sxvalue3=e.split(",")[1]
                    }
                    that.sxvalue3s=e.split(",")[0]
                }else if(flag==4){
                    if(e.split(",")[1]=='不限'){
                        that.sxvalue4='中标金额'
                    }else{
                        that.sxvalue4=e.split(",")[1]
                    }
                    that.sxvalue4s=e.split(",")[0]
                }else if(flag==5){
                    if(e.split(",")[1]=='不限'){
                        that.sxvalue5='招采单位'
                    }else{
                        that.sxvalue5=e.split(",")[1]
                    }
                    that.sxvalue5s=e.split(",")[0]
                }else if(flag==6){
                    if(e.split(",")[1]=='不限'){
                        that.sxvalue6='代理单位'
                    }else{
                        that.sxvalue6=e.split(",")[1]
                    }
                    that.sxvalue6s=e.split(",")[0]
                }else if(flag==7){
                    if(e.split(",")[1]=='不限'){
                        that.sxvalue7='联系方式'
                    }else{
                        that.sxvalue7=e.split(",")[1]
                    }
                    that.sxvalue7s=e.split(",")[0]
                }else if(flag==8){
                    if(e.split(",")[1]=='不限'){
                        that.sxvalue8='附件'
                    }else{
                        that.sxvalue8=e.split(",")[1]
                    }
                    that.sxvalue8s=e.split(",")[0]
                }  
                  that.currentPage=1
                  that.showTiaojian(that.activeName,that.keyWordsA.toString(),that.currentPage)

                }          
                
             },
           //分页查询数据
            handleCurrentChange(val) {
                this.currentPage=val
                this.showTiaojian(this.activeName,this.keyWordsA.toString(),this.currentPage)
               document.querySelector('#tabsQ').scrollIntoView({
                    behavior: "smooth"
                });
            },
            zbDetail(flag){
                let that=this
                let phoneNos=JSON.parse(localStorage.getItem('phoneNo'))
                that.objectIds=flag
                that.activeNameT='1'
                that.vipFlag=3
                that.goodsId='7163018607638296736'
              if(phoneNos!=null&&phoneNos!=''){
                    that.$axios.get('/webInterface/checkVipAuth', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            objectType:2,
                            objectId:flag
                        }
                    })
                    .then(function (res) {
                       console.log(res);
                       if(res.data.resultcode==1){
                        const {href}=that.$router.resolve({
                            // 跳转到的页面路径
                            path: '/zbDetails',
                            query: {
                                flag: flag,
                            }
                        })
                        window.open(href,'_blank')
                       }else{
                            that.ewmVisible=true
                            that.isChao=true //不是vip  isChao=true 弹框支付
                        
                       } 
                    })
                    .catch(function (err) { 
                    })
                }else{
                    setTimeout(()=>{  //设置延迟执行
                        that.$router.push({ 
                            name: '/login',
                            // params: { 
                            //     isFlagA: '1'
                            // },
                        })

                    },1000);
                }
            },
            //  companyDetail(flag){
            //    let phoneNos=JSON.parse(localStorage.getItem('phoneNo'))
            //     let that=this
            //     that.objectIds2=flag
            //     that.objectIds=''
            //     if(phoneNos!=null&&phoneNos!=''){
            //     that.$axios.get('/webInterface/checkVipAuth', {  //params参数必写 , 如果没有参数传{}也可以
            //             params: {  
            //                 objectType:1,
            //                 objectId:flag
            //             }
            //         })
            //         .then(function (res) {
            //            console.log(res);
            //            if(res.data.resultcode==1){
            //             const {href}=that.$router.resolve({
            //                 // 跳转到的页面路径
            //                 path: '/companyDetails',
            //                 // 传递的参数集合
            //                 query: {
            //                     flag: flag
            //                 }
            //             })
            //             window.open(href,'_blank')
            //            }else{
            //              //不是会员 需要开通5毛或者年费vip
            //              if(res.data.vipAuth==1){
            //                   //调接口 微信支付的弹框  3种情况
            //                   that.ewmVisible=true
            //                   that.isChao=true //不是vip  isChao=true 弹框支付
            //              }else{
            //                 that.ewmVisible=true
            //                 that.isChao=false
            //              }
            //            } 
            //         })
            //         .catch(function (err) { 
            //         })
            //     }else{
            //         setTimeout(()=>{  //设置延迟执行
            //             this.$router.push({path: '/login'});
            //         },600);
            //     }
            // },
            
             //关闭支付弹框
             handleClose(){
                // this.activeNameT=1
                // this.goodsId='7163018607638296736'
                this.ewmVisible=false
                this.isPay=true
                clearInterval(this.timerT)
                this.timerT = null
            },
            taocan(flag){
                let that=this
                that.vipFlag=flag
                if(flag==3){
					that.showQuan()
					if(that.quanList.length>0){
                        that.totalPrice=1599-that.quanList[0].couponInfo.couponAmount
						that.couponUserId=that.quanList[0].couponUserId
					}else{
						
                        that.totalPrice=1599
						that.couponUserId=''
					}
				}else{
					that.quanList=[]
					that.couponUserId=''
                    if(flag==1){
                        that.totalPrice=599
                    }else if(flag==2){
                        that.totalPrice=999
                    }
				}
		   },
            payfor(){
                let that = this;
				let goodsId=''
				let OneObjectId=''
				let twoObjectId=''
				if(that.activeNameT==1){
					goodsId='7163018607638296736'       //0.5/条
					OneObjectId=that.objectIds  
				}else{
					if(that.vipFlag==1){
						goodsId='5166747643280060475'  //包日
					}else if(that.vipFlag==2){
						goodsId='5166747643280060476'   //包月
					}else if(that.vipFlag==3){
						goodsId='5166747643280060474'   //包年
					}
					if(that.couponUserId!=undefined&&that.couponUserId!=''){
						twoObjectId=that.couponUserId
					}else{
						twoObjectId=''
					}
				}

                 that.$axios.get('/webInterface/generateOrder', {  //params参数必写 , 如果没有参数传{}也可以
                    params: {  
                        goodsId:goodsId,  //套餐id
                        orderIds:'',
                        objectId:OneObjectId, //单条详情id
                        quantity:1,
                        freight:0,
                        payType:2,  //1是支付宝支付，2是微信支付
                        objectType:2, //2是标讯，1是企业	
                        couponUserId:twoObjectId   //优惠券id
                    }
                })
                .then(function (res) {
                    console.log(res);
                    if(res.data.resultcode==1){
                        // that.ewmImg=res.data.reStr  //二维码
                        // that.isPay=false   
                        // that.$axios.post('/webInterface/queryGoodsInfo?id='+that.goodsId)
                        // .then(function (res) {
                        //     console.log(res);
                        //     if(res.data.resultcode==1){
                        //          that.orderPay=res.data.goodsInfo                               
                        //     }
                        // })
                        // .catch(function (err) { 
                        // })
                        //  that.timerT = window.setInterval(() => {
                        //     that.check(res.data.orderInfo.id)
                        // }, 3000);
                        that.ewmVisible=true
                        that.ewmImg=res.data.reStr  //二维码
                        that.isPay=false  
                        that.orderPays=res.data.orderInfo
                        that.$axios.post('/webInterface/queryGoodsInfo?id='+goodsId)
                        .then(function (res) {
                           console.log(res);
                            if(res.data.resultcode==1){
                                 that.orderPay=res.data.goodsInfo                               
                            }
                        })
                        .catch(function (err) { 
                        })
                         that.timerT = window.setInterval(() => {
                            that.check(res.data.orderInfo.id)
                        }, 3000);
                    }
                })
                .catch(function (err) { 
                })
            },
            check(orderId){
                var that=this
               that.$axios.post('/webInterface/queryOrderInfo?orderId='+orderId+'&flag=1')
                .then(function (res) {
                 if(res.data.resultcode=='1'&&res.data.orderInfo.state!=0){
                     that.paySuccess=true
                     //that.messageTitle='支付成功，'+that.totalTime +'秒后将跳转至详情页面'

                     that.messageTitle='支付成功，将跳转至详情页面'
                      that.ewmVisible=false
                      that.isPay=true
                      that.paySuccess=false
                      clearInterval(that.timerT)
                      that.totalTime = 3
                      that.timerT = null
                    //标讯
                      setTimeout(()=>{
                        that.$router.push({
                                path: '/zbDetails',
                                query: {
                                flag: that.objectIds, 
                            }
                        })
                      },500)

                    //  let clock = setInterval(() => {
                    //     that.totalTime--
                    //     that.messageTitle='支付成功，'+that.totalTime +'秒后将跳转至详情页面'
                    //     if (that.totalTime==0) {     			//当倒计时小于0时清除定时器
                    //         clearInterval(clock)
                    //         that.ewmVisible=false
                    //         that.isPay=true
                    //         that.paySuccess=false
                    //         clearInterval(that.timerT)
                    //         that.totalTime = 3
                    //         that.timerT = null
                    //         that.$router.push({
                    //                  path: '/zbDetails',
                    //                  query: {
                    //                  flag: that.objectIds, 
                    //               }
                    //             })
                            
                    //     }
                    //  },500)
					}else{
                        that.paySuccess=false
                        clearInterval(clock)
                        that.isPay=true
                        clearInterval(that.timerT)
                        that.totalTime = 3
                        that.timerT = null
                    }
                })
                .catch(function (err) { 
                })
            },
            pxChange(e){
                this.pxLabel=e
                //this.showZbList(this.currentPage,this.pxLabel)
                 this.showTiaojian(this.activeName,this.keyWordsA.toString(),this.currentPage)
            },
        }
    }
</script>
<style scoped lang="less">
  .topCon{
  background:url(../assets/images/banner1.png);
  width: 100%;
  height: 295px;
  background-size: 100% 100%;
  position: relative;
}
.flex{
  display: flex;
  align-items: center;
}
.topHalf{
  text-align: center;
}
.hTitle{
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    padding-top: 70px;
}
.inputClass{
    border: none;
    outline: none;
    width: 155px;
    margin-left: 10px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
}
.searchBtn{
    cursor: pointer;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    width: 47px;
    height: 32px;
    background: #0078FF;
    text-align: center;
    line-height: 32px;
}
.ju-tinput{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 250px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #0078FF;
    border-radius: 2px;
}
.shuline{
  width: 2px;
  height: 25px;
  background: #BBBBBB;
}
.selLeft{
  min-width: 14%;
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
}
.selectImg{
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.hotSearch{
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  width: 970px;
  margin: 0 auto;
}
.hotTitles{
  font-weight: 500;
  color: #fff;
}
.reLine{
  background: #fff;
  margin: 0 20px;
  width: 2px;
  height: 18px;
  background: rgba(255,255,255,0.6);
}
.itemHot{
  margin-right: 20px;
  cursor: pointer;
}
/* 小喇叭通知 */
ui,li {
  list-style: none;
  width: 100%;
  overflow: hidden; 
white-space: nowrap; 
text-overflow: ellipsis; 
}
.mobile-marquee-wrapper{
    flex: 1;
    height: 40px;
    overflow: hidden;
    position: relative;
    #news{
    overflow: hidden;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000000;
    cursor: pointer;
    white-space: nowrap;
    line-height: 40px;
    position: absolute;
    top: 0;
  }
}
 
  .halfC{
  width: 1140px;
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  border-radius: 5px;
  margin: 0 auto;
  padding: 20px;
}
/deep/.el-tabs{
    .el-tabs__header{
        margin: 0 0 18px;
    }
    .el-tabs__item{
        height: 43px !important;
        font-size: 16px !important;
    }
}
/deep/.el-pagination {
    .el-pagination__total,.el-pagination__jump{
    display: inline-block;
    font-size: 15px;
    }
    .el-pager li {
    font-size: 15px;
    }
}
.open{
    display: flex;
    justify-content: center;
    margin-top: -1px;
}
.statusA{
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #0078FF;
    border-width: 30px 25px 0 25px;
    border-style: solid;
    border-color: #fff transparent blue transparent;
    position: relative;
    width: 33px;
    padding: 0 14px;
    cursor: pointer;
}
select{
    padding: 3px;
    font-size: 16px;
    padding: 3px;
    font-size: 16px;
    border: 1px solid #DCDFE6;
    color: #000;
    height: 30px;
    outline: none;
}

.el-button{
    margin-left:0 !important;
    font-size: 16px !important;
    padding:0 !important;
    width: 82px;
    height: 30px;
}
.ant-spin-nested-loading {
    position: relative;
}
.ant-list-items {
    margin: 0;
    padding: 0;
    list-style: none;
}
.tc_list_item.tc_list_hover {
    transition: background-color .2s;
}
.tc_list_item {
    position: relative;
    // border-bottom: 1px solid #F3F3F3;
    padding: 10px 0 14px 0;

}
.title{
    font-size: 18px;
    font-weight: 700;
    padding: 10px 0;
    color: #333;
    cursor: pointer;
}

.gGdJlei{
    margin-bottom: 10px;
}
.fiklj9cw {
    color: #333;
}
.fiklj9cw .smailDiv {
    line-height: 22px;
    margin: 5px 0;
    max-width: 100%;
}
.fiklj9cw .smailDiv, .fiklj9cw .smailDiv>div {
    display: inline-block;
}
.fiklj9cw .smailDiv>div:first-child {
    color: #999;
    padding-right: 10px;
}
.fiklj9cw .smailDiv:not(:last-child) {
    margin-right: 30px;
}
/* ul{
    margin: 0;
    padding:0;
} */

.blueColor{
    color: #0078FF;
    cursor: pointer;
}
a{
    color:#000
}
.pagination{
    text-align: center;
    margin-top: 28px;
}


/deep/.el-input__inner{
    color: #222222;
}
/deep/.rightM{
    height: 30px;
    line-height: 30px;
    //margin-right:10px;
    .el-input{
        width: 160px !important;
        font-size: 16px !important;
        .el-input__inner{
            height: 30px !important;
            line-height: 30px !important;
            outline: none;
            padding-left: 9px;
            padding-right: 9px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #222222;
        }
        .el-input__inner::input-placeholder{
            color:#222;
        }
        .el-input__inner::-webkit-input-placeholder { /* WebKit browsers */ 
          color: #222; 
        }
        .el-input__suffix{
            display:none
        }
    }
}

// .jtImgsx{
//     width: 11px;
//     height: 12px;
//     position: absolute;
//     right: 16px;
//     top: 9px;
// }






.ant-spin-container {
    position: relative;
    transition: opacity .3s;
  }
  .ant-list-items {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    border-bottom: 1px solid #F3F3F3;
    padding: 10px 0;
  }
  .ant-list-items:last-child{
    border-bottom:none
  }
  .tc_list_item.tc_list_hover {
   // padding: 0 20px;
    transition: background-color .2s
  }
 
  .tc_list_item:not(:last-child):after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    border-bottom: 1px solid #eee;
  }
  .R-Dvn3zA{
    padding-top: 10px;
    display: flex;
  }
  .pYreFzHC {
    padding-right: 20px;
    // width: 80px;
    // height: 160px;
  }
  .pYreFzHC img {
    // border: 1px solid #eee;
    width: 60px;
    height: 60px;
    border-radius: 4px;
  }
  ._0aDOKXj6 {
    position: relative;
   width: calc(100% - 80px);
  }
  .FpwufnYn {
    display: flex;
    margin-bottom: 10px;
    cursor: pointer;
    justify-content: space-between;
  }
  .FpwufnYn a {
    font-size: 16px;
    font-family: PingFangSC-Medium,PingFang SC;
    font-weight: 700;
    color: #333;

}
.WMSbIy8X {
    position: absolute;
    top: 0;
    right: 0;
    height: 24px;
    color: #000;
    font-weight: 700;
}
.jutRowsA{
    height: 46px;
    background: #F5F7FA;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
        // margin: 10px 0;
}
.noticTitles{
    // line-height:46px;
    margin-right: 20px;
}
.noticTitles:last-child{
    margin-right: 0;
}
.huiColor{
     color:#999999;
}
.titles{
    font-size: 18px;
    font-weight: 700;
    color: #333;
    cursor: pointer;
}









 /* tab切换 */
 .chooseArea{
  font-size: 16px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #222222;
}
.areaCode,.timeCode{
  flex-wrap: wrap;
  cursor: pointer;
  width: 990px;
  height:100%
}
.areaCode .item,.timeCode .item{
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-right: 8px;
  padding: 0 12px;
  margin-bottom: 10px;
}
.selected{
  background: #EFF7FF;
  border-radius: 4px;
  font-weight: 400;
  color: #0078FF; 
 
}
.tabCon{
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #222222;
  margin-top: 20px;
}
.conRows{
  border-bottom: 1px solid #F3F3F3;
  padding: 20px 0;
}
input[name="color"]::-webkit-input-placeholder { /* WebKit browsers */
color: red;
font-size:15px;
}
select{
    border: none;
    outline: none;
    margin-right: 22px;
    font-size:16px
}
/deep/.el-cascader{
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    .el-input{
        width: 108px !important;
        font-size: 16px !important;
    .el-input__inner{
        height: 30px !important;
        line-height: 30px !important;
        border: none;
        outline: none;
        padding-left: 0;
        padding-right: 15px;
        text-align: center;
    }
    .el-input__inner::input-placeholder{
            color:#222;
        }
        .el-input__inner::-webkit-input-placeholder { /* WebKit browsers */ 
          color: #222; 
        }
    .el-input__suffix{
        display:none
    }
  }
}
/deep/.faTime{
.selectitem{
    .el-input{
        width: 100px !important;
        font-size: 16px !important;
        .el-input__inner{
            height: 30px !important;
            line-height: 30px !important;
            border: none;
            outline: none;
            padding-left: 8px;
            padding-right: 5px;
        }
        .el-input__inner::input-placeholder{
            color:#222;
        }
        .el-input__inner::-webkit-input-placeholder { /* WebKit browsers */ 
          color: #222; 
        }
        .el-input__suffix{
            display:none
        }
    }
}
    }
    
/deep/.aShaixuan{
    .phoneClass{
        .el-select{
           .el-input{
              width: 130px !important;
               .el-input__inner{
                 padding-right: 12px;
               }
           }
        }
    }
.el-select{
    height: 30px;
    line-height: 30px;
    .el-input{
        width: 110px !important;
        font-size: 16px !important;
        .el-input__inner{
            height: 30px !important;
            line-height: 30px !important;
            border: none;
            outline: none;
            padding-left: 0;
            padding-right: 15px;
            text-align: center;
        }
        .el-input__inner::input-placeholder{
            color:#222;
        }
        .el-input__inner::-webkit-input-placeholder { /* WebKit browsers */ 
          color: #222; 
        }
        .el-input__suffix{
            display:none
        }
    }
} 
}
/deep/ .el-date-editor {
    height: 33px;
    line-height: 30px;
    border: none !important;
    outline: none !important;
    padding-left: 10px !important;
    width: 210px;
    padding-right: 12px;
    margin-bottom:10px;
     .el-range-separator{
        display: none;
     }
     .el-input__icon{
       display: none;
    } 
    .el-range-input{
      width: 90px !important; 
      font-size: 16px !important;
      text-align: left;
      }
      .el-range-input::input-placeholder{
        color:#222;
     }
    .el-range-input::-webkit-input-placeholder { /* WebKit browsers */ 
        color: #222; 
     }
    }
    .selectitem{
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin-bottom: 10px;
        padding:0;
        width:75px
    }
    /deep/.selected{
        .el-range-input{
            background: none;
        border-radius: 4px;
        font-weight: 400;
        color: #0078FF;
      }
    }
    .jtImg{
        width: 11px;height: 12px;position: absolute;right: 132px;top: 9px;
    }
    .jtImgs{
        width: 11px;height: 12px;position: absolute;right:8px;top: 9px;
    }
    .el-popper{
        margin-top:0
    }
    .jtImgsxs{
        width: 11px;height: 12px;position: absolute;right:8px;top: 9px;
    }
    .jtImgsxsa{
        width: 11px;height: 12px;position: absolute;right:10px;top: 9px;
    }
    .jtImgsx{
        width: 11px;height: 12px;position: absolute;right: 3px;top: 9px;
    }
    /deep/.selectitem {
       .el-input{
        width: 76px !important;
        .el-input__inner{
            padding-right: 10px !important;
            padding-left: 11px;
            background: none;
            color: #0078FF;
        }
       }
    }
  
ul{
    padding:0;
    margin:0
}
    .newsList {
  width: 90%;
  height: 26px;
  overflow: hidden;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;

   /* background-color: red; */
}
.anim {
  transition: all 2s;
  margin-top: -30px;
}
#con1 li {
  list-style: none;
  cursor: pointer;
  color:#000;
}
.zcPr{
    max-width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.ant-popover-content{
    width: 150px;
    position: absolute;
    top: 48px;
    left: 126px;
    z-index: 999999;
    .ant-popover-inner {
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    
        .tc_select_number-range {
            padding: 12px;
            /deep/.el-form{
                .el-form-item{
                    .el-form-item__content{
                            display:flex
                        }
                }
            }
            
        }
    
}

}
.statusText{
    overflow: hidden;
    display: block;
}
.openClose{
    width: 62px;
    margin-top: 5px;
    color:#0078FF;
    cursor: pointer;
    font-size: 16px;
}
.jtImgsxsb{
    width: 12px;height: 12px;
}
.closeImg{
    width: 15px;height: 15px;
    cursor: pointer;
}





// 展开收起
.video-info {
        display: flex;
        // width: 100%;
        flex-direction: column;
        background-color: #fff;
        padding: 30rpx;
        
        position: relative;
        .info {
            display: flex;
            flex-direction: column;
            .tips {
                width: 126rpx;
                height: 40rpx;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%);
                
                position: absolute;
                bottom: 27rpx;
                right: 30rpx;
            }
        }
 
    }
    // 收起 按钮
    .hidebtn {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        color: #0078FF;
        font-size: 28rpx;
    }
    // 展开 文字
    .hide {
        word-break: break-word; //换行模式
        overflow: hidden;
        text-overflow: ellipsis; //修剪文字，超过2行显示省略号
        display: -webkit-box;
        -webkit-line-clamp: 2; //此处为上限行数
        -webkit-box-orient: vertical;
    }
    .sxTexts{
        width: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    }
    .jnNas{    
        //margin-bottom: 20px;
        // border-bottom: 1px solid #F3F3F3;
        // padding-bottom: 16px;
        font-size: 15px;
        .jnTextdy{
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #50545A;
        }
    }
    .tc_tag-input_root{
        background: #F2F4F7;
        border-radius: 4px;
        padding: 4px 10px;
        font-size: 14px;
        margin-right:10px;
        margin-bottom: 10px;
    }
    .tc_tag-input_roots{
        background: #F2F4F7;
        border-radius: 4px;
        padding: 4px 10px;
        font-size: 14px;
        margin-right: 10px;
        align-items: center;
        display: flex;
        text-align: center;
        margin-bottom: 10px;
        margin-top:5px;
    }
.half-bottom{
    // border-top:1px solid #F3F3F3;
    // padding-top: 20px;
    margin-top: 18px;
    padding-top: 20px;
    border-top: 1px solid #F3F3F3;
}
.isOpenClass{
    // border-bottom: 1px solid #F3F3F3;
    // padding-bottom: 20px;
    border-top: 1px solid #F3F3F3;
    margin-top: 20px;
}


// 弹框订阅
/deep/.addDy{
      .el-dialog__body{
        padding:30px 20px 0 20px;
        .dy-form-item{
            .el-select{
                .el-input{
                 width: 100%;
                }
            }
        .el-input{
            width: 76%;
            .el-input__inner{
                border-radius: 2px;
                font-size: 15px;
           }
        }
        .tsPl{
            width:76%;
        .el-tabs__header{
            margin:0;
            border-bottom:none;
            .el-tabs__nav{
                border:none;
                width:100%;
            }
            .el-tabs__item{
                border-bottom: none;
                border-left: none;
                border:1px solid #E4E7ED;
                margin-right: 10px;
                width:100%;
                font-size: 15px;
                text-align: center;
                border-radius: 3px;
            }
            .el-tabs__item.is-active{
                background: #409EFF;
                color:#fff;
                width:100%
            }
        }
    }
       }
      }

      .el-dialog__footer{
            text-align:center;
            padding:10px 20px 25px;
            .el-button{
                padding: 12px 28px;
                border-radius: 5px;
            }
        }
    }
    .sxTexts{
    width: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    }
    .gjTitle{
        padding: 0 0 8px;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #222222;
        width: 88px;
    }
    .gjTitles{
        padding: 0 0 20px;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #222222;
    }
    .el-radio{
        font-size:15px
    } 
    .dy-form-item{
        margin:0 0 22px;
    }
    /deep/.el-dialog__header {
        padding: 15px;
        border-bottom: none;
        margin: 0;
       background: #F2F4F7;
    }
    .tjHui{
        font-size: 14px;
       color: #999;    
    }
    /deep/.tc_card_body{
        text-align: center;
        .body_half{
            margin: 15px 0;
            border-bottom: 1px solid #F3F3F3;
            padding-bottom: 15px;
            .blue{
                color: #0078FF;
            }
            .half_rows{
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                padding: 15px 8px;
                background: #F5F7FA;
                border-radius: 5px;
                margin: 10px 0;
            }
        }
        .body_half:nth-child(2){
            border-bottom: none;
            padding-bottom: 4px;
            margin:15px 0 10px 15px
         }
        .el-button{
           color:#000;
           padding: 6px 10px; 
        }
    }
    .ljaddBtn{
        background: #0078FF;
        border-radius: 2px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: hsl(0, 0%, 100%);
        font-size: 15px;
        padding: 5px 10px;
        cursor: pointer
    }
    .xtWidth{
        width:88px
    }  
    .paySuccess{
        position: absolute;
        top: 77px;
        left: 50%;
        transform: translateX(-50%);
    }
    .kongImg{
        width:290px;
        height:280px;
    }
   .kong{
    text-align:center;
   }




// 弹框选择套餐支付
   /deep/.tkPay{
       
       .el-dialog{
           border-radius: 5px;
           max-height: 670px;
          overflow-y: auto;
       .el-dialog__header{
           background-image: url(../assets/images/tcTops.png);
           width:100%;
           height:140px;
           padding:0;
           .el-dialog__close{
               color: #fff;
           }
       }
       .el-dialog__body{
       border-radius: 5px;
       padding: 0;
       padding-bottom: 30px;
       .nav{
           .nav-top{
               margin: -50px 30px 20px 30px;
               background: #fff;
               box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
               border-radius: 10px;
           }
           .nav-tabel{
               margin: 10px 30px 10px 30px;
               // max-height: 300px;
               // overflow: auto;
           }
       }
       .navK{
           background: #FFFFFF;
           box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.08);
           border-radius: 10px;
           margin: -50px 30px 0 30px;
            padding: 30px;
           .fYcolor{
               background: #F6FAFF;
               border-radius: 5px;
               padding: 20px 30px;
               display:flex;
               .cpLeft{
                   width: 160px;
                   height: 200px;
                   background: #FFFFFF;
                   border-radius: 5px;
                   margin-right: 60px;
                   .ewmImg{
                       width:160px;
                       height:160px;
                       margin-bottom:7px;
                   }
               }
               .cpRight{
                   font-size: 13px;
                   font-family: Source Han Sans CN;
                   font-weight: 400;
                   color: #222222;
                   line-height: 30px;
                   margin-top: 20px;
               }
           }
       }
       }
   }
   }

   .tcTabelImg{
        width:100%;
        height:757px
    }
    .kaiTong{
        background-image: url(../assets/images/kaiTong.png);
        width:200px;
        height:46px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #222222;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .ktText{
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: 468px;
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;
    }

 /deep/.nav-top{
 
.topA{
   margin-top: -80px;
    background: #FFFFFF;
    border-radius: 5px;
}
.tao-half-row{
	width: 100%;
	position: relative;
    font-size: 12px;
    cursor: pointer;
    .taoTtile{
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #222222;
    }
}
.danTk2{
	background: #FFFFFF;
	border: 1px solid #E6E6E6;
	border-radius: 10px;
    text-align: center;
    padding: 20px 0;
}
.maright{
	margin-right: 20px;
}
.saleprice{
	height: 26px;
	line-height: 26px;
	text-decoration: line-through;
}
.danTk{
	background: #EDF5FF;
	border: 1px solid #0078FF;
	border-radius: 5px;
    text-align: center;
    padding: 20px 0;
}
.danTops{
	height: 24px;
	background: linear-gradient(0deg, #0078FF 0%, #0052F0 100%);
	border-radius: 5px 5px 0px 0px;
	margin-top: -2px;
	line-height: 24px;
}
.tq1Img{
	width:137px;
	height:12px;
}
.danTexts{
	width: 100%;
	font-size: 12px;
	font-family: PingFang SC;
	font-weight: 500;
}
.dlTexts{
		 overflow: hidden;
		 white-space: nowrap;
		 text-overflow: ellipsis;
		 max-width: 80%;
	}
    .dwZhek{
	  position: absolute;
	  right: -10px;
	  top:-11px;
	  width: 33px;
	  height: 18px;
	  background: #FF0000;
	  border-radius: 11px 0px 11px 0px;
	  color:#ffffff;
	  font-size: 12px;
	  font-family: PingFang SC;
	  font-weight: 500;
	  color: #FEFEFE;
	  line-height: 18px;
      text-align: center;
	}
	.danH{
		height:26px;line-height:26px;color:#999999
	}
  .tkgouImg{
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .sureBtn{
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        width: 200px;
        height: 46px;
        background: #0078FF;
        border-radius: 6px;
        line-height: 46px;   
        cursor: pointer;
    }
  .nav-middle{
    width: 100%;
    background: #FFFFFF;
    border: 2px dashed #EEEEEE;
    border-radius: 5px;
    padding: 10px 0 20px 0;
    text-align: center;
    margin:20px 0 12px 0;
        .nav-middleCon{
            align-items: flex-end;
            font-size: 14px;
            justify-content: center;
            margin-bottom: 18px;
            .sjPay{
                display: flex;
               align-items: flex-end;
            }
        }
    }







 .el-tabs--top{
     width: 100%;
     .el-tabs__header{
         margin: 0 !important;
     }
     .el-tabs__nav-wrap::after{
         background-color: #fff;
     }
     .el-tabs__nav-scroll{
         .el-tabs__nav{
             width: 100%;
             .el-tabs__active-bar{
                 left: 16%;
                 width: 120px !important;
             }
             .el-tabs__item{
                 height: 60px !important;
                 line-height: 20px !important;
                 font-size: 24px;
                 width: 50%;
                 text-align: center;
                 padding: 12px 0 0 0;
                 background: #F2F4F7;
                 border-radius: 5px 5px 0 0;
                 .bzImgs{
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     font-size: 18px;
                 } 
                 .bqqStext{
                     font-size: 12px;
                     font-family: Source Han Sans CN;
                     font-weight: 400;
                     color: #999999;
                 } 
             } 
             .el-tabs__item.is-active{
                 background: #fff;
                 color:#303133
             }
         }
     }
 }
 .firstContent{
     box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.04);
     border-radius: 5px;
     padding: 20px 30px;
 }
}


.tabs{
    .btn{
        position: relative;
        flex: 1;
        margin: 0 10px;
        border: 1px solid #CCCCCC;
        border-radius: 5px;
        padding: 19px 15px;
        cursor: pointer;
        transition: border-color .2s;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #222222;
        .tc_purchase-order_check {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 30px;
            height: 30px;
        }
    }
    .active{
        border-color: #432107;
        background-color: #fff;
        .tc_purchase-order_check{
            background-image: url(../assets/images/gou.png);
           background-size: 100% 100%;
        }
    }
}
.rows{
    justify-content: space-between;
}
.paySuccess{
        position: absolute;
        top: 77px;
        left: 50%;
        transform: translateX(-50%);
    }
.bangding{
    font-size: 14px;
    color: #999;
    position: relative;
        margin-top: 5px;
}
// .ewmImg{
//     position: absolute;
//     top: 22px;
//     left: 0;
//     background: #fff;
//     padding: 8px;
//     z-index: 999;
//     border-radius: 5px;
//     box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
// }
.dkq{
        width: 130px;
        height: 30px;
        background: #FF0000;
        border-radius: 4px;
        line-height: 30px;
        text-align: center;
        font-size: 18px;
        font-family: Source Han Sans CN;
        color: #FFE477;
        margin-right: 30px;
    }
    .footsCon{
        padding: 30px;
        border: 2px solid #0078FF; 
        border-radius: 5px;
    }
   .bqqText1{
		font-size: 18px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #222222;
	}
    .iconZ{
        width: 10px;
        height: 10px;
    }
	.bqqText2{
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #999999;
		text-align: center;
		margin: 4px 0;
	}
	.vipHalfs{
        text-align: center;
        width: calc(100%/7);
        margin: 18px 0 0 0;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #222222;
        img{
            width: 50px;
            height: 50px;
        }
	}
	.vipIconText{
		width: 72px;
		margin: 0 auto;
	}
</style>