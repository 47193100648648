<template>
    <div>
        <common-header @change="amountChange($event)" :message="beiji"></common-header>   
        <div class="topCon">

        <div class="topHalf">
           <div class="hTitle"><img style="width:451px;height:53px" src="../assets/images/bannerTitle.png" /></div>
           <div class="topTab">
              <el-tabs v-model="activeNameTop" @tab-click="handleClickTop">
                <el-tab-pane label="全部标讯" name="1">
                </el-tab-pane>   
                <el-tab-pane label="招标公告" name="2">
                </el-tab-pane>  
                <el-tab-pane label="中标结果" name="3">
                </el-tab-pane>    
                <el-tab-pane label="拟建项目" name="4">
                </el-tab-pane>    
              </el-tabs>
           </div>
           <div class="flex" style="justify-content: center;width: 1000px;margin:0 auto">
             <div class="ju-tinput">
                <input type="text" placeholder="请输入项目名称、关键词等" class="inputClass" v-model="searchCon">
                <img v-if="searchCon!=''&&searchCon!=null" src="../assets/images/guanbi.png" class="closeImg"  @click="del" />
                <div class="searchBtn" @click="searchClick">搜索</div>
             </div>
           </div> 
          </div>
       </div>
       <!-- 小喇叭通知 -->
       <div class="flex" style="margin: 12px auto;width: 1200px;">
            <img src="../assets/images/laba.png" style="width: 24px;height:19px;margin-right: 12px;">
            <div class="newsList" @mouseover="mouseOver" @mouseout="mouseLeave">
                <ul id="con1" ref="con1" :class="{ anim: animate == true }">
                    <li v-for="(item, key) in textA" :key="key" class="newsItem" @click="detailsNot(item.productMessageId)">
                        <span>{{ item.title }}</span>
                    </li>
                </ul>
            </div>
        </div>  
   
       <!-- 轮播 -->
        <div class="swiper ">
            <el-carousel height="150px">
            <el-carousel-item v-for="(items,index) in swipers" :key="index" >
                <img @click="showActive(index)" class="swiperImg" :src="items.src" />
            </el-carousel-item>
            </el-carousel>
        </div>

      <!-- 添加订阅 -->
       <div class="halfC tabCon" style="margin-top: 15px;" >
        <div class="tc_card_header flex">
            <div class="tc_card_title">
         
                 <div class="cCKqh3Ai jutSpace">
                    <div class="flex">
                        <span class="_3k2bXiHc">我的订阅</span>
                        <div class="MaFMvzl2" v-if="dyNotes==''&&dyNotes.length<=0">
                            目前暂无订阅，点击添加即可获得<span style="color:#FF0000">3天VIP</span>
                        </div>
                    </div>
                    <div class="ljaddBtn flex" @click="addDytk" v-if="dyNotes==''&&dyNotes.length<=0">
                        <img style="width:18px;height: 18px;margin-right: 2px;" src="../assets/images/addDyicon.png" />
                        添加订阅
                    </div>
                </div> 
            </div>
        </div>
        <div class="tc_card_body">
            <div class="body_half" v-for="(item,index) in dyNotes" :key="index">
                <div style="text-align: left;cursor: pointer;" @click="openDy(item.subscribeId)">{{item.keywords}}</div>
                <div class="" v-if="dyXqdetils!=''"> 
                    <div  v-for="(item,indexs) in dyXqdetils[index]" :key="indexs">
                       <div class="flex jutSpace half_rows">
                           <div class="blue" v-if="item.name!=undefined" @click="zbDetail(item.id)" :title="item.name" v-html="item.name"></div>
                           <div v-if="item.issueTimeStr!=undefined">{{item.issueTimeStr}}</div> 
                       </div> 
                    </div> 
                </div>
            </div>
            <el-button style="font-size: 13px;" v-if="dyNotes.length>0"><span @click="openDy('')">查看更多订阅内容</span></el-button> 
        </div>
      </div>  

      <!-- 筛选条件 -->
      <div class="halfC tabCon">
        <div v-if="isShow">
                   <div class="flex chooseArea" style="align-items: flex-start;">
                    <div style="color: #999999;width: 90px;line-height: 30px;">选择地区：</div>
                    <!-- 省 -->
                    <div class="flex areaCode " :style="{ 'max-height': status ? textHeight : '' }"
                                        :class="{ statusText: status }"
                                        ref="descs">
                            <div v-for="(item, index) in proviceList" :key="index" style="display: inline-block;">
                                <div  @click="proviceChang(index,item.areaCode)"   class="item" :class="{ selected: index == numberOne}">
                                    {{item.areaName}}
                                </div>
                            </div>
                    </div>
                   
                    <div
                        v-if="idShowText"
                        @click="showStaus"
                        :class="{ openSpan: status }"
                        class="openClose">{{ status ? "展开" : "收起" }}
                        <img v-if="status==true" class="jtImgsxsb" src="../assets/images/xiajt.png"  />
                        <img v-else class="jtImgsxsb" src="../assets/images/shangjt1.png"  />
                    </div>
                    </div>           
                    <!-- 市 -->
                    <div class="flex chooseArea" style="align-items: flex-start;margin-top:10px" v-if="isCity"> 
                    <div style="color: #999999;width: 90px;line-height: 30px;" >城市：</div>
                        <div class="flex areaCode" style=" margin-left:0;">
                            <div v-for="(item, index) in cityList" :key="item.id"  >
                                <div @click="cityChang(index,item.areaCode)" class="item"  :class="{ selected: index == numberTwo}">{{item.areaName}}</div>
                            </div>
                        </div> 
                    </div>
                    <!-- 县 -->
                    <div class="flex chooseArea" style="align-items: flex-start;margin-top:10px" v-if="isCountry"> 
                        <div style="color: #999999;width: 90px;line-height: 30px;" >区县：</div>
                        <div class="flex areaCode" style=" margin-left:0;">
                            <div v-for="(item, index) in areaList" :key="item.id"  >
                                <div @click="areaChang(index,item.areaCode)" class="item"  :class="{ selected: index == numberArea}">{{item.areaName}}</div>
                            </div>
                        </div> 
                    </div>  

         
            <div v-if="clickTabs!=5"> 
         <!-- 发布时间 -->
             <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px"  >
                <div style="color: #999999;width: 90px;line-height: 30px;">选择时间：</div>
                <div class="flex timeCode" >
                    <div v-for="(item, index) in timeList" :key="index"  >
                        <div v-if="item.optionCode==5" style="margin-right: 13px;"> 
                            <div style="position: relative;" class="faTime">
                                <el-select class="selectitem" v-model="valueYear" placeholder="往年" @change="hqYears($event,item.dictionaryOptionId)" 
                                :class="{selected: valueYear!==''&&valueYearzdy==''}">
                                    <el-option
                                    v-for="(item, index) in yearArr" :key="index"
                                    :label="item.value"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                                <img class="jtImgsxsa" src="../assets/images/jt.png"  />
                            </div> 
                        </div> 
                        <div v-else-if="item.optionCode==4">
                            <div style="position: relative;">
                                <el-date-picker 
                                v-model="valueYearzdy"
                                type="daterange"
                                range-separator=""
                                :class="{selected: valueYear==''&&valueYearzdy!==''}"
                                :picker-options="pickerOptions0"
                                @change="zdyYears(item.dictionaryOptionId)"
                                start-placeholder="自定义"
                                end-placeholder="">
                                </el-date-picker>
                            <img :class="valueYearzdy==''?'jtImg':'jtImgs'" src="../assets/images/jt.png"  />
                            </div> 
                        </div>

                        <div @click="timeChang(index,item.dictionaryOptionId)" class="item"  v-else
                        :class="{ selected: index == numberThree&&valueYear==''&&valueYearzdy==''}">
                        {{item.optionName}}</div>

                    </div>
                  
                </div>
             </div>  
            </div>
           

             <!-- 信息类型 -->  
            <div v-if="clickTabs==1">
                <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px"  >
                    <div style="color: #999999;width: 90px;line-height: 30px;">信息类型：</div>
                   <div>
                    <div class="flex timeCode" >
                        <div v-for="(item, index) in messageList" :key="index"  >
                            <div @click="messageChang(index,item.areaCode)" class="item"  :class="{ selected: index == number4}">{{item.areaName}}</div>
                        </div>
                    </div>
                    <div class="flex timeCode" style="margin-top: 10px;" v-if="number4!=''">
                        <div v-for="(item, index) in zbList" :key="index"  >
                            <div @click="messageChangs(index,item.dictionaryOptionId)" class="item"  :class="{ selected: index == number4s}">
                                {{item.optionName}}</div>
                        </div>
                     </div> 

                   </div>
                </div>
            </div>

            <div v-if="clickTabs!=1">
                <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px"  >
                    <div style="color: #999999;width: 90px;line-height: 30px;">信息类型：</div>
                   <div class="flex timeCode">
                        <div v-for="(item, index) in zbList" :key="index"  >
                            <div @click="messageChangs(index,item.dictionaryOptionId)" class="item"  :class="{ selected: index == number4s}">
                                {{item.optionName}}</div>
                        </div>
                     </div> 

                   </div>
               
            </div>

            <!-- 企业角色 -->
            <div class="flex chooseArea" style="align-items: flex-start;margin-top:20px" v-if="clickTabs==5">
                <div style="color: #999999;width: 90px;line-height: 30px;">企业角色</div>
                <div class="flex timeCode" >
                    <div v-for="(item, index) in zbList3" :key="index"  >
                        <div @click="zbChang(index,item.dictionaryOptionId,'4')" class="item"  :class="{ selected: index == number8}">{{item.optionName}}</div>
                    </div>
                </div>
            </div>  


            <!-- 更多筛选 -->
            <div class="flex chooseArea" style="align-items: center;margin-top:20px">
                <div style="color: #999999;width: 90px;line-height: 30px;">更多筛选：</div>
                <div class="flex timeCode aShaixuan" >

                    <div style="position: relative;margin-right: 12px;" v-if="clickTabs==5">
                      <div v-if="phoneNo!=''&&phoneNo!=null" >
                        <el-select  v-model="sxvalue9" placeholder="注册资本" @change="sxOne($event,'9')" @focus="moreSx()">
                            <div v-for="(item, index) in sxList9" :key="index" >
                                <div v-if="item.optionName=='自定义'"></div>
                                <el-option v-else
                                :label="item.optionName"
                                :value="item.dictionaryOptionId+','+item.optionName">
                            </el-option>
                            </div>
                            </el-select>
                            <img class="jtImgsx" src="../assets/images/jt.png"  />
                     </div>  
                     <div v-else @click="moreSx()" class="sxTexts">
                        <span >注册资本</span>
                        <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                     </div> 
                  </div>
                  <div style="position: relative;margin-right: 12px;" v-if="clickTabs==5">
                    <div v-if="phoneNo!=''&&phoneNo!=null" >
                     <el-select v-model="sxvalue10" placeholder="成立年限" @change="sxOne($event,'10')" @focus="moreSx()">
                        <div v-for="(item, index) in sxList10" :key="index">
                            <div v-if="item.optionName=='自定义'"></div>
                            <el-option v-else
                            :label="item.optionName"
                             :value="item.dictionaryOptionId+','+item.optionName">
                        </el-option>
                        </div>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                    </div>
                    <div v-else @click="moreSx()" class="sxTexts">
                        <span>成立年限</span>
                        <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                     </div> 
                  </div>


                  <div style="position: relative;margin-right: 12px;" v-if="clickTabs!==4&&clickTabs!=5">
                     <div v-if="phoneNo!=''&&phoneNo!=null" >
                     <el-select v-model="sxvalue1" placeholder="行业类型" @change="sxOne($event,'1')"  @focus="moreSx()">
                        <el-option
                        v-for="(item, index) in sxList1" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId+','+item.optionName">
                        </el-option>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                   </div>
                    <div v-else @click="moreSx()" class="sxTexts">
                        <span >行业类型</span>
                        <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                    </div> 
                  </div>

                  <div style="position: relative;margin-right: 12px;" v-if="clickTabs!=4&&clickTabs!=5">
                    <div v-if="phoneNo!=''&&phoneNo!=null" >
                     <el-select v-model="sxvalue2" placeholder="招采类型" @change="sxOne($event,'2')" @focus="moreSx()">
                        <el-option
                        v-for="(item, index) in sxList2" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId+','+item.optionName">
                        </el-option>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                    </div>
                    <div v-else @click="moreSx()" class="sxTexts">
                        <span >招采类型</span>
                        <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                    </div> 
                  </div>

                 <div style="position: relative;margin-right: 12px;" v-if="clickTabs==1||clickTabs==2">
                    <div v-if="phoneNo!=''&&phoneNo!=null" >
                      <el-select v-model="sxvalue4" placeholder="预算金额" @change="sxOne($event,'4')" @focus="moreSx()">
                        <div  v-for="(item, index) in sxList4" :key="index">
                            <div v-if="item.optionName=='自定义'"></div>
                            <el-option  v-else
                            @mouseover="mouseOver1(item.optionName)"
                            :label="item.optionName"
                            :value="item.dictionaryOptionId+','+item.optionName">
                            </el-option>
                        </div>
                     </el-select> 
                     <img class="jtImgsx" src="../assets/images/jt.png"  />
                   </div>
                   <div v-else @click="moreSx()" class="sxTexts">
                        <span >预算金额</span>
                        <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                    </div> 
                    </div>    

                  <div style="position: relative;margin-right: 12px;" v-if="clickTabs!=4&&clickTabs!=5">
                    <div v-if="phoneNo!=''&&phoneNo!=null" >
                     <el-select v-model="sxvalue5" placeholder="代理单位" @change="sxOne($event,'5')" @focus="moreSx()">
                        <el-option
                        v-for="(item, index) in sxList5" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId+','+item.optionName">
                        </el-option>
                     </el-select> 
                     <img class="jtImgsx" src="../assets/images/jt.png"  />
                    </div>
                    <div v-else @click="moreSx()" class="sxTexts">
                        <span >代理单位</span>
                        <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                    </div> 
                  </div>

                  <div style="position: relative;margin-right: 12px;" v-if="clickTabs==1||clickTabs==3">
                    <div v-if="phoneNo!=''&&phoneNo!=null" >
                        <el-select v-model="sxvalue6" placeholder="中标金额" @change="sxOne($event,'6')" @focus="moreSx()">
                            <div  v-for="(item, index) in sxList6" :key="index">
                                <div v-if="item.optionName=='自定义'"></div>
                                <el-option  v-else
                                :label="item.optionName"
                                :value="item.dictionaryOptionId+','+item.optionName">
                                </el-option> 
                            </div>
                        </el-select>
                        <img class="jtImgsx" src="../assets/images/jt.png"  />
                    </div>
                    <div v-else @click="moreSx()" class="sxTexts">
                        <span >中标金额</span>
                        <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                    </div> 
                  </div>

                  <div style="position: relative;margin-right: 12px;"  v-if="clickTabs==5">
                    <div v-if="phoneNo!=''&&phoneNo!=null" >
                        <el-select v-model="sxvalue77" placeholder="联系方式" @change="sxOne($event,'77')" @focus="moreSx()">
                            <el-option
                            v-for="(item, index) in sxList77" :key="index"
                            :label="item.optionName"
                            :value="item.dictionaryOptionId+','+item.optionName">
                            </el-option>
                        </el-select>
                        <img class="jtImgsx" src="../assets/images/jt.png"  />
                    </div>
                    <div v-else @click="moreSx()"  class="sxTexts">
                        <span>联系方式</span>
                        <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                    </div> 
                  </div>
                  <div style="position: relative;margin-right: 12px;"  v-else>
                    <div v-if="phoneNo!=''&&phoneNo!=null" >
                     <el-select v-model="sxvalue7" placeholder="联系方式" @change="sxOne($event,'7')" @focus="moreSx()">
                        <el-option
                        v-for="(item, index) in sxList7" :key="index"
                         :label="item.optionName"
                         :value="item.dictionaryOptionId+','+item.optionName">
                        </el-option>
                    </el-select>
                    <img class="jtImgsx" src="../assets/images/jt.png"  />
                   </div>
                   <div v-else @click="moreSx()"  class="sxTexts">
                        <span >联系方式</span>
                        <img style="width: 11px;height: 12px;margin-top:2px" src="../assets/images/jt.png"  />
                    </div> 
                  </div>
                </div>
            </div>


             </div>
      </div>

      <div class="halfC tabCon" style="margin-top: 15px;" id="tabsQ">
        
         <div v-if="clickTabs!=5">
         <div class="contentAll">
           <div class="ant-spin-nested-loading">
            <ul class="ant-list-items" v-for="(item,index) in listAll" :key="index">
                 <!-- 中标公告 -->
                 <div class="items-half">
                    <div class="tc_list_item tc_list_hover">
                        <div class="title" @click="zbDetail(item.id)">
                            <div v-html="item.name"></div> 
                        </div>
                        <div class="ant-content">
                            <div class="gGdJlei"  >
                                <span class="ant-tag ant-tag-orange bordered" v-if="item.dataType==1">{{item.dataTypeStr }} 
                                    <span v-if="item.dataSubTypeStr!=''&&item.dataSubTypeStr!=undefined">| {{item.dataSubTypeStr}}</span>
                                </span>
                                <span class="ant-tag ant-tag-green bordered" v-else-if="item.dataType==2">{{item.dataTypeStr }} 
                                   <span v-if="item.dataSubTypeStr!=''&&item.dataSubTypeStr!=undefined">| {{item.dataSubTypeStr}}</span>
                                </span>
                                <span class="ant-tag ant-tag-blue bordered" v-else>{{item.dataTypeStr }} 
                                    <span v-if="item.dataSubTypeStr!=''&&item.dataSubTypeStr!=undefined">| {{item.dataSubTypeStr}}</span>
                                </span>

                                <span class="ant-tag bordered" v-if="item.provinceCode!=''&&item.provinceCode!=undefined">
                                    <span v-if="item.provinceCode!=''&&item.provinceCode!=undefined">{{ item.provinceCode }}</span>
                                    <span v-if="item.cityCode!=''&&item.cityCode!=undefined"> | {{ item.cityCode }} </span>
                                    <span v-if="item.countyCode!=''&&item.countyCode!=undefined"> | {{ item.countyCode }} </span>
                                </span>  

                                <span  v-if="item.industryCategory!=''&&item.industryCategory!=undefined">
                                    <span class="ant-tag bordered"
                                    v-for="(items,indexs) in arrPao[index]" :key="indexs">
                                        {{ items }} 
                                    </span>
                                </span>
                                <span  v-if="item.procurementType!=''&&item.procurementType!=undefined">
                                    <span class="ant-tag bordered"
                                    v-for="(items,indexs) in arrPro[index]" :key="indexs">
                                        {{ items }} 
                                    </span>
                                </span>


                            </div>  
                            
                         
                            <div class="fiklj9cw" v-if="item.dataType==1">
                                <div class="smailDiv" v-if="item.bidCode!=''&&item.bidCode!=undefined">
                                    <div>项目编号:</div>
                                    <div>{{item.bidCode}}</div>
                                </div>
                                <div class="smailDiv" v-if="item.bidDeptName!=''&&item.bidDeptName!=undefined">
                                    <div>招采单位:</div>
                                    <div >{{ item.bidDeptName }}</div> 
                                </div>
                                <div class="smailDiv" v-if="item.agentDeptName!=''&&item.agentDeptName!=undefined">
                                    <div>代理单位:</div>
                                    <div >{{ item.agentDeptName }}</div>
                                </div>                            
                               
                                <div v-if="item.pageWinPackageList!=''&&item.pageWinPackageList!=undefined">
                                  <div class="fiklj9cw" v-for="(items,indexs) in item.pageWinPackageList" :key="indexs">
                                     <div class="smailDiv">
                                        <div>中标单位:</div>
                                        <div>{{ items.windeptname }}</div> 
                                    </div>
                                    <div class="smailDiv">
                                        <div>中标金额:</div>
                                        <div>{{items.winamountStr}}</div>
                                    </div> 
                                  </div>
                                </div> 
                                <div class="fiklj9cw">
                                    <div class="smailDiv" v-if="item.agentDeptPhone!=''&&item.agentDeptPhone!=undefined">
                                        <div>联系方式:</div>
                                        <div>{{item.agentDeptPhone}}</div>
                                    </div>
                                    <div class="smailDiv" v-if="item.issueTimeStr!=''&&item.issueTimeStr!=undefined">
                                        <div>发布时间:</div>
                                        <div>{{item.issueTimeStr}}</div>
                                    </div>
                                </div> 

                            </div>

                            <div class="fiklj9cw" v-else-if="item.dataType==2">
                                <div class="smailDiv" v-if="item.bidCode!=''&&item.bidCode!=undefined">
                                    <div>项目编号:</div>
                                    <div>{{item.bidCode}}</div>
                                </div>
                                <div class="smailDiv" v-if="item.bidDeptName!=''&&item.bidDeptName!=undefined">
                                    <div>招采单位:</div>
                                    <div >{{ item.bidDeptName }}</div> 
                                </div>
                                <div class="smailDiv" v-if="item.agentDeptName!=''&&item.agentDeptName!=undefined">
                                    <div>代理单位:</div>
                                    <div >{{ item.bidDeptName }}</div> 
                                </div>
                                <div class="fiklj9cw">
                                    <div class="smailDiv" v-if="item.issueTimeStr!=''&&item.issueTimeStr!=undefined">
                                        <div>发布时间:</div>
                                        <div>{{item.issueTimeStr}}</div>
                                    </div>
                                    <div class="smailDiv" v-if="item.openTimeStr!=''&&item.openTimeStr!=undefined">
                                        <div>审批时间:</div>
                                        <div>{{item.openTimeStr}}</div>
                                    </div>
                                </div> 
                            </div> 

                            <div class="fiklj9cw" v-else>
                                    <div class="smailDiv" v-if="item.bidCode!=''&&item.bidCode!=undefined">
                                        <div>项目编号:</div>
                                        <div>{{item.bidCode}}</div>
                                    </div>
                                    <div class="smailDiv" v-if="item.bidDeptName!=''&&item.bidDeptName!=undefined">
                                        <div>招采单位:</div>
                                        <div >{{ item.bidDeptName }}</div> 
                                    </div>
                                 

                                <div class="fiklj9cw">
                                    <div class="smailDiv" v-if="item.budgetAmountStr!=''&&item.budgetAmountStr!=undefined">
                                        <div>预算金额:</div>
                                        <div>{{item.budgetAmountStr}}</div>
                                    </div>
                                    <div class="smailDiv" v-if="item.agentDeptPhone!=''&&item.agentDeptPhone!=undefined">
                                        <div>联系方式:</div>
                                        <div>{{item.agentDeptPhone}}</div>
                                    </div> 
                                </div>
                                <div class="fiklj9cw">
                                    <div class="smailDiv" v-if="item.issueTimeStr!=''&&item.issueTimeStr!=undefined">
                                        <div>发布时间:</div>
                                        <div>{{item.issueTimeStr}}</div>
                                    </div>
                                    <div class="smailDiv" v-if="item.deadTimeStr!=''&&item.deadTimeStr!=undefined">
                                        <div v-if="item.dataSubType==101">预计采购时间:</div>
                                        <div v-else>投标截止时间:</div>
                                        <div>{{item.deadTimeStr}}</div>
                                    </div>
                                </div> 
                            </div>


                        </div>
                    </div>
                </div>
            
            </ul>  
           </div>
         </div>
         </div>
         <div v-else>
            <!-- 标讯企业 -->
            <div class="ant-spin-container">
                <div class="ant-list-items" v-for="(item,index) in gysList" :key="index">
                    <div class="tc_list_item tc_list_hover">
                        <div class="R-Dvn3zA">
                            <div class="pYreFzHC">
                                <img :src="item.logo" />
                            </div>
                            <div class="_0aDOKXj6">
                                <div class="FpwufnYn flex" @click="companyDetail(item.id)">
                                    <div  class="titles">{{item.name}}</div>
                                    <!-- <div class="WMSbIy8X flex"> -->
                                    <div class="">招投标数：<span style="color:red">{{item.bidNum}}</span></div>
                                   <!-- </div> -->
                                </div>
                                <div class="gGdJlei">
                                   <!-- <span class="ant-tag ant-tag-green bordered">{{item.categoryCode}}</span> -->
                                   <span class="ant-tag ant-tag-green bordered" v-if="item.regStatus!=undefined">{{item.regStatus}}</span>
                                   <span class="ant-tag ant-tag-blue bordered" v-if="item.dataUnitType==0">招采单位</span>
                                   <span class="ant-tag ant-tag-blue bordered" v-if="item.dataUnitType==1">中标单位</span>
                                   <span class="ant-tag ant-tag-blue bordered" v-if="item.dataUnitType==2">建设单位</span>
                                    <span class="ant-tag  bordered" v-if="item.companyOrgType!=undefined">{{item.companyOrgType}}</span>
                                    
                                </div>

                                
                                <div class="flex fiklj9cw" >
                                    <div class="smailDiv" v-if="item.legalEntityId!=''&&item.legalEntityId!=undefined">
                                        <div >法定代表人:</div>
                                        <div>{{item.legalEntityId}}</div>
                                    </div>
                                    <div class="flex smailDiv" v-if="item.regCapitalAmount!=''&&item.regCapitalAmount!=undefined">
                                        <div>注册资本:</div>
                                        <div>{{item.regCapitalAmount}}</div>
                                    </div>
                                    <div class="flex smailDiv" v-if="item.estiblishTime!=''&&item.estiblishTime!=undefined">
                                        <div >成立时间:</div>
                                        <div>{{item.estiblishTime}}</div>
                                    </div>
                                </div>

                                <div class="flex fiklj9cw" >
                                    <div class="flex smailDiv" v-if="item.regLocation!=''&&item.regLocation!=undefined">
                                        <div >企业地址:</div>
                                        <div>{{item.regLocation}}</div>
                                    </div>
                                    <div class="flex smailDiv" v-if="item.result!=''&&item.result!=undefined">
                                        <div >联系方式:</div>
                                        <div>{{item.result}}</div>
                                    </div>
                                </div>
                         
                              <div  style=" margin: 10px 0;">
                                <div class="flex fiklj9cw jutRowsA" v-if="item.scripingpage!=''&&item.scripingpage!=undefined">
                                    <div class="flex smailDiv" >
                                         <div>{{item.scripingpage.issueTime}}</div> 
                                    </div>
                                     <div class="flex smailDiv" v-if="item.scripingpage.dataType==1">
                                        <div >中标结果 | 中标</div>
                                    </div>
                                    <div class="flex smailDiv" v-else>
                                        <div >招标公告 | 招标</div>
                                    </div>
                                    <div class="flex smailDiv" v-if="item.scripingpage.provinceCode!=''&&item.scripingpage.provinceCode!=undefined">
                                        <div>{{item.scripingpage.provinceCode}}</div>
                                    </div>
                                    <div class="flex smailDiv" style="max-width: 50%;margin-top: 11px;">
                                       <div  @click="zbDetail(item.scripingpage.id)" style="color: #0078FF;cursor: pointer;width: 100%;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{item.scripingpage.name}}</div>
                                    </div>
                                    <div class="flex smailDiv">
                                        <div>{{ item.scripingpage.enterpriseRole }}</div>
                                        <!-- <div v-if="item.scripingpage.dataType==1">中标单位</div> -->
                                        <!-- <div v-if="item.scripingpage.enterpriseRole==0"></div>
                                        <div v-else-if="item.scripingpage.enterpriseRole==1">中标单位</div>
                                        <div v-else>代理单位</div> -->
                                    </div> 
                                </div>
                              </div>
                             
                                <div class="flex fiklj9cw" >
                                    <div class="flex" style="overflow: hidden;" v-if="item.zcProductNames!=''&&item.zcProductNames!=undefined">
                                        <div >招采产品:</div>
                                        <div class="zcPr">{{item.zcProductNames}}</div>
                                    </div>
                                    <div class="flex" style="overflow: hidden;" v-if="item.zbProductNames!=''&&item.zbProductNames!=undefined">
                                        <div >中标产品:</div>
                                        <div class="zcPr">{{item.zbProductNames}}</div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div> 
         </div>

      <!-- 弹框二维码支付 -->
      <div class="tkPay">
        <el-dialog
        :visible.sync="ewmVisible"
        width="760px"
        :before-close="handleClose">
        <div class="nav" v-if="isPay==true">
            <div class="ktText">开通产品套餐，查看更多数据</div>
            <div class="nav-top">
               <div v-if="isChao">
                    <el-tabs v-model="activeNameT" @tab-click="handleClickT">
                        <el-tab-pane name="1">
                            <div slot="label">
                                <div class="bzImgs"><img style="margin-right:9px;width: 49px;height: 18px;" src="../assets/images/hot.png" />单条购买</div>
                                <div class="bqqStext">标圈圈，找更多商机！</div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane name="2" >
                            <div slot="label">
                                <div class="bzImgs"><img style="margin-right:9px;width: 38px;height: 18px;" src="../assets/images/vip.png" />标准版</div>
                                <div class="bqqStext">标圈圈，找更多商机！</div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>


                    <div class="firstContent" v-if="activeNameT==2">
                        <div style="margin-bottom: 16px;font-size: 18px;">选择合适套餐，查看更多详情！</div>
                        <div class="nav-topA flex aligenCenter">
                            <div class="tao-half-row maright" @click="taocan('1')">
                                <div :class="vipFlag==1?'danTk':'danTk2'">
                                    <div class="taoTtile">基准价</div>
                                    <div style="margin: 10px 0 0 0;">
                                        <span style="color:#FF0000">¥<span style="font-size:30px;">599</span></span>
                                        <span>/3个月</span>
                                    </div>
                                    <div class="danTexts flex jutCenter">
                                        <div class="flex jutCenter danH" >每天仅需6.6元</div>
                                    </div>
                                </div>
                                <img v-if="vipFlag==1" class="tkgouImg" src="../assets/images/gou.png" />
                            </div>
                            <div class="tao-half-row maright" @click="taocan('2')">
                                <div  :class="vipFlag==2?'danTk':'danTk2'">
                                    <div class="taoTtile">用户优惠</div>
                                    <div style="margin: 10px 0 0 0;">
                                        <span style="color:#FF0000">¥<span style="font-size:30px;">999</span></span>
                                        <span>/6个月</span>
                                    </div>
                                    <div class="danTexts flex jutCenter">
                                        <div class="saleprice">原价¥1198</div>
                                    </div>
                                </div>
                                <div class="dwZhek">83折</div>
                                <img v-if="vipFlag==2" class="tkgouImg" src="../assets/images/gou.png" />
                            </div>
                            <div class="tao-half-row" @click="taocan('3')"> 
                                <div :class="vipFlag==3?'danTk':'danTk2'">
                                    <div class="taoTtile">超值推荐</div>
                                    <div style="margin: 10px 0 0 0;">
                                        <span style="color:#FF0000">¥<span style="font-size:30px;">1599</span></span>
                                        <span>/12个月</span>
                                    </div>
                                    <div class="danTexts flex jutCenter">
                                        <div class="saleprice">原价¥2396</div>
                                    </div>
                                </div>
                                <div class="dwZhek">67折</div>
                                <img v-if="vipFlag==3" class="tkgouImg" src="../assets/images/gou.png" />
                            </div> 
                        </div>

                        <div class="nav-middle">
                            <div class="flex nav-middleCon">
                                <div class="dkq" v-if="couponUserId!=''">{{quanList[0].couponInfo.couponAmount}}抵扣券</div>
                                <div class="sjPay">实付金额：<span style="color:#FF0000;margin-bottom: -6px;">￥<span style="font-size: 30px;">{{totalPrice}}</span></span></div>
                            </div>
                            <div class="flex jutCenter"><div class="sureBtn" @click="payfor">立即开通</div></div>
                        </div>
                        <div style="font-size: 12px;">注：试运营期VIP可通过<span style="cursor: pointer;color:#0078FF" @click="Sign">点击上方领取VIP会员</span>获得，我们提供多种活动给大家赠送VIP体验机会，
                            支付购买后<span><router-link to="/userCenter?actives=8" style="color:#0078FF">发票</router-link></span>可在个人中心申请。</div>
                    </div>


                   <div class="firstContent" v-else>
                        <div class="tao-half-row maright">
                            <div class="danTk">
                                <div class="taoTtile">单条标讯</div>
                                <div style="margin: 10px 0 0 0;">
                                    <span style="color:#FF0000">¥<span style="font-size:30px;">0.5</span>元</span>
                                    <span>/条</span>
                                </div>
                                <div class="danTexts flex jutCenter">
                                    <div class="flex jutCenter danH" >永久查看</div>
                                </div>
                            </div>
                            <img class="tkgouImg" src="../assets/images/gou.png" />
                        </div>

                        <div class="nav-middle">
                            <div class="flex jutCenter"><div class="sureBtn" @click="payfor" style="margin-top: 10px;">立即开通</div></div>
                        </div>

                        <div style="font-size: 12px;">注：试运营期VIP可通过<span style="cursor: pointer;color:#0078FF" @click="Sign">点击上方领取VIP会员</span>获得，我们提供多种活动给大家赠送VIP体验机会，
                            支付购买后<span><router-link to="/userCenter?actives=8" style="color:#0078FF">发票</router-link></span>可在个人中心申请。</div>
                   </div>
               </div>
               <!-- <div v-else class="tabs">
                  <div class="btn active"  style="text-align: center;">
                            <div>高级VIP</div>
                            <div style="font-size: 12px;color: #999999;">更多特权，超值服务</div>
                            <div style="font-size: 30px;color: #999999;">敬请期待</div>
                            <i class="tc_purchase-order_check"></i>
                    </div>
               </div> -->
            </div>

            <div  class="nav-tabel">
                <div class="footsCon">
                    <div class="flex aligenCenter jutCenter bqqText1">
                        <img class="iconZ" src="../assets/images/vipJx.png" />
                        <div style="margin:0 5px;">标准版会员特权</div>
                        <img class="iconZ" src="../assets/images/vipJx.png" />
                    </div>	
                    <div class="bqqText2">标圈圈，找更多商机！</div>
                    <div class="flex" style="flex-wrap: wrap;align-items: flex-start;">
                        <div class="vipHalfs" v-for="(item,index) in bqqList" :key="index">
                            <img class="vipIcons" :src="item.url" />
                            <div class="vipIconText">{{item.text}}</div>
                        </div>
                    </div>
                </div>              
            </div>
            
        </div>

        <div v-else class="navK">
            <div class="ktText">开通产品套餐，查看更多数据</div>
            <div class="paySuccess" v-if="paySuccess">
                <el-alert :title="messageTitle" type="success"  center show-icon :closable="false"></el-alert>
            </div>
            <div class="fYcolor">
                <div class="cpLeft">
                    <img :src="ewmImg" class="ewmImg" />
                    <div class="flex jutCenter">
                        <img src="../assets/images/sao.png" style="width:17px;height:17px;margin-right: 5px;" />
                        <div style="color:#999999">使用<span style="color:#1E88FF">微信</span>扫码支付</div>
                    </div>
                </div>
                <div class="cpRight">
                    <div>开通账号：{{phoneNo}}</div>
                    <div>
                        <div>开通套餐：{{orderPay.name}}</div>
                        <div>到期时间：{{orderPay.days}}</div>
                        <div v-if="orderPay.days=='永久'">实付金额：<span style="color:#FF0000">￥<span style="font-size:30px">{{orderPay.salePrice}}</span>元/条</span></div>
                        <div v-else>实付金额：<span style="color:#FF0000">￥<span style="font-size:30px">{{orderPay.salePrice}}</span>元/年</span></div>
                        <div style="color: #999999;margin-top: 6px;">发票获取：完成支付后可在【个人中心-我的发票】中申请</div>
                    </div>
                    <!-- <div style="color: #999999;margin-top: 5px;">发票获取：完成支付后可在【个人中心-我的发票】中申请</div> -->
                    
                </div>
            </div>
        </div>
        </el-dialog>
      </div>

       <!-- 分页 -->
       <div class="pagination">
        <el-pagination
            @current-change="handleCurrentChange"
            background
            :current-page="currentPage"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
       </div>
      </div>

      <!-- 弹框添加订阅 -->
      <div class="addDy">
            <el-dialog
            title="我的订阅"
            :visible.sync="dialogVisible"  @close="closeDy"
            width="65%">
           
            <div class="dy-form-item flex">
                <div class="gjTitle xtWidth">
                    <!-- <img style="width:4px;height: 4px;margin-bottom: 7px;" src="../assets/images/redIcon.png" /> -->
                    关键词:</div>
                    <div class="flex timeCode">
                        <div class="flex" v-if="keyWordsAs!=''&&keyWordsAs.length>0" style="flex-wrap: wrap;">
                            <div class="tc_tag-input_roots" v-for="(item,index) in keyWordsAs" :key="index">
                                <span>{{item}}</span>
                                <img src="../assets/images/guanbi1.png" @click="deletess(item,index)" style="width: 11px;height: 12px;margin-left: 4px;margin-top: 3px;" />
                            </div>
                        </div>
                        <div class="flex" v-if="keyWordsAs.length<10">
                            <div class="ju-tinputs">
                                <input type="text" placeholder="请输入关键词" class="inputClassss" v-model="addContents">
                                <img v-if="addContents!=''&&addContents!=null" src="../assets/images/guanbi.png" class="closeImg"  @click="dels" />
                                <div class="searchBtns" @click="addClicks">添加</div>
                            </div>
                        </div>
                    </div>
            </div>
             
            <div style="margin-bottom:15px">  
                    <!-- 选择地区--省 -->
                    <div class="flex chooseArea" style="align-items: flex-start;">
                        <div class="gjTitle xtWidth">选择地区:</div>
                            <!-- 省 -->
                            <div class="flex areaCode " :style="{ 'max-height': status ? textHeight : '' }"
                                                :class="{ statusText: status }" ref="descs">
                                    <div v-for="(item, index) in proviceList" :key="index" style="display: inline-block;">
                                        <div  @click="dyproviceChang(index,item.areaCode,item.areaName)"   class="item" :class="{ selected: item.areaCode == dyprovinceCode}">
                                            {{item.areaName}}
                                        </div>
                                    </div>
                            </div>
                    
                            <div
                                v-if="idShowText"
                                @click="showStaus"
                                :class="{ openSpan: status }"
                                class="openClose">{{ status ? "展开" : "收起" }}
                                <img v-if="status==true" class="jtImgsxsb" src="../assets/images/xiajt.png"  />
                                <img v-else class="jtImgsxsb" src="../assets/images/shangjt1.png"  />
                            </div> 
                        </div>
                        <!-- 市 -->
                        <div class="flex chooseArea" style="align-items: flex-start;margin-top:8px" v-if="dyisCity"> 
                        <div style="line-height: 30px;width: 88px;" >城市：</div>
                            <div class="flex areaCode" style=" margin-left:0;">
                                <div v-for="(item, index) in cityList" :key="item.id"  >
                                    <div @click="dycityChang(index,item.areaCode,item.areaName)" class="item"  :class="{ selected: index == dynumberTwo}">{{item.areaName}}</div>
                                </div>
                            </div> 
                        </div>
                        <!-- 县 -->
                        <div class="flex chooseArea" style="align-items: flex-start;margin-top:8px" v-if="dyisCountry"> 
                            <div style="line-height: 30px;width: 88px;" >区县：</div>
                            <div class="flex areaCode" style=" margin-left:0;">
                                <div v-for="(item, index) in areaList" :key="item.id"  >
                                    <div @click="dyareaChang(index,item.areaCode,item.areaName)" class="item"  :class="{ selected: index == dynumberArea}">{{item.areaName}}</div>
                                </div>
                            </div> 
                        </div>     
            </div>

            <div class="flex" style="margin-bottom:15px">
                <div class="flex chooseArea" style="align-items: flex-start;"  >
                    <div class="gjTitle xtWidth">
                        信息类型:</div>
                   <div>
                    <div class="flex timeCode" >
                        <div v-for="(item, index) in messageList" :key="index"  >
                            <div @click="dymessageChang(index,item.areaCode,item.areaName)" class="item"  :class="{ selected: index == dynumber4}">{{item.areaName}}</div>
                        </div>
                    </div>
                    <div class="flex timeCode" style="margin-top: 8px;" v-if="dynumber4!=''">
                        <div v-for="(item, index) in zbList" :key="index"  >
                            <div @click="dymessageChangs(index,item.dictionaryOptionId,item.optionName)" class="item"  :class="{ selected: index == dynumber4s}">
                                {{item.optionName}}</div>
                        </div>
                     </div> 

                   </div>
                </div>
            </div>

             <div class="ant-row">
                  <div class="gjTitles flex" style="align-items: flex-start;">
                    <div class="xtWidth">微信通知：</div>
                    <div>
                      <div>
                        <el-radio v-model="notice2" label="1">是</el-radio>
                        <el-radio v-model="notice2" label="0">否</el-radio>
                      </div>
 
                      <div class="bangding" v-if="wechatStatus==1">订阅消息将推送至绑定微信 
                        <!-- <span @click="jiebang('1')" style="color: #409EFF;cursor: pointer;" >解绑</span> -->
                      </div>
 
                       <div class=" bangding"  v-else>
                            <div>请先
                            <span style="color: #409EFF;cursor: pointer;" @mouseover="mouseOver" @mouseleave="mouseLeave">绑定微信</span>
                            绑定后订阅信息将推送至微信
                            </div>
                            <div ref="wechatEwm" class="ewmImg" style="display:none">
                                <img src="../assets/images/gzhEwm.jpg" style="width:100px;height:100px" />
                            </div>
                       </div>
                    </div>
                  </div> 
            </div>

            <div class="ant-row">
                <div class="gjTitles flex" style="align-items: flex-start;">
                    <div class="xtWidth">邮件通知：</div>
                    <div style="width: 75%;">
                      <div>
                        <el-radio v-model="notice3" label="1">是</el-radio>
                        <el-radio v-model="notice3" label="0">否</el-radio>
                      </div>
                      <div class="bangding" v-if="emailA!=null&&emailA.length>0">订阅消息将推送至绑定邮箱
                         <span @click="jiebang('2')" style="color: #409EFF;cursor: pointer;">解绑</span> 
                      </div>
                      <div v-else style="margin-top:10px">
                        <div v-if="notice3!=0">
                            <el-input
                            type="text"
                            maxlength="50" 
                            placeholder="请输入邮箱号"
                            v-model="emailCon">
                        </el-input>
                        </div>
                      </div>
                    </div>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDy()" style="margin-right: 20px;">取 消</el-button>
                <el-button type="primary" @click="addDys()" >确 定</el-button>
            </span>
            </el-dialog>
        </div>
 
      <common-foot></common-foot>

    <common-active :showTk="beiji" :showActivity="flagActivity" @change="amountChanges($event)"></common-active>

    </div>
</template>

<script>
   import CommonHeader from '@/components/CommonHeader.vue';
   import CommonFoot from '@/components/CommonFoot.vue';
   import IndexOne from '@/components/IndexOne.vue';
   import CommonActive from '@/components/CommonActive.vue';
    import { reactive } from 'vue';
    export default {
      components:{
       CommonHeader,
       IndexOne,
       CommonFoot,
       CommonActive
       }, 
        data() {
            return {
                vipEndtime:'',
                activeNameS:'1',
                activeName:'1',
                swipers:[
                    {src:require('../assets/images/bannerYj.png')},
                    {src:require('../assets/images/banner11.png')},
                ],
                showAll:false,
                isShow:true,
                currentPage: 1,
                pageSize:10,
                total:0,
                pxvalue:'智能排序',
                pxvalue2:'智能排序',
                pxIndex:'1',
                intervalId:null,
                textA:[],
                animate:false, //控制过渡动画是否开启   
      
                tabsArrA:[
                    {label:'全部标讯',name:'1'},
                    {label:'招标公告',name:'2'},
                    {label:'中标公告',name:'3'},
                    {label:'拟建项目',name:'4'},
                    // {label:'标讯企业',name:'5'},
                ],
                clickTabs:'1',
                gysList:[ ],
                listAll:[],
                newArr:[]  ,


                numberOne: 0,
                numberTwo:'',
                numberArea:'',
                numberThree:'',
                number4:0,
                qyClass:0,
                provinceCode:'',
                cityCode:'',
                areaCode:'',
                proviceList:[],
                cityList:[],
                isCity:false,
                areaList:[],
                isCountry:false,
                timeCode:'',
                timeList:[],
                valueYear: '',
                messageList:[
                    {areaCode:'',areaName:'全部'},
                    {areaCode:'1',areaName:'招标公告'},
                    {areaCode:'2',areaName:'中标结果'},
                    {areaCode:'3',areaName:'拟建项目'},
                ],

                messageLists:[],
                number4s:0,
                messageCode:'',
                messageCodesA:'',

                messageCode:'',
                sxList1:[],
                sxvalue1: '',
                sxList2: [ ],
                sxvalue2: '',
                sxList3: [],
                sxvalue3: '',
                sxList4: [ ],
                sxvalue4: '',
                sxList5: [ ],
                sxvalue5: '',
                sxList6: [],
                sxvalue6: '',
                sxList7: [ ],
                sxvalue7: '',
                sxList8: [ ],
                sxvalue8: '',

                sxList9: [],
                sxvalue9: '',
                sxList10: [ ],
                sxvalue10: '',
                sxList11: [ ],
                sxvalue11: '',
                sxList12: [ ],
                sxvalue12: '',

                sxList77: [ ],
                sxvalue77: '',
                sxvalue77s: '',

                valueYearzdy:'',
                startTime:'',
                endTime:'',
                yearArr:[],
                pickerOptions0: {
                    disabledDate(time) {
                        return time.getTime() > Date.now() - 8.64e6;//如果没有后面的-8.64e6就是不可以选择今天的
                     }
                }, 
                aa:'',
                number5:0,
                number6:0,
                number7:0,
                number8:0,
                zbList:[],
                zbvalue:'',
                zbList1:[],
                zbvalue1:'',
                zbList2:[],
                zbvalue2:'',
                zbList3:[],
                zbvalue3:'',
                zdyTime:'',
                messClick:'',
                pxLabel:'',
                searchCon:'',
                qyflag1:'',
                qyflag2:'',
                qyflag3:'',
                
                form: {
                    price1:'',
                    price2:'',
                },

                sxvalue1s: '',
                sxvalue2s: '',
                sxvalue4s: '',
                sxvalue5s: '',
                sxvalue6s: '',
                sxvalue7s: '',
                sxvalue9s: '',
                sxvalue10s: '',

                //签到
                beiji:false,

                textHeight: null,
                status: false,
                idShowText: false,

                activeNameTop:'1',
                phoneNo:'',
                vipAuth:'',
                level:[],
                ewmVisible:false,
                isPay:true,
                isChao:true,

                dialogVisible:false,
                gjname:'',  //订阅名称
                gjtext:'',  //关键词
                optionTime: [{
                    value: '0',
                    label: '0:00'
                    }, {
                    value: '1',
                    label: '1:00'
                    }, {
                    value: '2',
                    label: '2:00'
                    }, {
                    value: '3',
                    label: '3:00'
                    }, {
                    value: '4',
                    label: '4:00'
                   }, {
                    value: '5',
                    label: '5:00'
                    }, {
                    value: '6',
                    label: '6:00'
                    }, {
                    value: '7',
                    label: '7:00'
                    }, {
                    value: '8',
                    label: '8:00'
                   }, {
                    value: '9',
                    label: '9:00'
                   }, {
                    value: '10',
                    label: '10:00'
                    }, {
                    value: '11',
                    label: '11:00'
                    }, {
                    value: '12',
                    label: '12:00'
                    },
                    {
                    value: '13',
                    label: '13:00'
                    }, {
                    value: '14',
                    label: '14:00'
                   }, {
                    value: '15',
                    label: '15:00'
                    }, {
                    value: '16',
                    label: '16:00'
                    }, {
                    value: '17',
                    label: '17:00'
                    }, {
                    value: '18',
                    label: '18:00'
                   }, {
                    value: '19',
                    label: '19:00'
                   }, {
                    value: '20',
                    label: '20:00'
                    }, {
                    value: '21',
                    label: '21:00'
                    }, {
                    value: '22',
                    label: '22:00'
                    },{
                    value: '23',
                    label: '23:00'
                    }
                ],
                timeS:'9:00',
                notice1:'0',
                notice2:'1',
                notice3:'1',
                notice4:'1',
                dyNotes:[],
                goodsId:'7163018607638296736',   //vip套餐id
                objectIds:'', //点击详情时的Id
                objectIds2:'', //点击详情时的公司Id
                timerT: null, // 定时器
                timerTs: null, // 定时器
                orderPay:[],
                orderPays:[],
                ewmImg:'',
                paySuccess:false,
                totalTime:3,
                messageTitle:'',
                tempPage:'',

                orderInfoId:'',

                isFlag:1,
                dyXqdetils:[],
                newUrl:'',
                isDl:'',
                arrs:[],

                level:'',
                emailA:'',
                emailCon:'',
                userInfos:[],
                wechatStatus:'',
                tabsArr:[],


                bqqList:[
					{id:1,text:'招标公告',url:'https://www.biaox.cn/webcrawler/wechat/images/zbvipIcon1.png'},
					{id:2,text:'中标公告',url:'https://www.biaox.cn/webcrawler/wechat/images/zbvipIcon2.png'},
					{id:3,text:'拟建项目',url:'https://www.biaox.cn/webcrawler/wechat/images/zbvipIcon3.png'},
					{id:4,text:'招标预告',url:'https://www.biaox.cn/webcrawler/wechat/images/zbvipIcon4.png'},
					{id:5,text:'精选项目',url:'https://www.biaox.cn/webcrawler/wechat/images/zbvipIcon5.png'},
					{id:6,text:'审批项目',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon6.png'},
					{id:7,text:'企业直采',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon7.png'},
					{id:8,text:'政府采购',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon8.png'},
					{id:9,text:'询价订单',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon9.png'},
					{id:10,text:'谈判采购',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon10.png'},
					{id:11,text:'项目分包',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon11.png'},
					{id:12,text:'采购意向',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon12.png'},
					{id:13,text:'招标订阅',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon13.png'},
					{id:14,text:'中标订阅',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon14.png'},
					{id:15,text:'拟建订阅',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon15.png'},
					{id:16,text:'邮件提醒',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon16.png'},
					{id:17,text:'系统提醒',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon17.png'},
					{id:18,text:'微信提醒',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon18.png'},
					{id:19,text:'标讯导出',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon19.png'},
					{id:20,text:'附件查看',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon20.png'},
					
					{id:21,text:'企业监控',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon21.png'},
					{id:22,text:'企业分析',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon22.png'},
					{id:23,text:'业务渠道拓展',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon23.png'},
					{id:24,text:'商机分析预测',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon24.png'},
					{id:25,text:'AI大数据报告',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon25.png'},
					{id:26,text:'项目分析报价分析',url:'https://www.biaox.cn/webcrawler/wechat/images/vipIcon26.png'},
				],
                vipFlag:'3',
                quanList:[],
				couponUserId:'',
                activeNameT:'1',
                totalPrice:'1599',
                arrPro:[],
				arrPao:[],

                dynumberOne: 0,
                dynumberTwo:'',
                dynumberArea:'',
                dynumberThree:'',
                dyprovinceCode:'',
                dycityCode:'',
                dyareaCode:'',
                dyisCity:false,
                dyisCountry:false,

                dynumber4:0,
                dynumber4s:0,
                dymessageName:'',
                dymessageCodesA:'',
                keyWords:[],
                keyWordsAs:[],
                addContents:'',
                flagActivity:'',
            }
        },
      
        computed:{
            flag:function(){
                if(this.showAll == false){
                    this.isShow=true
                    return '收起'
                }
                else{
                    this.isShow=false
                    return '展开'
                }
            },
        },
        mounted() {
            this.phoneNo=JSON.parse(localStorage.getItem('phoneNo'))
             //获取当前年份的前4年
             let now   = new Date();
            var y = now.getFullYear();
            var m = now.getMonth() + 1;
            var d = now.getDate();
            m = m < 10 ? "0" + m : m; //月小于10，加0
            d = d < 10 ? "0" + d : d; //day小于10，加0
            this.vipEndtime=y+1 + "-" + m + "-" + d

            let yearn  = now.getFullYear();
            let yearArrs=[]
            yearArrs.push(yearn-4,yearn-3,yearn-2,yearn-1)
            this.yearArr=[
                {label:1,value:yearArrs[0]},
                {label:2,value:yearArrs[1]},
                {label:3,value:yearArrs[2]},
                {label:4,value:yearArrs[3]},
            ]

            this.sxList('pub_date')  //发布时间
            this.sxList('industry_category')  //更多筛选-发布类型
            this.sxList('bidding_procurement_type')  //招采类型
            this.sxList('bidding_unit')   //招采单位
            this.sxList('budget_amount')  //预算金额
            this.sxList('agency')  
            this.sxList('bid_winning_amount')    //中标金额
            this.sxList('contact_information')  
            this.sxList('en_contact_information')
            this.sxList('enclosure')  
            this.sxList('bidding_type')     //招标类型
            this.sxList('bid_winning_type') 
            this.sxList('approval_stage') 
            this.sxList('enterprise_role')

            this.sxList('registered_capital') 
            this.sxList('establishment_period')
            this.sxList('certificate_information') 
            this.sxList('building_qualification')

            this.showDy()
         
           this.provice('')

           this.notice()

       
           this.timer=setInterval(this.scroll, 3000);

           this.querInfo()

            this.paySuccess=false
            this.isPay=true
            clearInterval(this.timerT)
            this.totalTime = 3
            this.timerT = null
            //查询用户信息
            this.queryInfos()
           
            this.$nextTick(() => {
                setTimeout(() => {
                    this.calculateText()
                }, 500)
            })
        },
        destroyed () {
            let that=this
            clearInterval(that.timerT)
            that.totalTime = 3
            that.timerT = null
        },
        methods: {
            Sign(){
                let that=this
                that.ewmVisible=false
                that.isChao=false
                setTimeout(()=>{
                    that.amountChanges('true','1')
                },1000)
            },
            amountChange(e){
              this.beiji=e
              this.flagActivity='1'
            },
            amountChanges(e,flag){ 
              this.beiji=e
              if(flag!=undefined){
                this.flagActivity=flag
              }
            },
            showActive(flag){
                //传值给子组件
                let that=this
                that.amountChanges('true',flag)
               
            },
            scroll() {
                this.animate = true; // 因为在消息向上滚动的时候需要添加css3过渡动画，所以这里需要设置true
                setTimeout(() => {
                    this.textA.push(this.textA[0]); // 将数组的第一个元素添加到数组的
                    this.textA.shift(); //删除数组的第一个元素
                    this.animate = false; // margin-top 为0 的时候取消过渡动画，实现无缝滚动
                }, 1000);
            },
            mouseOver(){
              clearInterval(this.timer)
            },
          
            del(){
               this.searchCon=''
               if(this.clickTabs==5){
                this.showQyList(this.currentPage,this.qyflag1,this.qyflag2,this.qyflag3)
               }else{
                this.showZbList(this.currentPage,this.messClick,this.pxLabel)
               }
            },
            detailsNot(noticeId){
                const {href}=this.$router.resolve({
                // 跳转到的页面路径
                path: '/noticeDetails',
                // 传递的参数集合
                query: {
                    noticeId: noticeId
                }
              })
              window.open(href,'_blank')
            }, 
            // 查询是否有优惠券  
            showQuan(){
				let that = this;
                that.$axios.post('/webInterface/queryCouponUserList?couponType=3'+'&useState=1')
                    .then(function (res) {
                        //console.log(res);
                        if(res.data.resultcode==1){
                            that.quanList=res.data.couponUserList
                            if(that.quanList.length>0){
                                if(that.vipFlag==1){
                                    that.totalPrice=599-that.quanList[0].couponInfo.couponAmount
                                }else if(that.vipFlag==2){
                                    that.totalPrice=999-that.quanList[0].couponInfo.couponAmount
                                }else{
                                    that.totalPrice=1599-that.quanList[0].couponInfo.couponAmount
                                }
                                that.couponUserId=that.quanList[0].couponUserId
                            }else{
                                that.couponUserId=''
                                if(that.vipFlag==1){
                                    that.totalPrice=599
                                }else if(that.vipFlag==2){
                                    that.totalPrice=999
                                }else{
                                    that.totalPrice=1599
                                }

                            }
                        }
                    })
                    .catch(function (err) { 
                    })
			},
            notice(){
                this.$axios.post('/webInterface/getPublishMessageList?type=1').then(res => {
                    if (res.data.noticeList.length>0) {
                     this.textA=res.data.noticeList
                    }
                }).catch(err => {
                })
            },
            handleClickTop(event){
            },
            handleClickpl(event){
            },
            handleClick(event) {
                this.clickTabs=event._props.name
                this.currentPage=1
                this.provinceCode=''
                this.numberOne=0
                this.numberThree=''
                this.valueYear=''
                this.valueYearzdy=''
                this.timeCode=''
                 this.startTime=''
                 this.endTime=''


                  //切换菜单时更多筛选内容清空
                  this.sxvalue1='行业类型'
                 this.sxvalue1s=''
                 this.sxvalue2='招采类型'
                 this.sxvalue2s=''
                //  this.sxvalue3='招采单位'
                //  this.sxvalue3s=''
                 this.sxvalue4='预算金额'
                 this.sxvalue4s=''
                 this.sxvalue5='代理单位'
                 this.sxvalue5s=''
                 this.sxvalue6='中标金额'
                 this.sxvalue6s=''
                 this.sxvalue7='联系方式'
                 this.sxvalue7s=''
                 this.sxvalue7='联系方式'
                 this.sxvalue77s=''
                 this.sxvalue9='注册资本'
                 this.sxvalue9s=''
                 this.sxvalue10='成立年限'
                 this.sxvalue10s=''
                  
                if(this.clickTabs==1){
                    this.messClick=''
                    this.zbList=''
                    this.number4s=0
                    this.messageCodesA=''
                    this.showZbList(this.currentPage,'',this.pxLabel)        
                }else if(this.clickTabs==2){
                    this.messClick='1'
                    this.sxList('bidding_type')
                    this.number4s=0
                    this.messageCode=''
                    this.messageCodesA=''
                    this.showZbList(this.currentPage,'1',this.pxLabel)
                }else if(this.clickTabs==3){
                    this.messClick='2'
                    this.sxList('bid_winning_type')
                    this.number4s=0
                    this.messageCode=''
                    this.messageCodesA=''
                    this.showZbList(this.currentPage,'2',this.pxLabel)
                }else if(this.clickTabs==4){
                    this.messClick='3'
                    this.sxList('proposed_type')
                    this.number4s=0
                    this.messageCode=''
                    this.messageCodesA=''
                    this.showZbList(this.currentPage,'3',this.pxLabel)
                }else if(this.clickTabs==5){
                    this.zbList=''
                    this.number4s=0
                    this.messageCode=''
                    this.messageCodesA=''
                    this.showQyList(this.currentPage,this.qyflag1,this.qyflag2,this.qyflag3)
                }
            },

            calculateText() {
                // 这是默认两行数据的高度，一行的高度可以自定义 可以*3 *4达到三行或者四行显示展示和收起的效果
                let twoHeight = 22 * 2;
                this.textHeight = `${twoHeight}px`;
                let curHeight =120
                if (curHeight > twoHeight) {
                    this.status = true;
                    this.idShowText = true;
                } else {
                    this.status = false;
                    this.idShowText = false;
                }
            },
            showStaus(){
             if(this.status==true){
                this.status=false
             }else{
                this.status=true
             }
            },

            provice(code){
                this.$axios.post('/webInterface/queryAreaList?parentAreaCode='+code).then(res => {
                    if (res.data) {
                       // console.log(res.data) 
                        let aa= {areaCode:'',areaName:'全部'}
                     if(code==''){
                       this.proviceList=res.data.localareaList
                       this.proviceList.unshift(aa)
                    }else{
                        this.cityList=res.data.localareaList
                        this.cityList.unshift(aa)
                    } 
                    }
                }).catch(err => {
                    //console.log('请求失败')
                })
            },
            provices(code){
                this.$axios.post('/webInterface/queryAreaList?parentAreaCode='+code).then(res => {
                    //console.log(res.data) 
                    if (res.data) {
                        let aa= {areaCode:'',areaName:'全部'}
                       this.areaList=res.data.localareaList
                       this.areaList.unshift(aa)

                    }
                }).catch(err => {
                    //console.log('请求失败')
                })
            },
            sxList(text){
                this.$axios.post('/webInterface/querySysRefList?optionCode='+text).then(response => {
                    if (response.data) {
                        let aa= {dictionaryOptionId:'',optionName:'全部'}
                        let bb= {dictionaryOptionId:'',optionName:'不限'}
                        if(text=='industry_category'){
                            this.sxList1=response.data.sysRefListList  //行业分类
                        }else if(text=='pub_date'){
                            this.timeList=response.data.sysRefListList   //时间选择
                            this.timeList.unshift(aa)
                            this.timeCode=this.timeList[5].dictionaryOptionId
                            this.numberThree=5 

                            this.showZbList(this.currentPage,'','')
                        }else if(text=='bidding_procurement_type'){
                            this.sxList2=response.data.sysRefListList //招采分类
                        }else if(text=='bidding_unit'){
                            this.sxList3=response.data.sysRefListList   //招采单位
                        }else if(text=='budget_amount'){
                            this.sxList4=response.data.sysRefListList   //招采单位
                        }else if(text=='agency'){
                            this.sxList5=response.data.sysRefListList   //招采单位
                        }else if(text=='bid_winning_amount'){
                            this.sxList6=response.data.sysRefListList   //招采单位
                        }else if(text=='contact_information'){
                            this.sxList7=response.data.sysRefListList   //招采单位
                        }else if(text=='en_contact_information'){
                            this.sxList77=response.data.sysRefListList
                        }else if(text=='enclosure'){
                            this.sxList8=response.data.sysRefListList   //招采单位
                        }else if(text=='approval_stage'){
                            this.zbList2=response.data.sysRefListList   //招标类型
                            this.zbList2.unshift(aa)
                        }else if(text=='enterprise_role'){
                            this.zbList3=response.data.sysRefListList   //招标类型
                        }
                        else if(text=='registered_capital'){
                            this.sxList9=response.data.sysRefListList   //招标类型
                        }else if(text=='establishment_period'){
                            this.sxList10=response.data.sysRefListList   //招标类型
                        }else if(text=='certificate_information'){
                            this.sxList11=response.data.sysRefListList   //招标类型
                        }else if(text=='building_qualification'){
                            this.sxList12=response.data.sysRefListList   //招标类型
                        }else if(text=='bidding_type'){
                            this.zbList=response.data.sysRefListList   //招标类型
                        }else if(text=='bid_winning_type'){
                            this.zbList=response.data.sysRefListList   //中标类型
                        }else if(text=='proposed_type'){
                            this.zbList=response.data.sysRefListList   //拟建项目
                            this.zbList.unshift(bb)
                        }
                        
                    }
                }).catch(err => {
                })
            },
           //点击省
            proviceChang(index,areaCode) {   
                this.numberOne = index;
                this.provinceCode=areaCode
                this.currentPage=1
                this.numberTwo = '';
                this.cityCode=''
                this.numberArea = ''
                this.areaCode=''
                this.provice(areaCode)
                this.areaList=[]
                this.isCountry=false
                if(index!=0){
                    this.isCity=true 
                }else{
                    this.isCity=false
                    this.isCountry=false
                }
                if(this.clickTabs!=5){
                    this.showZbList(this.currentPage,this.messClick,this.pxLabel)
                }else{
                    this.showQyList(this.currentPage,this.qyflag1,this.qyflag2,this.qyflag3)
                }
            },
            dyproviceChang(index,areaCode) {   
                this.dynumberOne = index;
                this.dyprovinceCode=areaCode
                this.currentPage=1
                this.dynumberTwo = '';
                this.dycityCode=''
                this.dynumberArea = ''
                this.dyareaCode=''              
                this.provice(areaCode)
                this.areaList=[]
                this.dyisCountry=false
                if(index!=0){
                    this.dyisCity=true 
                }else{
                    this.isCity=false
                    this.dyisCountry=false
                }
                
            },
            //点击市
            cityChang(index,areaCode) {
                this.numberTwo = index
                this.cityCode=areaCode
                this.numberArea = ''
                this.areaCode=''
                this.provices(areaCode)
                this.currentPage=1
                if(index!=0){
                    this.isCountry=true
                }else{
                    this.isCountry=false
                }
                if(this.clickTabs!=5){
                    this.showZbList(this.currentPage,this.messClick,this.pxLabel)
                }else{
                    this.showQyList(this.currentPage,this.qyflag1,this.qyflag2,this.qyflag3)
                }
            },
            dycityChang(index,areaCode) {
                this.dynumberTwo = index;
                this.dycityCode=areaCode
                this.dynumberArea = ''
                this.dyareaCode=''
                this.provices(areaCode)
                if(index!=0){
                    this.dyisCountry=true
                }else{
                    this.dyisCountry=false
                }
            },
            areaChang(index,areaCode) {
                this.numberArea = index;
                this.areaCode=areaCode
                this.currentPage=1
                if(this.clickTabs!=5){
                    this.showZbList(this.currentPage,this.messClick,this.pxLabel)
                }else{
                    this.showQyList(this.currentPage,this.qyflag1,this.qyflag2,this.qyflag3)
                }
            },
            dyareaChang(index,areaCode,cityName2) {
                this.dynumberArea = index;
                this.dyareaCode=areaCode
            },
            hqYears(e,id){
                this.valueYear=e
                this.valueYearzdy=''  //自定义时间清空
                this.zdyTime=''   
                this.startTime=''   //自定义开始时间清空
                this.endTime=''    //自定义结束时间清空
                this.timeCode=id   //时间清空
                this.currentPage=1
                console.log(e)
                this.showZbList(this.currentPage,this.messClick,this.pxLabel)
            },
            zdyYears(id){
               this.startTime=this.timeZh(this.valueYearzdy[0])  //开始时间
               this.endTime=this.timeZh(this.valueYearzdy[1])   //结束时间
               //this.zdyTime=this.startTime+'-'+this.endTime
               this.currentPage=1
               this.valueYear=''  //往年
               this.timeCode=id  //时间
               this.showZbList(this.currentPage,this.messClick,this.pxLabel)
            },
           
            timeZh(time){
                var date = new Date(time)
                var y = date.getFullYear()
                var m = date.getMonth() + 1
                m = m < 10 ? ('0' + m) : m
                var d = date.getDate()
                d = d < 10 ? ('0' + d) : d
                const times = y + '-' + m + '-' + d
                return times
            },
            timeChang(index,areaCode) {
                this.numberThree = index;
                this.timeCode=areaCode  //时间
                //this.wnTime='' 
                this.valueYear=''  //往年
                this.valueYearzdy=''  //自定义
                this.currentPage=1
                this.showZbList(this.currentPage,this.messClick,this.pxLabel)
            },
            messageChang(index,areaCode) {
                this.currentPage=1
                this.number4 = index;
                this.messageCode=areaCode
                this.messageCodesA=''
                this.number4s=0
                if(index==1){
                    this.sxList('bidding_type')
                }else if(index==2){
                    this.sxList('bid_winning_type')
                }else if(index==3){
                    this.sxList('proposed_type')
                }else{
                    this.zbList=''
                }
                this.showZbList(this.currentPage,this.messClick,this.pxLabel)
            },
            dymessageChang(index,areaCode) {
                this.currentPage=1
                this.dynumber4 = index;
                this.dymessageCode=areaCode
                this.dymessageCodesA=''
                this.dynumber4s=0       
                if(index==1){
                    this.sxList('bidding_type')
                }else if(index==2){
                    this.sxList('bid_winning_type')
                }else if(index==3){
                    this.sxList('proposed_type')
                }else{
                    this.zbList=''
                }
            },
            messageChangs(index,areaCode){
                this.currentPage=1
                this.number4s = index;
                this.messageCodesA=areaCode
                this.showZbList(this.currentPage,this.messClick,this.pxLabel)
            },
            dymessageChangs(index,areaCode){
                this.currentPage=1
                this.dynumber4s = index
                this.dymessageCodesA=areaCode
            },
            zbChang(index,areaCode,flag){
              if(flag==1){
                this.zbvalue=areaCode   //招标类型
                this.number5=index
              }else if(flag==2){
                this.zbvalue1=areaCode   //中标类型
                this.number6=index
              }else if(flag==3){
                this.zbvalue2=areaCode  //审批阶段
                this.number7=index
              }else if(flag==4){
                this.zbvalue3=areaCode   //企业角色
                this.number8=index
              }
              if(this.clickTabs==5){
                this.showQyList(this.currentPage,this.qyflag1,this.qyflag2,this.qyflag3)
               }else{
                this.showZbList(this.currentPage,this.messClick,this.pxLabel)
               }
             
            },
            moreSx(){
                 if(this.phoneNo!=null&&this.phoneNo!=''){

                 }else{
                    setTimeout(()=>{  //设置延迟执行
                        this.$router.push({path: '/login'});
                    },600);
                 }
            },
            sxOne(e,flag){
                if(flag==1){
                     if(e.split(",")[1]=='不限'){
                        this.sxvalue1='行业类型'
                     }else{
                        this.sxvalue1=e.split(",")[1]
                     }
                     this.sxvalue1s=e.split(",")[0]
                }else if(flag==2){
                    if(e.split(",")[1]=='不限'){
                        this.sxvalue2='招采类型'
                     }else{
                        this.sxvalue2=e.split(",")[1]
                     }
                     this.sxvalue2s=e.split(",")[0]
                }else if(flag==3){
                    this.sxvalue3=e
                }else if(flag==4){
                    if(e.split(",")[1]=='不限'){
                        this.sxvalue4='预算金额'
                     }else{
                        this.sxvalue4=e.split(",")[1]
                     }
                     this.sxvalue4s=e.split(",")[0]
                }else if(flag==5){
                    if(e.split(",")[1]=='不限'){
                        this.sxvalue5='代理单位'
                     }else{
                        this.sxvalue5=e.split(",")[1]
                     }
                     this.sxvalue5s=e.split(",")[0]
                }else if(flag==6){
                    if(e.split(",")[1]=='不限'){
                        this.sxvalue6='中标金额'
                     }else{
                        this.sxvalue6=e.split(",")[1]
                     }
                     this.sxvalue6s=e.split(",")[0]
                }else if(flag==7){
                    if(e.split(",")[1]=='不限'){
                        this.sxvalue7='联系方式'
                     }else{
                        this.sxvalue7=e.split(",")[1]
                     }
                     this.sxvalue7s=e.split(",")[0]
                }else if(flag==77){
                    if(e.split(",")[1]=='不限'){
                        this.sxvalue77='联系方式'
                     }else{
                        this.sxvalue77=e.split(",")[1]
                     }
                     this.sxvalue77s=e.split(",")[0]
                }else if(flag==8){
                    this.sxvalue8=e
                }else if(flag==9){
                    if(e.split(",")[1]=='不限'){
                        this.sxvalue9='注册资本'
                     }else{
                        this.sxvalue9=e.split(",")[1]
                     }
                     this.sxvalue9s=e.split(",")[0]
                }else if(flag==10){
                    if(e.split(",")[1]=='不限'){
                        this.sxvalue10='成立年限'
                     }else{
                        this.sxvalue10=e.split(",")[1]
                     }
                     this.sxvalue10s=e.split(",")[0]
                }else if(flag==11){
                    this.sxvalue11=e
                }else if(flag==12){
                    this.sxvalue12=e
                }
                this.currentPage=1
                if(this.clickTabs==5){
                this.showQyList(this.currentPage,this.qyflag1,this.qyflag2,this.qyflag3)
               }else{
                this.showZbList(this.currentPage,this.messClick,this.pxLabel)
               }
             },
             //查询数据列表
            showZbList(currentP,clickTabs,pxFlag){
                var that=this
                let mesId=''
                if(clickTabs==''){
                    mesId=that.messageCode
                }else{
                    mesId=clickTabs
                }
                let addressCode=''
                if(that.cityCode==''){
                    addressCode= that.provinceCode
                }else if(that.areaCode==''){
                    addressCode= that.cityCode
                }else{
                    addressCode=that.areaCode
                }
              
                that.$axios.get('/webInterface/queryScripingpageList', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            pageSize:that.pageSize,
                            pageNo:currentP,
                            areaCode: addressCode,  //地区
                            pubDate:that.timeCode,  //发布时间
                            dataType:mesId,  //信息类型
                            dataSubType:that.messageCodesA,   //信息类型二级
                            industryCategory:that.sxvalue1s, // 行业分类 
                            biddingProcurementType:that.sxvalue2s, //招采类型 
                            biddingUnit:that.sxvalue3,//招采单位 
                            budgetAmount:that.sxvalue4s,   //预算金额 
                            agency:that.sxvalue5s,//代理单位
                            bidWinningAmount:that.sxvalue6s, //中标金额 
                            contactInformation:that.sxvalue7s, //联系方式
                            enclosure:that.sxvalue8,   //附件
                            registeredCapital:that.sxvalue9s,  //注册资本  
                            establishmentPeriod:that.sxvalue10s, //成立年限
                            certificateInformation:that.sxvalue11, //证书信息
                            buildingQualification:that.sxvalue12,//建筑资质

                            biddingType:that.zbvalue,//招标类型
                            bidWinningType:that.zbvalue1,//中标类型
                           approvalStage:that.zbvalue2,//审批阶段

                           putDateOrder:pxFlag,   //排序方式
                           searchData:encodeURI(that.searchCon),
                              //往年多传的参数
                            pubDateYear:that.valueYear,
                            //自定义时间传的参数
                            pubDateStart:that.startTime,
                            pubDateEnd:that.endTime,
                            isHome:1
                        }
                    })
                    .then(function (res) {
                   //  console.log(res);
                    if(res.data.resultcode==1){
                        that.listAll = res.data.scripingpageList
                        that.total=res.data.totalCount

                        var arr=[]
                        var arrs=[]
                        for(var i=0;i<that.listAll.length;i++){
                            arr.push(that.listAll[i].procurementType.split(','))
                            arrs.push(that.listAll[i].industryCategory.split(','))
                        }
                        that.arrPro=arr
                        that.arrPao=arrs


                     }else if(res.data.resultcode==-1){
                        that.vipAuth=res.data.vipAuth
                        if(res.data.vipAuth=='-9999'){
                            setTimeout(()=>{  //设置延迟执行
                                that.$router.push({path: '/login'});
                            },600);
                        }
                     }else{
                       // that.$message.warning(res.data.message)
                        // that.listAll =[]
                        // that.total=0
                        // that.currentPage=1
                        //that.showZbList(that.currentPage,that.messClick,that.pxLabel)
                      }
                    })
                    .catch(function (err) {
                    })
            },
            //查询企业
            // showQyList(currentP,flag1,flag2,flag3){
            //     var that=this
            //     let addressCode=''
            //     if(that.cityCode==''){
            //         addressCode= that.provinceCode
            //     }else if(that.areaCode==''){
            //         addressCode= that.cityCode
            //     }else{
            //         addressCode=that.areaCode
            //     }
            //     that.$axios.get('/webInterface/queryEnCompanyList', {  //params参数必写 , 如果没有参数传{}也可以
            //             params: {  
            //                 pageSize:that.pageSize,
            //                 pageNo:currentP,
            //                 areaCode: addressCode,  //地区
            //                 enterpriseRole:that.zbvalue3,  //企业角色
            //                 industryCategory:that.sxvalue1s, // 行业分类 
            //                 registeredCapital:that.sxvalue9s,  //注册资本  
            //                 establishmentPeriod:that.sxvalue10s, //成立年限
            //                 certificateInformation:that.sxvalue11, //证书信息
            //                 buildingQualification:that.sxvalue12,//建筑资质
            //                 contactInformation:that.sxvalue77s, //联系方式

            //                 searchData:encodeURI(that.searchCon),
            //                 bidNumOrder:flag1,  
            //                 registeredCapitalOrder:flag2,
            //                 establishmentPeriodOrder:flag3,
            //                 isHome:1
            //             }
            //         })
            //         .then(function (res) {
            //            //console.log(res);
            //            if(res.data.resultcode==1){
            //             that.gysList = res.data.enCompanyList
            //             that.total=res.data.totalCount
            //          } else if(res.data.resultcode==-1){
            //             that.vipAuth=res.data.vipAuth
            //             if(res.data.vipAuth=='-9999'){
            //                 setTimeout(()=>{  //设置延迟执行
            //                     that.$router.push({path: '/login'});
            //                 },600);
            //             }
            //             // setTimeout(()=>{  //设置延迟执行
            //             //     that.$router.push({path: '/login'});
            //             // },600);
            //          } else{
            //             that.$message.warning(res.data.message)
            //             that.gysList =[]
            //             that.total=0
            //             that.currentPage=1
            //             that.showQyList(that.currentPage,that.qyflag1,that.qyflag2,that.qyflag3)
            //           }
            //         })
            //         .catch(function (err) {
            //            // console.log(err);
            //         })
            // },
            batch(){

            },
           //分页查询数据
            handleCurrentChange(val) {
                this.currentPage=val
               if(this.clickTabs==5){
                this.showQyList(this.currentPage,this.qyflag1,this.qyflag2,this.qyflag3)
               }else{
                this.showZbList(this.currentPage,this.messClick,this.pxLabel)
               }
               document.querySelector('#tabsQ').scrollIntoView({
                    behavior: "smooth"
                });
            },
           
           zbDetail(flag){
            let phoneNos=JSON.parse(localStorage.getItem('phoneNo'))
            var that=this
            that.objectIds=flag
     
            that.activeNameT='1'
            that.vipFlag=3
            that.goodsId='7163018607638296736'

           if(phoneNos!=null&&phoneNos!=''){
                    that.$axios.get('/webInterface/checkVipAuth', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            objectType:2,
                            objectId:flag
                        }
                    })
                    .then(function (res) {
                        console.log(res);
                       if(res.data.resultcode==1){
                        const {href}=that.$router.resolve({
                            // 跳转到的页面路径
                            path: '/zbDetails',
                            query: {
                                flag: flag,
                                //tabs:zText 
                            }
                        })
                        window.open(href,'_blank')
                       }else{
                            that.ewmVisible=true
                            that.isChao=true //不是vip  isChao=true 弹框支付
                       } 
                    })
                    .catch(function (err) { 
                    })
                }else{
                    setTimeout(()=>{  //设置延迟执行
                        this.$router.push({path: '/login'});
                    },600);
                }
            },
            //关闭支付弹框
            handleClose(){
                this.ewmVisible=false
                this.isPay=true
                clearInterval(this.timerT)
                this.timerT = null
            },
            taocan(flag){
                let that=this
                that.vipFlag=flag
                if(flag==3){
					that.showQuan()
					if(that.quanList.length>0){
						
                        that.totalPrice=1599-that.quanList[0].couponInfo.couponAmount
						that.couponUserId=that.quanList[0].couponUserId
					}else{
						
                        that.totalPrice=1599
						that.couponUserId=''
					}
				}else{
					that.quanList=[]
					that.couponUserId=''
                    if(flag==1){
                        that.totalPrice=599
                    }else if(flag==2){
                        that.totalPrice=999
                    }
				}
		   },
           handleClickT(tab, event) {
                console.log(tab._props.name)
                this.activeNameT=tab._props.name
                this.showQuan()
            },
            //购买VIP
            payfor(){
                let that = this;
				let goodsId=''
				let OneObjectId=''
				let twoObjectId=''
				if(that.activeNameT==1){
					goodsId='7163018607638296736'       //0.5/条
					OneObjectId=that.objectIds  
				}else{
					if(that.vipFlag==1){
						goodsId='5166747643280060475'  //包日
					}else if(that.vipFlag==2){
						goodsId='5166747643280060476'   //包月
					}else if(that.vipFlag==3){
						goodsId='5166747643280060474'   //包年
					}
					if(that.couponUserId!=undefined&&that.couponUserId!=''){
						twoObjectId=that.couponUserId
					}else{
						twoObjectId=''
					}
				}
               that.$axios.get('/webInterface/generateOrder', {  //params参数必写 , 如果没有参数传{}也可以
                    params: {  
                        goodsId:goodsId,  //套餐id
                        orderIds:'',
                        objectId:OneObjectId, //单条详情id
                        quantity:1,
                        freight:0,
                        payType:2,  //1是支付宝支付，2是微信支付
                        objectType:2, //2是标讯，1是企业	
                        couponUserId:twoObjectId   //优惠券id
                    }
                })
                .then(function (res) {
                    console.log(res);
                    if(res.data.resultcode==1){
                        that.ewmVisible=true
                        that.ewmImg=res.data.reStr  //二维码
                        that.isPay=false  
                 
                        that.$axios.post('/webInterface/queryGoodsInfo?id='+goodsId)
                        .then(function (res) {
                          // console.log(res);
                            if(res.data.resultcode==1){
                                 that.orderPay=res.data.goodsInfo                               
                            }
                        })
                        .catch(function (err) { 
                        })
                         //that.isFlag=1
                         that.timerT = window.setInterval(() => {
                            that.check(res.data.orderInfo.id)
                           // that.isFlag++
                        }, 3000);
                    }else{
                        this.$message.error(res.data.resultmessage)
                    }
                })
                .catch(function (err) { 
                })

            },
            check(orderId){
                var that=this
                that.$axios.post('/webInterface/queryOrderInfo?orderId='+orderId+'&flag=1')
                .then(function (res) {
                    console.log(res);
                 
                 if(res.data.resultcode=='1'&&res.data.orderInfo.state!=0){
                      that.paySuccess=true
                      that.messageTitle='支付成功，将跳转至详情页面'
                      that.ewmVisible=false
                      that.isPay=true
                      that.paySuccess=false
                      clearInterval(that.timerT)
                      that.totalTime = 3
                      that.timerT = null
                    //标讯
                        setTimeout(()=>{
                            that.$router.push({
                                    path: '/zbDetails',
                                    query: {
                                    flag: that.objectIds, 
                                }
                            })
                        },500)
					}else{
                        that.paySuccess=false
                        clearInterval(clock)
                        that.isPay=true
                        clearInterval(that.timerT)
                        that.totalTime = 3
                        that.timerT = null
                    }
                })
                .catch(function (err) { 
                })
            },
           
            pxChange(e){
                console.log(e)
                this.pxLabel=e
                this.showZbList(this.currentPage,this.messClick,this.pxLabel)
            },
            pxChange2(e){
                console.log(e)
                if(e==1||e==2){
                    this.qyflag1=e
                }else if(e==3){
                    this.qyflag2=1
                }else if(e==4){
                    this.qyflag2=2
                }else if(e==5){
                    this.qyflag3=1
                }else if(e==6){
                    this.qyflag3=2
                }
                this.showQyList(this.currentPage,this.qyflag1,this.qyflag2,this.qyflag3) 
            },
            searchClick(){
               if(this.searchCon!=''){
                const {href}=this.$router.resolve({
                    // 跳转到的页面路径
                    path: '/list',
                    // 传递的参数集合
                    query: {
                        code: this.activeNameTop,
                        keyword:this.searchCon
                    }
                })
                window.open(href,'_blank')
                this.searchCon=''
                }else{
                    this.$message.warning('搜索内容不能为空')
                }
            },
            submitForm(formName) {
           
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            mouseOver1(){
                
            },
            chooseTime(e){
                this.timeS=e
            },
            openDy(subscribeId){
                const {href}=this.$router.resolve({
                    // 跳转到的页面路径
                    path: '/subscribe',
                    // 传递的参数集合
                    query: {
                        subscribeId:subscribeId
                    }
                })
                window.open(href,'_blank')
            },
            addDytk(){
                let that=this
                if(that.isDl=='-9999'){
                     setTimeout(()=>{  //设置延迟执行
                        that.$router.push({path: '/login'});
                    },600);
                }else{
                    if(that.wechatStatus==0){   //未绑定实时刷新是否已绑定
                        that.timerTs = window.setInterval(() => {
                            that.queryInfos()
                        }, 3000);
                    }
                    if(that.level.userMemberRankStr=='普通用户'&&that.dyNotes.length==1){
                        that.$message.warning('需要VIP权限')
                    }else if(that.level.userMemberRankStr=='VIP'&&that.tabsArr.length==10){
                       that.$message.warning('需要高级VIP权限')
                    }else{
                        that.showDy()
                        setTimeout(()=>{
                            if(that.tabsArr.length==0){
                                that.dialogVisible=true
                                that.keyWords=''
                                that.gjname=''
                                that.gjtext=''
                                that.keyWordsAs=[]
                                that.timeS='9:00'
                                that.notice1='0'
                                that.notice2='1'                                
                                that.notice3='1'
                            }
                        },500)
                    }
                     
                }
            },
             closeDy(){
                let that=this
                that.dialogVisible = false
                clearInterval(that.timerTs)
            },
           showDy(){
               let that=this
                that.$axios.post('/webInterface/queryScripingSubscribeList').then(res => {
                    if(res.data.resultcode==1){
                        //console.log(res)
                        that.tabsArr=res.data.scripingSubscribeList  
                        for(var i=0;i<res.data.scripingSubscribeList.length;i++){
                            if(i<2){
                                that.dyNotes[i]=res.data.scripingSubscribeList[i]
                            }
                        }
                        that.showDydetails()
                    }else{
                       that.isDl='-9999'
                    }
                }).catch(err => {
                    //console.log('请求失败')
                })
            },
            showDydetails(){
                let that=this
                that.dyXqdetils=[]
                 that.$axios.get('/webInterface/queryScripingpageList', {  //params参数必写 , 如果没有参数传{}也可以
                    params: { 
                        pageSize:2,
                        pageNo:1,
                        subscribeId:that.dyNotes[0].subscribeId,
                        pubDate: "",
                        pubDateEnd: "",
                        pubDateStart: "",
                        //subscribeKeywords:that.dyNotes[0].keywords,
                    }
                })
                .then(function (res) {
                    //console.log(res);
                    if(res.data.resultcode==1){
                        that.dyXqdetils.push(res.data.scripingpageList)

                     }
                })
                .catch(function (err) {
                    // console.log(err);
                })
            },
          //新增订阅
            addDys(){
                var that=this
                let regex = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;

                that.provinceCode=that.dyprovinceCode
                that.cityCode=that.dycityCode
                that.areaCode=that.dyareaCode
                that.messageCode=that.dymessageCode
                that.messageCodesA=that.dymessageCodesA

                if(that.emailA!=''&&!regex.test(that.emailA)){
                        that.$message({
                            message: '请输入正确的邮箱',
                            type: 'warning'
                        })
                        return
                }
                if(that.notice3==0){
                    that.emailCon=''
                }else{
                    if(that.emailCon!=''&&!regex.test(that.emailCon)){
                        that.$message({
                            message: '请输入正确的邮箱',
                            type: 'warning'
                        });
                        return
                    } 
                }
                

                 if(that.notice2==1&&that.wechatStatus==0){
                    that.$message({
                        message: '未绑定微信，无法推送订阅消息',
                        type: 'warning'
                    });
                    return 
                }else if(that.notice3==1&&that.emailA==''&&that.emailCon==''){
                      that.$message({
                            message: '未绑定邮箱，无法推送订阅消息',
                            type: 'warning'
                        });
                        return 
                }else{
                     //更新邮箱
                     let content=''
                     if(that.emailCon==''){
                        content=that.emailA
                     }else{
                        content=that.emailCon
                     }
                     let addressCode=''
                    if(that.cityCode==''){
                        addressCode= that.provinceCode
                    }else if(that.areaCode==''){
                        addressCode= that.provinceCode+','+that.cityCode
                    }else{
                        addressCode=that.provinceCode+','+that.cityCode+','+that.areaCode
                    }
                    that.$axios.post('/webInterface/upUserInfo?email='+content)
                    .then(function (res) {
                        if(res.data.resultcode==1){
                        }
                    })
                    .catch(function (err) {
                        console.log(err);
                    })
                   
                   that.$axios.get('/webInterface/opScripingSubscribe', {  //params参数必写 , 如果没有参数传{}也可以
                        params: { 
                            // subscribeId:'',
                            // subscribeName:that.gjname,  //订阅名称
                            // keywords:that.gjtext,    //关键字
                            // pushFrequency:'1', //推送频率
                            // pushTime:that.timeS,  //推送时间
                            // sysNotice:that.notice1,
                            // wechatNotice:that.notice2,                                  
                            // emailNotice:that.notice3,  
                            // opFlag:1   ,
                            
                            
                            subscribeId:'',
                            // subscribeName:that.gjtext,
                            // keywords:that.gjtext,
                            subscribeName:that.keyWordsAs.toString(),
                            keywords:that.keyWordsAs.toString(),
                            pushFrequency:'1', //推送频率
                            pushTime:that.timeS,  //推送时间
                            sysNotice:that.notice1,
                            wechatNotice:that.notice2,                                  
                            emailNotice:that.notice3,  
                            opFlag:1,
                            qryAreaCode:addressCode,              //地区
                            qryDataType:that.messageCode,         //信息类型
                            qryDataSubType:that.messageCodesA,   //信息类型二级分类     
                        }
                    })
                    .then(function (res) {
                      // console.log(res);
                       if(res.data.resultcode==1){
                           that.dialogVisible = false
                            that.$message({
                                message: '创建订阅成功',
                                type: 'success'
                            });
                            that.showDy()
                        }else{
                            that.$message.warning(res.data.resultmessage)
                        }
                    })
                    .catch(function (err) {
                       // console.log(err);
                    })
                }
            },
            dels(){
               this.addContents=''
            },
             //添加关键词
            addClicks(){
               let that=this
               if(that.addContents==''){
                  that.$message.warning('关键词不能为空')
               }else{
                that.addContents=that.addContents.replace(/\s*/g,"")
				   if(that.keyWordsAs.length>=10){
                      that.$message.warning('最多只能添加10个关键字')
				   }else{
					   if(that.addContents.length<2){
                        that.$message.warning('关键字至少输入2个字')
					   }else{
                        that.keyWordsAs.push(that.addContents)
                         that.addContents=''
					   }
				   }
               }
               
            },
            //删除关键词
            deletess(item,index){
                this.keyWordsAs.splice(index,1)
            },
            queryInfos(){
                var that=this
                that.$axios.post('/webInterface/queryUserInfo')
                    .then(function (res) {
                     //console.log(res)
                     if(res.data.resultcode==1){
                        that.userInfos=res.data.userInfo
                        that.wechatStatus=res.data.status
                        if(that.userInfos.email!=undefined){
                            that.emailA=that.userInfos.email
                        }else{
                            that.emailA=''
                        }
                        
                        if(that.wechatStatus==1){ 
                            clearInterval(that.timerTs)
                        }
                    }
                    })
                    .catch(function (err) {
                        console.log(err);
                    })
            },
            querInfo(){
              var that=this
              that.$axios.post('/webInterface/queryUserOther')
                .then(function (res) {
                    //console.log(res)
                    if(res.data.resultcode==1){ 
                      that.level=res.data.userOther
                    }
                })
                .catch(function (err) {
                    console.log(err);
                })
            },
            //解绑
            jiebang(flag){
              let that=this
              if(flag==1){
                 that.$axios.post('/webInterface/unbidWx')
                    .then(function (res) {
                    // console.log(res)
                     if(res.data.resultcode==1){
                        that.wechatStatus=0
                        that.queryInfos()
                        that.timerTs = window.setInterval(() => {
                            that.queryInfos()
                        }, 3000);
                      }
                    })
                    .catch(function (err) {
                        console.log(err);
                    })
              }else{
                   //解绑邮箱
                    that.$axios.post('/webInterface/upUserInfo?email=')
                    .then(function (res) {
                      //  console.log(resetForm)
                     if(res.data.resultcode==1){
                        that.emailCon=''
                        that.emailA=''
                        that.notice3=0
                        that.queryInfos()
                        
                      }
                    })
                    .catch(function (err) {
                    })
              }
            },
              // 移入
           mouseOver() {
              if(this.$refs.wechatEwm!=undefined){
                 this.$refs.wechatEwm.style='display:show'
              }
               
            },
            // 移出
            mouseLeave() {
                if(this.$refs.wechatEwm!=undefined){
                 this.$refs.wechatEwm.style='display:none'
                }
            },
           
        }
    }
</script>
<style scoped lang="less">
  .topCon{
    background:url(../assets/images/banner1.png);
    width: 100%;
    height: 295px;
    background-size: 100% 100%;
    position: relative;
    }
    .flex{
    display: flex;
    align-items: center;
    }
    .topHalf{
    text-align: center;
    }
    .hTitle{
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        padding-top: 52px;
    }
    .inputClass{
        border: none;
        outline: none;
        width: 80%;
        margin-right: 15px;
        margin-left: 12px;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
    }
    .searchBtn{
        letter-spacing: 2px;
        cursor: pointer;
        width: 80px;
        height: 38px;
        background: #FEBF32;
        border-radius: 6px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 38px;
        text-align: center;
    }
    .ju-tinput{
        display: flex;
        padding: 4px 6px 4px 4px;
        align-items: center;
        justify-content: space-between;
        width: 900px;
        height: 42px;
        background: #FFFFFF;
        border-radius: 6px;
    }
    .shuline{
    width: 2px;
    height: 25px;
    background: #BBBBBB;
    }
    .selLeft{
    min-width: 14%;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
    }
    .selectImg{
    width: 28px;
    height: 28px;
    margin-right: 10px;
    }

    .hotSearch{
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    width: 970px;
    margin: 0 auto;
    }
    .hotTitles{
    font-weight: 500;
    color: #fff;
    }
    .reLine{
    background: #fff;
    margin: 0 20px;
    width: 2px;
    height: 18px;
    background: rgba(255,255,255,0.6);
    }
    .itemHot{
    margin-right: 20px;
    cursor: pointer;
    }
    /* 小喇叭通知 */
    ui,li {
    list-style: none;
    width: 100%;
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis; 
    }
    .mobile-marquee-wrapper{
        flex: 1;
        height: 40px;
        overflow: hidden;
        position: relative;
        #news{
        overflow: hidden;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        cursor: pointer;
        white-space: nowrap;
        line-height: 40px;
        position: absolute;
        top: 0;
    }
    }
    
    .halfC{
    width: 1140px;
    background: #FFFFFF;
    border: 1px solid #F3F3F3;
    border-radius: 5px;
    margin: 0 auto;
    padding: 17px 20px;
    }
    // /deep/#tabsQ{
    //     .el-tabs{
    //     .el-tabs__header{
    //         //margin: 0 0 20px;
    //         margin:0
    //     }
    //     .el-tabs__active-bar {
    //     background-color: #0078FF;
    //     }
    //     .el-tabs__item{
    //         height: 43px !important;
    //         font-size: 16px !important;
    //     }
    //     .is-active {
    //     color: #0078FF;
    //     }
    // }
    // }
    /deep/.el-pagination {
        .el-pagination__total,.el-pagination__jump{
        display: inline-block;
        font-size: 15px;
        }
        .el-pager li {
        font-size: 15px;
        }
    }
    .open{
        display: flex;
        justify-content: center;
        margin-top: -1px;
    }
    .status{
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #0078FF;
        border-width: 30px 25px 0 25px;
        border-style: solid;
        border-color: #fff transparent blue transparent;
        position: relative;
        width: 33px;
        padding: 0 14px;
        cursor: pointer;
    }
    select{
        padding: 3px;
        font-size: 16px;
        padding: 3px;
        font-size: 16px;
        border: 1px solid #DCDFE6;
        color: #000;
        height: 30px;
        outline: none;
    }

    // /deep/.dialog-footer{

    //     .el-button{
    //     margin-left:0 !important;
    //     font-size: 16px !important;
    //     padding:0 !important;
    //     width: 82px;
    //     height: 30px;
    // }}
    .ant-spin-nested-loading {
        position: relative;
    }
    .ant-list-items {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .tc_list_item.tc_list_hover {
        transition: background-color .2s;
    }
    .tc_list_item {
        position: relative;
        // border-bottom: 1px solid #F3F3F3;
        padding: 10px 0 14px 0;

    }
    .title{
        font-size: 18px;
        font-weight: 700;
        padding: 10px 0;
        color: #333;
        cursor: pointer;
    }

    .gGdJlei{
        margin-bottom: 10px;
    }
    .fiklj9cw {
        color: #333;
    }
    .fiklj9cw .smailDiv {
        line-height: 22px;
        margin: 5px 0;
        max-width: 100%;
    }
    .fiklj9cw .smailDiv, .fiklj9cw .smailDiv>div {
        display: inline-block;
    }
    .fiklj9cw .smailDiv>div:first-child {
        color: #999;
        padding-right: 10px;
    }
    .fiklj9cw .smailDiv:not(:last-child) {
        margin-right: 30px;
    }
    /* ul{
        margin: 0;
        padding:0;
    } */

    .blueColor{
        color: #0078FF;
        cursor: pointer;
    }
    a{
        color:#000
    }
    .pagination{
        text-align: center;
        margin-top: 28px;
    }


    /deep/.el-input__inner{
        color: #222222;
    }
    /deep/.rightM{
        height: 30px;
        line-height: 30px;
        //margin-right:10px;
        .el-input{
            width: 160px !important;
            font-size: 16px !important;
            .el-input__inner{
                height: 30px !important;
                line-height: 30px !important;
                outline: none;
                padding-left: 9px;
                padding-right: 9px;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #222222;
            }
            .el-input__inner::input-placeholder{
                color:#222;
            }
            .el-input__inner::-webkit-input-placeholder { /* WebKit browsers */ 
            color: #222; 
            }
            .el-input__suffix{
                display:none
            }
        }
    }

    // .jtImgsx{
    //     width: 11px;
    //     height: 12px;
    //     position: absolute;
    //     right: 16px;
    //     top: 9px;
    // }






    .ant-spin-container {
        position: relative;
        transition: opacity .3s;
    }
    .ant-list-items {
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;
        border-bottom: 1px solid #F3F3F3;
        padding: 10px 0;
    }
    .ant-list-items:last-child{
        border-bottom:none
    }
    .tc_list_item.tc_list_hover {
    // padding: 0 20px;
        transition: background-color .2s
    }
    
    .tc_list_item:not(:last-child):after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 20px;
        right: 20px;
        border-bottom: 1px solid #eee;
    }
    .R-Dvn3zA{
        padding-top: 10px;
        display: flex;
    }
    .pYreFzHC {
        padding-right: 20px;
        // width: 80px;
        // height: 160px;
    }
    .pYreFzHC img {
        // border: 1px solid #eee;
        width: 60px;
        height: 60px;
        border-radius: 4px;
    }
    ._0aDOKXj6 {
        position: relative;
    width: calc(100% - 80px);
    }
    .FpwufnYn {
        display: flex;
        margin-bottom: 10px;
        cursor: pointer;
        justify-content: space-between;
    }
    .FpwufnYn a {
        font-size: 16px;
        font-family: PingFangSC-Medium,PingFang SC;
        font-weight: 700;
        color: #333;

    }
    .WMSbIy8X {
        position: absolute;
        top: 0;
        right: 0;
        height: 24px;
        color: #000;
        font-weight: 700;
    }
    .jutRowsA{
        height: 46px;
        background: #F5F7FA;
        border-radius: 5px;
        padding: 0 10px;
        font-size: 16px;
            // margin: 10px 0;
    }
    .noticTitles{
        // line-height:46px;
        margin-right: 20px;
    }
    .noticTitles:last-child{
        margin-right: 0;
    }
    .huiColor{
        color:#999999;
    }
    .titles{
        font-size: 18px;
        font-weight: 700;
        color: #333;
        cursor: pointer;
    }









    /* tab切换 */
    .chooseArea{
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #222222;
    }
    .areaCode,.timeCode{
    flex-wrap: wrap;
    cursor: pointer;
    width: 1050px;
    }
    .areaCode .item,.timeCode .item{
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin-right: 8px;
    padding: 0 12px;
    margin-bottom: 10px;
    }
    .selected{
    background: #EFF7FF;
    border-radius: 4px;
    font-weight: 400;
    color: #0078FF; 
    
    }
    .tabCon{
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #222222;
    margin-top: 20px;
    }
    .conRows{
    border-bottom: 1px solid #F3F3F3;
    padding: 20px 0;
    }
    input[name="color"]::-webkit-input-placeholder { /* WebKit browsers */
    color: red;
    font-size:15px;
    }
    select{
        border: none;
        outline: none;
        margin-right: 22px;
        font-size:16px
    }
    /deep/.el-cascader{
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        .el-input{
            width: 108px !important;
            font-size: 16px !important;
        .el-input__inner{
            height: 30px !important;
            line-height: 30px !important;
            border: none;
            outline: none;
            padding-left: 0;
            padding-right: 15px;
            text-align: center;
        }
        .el-input__inner::input-placeholder{
                color:#222;
            }
            .el-input__inner::-webkit-input-placeholder { /* WebKit browsers */ 
            color: #222; 
            }
        .el-input__suffix{
            display:none
        }
    }
    }
    /deep/.faTime{
    .selectitem{
        .el-input{
            width: 100px !important;
            font-size: 16px !important;
            .el-input__inner{
                height: 30px !important;
                line-height: 30px !important;
                border: none;
                outline: none;
                padding-left: 8px;
                padding-right: 5px;
            }
            .el-input__inner::input-placeholder{
                color:#222;
            }
            .el-input__inner::-webkit-input-placeholder { /* WebKit browsers */ 
            color: #222; 
            }
            .el-input__suffix{
                display:none
            }
        }
    }
        }
        
    /deep/ .el-date-editor {
        height: 33px;
        line-height: 30px;
        border: none !important;
        outline: none !important;
        padding-left: 10px !important;
        width: 210px;
        padding-right: 12px;
        margin-bottom:10px;
        .el-range-separator{
            display: none;
        }
        .el-input__icon{
        display: none;
        } 
        .el-range-input{
        width: 90px !important; 
        font-size: 16px !important;
        text-align: left;
        }
        .el-range-input::input-placeholder{
            color:#222;
        }
        .el-range-input::-webkit-input-placeholder { /* WebKit browsers */ 
            color: #222; 
        }
        }
        .selectitem{
            height: 30px;
            line-height: 30px;
            text-align: center;
            margin-bottom: 10px;
            padding:0;
            width:75px
        }
        /deep/.selected{
            .el-range-input{
                background: none;
            border-radius: 4px;
            font-weight: 400;
            color: #0078FF;
        }
        }
        .jtImg{
            width: 11px;height: 12px;position: absolute;right: 132px;top: 9px;
        }
        .jtImgs{
            width: 11px;height: 12px;position: absolute;right:8px;top: 9px;
        }
        .el-popper{
            margin-top:0
        }
        .jtImgsxs{
            width: 11px;height: 12px;position: absolute;right:8px;top: 9px;
        }
        .jtImgsxsa{
            width: 11px;height: 12px;position: absolute;right:10px;top: 9px;
        }
        .jtImgsx{
            width: 11px;height: 12px;position: absolute;right: 3px;top: 9px;
        }
        /deep/.selectitem {
        .el-input{
            width: 76px !important;
            .el-input__inner{
                padding-right: 10px !important;
                padding-left: 11px;
                background: none;
                color: #0078FF;
            }
        }
        }
    
    ul{
        padding:0;
        margin:0
    }
        .newsList {
    width: 90%;
    height: 26px;
    overflow: hidden;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;

    /* background-color: red; */
    }
    .anim {
    transition: all 2s;
    margin-top: -30px;
    }
    #con1 li {
    list-style: none;
    cursor: pointer;
    color:#000;
    }
    .zcPr{
        max-width: 80%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .ant-popover-content{
        width: 150px;
        position: absolute;
        top: 48px;
        left: 126px;
        z-index: 999999;
        .ant-popover-inner {
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
        
            .tc_select_number-range {
                padding: 12px;
                /deep/.el-form{
                    .el-form-item{
                        .el-form-item__content{
                                display:flex
                            }
                    }
                }
                
            }
        
    }

    }
    /deep/.topTab{
        .el-tabs{
            .el-tabs__header{
                margin:15px 0 15px 0 !important;
            
            .el-tabs__nav-wrap::after {
                background: none !important;
                }
            .el-tabs__nav{
                transform: translateX(-50%) !important;;
                left: 50%;
                .el-tabs__active-bar{
                    display: none !important;
                }
                .el-tabs__item.is-active {
                   color: #0078FF;
                    background: url(../assets/images/yxTop.png);
                    // width: 106px;
                    // height:42px !important;
                }
                
                .el-tabs__item{
                    font-size: 18px !important;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #FFFFFF;
                    background: url(../assets/images/wxTop.png);
                    width: 106px;
                    height:42px !important;
                    padding: 0 !important;
                    line-height: 36px !important;
                    margin: 0 20px;
                }
            }
            }
        }
    
    }
    .swiper{
        width: 1200px;
        height:120px;
        margin: 0 auto;
    }
    /deep/.el-carousel__container{
        height:120px !important;
    }
    .closeImg{
        width: 15px;height: 15px;
        cursor: pointer;
    }

    .tabs{
        .btn{
            position: relative;
            flex: 1;
            margin: 0 10px;
            border: 1px solid #CCCCCC;
            border-radius: 5px;
            padding: 19px 15px;
            cursor: pointer;
            transition: border-color .2s;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #222222;
            .tc_purchase-order_check {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 30px;
                height: 30px;
            }
        }
        .active{
            border-color: #432107;
            background-color: #fff;
            .tc_purchase-order_check{
                background-image: url(../assets/images/gou.png);
            background-size: 100% 100%;
            }
        }
    }
    .rows{
        justify-content: space-between;
    }
    /deep/.tkPay{
       
        .el-dialog{
            border-radius: 5px;
            max-height: 670px;
           overflow-y: auto;
        .el-dialog__header{
            background-image: url(../assets/images/tcTops.png);
            width:100%;
            height:140px;
            padding:0;
            .el-dialog__close{
                color: #fff;
            }
        }
        .el-dialog__body{
        border-radius: 5px;
        padding: 0;
        padding-bottom: 30px;
        .nav{
            .nav-top{
                margin: -50px 30px 20px 30px;
                background: #fff;
                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
                border-radius: 10px;
            }
            .nav-tabel{
                margin: 10px 30px 10px 30px;
                // max-height: 300px;
                // overflow: auto;
            }
        }
        .navK{
            background: #FFFFFF;
            box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.08);
            border-radius: 10px;
            margin: -50px 30px 0 30px;
             padding: 30px;
            .fYcolor{
                background: #F6FAFF;
                border-radius: 5px;
                padding: 20px 30px;
                display:flex;
                .cpLeft{
                    width: 160px;
                    height: 200px;
                    background: #FFFFFF;
                    border-radius: 5px;
                    margin-right: 60px;
                    .ewmImg{
                        width:160px;
                        height:160px;
                        margin-bottom:7px;
                    }
                }
                .cpRight{
                    font-size: 13px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #222222;
                    line-height: 30px;
                    margin-top: 20px;
                }
            }
        }
        }
    }
    }

    .tcTabelImg{
        width:100%;
        height:757px
    }
    .kaiTong{
        background-image: url(../assets/images/kaiTong.png);
        width:200px;
        height:46px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #222222;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .ktText{
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: 468px;
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;
    }
    .tc_card_header {
        // padding: 0 20px;
        position: relative;
        display: flex;
        .tc_card_title {
            display: flex;
            flex: 1;
            align-items: center;
            font-size: 16px;
            color: #333;
            font-weight: 700;
            height: 100%;
            .cCKqh3Ai {
                color: #666;
                display: flex;
                align-items: center;
                width: 100%;
                border-bottom: 1px solid #F3F3F3;
                padding-bottom: 10px;
                ._3k2bXiHc {
                    font-size: 16px;
                    margin-right: 20px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #222222;
                    // cursor: pointer;
                }
                .MaFMvzl2 {
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #999999;
                }
            }
            .o0VoHoNS {
                padding: 0 10px;
                color: #e3e3e3;
                display: inline-block;
            }
            ._2Fb59Pe9 {
                font-weight: 400;
                cursor: pointer;
                color: #1660d4;
                font-size: 14px;
            }
        }
        .tc_card_header>.tc_card_extra {
            display: flex;
            align-items: center;
            color: #999;
            height: 100%;
            line-height: 1;
            .tc_more{
            color: #666;
            font-size: 14px;
            }
        }
    }

    // 弹框订阅
    /deep/.addDy{
        .el-dialog__body{
            padding:30px 20px 0 20px;
            .dy-form-item{
                .el-select{
                    .el-input{
                    width: 100%;
                    }
                }
            .el-input{
                width: 76%;
                .el-input__inner{
                    border-radius: 2px;
                    font-size: 15px;
            }
            }
            .tsPl{
                width:76%;
            .el-tabs__header{
                margin:0;
                border-bottom:none;
                .el-tabs__nav{
                    border:none;
                    width:100%;
                }
                .el-tabs__item{
                    border-bottom: none;
                    border-left: none;
                    border:1px solid #E4E7ED;
                    margin-right: 10px;
                    width:100%;
                    font-size: 15px;
                    text-align: center;
                    border-radius: 3px;
                }
                .el-tabs__item.is-active{
                    background: #409EFF;
                    color:#fff;
                    width:100%
                }
            }
        }
        }
        }

        .el-dialog__footer{
                text-align:center;
                padding:10px 20px 25px;
                .el-button{
                    padding: 12px 28px;
                    border-radius: 5px;
                }
            }
        }
        .sxTexts{
        width: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 15px;
        }
        .gjTitle{
            padding: 0 0 8px;
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #222222;
            width: 88px;
        }
        .gjTitles{
            padding: 0 0 20px;
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #222222;
        }
        .el-radio{
            font-size:15px
        }
        .dy-form-item{
            margin:0 0 24px;
        }
        /deep/.el-dialog__header {
            padding: 15px;
            border-bottom: none;
            margin: 0;
        background: #F2F4F7;
        }
        .tjHui{
            font-size: 14px;
        color: #999;    
        }
        /deep/.tc_card_body{
            text-align: center;
            .body_half{
                margin: 15px 0;
                border-bottom: 1px solid #F3F3F3;
                padding-bottom: 15px;
                .blue{
                    color: #0078FF;
                    width: 80%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-align: left;
                    text-overflow: ellipsis;
                }
                .half_rows{
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    padding: 15px 8px;
                    background: #F5F7FA;
                    border-radius: 5px;
                    margin: 10px 0;
                    cursor: pointer;
                }
            }
            // .body_half:nth-child(2){
            //     border-bottom: none;
            //     padding-bottom: 4px;
            //     margin:15px 0 10px 15px
            //  }
            .el-button{
            color:#000;
            padding: 6px 10px; 
            }
        }
        .ljaddBtn{
            background: #0078FF;
            border-radius: 2px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: hsl(0, 0%, 100%);
            font-size: 15px;
            padding: 5px 10px;
            cursor: pointer
        }
        .xtWidth{
            width:88px
        }  
        .paySuccess{
            position: absolute;
            top: 77px;
            left: 50%;
            transform: translateX(-50%);
        }
        .bangding{
        font-size: 14px;
        color: #999;
        position: relative;
            margin-top: 5px;
    }

    .footsCon{
        padding: 30px;
        border: 2px solid #0078FF; 
        border-radius: 5px;
    }
   .bqqText1{
		font-size: 18px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #222222;
	}
    .iconZ{
        width: 10px;
        height: 10px;
    }
	.bqqText2{
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #999999;
		text-align: center;
		margin: 4px 0;
	}
	.vipHalfs{
        text-align: center;
        width: calc(100%/7);
        margin: 18px 0 0 0;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #222222;
        img{
            width: 50px;
            height: 50px;
        }
	}
	.vipIconText{
		width: 72px;
		margin: 0 auto;
	}

    // .ewmImg{
    //         position: absolute;
    //     top: 22px;
    //     left: 0;
    //     background: #fff;
    //     padding: 8px;
    //     z-index: 999;
    //     border-radius: 5px;
    //     box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    // }

.statusText{
    overflow: hidden;
    display: block;
}
.openClose{
    width: 62px;
    margin-top: 5px;
    color:#0078FF;
    cursor: pointer;
    font-size: 16px;
}
.jtImgsxsb{
    width: 12px;height: 12px;
}
.closeImg{
    width: 15px;height: 15px;
    cursor: pointer;
}
/deep/.aShaixuan{    
    .el-select{
        height: 30px;
        line-height: 30px;
        .el-input{
            width: 110px !important;
            font-size: 16px !important;
            .el-input__inner{
                height: 30px !important;
                line-height: 30px !important;
                border: none;
                outline: none;
                padding-left: 0;
                padding-right: 15px;
                text-align: center;
            }
            .el-input__inner::input-placeholder{
                color:#222;
            }
            .el-input__inner::-webkit-input-placeholder { /* WebKit browsers */ 
              color: #222; 
            }
            .el-input__suffix{
                display:none
            }
        }
    } 
 }









 /deep/.nav-top{
 
    .el-tabs--top{
        width: 100%;
        .el-tabs__header{
            margin: 0 !important;
        }
        .el-tabs__nav-wrap::after{
            background-color: #fff;
        }
        .el-tabs__nav-scroll{
            .el-tabs__nav{
                width: 100%;
                .el-tabs__active-bar{
                    left: 16%;
                    width: 120px !important;
                }
                .el-tabs__item{
                    height: 60px !important;
                    line-height: 20px !important;
                    font-size: 24px;
                    width: 50%;
                    text-align: center;
                    padding: 12px 0 0 0;
                    background: #F2F4F7;
                    border-radius: 5px 5px 0 0;
                    .bzImgs{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 18px;
                    } 
                    .bqqStext{
                        font-size: 12px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #999999;
                    } 
                } 
                .el-tabs__item.is-active {
                    background: #fff;
                    color:#303133
                }

            }
        }
    }
    .firstContent{
        box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.04);
        border-radius: 5px;
        padding: 20px 30px;
    }
}
// .tImg{
//     width: 585px;
//     height:540px;
//     box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.08);
//     border-radius: 3px;
// }
// .tImgs{
//     width:1200px;
//     height:1581px;
//     // box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.08);
//     border-radius: 3px;
//     margin-top: 30px;
//     box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.08);
// }

.topA{
   margin-top: -80px;
    background: #FFFFFF;
    border-radius: 5px;
}
.tao-half-row{
	width: 100%;
	position: relative;
    font-size: 12px;
    cursor: pointer;
    .taoTtile{
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #222222;
    }
}
.danTk2{
	background: #FFFFFF;
	border: 1px solid #E6E6E6;
	border-radius: 10px;
    text-align: center;
    padding: 20px 0;
}
.maright{
	margin-right: 20px;
}
.saleprice{
	height: 26px;
	line-height: 26px;
	text-decoration: line-through;
}
.danTk{
	background: #EDF5FF;
	border: 1px solid #0078FF;
	border-radius: 5px;
    text-align: center;
    padding: 20px 0;
}
.danTops{
	height: 24px;
	background: linear-gradient(0deg, #0078FF 0%, #0052F0 100%);
	border-radius: 5px 5px 0px 0px;
	margin-top: -2px;
	line-height: 24px;
}
.tq1Img{
	width:137px;
	height:12px;
}
.danTexts{
	width: 100%;
	font-size: 12px;
	font-family: PingFang SC;
	font-weight: 500;
}
.dlTexts{
		 overflow: hidden;
		 white-space: nowrap;
		 text-overflow: ellipsis;
		 max-width: 80%;
	}
    .dwZhek{
	  position: absolute;
	  right: -10px;
	  top:-11px;
	  width: 33px;
	  height: 18px;
	  background: #FF0000;
	  border-radius: 11px 0px 11px 0px;
	  color:#ffffff;
	  font-size: 12px;
	  font-family: PingFang SC;
	  font-weight: 500;
	  color: #FEFEFE;
	  line-height: 18px;
      text-align: center;
	}
	.danH{
		height:26px;line-height:26px;color:#999999
	}
  .tkgouImg{
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .sureBtn{
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        width: 200px;
        height: 46px;
        background: #0078FF;
        border-radius: 6px;
        line-height: 46px;   
        cursor: pointer;
    }
  .nav-middle{
    width: 100%;
    background: #FFFFFF;
    border: 2px dashed #EEEEEE;
    border-radius: 5px;
    padding: 10px 0 20px 0;
    text-align: center;
    margin:20px 0 12px 0;
        .nav-middleCon{
            align-items: flex-end;
            font-size: 14px;
            justify-content: center;
            margin-bottom: 18px;
            .sjPay{
                display: flex;
               align-items: flex-end;
            }
        }
    }

    .dkq{
        width: 130px;
        height: 30px;
        background: #FF0000;
        border-radius: 4px;
        line-height: 30px;
        text-align: center;
        font-size: 18px;
        font-family: Source Han Sans CN;
        color: #FFE477;
        margin-right: 30px;
    }
    .searchBtns{
        cursor: pointer;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        width: 47px;
        height: 32px;
        background: #0078FF;
        text-align: center;
        line-height: 32px;
    }
    .ju-tinputs{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 250px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #0078FF;
    border-radius: 2px;
}
.tc_tag-input_roots{
        background: #F2F4F7;
        border-radius: 4px;
        padding: 4px 10px;
        font-size: 14px;
        margin-right: 10px;
        align-items: center;
        display: flex;
        text-align: center;
        margin-bottom: 10px;
        margin-top:5px;
    }
    .inputClassss{
        border: none;
        outline: none;
        width: 155px;
        margin-left: 10px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
    }
</style>

