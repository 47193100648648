<template>
    <div class=" header">
        <div class="header-container flex">
            <div class="left flex alignCenter">
                <div class="logo">
                    <router-link to="/" style="height: 34px;display: flex;align-items: center;">
                      <img class="imgLogo" src="../assets/images/bqqLogo.png" alt="标圈圈" />
                      <!-- <span>标圈圈</span> -->
                      <img class="imgLogos" src="../assets/images/bqqT.png" alt="" />
                    </router-link>
                </div>

                <!-- <div class="flex">
                     <el-input v-model="input2" placeholder="请输入内容"></el-input> 
                     <el-button type="primary" @click="getInputValue">搜索</el-button>
                </div> -->
            </div> 

            <div class="right">
                <!-- <el-menu :default-active="activeIndex" class="el-menu-demo" text-color="#000" mode="horizontal" @select="handleSelect">
                            <el-menu-item index="1">
                                <router-link to="/login">登录</router-link>
                            </el-menu-item>
                            <el-menu-item index="2">
                                <router-link to="/register">注册</router-link>
                            </el-menu-item>
                 </el-menu> -->

                <!-- 需要判断是否登录--没登录 -->
                <div v-if="isShow"></div>
                <div v-else> 
                <div v-if="phoneNo!=''&&phoneNo!=undefined&&phoneNo!=null" >
                    <div  class="flex jutSpace alignCenter">
                        <img  @click="Sign" src="../assets/images/vipIcon.png" style="width: 181px;height: 70px;margin-right:21px" />
                        <div class="hyTc" @click="showViptc">
                            <!-- <img src="../assets/images/hy.png" style="width: 27px;height: 23px;margin-right:3px" />
                            <span style="margin-top:3px">VIP套餐</span> -->
                            <!-- <img src="../assets/images/hy1.png" style="width: 83px;height: 18px;margin-right:3px" /> -->
                            <img style="width: 35px;height: 16px;margin-right: 1px;" src="../assets/images/vipT.png" />
                            <span class="fwSpan">服务</span>
                        </div>

                       <div class="model" @mouseenter="enter" @mouseleave="leave">
                            <div style="position:relative;margin-top: 3px;margin-left: 26px;margin-right: 14px;">
                                 <span  class="fwSpan"  @click="userMore('6')">消息</span> 
                                <span class="dian" v-if="num3>0"></span>
                            </div>
                           

                            <div  class="seen" v-if="seen">
                              <div>消息中心(<span style="color:#FF0000">{{num3}}</span>条未读消息)</div>

                              <div class="list-itemMessage" v-for="(item,index) in listAllMess" :key="item.id">
                                    <div class="half-Content flex aligenCenter" >
                                      <div style="display: flex;width: 100%;"  @click.stop="messageDetail(item.id)">
                                        <div class="leftRadius" v-if="item.readState==0"></div>
                                        <div class="rightR">
                                            <div class="flex jutSpace">
                                                <div v-if="item.subscribeName!=''&&item.subscribeName!=undefined" class="subTitle">{{item.subscribeName}}</div>
                                             </div>
                                            <div class="times">{{item.sendTime}}</div>
                                            <div v-for="(items,indexs) in item.messageList" :key="indexs" class="flex aligenCenter">
                                               <div v-if="indexs<2" class="flex aligenCenter sChildren" >
                                                 <div>{{indexs+1}}.</div>
                                                 <div class="sRight">{{items}}</div>
                                               </div>
                                            </div>
                                        </div>
                                      </div>
                                    </div>	
                             </div>

                             <div v-if="num3>0" style="text-align: center;margin-top: 10px;">
                                <span @click="userMore('6')" class="moreClass" >查看更多</span> 
                                <!-- <router-link class="moreClass" to="/userCenter?actives=6">查看更多</router-link> -->
                            </div>
                            </div>
                       </div> 
                       
                       
                      <el-menu :default-active="activeIndex" class="el-menu-demo" text-color="#000" mode="horizontal" @select="handleSelect">
                           <el-submenu index="2">
                                <template slot="title">
                                    <img style="width:40px;height:40px;" class="bordR" v-if="isShowTx" :src="userInfos.headPic" />
                                    <img style="width:40px;height:40px;" class="bordR" v-else src="../assets/images/user.png" />
                                </template>
                                <el-menu-item index="2-1" style="height: 82px;">
                                     <img style="width:50px;height:50px;margin-right: 12px;" class="bordR" v-if="isShowTx" :src="userInfos.headPic" />
                                     <img style="width:50px;height:50px;margin-right: 12px;" class="bordR" v-else src="../assets/images/user.png" />
                
                                    <div class="">
                                        <div class="flex jutSpace">
                                            <div style="margin-bottom:8px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #222222;">
                                                <span style="margin-right:8px" v-if="userInfos.realName!=''&&userInfos.realName!=null">{{userInfos.realName}}</span>
                                                <span v-else>
                                                    <span v-if="userInfos.nickname!=''&&userInfos.nickname!=null" style="margin-right:10px">{{userInfos.nickname}}</span>
                                                </span>
                                                <!-- <span v-if="userInfos.realName!=''&&userInfos.realName!=undefined||userInfos.nickname!=''&&userInfos.nickname!=undefined"> -->
                                                    <span v-if="level.userMemberRank!=''&&level.userMemberRank!=undefined">
                                                        <img v-if="level.userMemberRank==1" class="vipImage" src="../assets/images/vip.png" />
                                                        <img v-if="level.userMemberRank==2" class="svipImage" src="../assets/images/svip.png" />
                                                        <!-- <span>{{level.userMemberRankStr}}</span> -->
                                                    </span>
                                                <!-- </span> -->
                                             </div>
                                        </div>
                                        <div class=" dqColor">
                                           <div style="">账号：{{ userInfos.phoneNo }}</div>
                                           <div style="margin-top: 5px;display: flex;align-items: center;" v-if="level.userMemberRank!=0">会员到期：<span style="color:#0078FF">{{level.memberEndTime}}</span></div>
                                        </div>
                                    </div>

                                </el-menu-item>
                                <el-menu-item index="2-2">
                                     <img src="../assets/images/cicon3.png" class="seIcon"> 
                                    <!-- <i class="el-icon-user"></i> -->
                                    <router-link to="/userCenter?actives=1">个人中心</router-link>
                                </el-menu-item>
                                <el-menu-item index="2-3">
                                    <img src="../assets/images/cicon2.png" class="seIcon">
                                    <div @click="exit()">退出登录</div>
                                </el-menu-item>
                            </el-submenu> 
                      </el-menu>
                    </div>      
                </div> 


                <div v-else>
                        <el-menu :default-active="activeIndex3" class="el-menu-demo" text-color="#000" mode="horizontal" @select="handleSelect">
                            <el-menu-item index="1">
                                <router-link to="/login">登录</router-link>
                            </el-menu-item>
                            <el-menu-item index="2">
                                <router-link to="/register">注册</router-link>
                            </el-menu-item>
                        </el-menu>
                </div>
                </div>
               
            </div>
       </div>
    </div>
    
</template>

<script>
    export default {
        props:['message'],
        data() {
            return {
                activeIndex: '2',
                activeIndex3:'',
                input2:'',
                listMaterial:{},
                isLogin:true,
                phoneNo:'',
                userInfos:{},
                isShowTx:'',

                sign: false,
                level:[],
                isShow:true,
                seen:false,
                listAllMess:[],
                objId:'',
                num3:''
            };
        },
        mounted() {
            var that=this
           // that.querInfo()
        // 判断登录状态
            let phoneNo = JSON.parse(localStorage.getItem('phoneNo'));
            that.phoneNo=phoneNo
            
            setTimeout(function(){
                //that.phoneNo=that.$store.state.phoneNo
                that.isShow=false
            },300)
            that.querInfo()
            that.showVip()
            that.showMessage()
            that.showMessageNum()
        },

        methods: {
            userMore(flag){
                console.log(flag)
                this.$router.push({path:'/userCenter',query:{actives:'6'}})
            },
            enter() {
                if(this.num3>0){
                    this.seen = true
                }
            },
            leave() {this.seen = false},
            showMessage(){
                let that=this
                that.$axios.get('/webInterface/querySysSubscribeList', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            objectType:'',
                            pageSize:2,
                            pageNo:1, 
                        }
                    })
                    .then(function (res) {
                       //console.log(res);
                       if(res.data.resultcode==1){
                         that.listAllMess=res.data.sysSmsSendlogList
                       }
                    })
                    .catch(function (err) { 
                    })
            },
            messageDetail(id){
                let that=this
                that.$axios.post('/webInterface/upSysSubscribe?id='+id)
                    .then(function (res) {
                       //console.log(res);
                       if(res.data.resultcode==1){
                       
                        for(var i=0;i<that.listAllMess.length;i++){
                            if(that.listAllMess[i].id==id){
                                that.listAllMess[i].readState=1
                            }
                        }
                          that.showMessageNum()
                        const {href}=that.$router.resolve({
                            // 跳转到的页面路径
                            path: '/subscribe',
                        })
                        window.open(href,'_blank')
                       }
                    })
                    .catch(function (err) { 
                    })  
            },
            showMessageNum(){
                let that=this
                that.$axios.post('/wx/queryMineCount',)
                    .then(function (res) {
                      // console.log(res);
                       if(res.data.resultcode==1){
                            if(res.data.num3>999){
								that.num3='999+'
							}else{
								that.num3=res.data.num3
							}
                       }
                    })
                    .catch(function (err) { 
                    })
            },
           
            showViptc(){
                var that=this
                //that.$message.warning('该功能暂未开放')
                this.$router.push({path: '/vipPackage'});
            },
            Sign(){
                let that=this
                that.sign = true;
                that.$emit('change', that.sign,'1');

                //  that.$axios.post('/webInterface/querySignDetail?flag=1').then(res => {
                //     console.log(res) 
                //     if(res.data.resultcode==1){
                //       that.rlArr=res.data.webObjList
                //       that.str3=res.data.str2
                //       that.str4=3-res.data.str2
                //       that.state=res.data.status
                //   }
                //  }).catch(err => {
                //    // console.log('请求失败')
                //   })
            },
            showVip(){
               var that=this
              that.$axios.post('/webInterface/queryUserOther')
                .then(function (res) {
                    if(res.data.resultcode==1){ 
                    that.level=res.data.userOther
                    }
                })
                .catch(function (err) {
                    console.log(err);
                })
			},
            querInfo(){
                var that=this
                that.$axios.post('/webInterface/queryUserInfo')
                    .then(function (res) {
                     //console.log(res);
                     if(res.data.resultcode==1){ 
                        that.userInfos=res.data.userInfo;
                        that.phoneNo=that.userInfos.phoneNo
                        if(that.userInfos.headPic!=''&&that.userInfos.headPic!=undefined){
                            that.isShowTx=true
                        }else{
                            that.isShowTx=false
                        }
                     }else if(res.data.resultcode==-9999){
                        that.userInfos={}
                        that.phoneNo=''
				        localStorage.clear();
                     } 
                    })
                    .catch(function (err) {
                        console.log(err);
                    })
            },
            handleSelect(key, keyPath) {
                //console.log(key, keyPath);
            },
            getInputValue(){
                //console.log(this.input2)
            },
            exit(){
                var that=this
                that.$axios.post('/j_spring_security_logout?isPage=1')
                    .then(function (res) {
                     //console.log(res);
                     if(res.data.success=="true"){
                       //清除缓存
                        that.phoneNo=''
                        that.userInfos={}
                        localStorage.clear();
                        setTimeout(() => {
                            that.$router.push({
                            path: '/'
                            }, () => {
                            }, () => {
                            });//退出登录后2秒后跳转至首页
                        }, 800)
                        //that.$router.go(0)
                     }
                    })
                    .catch(function (err) {
                        console.log(err);
                    })
               

            }
        }
    }
</script>
<style scoped lang="less">
   .header{
    width:100%;
    height: 74px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
    .header-container{
        width:1200px;
        margin:0 auto;
        align-items: center;
        justify-content: space-between;
        height: 100%;
            .logo{
                  font-size: 17px;
                display: flex;
                align-items: center;
                .imgLogo{
                    width: 34px;
                    height: 34px;
                    margin-right: 7px;
                }
                .imgLogos{
                    width: 93px;
                    height: 24px;
                }
            }
            .el-input{
                .el-input__inner{
            // width: 420px !important;
                border-radius: 4px 0 0 4px !important;
               }
           }
          .el-button{
                border-radius: 0 4px 4px 0 !important;
              }
       .right{
        /deep/.el-menu.el-menu--horizontal{
            border-bottom:none !important;
            .is-active{
                .el-submenu__title{
                    border-bottom: none !important;
                }
              } 
            .hyImg{
                width:36px;
                height:30px;
                margin-top: -8px;
            }
            .el-submenu.is-active .el-submenu__title {
                border-bottom: none !important;
            }
            .is-active{
                border-bottom:none !important;
            }
            .el-submenu.is-active .el-submenu__title {
            border-bottom-color: none !important;
            }
        }
            
       } 
       
    } 
    
   }

  
   .seIcon{
    width:18px;
    height:18px;
    margin-right:10px
   }
   .el-menu-item{
        color: #909399;
        display: flex;
        border-bottom: 1px solid #F3F3F3;
        padding: 19px !important;
        align-items: center;
        line-height: 15px !important;
        min-height:47px !important;
   }
   a{
    color: #000;
   }
   .bordR{
    border-radius:50%;
}
.hyTc{
    cursor: pointer;
    display: flex;
    align-items: center;
    //margin-right: 5px;
    // font-size: 16px;
    // font-family: Source Han Sans CN;
    // font-weight: 600;
    // background: linear-gradient(143deg, #FFE749 0%, #FFD050 100%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    margin-top: 3px;
   // font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #222222;
}
.dqColor{
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    justify-content: space-between;
}
.vipImage{
   width: 24px;
    height: 11px;
    margin: 0 4px 0 0;
}
.svipImage{
    width: 35px;
    height: 11px;
    margin: 0 4px 0 0;
}
.dian{
    position:absolute;
    top:18px;
    right:-3px;
    width: 8px;
    height: 8px;
    background: #FF0000;
    border-radius: 50%;
}
.model{
    position: relative;
    height: 62px;
    line-height: 62px;
    //font-size: 18px;
}
.seen{
    z-index: 100;
    min-width: 200px;
    border: none;
    padding: 12px;
    border-radius: 2px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    margin-top: 5px;
    background-color: #FFF;
    position: absolute;
    top: 60px;
    left: -10px;
    font-size: 16px;
    max-width: 280px;
    line-height: 17px;
    cursor: pointer;
}
.list-itemMessage{
            padding: 12px 0;
            border-bottom: 1px solid #F3F3F3;
            cursor: pointer;
            .leftRadius{
                width: 4px;
                height: 4px;
                background: #E60012;
                border-radius: 50%;
                margin-top: 7px;
                margin-right: 4px;
            }
            .rightR{
                //line-height: 26px;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #222222;
                width: 96%;
                .times{
                    font-size: 13px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #999999;
                    margin: 4px 0;
                }
                .sRight{
                    overflow:hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -o-text-overflow:ellipsis;
                    max-width: 95%;
                }
                .sChildren{
                    line-height: 26px;
                    font-size: 14px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }     
        }
        .subTitle{
        max-width: 85%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .moreClass{
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #0078FF;
      }
      .fwSpan{
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #222;
     }
</style>