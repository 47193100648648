<template>
    <div>
        <common-header @change="amountChange($event)" :message="beiji"></common-header>   
        <div class="halfC" style="margin-top: 15px;">
            <h1 class="htitle">{{zbList.name}}</h1>
            <div class="ant-content">
                <div class="gGdJlei">
                    <span class="ant-tag ant-tag-orange bordered" v-if="zbList.dataType==1">{{zbList.dataTypeStr }} 
                        <span class="fSpan" v-if="zbList.dataSubTypeStr!=''&&zbList.dataSubTypeStr!=undefined">| {{zbList.dataSubTypeStr}}</span>
                    </span>
                    <span class="ant-tag ant-tag-green bordered" v-else-if="zbList.dataType==2">{{zbList.dataTypeStr }}
                        <span class="fSpan" v-if="zbList.dataSubTypeStr!=''&&zbList.dataSubTypeStr!=undefined">| {{zbList.dataSubTypeStr}}</span>
                    </span> 
                    <span class="ant-tag ant-tag-blue bordered" v-else>{{zbList.dataTypeStr }}
                        <span class="fSpan" v-if="zbList.dataSubTypeStr!=''&&zbList.dataSubTypeStr!=undefined">| {{zbList.dataSubTypeStr}}</span>
                    </span>
                    <span class="ant-tag bordered" v-if="zbList.provinceCode!=''&&zbList.provinceCode!=undefined">
                        <span class="fSpan" v-if="zbList.provinceCode!=''&&zbList.provinceCode!=undefined">{{ zbList.provinceCode }}</span>
                        <span class="fSpan" v-if="zbList.cityCode!=''&&zbList.cityCode!=undefined"> | {{ zbList.cityCode }} </span>
                        <span class="fSpan" v-if="zbList.countyCode!=''&&zbList.countyCode!=undefined"> | {{ zbList.countyCode }} </span>
                     </span>

                    <span  v-if="zbList.industryCategory!=''&&zbList.industryCategory!=undefined">
					<span class="ant-tag bordered fSpan"
					 v-for="(items,indexs) in arrPao[0]" :key="indexs">
						 {{ items }} 
                    </span>
                    </span> 

                    <span  v-if="zbList.procurementType!=''&&zbList.procurementType!=undefined">
					 <span class="ant-tag bordered fSpan"
					 v-for="(items,indexs) in arrPro[0]" :key="indexs">
						 {{ items }} 
                    </span>
                    </span>
                </div> 
                <div class="flex alignCenter jutCenter xgjIcon">           
                    <div class="flex alignCenter iconHalf" v-for="(item,index) in iconList" :key="index" >
                       <div class="flex alignCenter" @click="smNavgite(item.id,index,zbList.url)" v-if="item.id!=5">
                        <img class="iconImg" :src="item.src" />
                        <el-button v-if="index==1" :plain="true">{{item.text}}</el-button>
                        <div v-else-if="index==2" style="font-size: 14px;">{{item.text}}
                        </div> 
                        <el-button v-else :plain="true">{{item.text}}</el-button>
                       </div>
                       <div v-else  class="flex alignCenter" @click="smNavgite(item.id,index,zbList.url)">
                            <img class="iconImg" :src="item.src" />
                            <el-button v-if="index==1" :plain="true">{{item.text}}</el-button>
                            <div v-else-if="index==2" style="font-size: 14px;">{{item.text}}
                            </div> 
                            <el-button v-else :plain="true">{{item.text}}</el-button>
                       </div>
                    </div>
                </div> 
                <!-- 中标 -->
                <div class="xmTags" v-if="zbList.dataType==1">
                    <div class="fiklj9cw">
                        <div class="smailDiv" v-if="zbList.bidDeptName!=''&&zbList.bidDeptName!=undefined">
                            <div>招采单位:</div>
                            <div >{{ zbList.bidDeptName }}</div> 
                        </div>
                       
                        <div class="smailDiv" v-if="zbList.bidDeptContactor!=''&&zbList.bidDeptContactor!=undefined">
                            <div>联系人:</div>
                                <div >{{ zbList.bidDeptContactor }}</div> 
                        </div>
                        <div class="smailDiv" v-if="zbList.bidDeptPhone!=''&&zbList.bidDeptPhone!=undefined">
                            <div>联系电话:</div>
                             <div >{{ zbList.bidDeptPhone }}</div> 
                        </div>
                    </div>
                    <div class="fiklj9cw">
                        <div class="smailDiv" v-if="zbList.agentDeptName!=''&&zbList.agentDeptName!=undefined">
                            <div>代理单位:</div>
                            <div >{{ zbList.agentDeptName }}</div>
                            <!-- <div v-if="zbList.agentDeptId!=''&&zbList.agentDeptId!=undefined" @click="companyDetail(zbList.agentDeptId)" class="blueColor">{{ zbList.agentDeptName }}</div> 
                            <div v-else >{{ zbList.agentDeptName }}</div>  -->
                        </div>
                        <div class="smailDiv" v-if="zbList.agentDeptContactor!=''&&zbList.agentDeptContactor!=undefined">
                            <div>联系人:</div>
                                <div >{{ zbList.agentDeptContactor }}</div> 
                        </div>
                        <div class="smailDiv" v-if="zbList.agentDeptPhone!=''&&zbList.agentDeptPhone!=undefined">
                            <div>联系电话:</div>
                            <div>{{zbList.agentDeptPhone}}</div>
                        </div>
                    </div>
                  
                    <div v-if="zbList.pageWinPackageList!=''&&zbList.pageWinPackageList!=undefined">
                        <div class="fiklj9cw" v-for="(items,indexs) in zbList.pageWinPackageList" :key="indexs">
                                <div class="smailDiv">
                                <div>中标单位:</div>
                                <div >{{ items.windeptname }}</div> 
                                <!-- <div v-if="items.windeptId!=''&&items.windeptId!=undefined" @click="companyDetail(items.windeptId)" class="blueColor">{{ items.windeptname }}</div> 
                                <div v-else >{{ items.windeptname }}</div>  -->
                            </div>
                            <div class="smailDiv" v-if="items.winamountStr!=''&&items.winamountStr!=undefined">
                                <div>中标金额:</div>
                                <div>{{items.winamountStr}}</div>
                            </div>
                        </div>
                    </div> 
                

                    <div class="fiklj9cw">
                        <div class="smailDiv" v-if="zbList.bidCode!=''&&zbList.bidCode!=undefined">
                            <div>项目编号:</div>
                            <div>{{ zbList.bidCode }}</div>
                        </div>
                        <div class="smailDiv" v-if="zbList.issueTimeStr!=''&&zbList.issueTimeStr!=undefined">
                            <div>发布时间:</div>
                            <div>{{zbList.issueTimeStr}}</div>
                        </div>
                    </div>

                </div>

                <!-- 拟建 -->
                <div class="xmTags" v-else-if="zbList.dataType==2">
                    <div class="fiklj9cw">
                        <div class="smailDiv" v-if="zbList.bidCode!=''&&zbList.bidCode!=undefined">
                            <div>项目编号:</div>
                            <div>{{zbList.bidCode}}</div>
                        </div>
                        <div class="smailDiv" v-if="zbList.bidDeptName!=''&&zbList.bidDeptName!=undefined">
                            <div>招采单位:</div>
                            <div >{{ zbList.bidDeptName }}</div> 
                            <!-- <div v-if="zbList.bidDeptId!=''" @click="companyDetail(zbList.bidDeptId)" class="blueColor">{{ zbList.bidDeptName }}</div> 
                                <div v-else >{{ zbList.bidDeptName }}</div>  -->
                        </div>
                    </div>
                    <div class="fiklj9cw">
                        <div class="smailDiv" v-if="zbList.issueTimeStr!=''&&zbList.issueTimeStr!=undefined">
                            <div>发布时间:</div>
                            <div>{{zbList.issueTimeStr}}</div>
                        </div>
                        <div class="smailDiv" v-if="zbList.openTimeStr!=''&&zbList.openTimeStr!=undefined">
                            <div>审批时间:</div>
                            <div>{{zbList.openTimeStr}}</div>
                        </div>
                    </div>
                </div>
                <!-- 招标 -->
                <div class="xmTags" v-else>
                    <div class="fiklj9cw">
                        <div class="smailDiv" v-if="zbList.bidCode!=''&&zbList.bidCode!=undefined">
                            <div>项目编号:</div>
                            <div>{{zbList.bidCode}}</div>
                        </div>
                        <div class="smailDiv" v-if="zbList.budgetAmountStr!=''&&zbList.budgetAmountStr!=undefined&&zbList.budgetAmountStr!='null'">
                            <div>预算金额:</div>
                            <div>{{zbList.budgetAmountStr}}</div> 
                        </div>
                    </div>
                    <div class="fiklj9cw">
                        <div class="smailDiv" v-if="zbList.bidDeptName!=''&&zbList.bidDeptName!=undefined">
                            <div>招采单位:</div>
                            <div >{{ zbList.bidDeptName }}</div>
                            <!-- <div v-if="zbList.bidDeptId!=''&&zbList.bidDeptId!=undefined" @click="companyDetail(zbList.bidDeptId)" class="blueColor">{{ zbList.bidDeptName }}</div> 
                            <div v-else >{{ zbList.bidDeptName }}</div>  -->
                        </div>
                        <div class="smailDiv" v-if="zbList.bidDeptContactor!=''&&zbList.bidDeptContactor!=undefined">
                            <div>联系人:</div>
                            <div >{{ zbList.bidDeptContactor }}</div> 
                        </div>
                        <div class="smailDiv" v-if="zbList.bidDeptPhone!=''&&zbList.bidDeptPhone!=undefined">
                            <div>联系电话:</div>
                             <div >{{ zbList.bidDeptPhone }}</div> 
                        </div>
                    </div>
                    <div class="fiklj9cw">
                        <div class="smailDiv" v-if="zbList.agentDeptName!=''&&zbList.agentDeptName!=undefined">
                            <div>代理单位:</div>
                            <div >{{ zbList.agentDeptName }}</div> 
                            <!-- <div v-if="zbList.agentDeptId!=''&&zbList.agentDeptId!=undefined" @click="companyDetail(zbList.agentDeptId)" class="blueColor">{{ zbList.agentDeptName }}</div> 
                            <div v-else >{{ zbList.agentDeptName }}</div>  -->
                        </div>
                        <div class="smailDiv" v-if="zbList.agentDeptContactor!=''&&zbList.agentDeptContactor!=undefined">
                            <div>联系人:</div>
                                <div >{{ zbList.agentDeptContactor }}</div> 
                        </div>
                        <div class="smailDiv" v-if="zbList.agentDeptPhone!=''&&zbList.agentDeptPhone!=undefined">
                            <div>联系电话:</div>
                            <div>{{zbList.agentDeptPhone}}</div>
                        </div>
                    </div>
                  
                    <div class="fiklj9cw">
                        <div class="smailDiv" v-if="zbList.issueTimeStr!=''&&zbList.issueTimeStr!=undefined">
                            <div>发布时间:</div>
                            <div>{{zbList.issueTimeStr}}</div>
                        </div>
                        <div class="smailDiv" v-if="zbList.deadTimeStr!=''&&zbList.deadTimeStr!=undefined">
                            <div v-if="zbList.dataSubType==101">预计采购时间:</div>
                            <div v-else>投标截止时间:</div>
                            <div>{{zbList.deadTimeStr}}</div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="halfC" style="margin-top: 15px;" >
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="公告原文" name="first">
                    <div class="noticeCon">
                        <!-- <div style="font-size:18px;margin-top: 5px;">{{zbList.name}}</div> -->
                        
                        <div v-if="zbList.dataType!=2">
                            
                           <div v-if="zbList.sitecontentBase64==''">
                                <div v-if="zbList.sitecontent!=''">
                                    <div  v-html="zbList.sitecontent"></div>
                                </div>
                                <div v-else>
                                    <div style="text-align: center;margin-top: 10px;">
                                        <img  src="@/assets/images/empty.png"  alt=""/>
                                    </div>
                                    <div style="margin-top: 10px;text-align: center;">暂无内容，请点击上方<span style="color: #0078FF;">【查看原文】</span>按钮查看内容</div>
                                </div>

                            </div>
                            <div v-else>
                                 <div v-if="zbList.sitecontentType==4">
                                    <img :src="zbList.sitecontentBase64" />
                                </div>
                                <div v-else>
                                    <iframe :src="zbList.sitecontentBase64" width="100%" height="880px"></iframe>  
                                </div>
                            </div>  
                             <!-- <iframe width="100%" height="800px" :src="pdfSrc"></iframe>  -->
                           
                            <!-- <div v-if="zbList.sitecontentType==2||zbList.sitecontentType==5">
                                <iframe :src="zbList.sitecontentBase64" width="100%" height="100%"></iframe> 
                            </div>
                            <div  v-else v-html="zbList.sitecontent"></div> -->
                            
                        </div>
                        
                        <div v-else>
                            <div class="tabTitle mtop12">项目基本信息</div>
                            <table class="ntable sborder " >
                                <tr>
                                    <td class="tdA" >
                                        项目代码 
                                    </td>
                                    <td class="tds">
                                        <span class="copy-value">{{zbList.bidCode}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="tdA">
                                        项目名称 
                                    </td>
                                    <td class="tds">
                                        <span class="copy-value">{{zbList.bidName}}</span>
                                    </td>
                                </tr>
                            </table>
                            <div v-if="zbList.proposedApprovalDetialList!=''&&zbList.proposedApprovalDetialList!=undefined">
                                <div class="tabTitle mtop12">办理结果信息</div>
                                <div v-for="(item,index) in zbList.proposedApprovalDetialList" :key="index">
                                    <table class="ntable sborder ">
                                    <tr>
                                        <td class="tdA" >审批部门 </td>
                                        <td class="tds"><span class="copy-value">{{item.approvalDep}}</span></td>
                                    </tr>
                                    <tr>
                                        <td class="tdA">审批事项 </td>
                                        <td class="tds"><span class="copy-value">{{item.approvalEvent}}</span></td>
                                    </tr>
                                    <tr>
                                        <td class="tdA">审批文号 </td>
                                        <td class="tds"><span class="copy-value">{{item.approvalNo}}</span></td>
                                    </tr>
                                    <tr>
                                        <td class="tdA">审批结果 </td>
                                        <td class="tds"><span class="copy-value">{{item.approvalResult}}</span></td>
                                    </tr>
                                    <tr>
                                        <td class="tdA">审批时间 </td>
                                        <td class="tds"><span class="copy-value">{{item.approvalTime}}</span></td>
                                    </tr>
                                    </table>
                                </div>
                            </div>
                            <div v-if="sitecontent!=''&&sitecontent!=undefined">
                                <div class="tabTitle mtop12">信息详情</div>
                                <div class="dSitcontent" v-html="sitecontent"></div>
                                <!-- <div class="uni-common-mt" style="font-size: 14px;line-height: 23px;margin-top: 12px;">
                                    <div  v-if="zbList.sitecontentBase64==''" style="word-break:break-all;overflow: auto;max-width: 100%;">
                                        <div v-html="zbList.sitecontent"></div>
                                    </div> 
                                    <div v-else style="margin: 5px 5px 15px 5px;">
                                        <div v-if="zbList.sitecontentType==4">
                                            <image :src="zbList.sitecontentBase64"></image>
                                        </div>
                                        <div v-else>
                                            <image class="ggywImg" src="../assets/images/ggyw.png"></image>
                                            <div v-if="scrollTop>0" class="footBtnsC" @click.stop="open(zbList.sitecontentBase64)">查看原文</div>
                                        </div>
                                    </div>
                                </div>  -->
                            </div>
                        </div>
                        <!-- <div style="margin-top:30px" v-html="zbList.wincontent" v-if="zbList.sitecontentBase64==''"></div>
                        <div v-else>
                            <iframe :src="zbList.sitecontent" style="width:100%;height:100%" frameborder="0"></iframe>
                        </div> -->
                    </div>
                </el-tab-pane>
                <!-- <el-tab-pane label="潜在供应商" name="second" v-if="isTabs==5">
                    <div class="ant-spin-container">
                        <div class="ant-list-items" v-for="(item,index) in gysList" :key="index">
                            <div class="tc_list_item tc_list_hover">
                                <div class="R-Dvn3zA">
                                    <div class="pYreFzHC">
                                        <img src="https://image.qcc.com/auto/31396f80d7a8bedee0f4047e36ab4f42.jpg?x-oss-process=style/logo_120" />
                                    </div>
                                    <div class="_0aDOKXj6">
                                        <div class="FpwufnYn flex">
                                            <div class="title">{{item.compony}}</div>
                                            <div class="WMSbIy8X flex">
                                                <div style="margin-right:20px">中标金额：<span style="color:red">{{item.price}}</span></div>
                                                <div class="">中标数：<span style="color:red">{{item.num}}</span></div>
                                            </div>
                                        </div>
                                       <div  style="margin-bottom:10px">
                                            <span class="ant-tag ant-tag-blue bordered">{{item.tags}}</span>
                                            <span class="ant-tag bordered">{{item.address}}</span>
                                       </div>
                                      
                                        
                                        <div class="flex noticTitleA" style="align-items: flex-start">
                                            <div class="huiColor">中标产品：</div>
                                            <div style="flex: 1;">{{item.cp}}</div>
                                        </div>
                                        <div class="flex noticTitleA" style="align-items: flex-start">
                                            <div class="huiColor">联系方式：</div>
                                            <div>{{item.user}}</div>
                                        </div>

                                        <div class="flex jutRowsA">
                                            <div class="flex noticTitles" >
                                                <div  class="huiColor">中标时间：</div>
                                                <div>{{item.time}}</div>
                                            </div>
                                            <div class="flex noticTitles">
                                                <div  class="huiColor">中标结果：</div>
                                                <div>{{item.result}}</div>
                                            </div>
                                            <div class="flex noticTitles">
                                                <div  class="huiColor">地址：</div>
                                                <div>{{item.address}}</div>
                                            </div>
                                            <div class="flex noticTitles">
                                                <div  class="huiColor">提及单位：</div>
                                                <div style="max-width: 150px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{item.dw}}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane> -->
            </el-tabs>
        </div>  
        <div >


                <el-drawer
                    title=""
                    :visible.sync="isShow3"
                    size="400px">
                        <div style="padding: 0 20px;font-size: 14px;">
                            <div class="gjTitle">跟进内容</div>
                            <el-input
                                type="textarea"
                                maxlength="50"
                                @blur="gjText"
                                :rows="4"
                                placeholder="请输入跟进内容(最多输入50个字符)"
                                v-model="gjtextarea">
                            </el-input>
                            <div class="gjTitle">跟进阶段</div>
                            <div class="gjTabs">
                                <el-tabs v-model="gjChoose" type="card" @tab-click="handleClicks" >
                                    <el-tab-pane v-for="(item,index) in gjList" :key="index" :label="item.text" :name="item.name"></el-tab-pane>
                                </el-tabs>
                            </div>

                            <div class="gjTitle">提醒时间</div>
                            <el-date-picker
                            v-model="gjTime"
                            type="datetime"
                            @change="timeClick"
                            :picker-options="pickerOptions0"
                            placeholder="请选择提醒时间">
                            </el-date-picker>
                            <!-- <div class="gjTitle flex jutSpace">
                                <div>开标提醒</div>
                                <el-switch
                                v-model="valueSwitch"
                                @change="switchChang">
                                </el-switch>
                            </div>

                            <div class="flex jutSpace">
                                <div style="color: #999;">开标前24小时/开标时会提醒您</div>
                                <div style="color: #409EFF;">距离开标还有6天</div>
                            </div> -->
                        </div>

                        <div class="demo-drawer__footer">
                            <el-button @click="cancelForm">取 消</el-button>
                            <el-button type="primary" @click="sure" >保 存</el-button>
                        </div>



                </el-drawer>

        </div>  

        <div >
            <el-dialog
                    :visible.sync="isDialog"
                    width="30%">
                    </el-dialog>
        </div>

        <common-foot></common-foot>
        <common-active :showTk="beiji" :showActivity="flagActivity" @change="amountChanges($event)"></common-active>
    </div>
</template>

<script>
   import CommonHeader from '@/components/CommonHeader.vue';
   import CommonFoot from '@/components/CommonFoot.vue';
   import CommonActive from '@/components/CommonActive.vue';
   var graceRichTextReg;
// 批量替换的样式 [ 根据项目需求自行设置 ]
var GRT = [
	 ['text', "font-size:1em;line-height:2em;"],
	 ['p', "font-size:1em;word-wrap: break-word;"],
	['img', "max-width:100%;"],
	['table', "border:1px solid #616161;border-collapse: collapse;"],
	['th', "border-right:1px solid #616161; border-bottom:1px solid #616161;"],
	['td', "border-right:1px solid #616161; border-bottom:1px solid #616161; padding:3px;border-left:1px solid #616161;"]
];
    export default {
      components:{
       CommonHeader,
       CommonFoot,
       CommonActive
       }, 
        data() {
            return {
             flag:'',
             isTabs:'',
             iconList:[
                // {id:'1',src:require('../assets/images/aicon1.png'),text:'导出'},
                {id:'2',src:require('../assets/images/aicon2.png'),text:'收藏'},
                // {id:'3',src:require('../assets/images/aicon3.png'),text:'跟进'},
                // {id:'4',src:require('../assets/images/aicon4.png'),text:'手机查看'},
                {id:'5',src:require('../assets/images/aicon5.png'),text:'查看原文'},
             ],
             activeName:'first',
         
               isFlag:'0',
               isShow3:false,
               gjtextarea:'',
               gjList:[
                {name:'1',text:'有机会'},{name:'2',text:'去现场'},{name:'3',text:'找到关键人'},
                {name:'4',text:'投标'},{name:'5',text:'送资料'},{name:'6',text:'报价'},
               ],
               gjChoose:'',
               gjTime:'',
               pickerOptions0: {
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 8.64e6;//如果没有后面的-8.64e6就是不可以选择今天的
                     }
                }, 
                valueSwitch: true,
                innerDrawer: false,
                gjLists: [{
                    id:1,
                    content: '活动按期开始',
                    year: '2018-04-16',
                    tips:'有机会',
                    time:'2018-04-15 15:00:00',
                    isFlag:false,
                    },{
                        id:2,
                    content: '活动按期开始',
                    year: '2018-04-15',
                    tips:'有机会',
                    time:'2018-04-15 15:00:00',
                    isFlag:false,
                    },{
                        id:3,
                    content: '活动按期开始',
                    year: '2018-04-14',
                    tips:'有机会',
                    time:'2018-04-15 15:00:00',
                    isFlag:false,
                    }],
                    visible: false,
                    visibles:false,
                    hover: false,
                    isDialog:false,
                    gysList:[],
                    zbList:'',
                    isShouc:'',
                    beiji:false,

                    
                phoneNo:'',
                vipAuth:'',
               
                gjtext:'',  //关键词
                messageTitle:'',
                tempPage:'',
                sitecontent:'',

                arrPao:[],
			    arrPro:[],
                flagActivity:'',
                pdfSrc:''
            };
        },
        created(){
            this.flag = this.$route.query.flag
            //this.isTabs=this.$route.query.tabs
 
           // this.pdfSrc = 'https://bulletin.cebpubservice.com/resource/ceb/js/pdfjs-dist/web/viewer.html?file=https://bulletin.cebpubservice.com/details/bulletin/getBulletin/d630825fb8f94dbf99e85f02ecd22711/8a9494758aac89e2018b18e7cb3e72b6';

        },
        mounted() {
            this.showDetails();
            //this.showShoucang()
            let phoneNos=JSON.parse(localStorage.getItem('phoneNo'))
            this.phoneNo=phoneNos
        },
        methods: {
            amountChange(e){
              this.beiji=e
            },
            amountChanges(e){
              this.beiji=e
              this.flagActivity='1'
            },
            handleClick(e) {
                console.log(e._props.name);
            },
            showDetails(){
                var that=this
                that.$axios.get('/webInterface/queryScripingpage', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            id:that.flag,
                        }
                    })
                    .then(function (res) {
                     console.log(res);
                     if(res.data.resultcode==-9999){
                      that.$message.error('登录信息失效，请重新登录')
                        setTimeout(()=>{  //设置延迟执行
                            that.$router.push({path: '/login'});

                            // router.replace({
                            //     name:"login",
                            //     query: {redirect: router.currentRoute.fullPath}
                            // })


                       },1500);
                    }else{
                     that.isShouc=res.data.bookmarkState
                     that.zbList=res.data.scripingpage
                     
                    var arr=[]
					var arrsa=[]
					arr.push(that.zbList.procurementType.split(','))
					arrsa.push(that.zbList.industryCategory.split(','))
					that.arrPro=arr
					that.arrPao=arrsa

                     let arrs=res.data.scripingpage.sitecontent
					if(arrs!=''&&arrs!=undefined){
                        that.sitecontent = that.graceRichText(arrs)
					    that.sitecontent=that.sitecontent.replace('www.plap.cn','www.biaox.cn')
                    }else{
                        that.zbList.sitecontent = ''
                    }
                     if(that.isShouc==1){
                        that.iconList[0].src=require('../assets/images/aicon22.png')
                        that.iconList[0].text='取消收藏'
                        that.isFlag=1
                     }
                     }
                    })
                    .catch(function (err) {
                        console.log(err);
                    })
            },
            graceRichText(html){
                html = html.replace(/<pre.*pre>?/gis, function(word){
                    return word =  word.replace(/[\n]/gi,'');
                    word =  word.replace(/    /gi,'<span style="padding-left:2em;"></span>');
                    return word.replace(/[\t]/gi, '<span style="padding-left:2em;"></span>');
                });
                // html = html.replace(/<pre/gi, '<p style="font-size:1em; margin:12px 0; line-height:1.2em; background:#F6F7F8; border-radius:5px; padding:8px 4%; width:92%;"');
                html = html.replace(/<\/pre/gi,"</p");
                html = html.replace(/solid black 1.0pt;/gi,"1px solid #000");
                html = html.replace(/<\!--<\/td-->/gi,"");
                //html = html.replace(/text-decoration:underline ;/gi,"");font-weight:bold;
                html = html.replace(/color:#02396f;text-decoration:underline ;/gi,"color:#222222;");
                
                html=html.replace(/vertical-align:center;/gi, "")
                html=html.replace(/vertical-align:middle;/gi, "")
                html=html.replace(/boder-left:none;/gi, "")
                html=html.replace(/samp/gi,'span')
                html=html.replace(/<h1/gi,'<h3')
                html=html.replace(/<\/h1>/gi,'</h3>')
                html=html.replace(/<h2/gi,'<h3')
                html=html.replace(/<\/h2>/gi,'</h3>')
                html=html.replace(/&nbsp;/gi,'')
                html=html.replace(/border-width:/gi,'')
                html=html.replace(/padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;/gi,'')
                
                html=html.replace(/line-height: 1em;/gi,'')
                html=html.replace(/style="height: 18.15pt"/gi,'')
                
                
                for(let i = 0; i < GRT.length; i++){
                    graceRichTextReg = new RegExp('<'+GRT[i][0]+'>|<'+GRT[i][0]+' (.*?)>', 'gi');
                    html = html.replace(graceRichTextReg , function(word){
                        // 分析 dom 上是否带有 style=""
                        if(word.indexOf('style=') != -1){
                            var regIn = new RegExp('<' + GRT[i][0] + '(.*?)style="(.*?)"(.*?)(/?)>', 'gi');
                            return word.replace(regIn, '<'+ GRT[i][0] +'$1style="$2 ' + GRT[i][1] +'"$3$4>');
                        }else{
                            var regIn = new RegExp('<' + GRT[i][0] + '(.*?)(/?)>', 'gi');
                            return word.replace(regIn, '<'+ GRT[i][0] +'$1 style="' + GRT[i][1] +'$2">');
                        }
                    });
                }
                return html;
            },
            smNavgite(flag,index,url){
                var that=this
                if(flag==1){
                  //导出
                }else if(flag==2){
                    //console.log(that.isFlag)
                    if(that.isFlag==1){
                        that.$axios.get('/webInterface/upBookmark', {  //params参数必写 , 如果没有参数传{}也可以
                                params: {  
                                    objectId:that.zbList.id,
                                    objectType:2
                                }
                            })
                            .then(function (res) {
                            console.log(res);
                              if(res.data.resultcode==1){
                                that.iconList[index].src=require('../assets/images/aicon2.png')
                                that.iconList[index].text='收藏'
                                that.isFlag=0
                                that.$message({
                                    message: '取消收藏成功',
                                    type: 'success'
                                });
                              }
                            })
                            .catch(function (err) {
                                console.log(err);
                            })

                       
                    }else{
                        that.$axios.get('/webInterface/upBookmark', {  //params参数必写 , 如果没有参数传{}也可以
                                params: {  
                                    objectId:that.zbList.id,
                                    objectType:2
                                }
                            })
                            .then(function (res) {
                            console.log(res);
                              if(res.data.resultcode==1){

                                that.iconList[index].src=require('../assets/images/aicon22.png')
                                that.iconList[index].text='取消收藏'
                                that.isFlag++
                                that.$message({
                                    message: '收藏成功',
                                    type: 'success'
                                });
                              }
                            })
                            .catch(function (err) {
                                console.log(err);
                            })

                    }
                    
               }else if(flag==3){
                  this.isShow3=true
               }else if(flag==4){
                  this.isDialog=true
               }else{
                //console.log(url)
                window.open(url, '_blank')
                //window.location.href = url;
                 //window.location.href =url
               //  window.location.href = ''
               }
            },
            cancelForm(){
                this.isShow3=false
            },
            sure(){

            },
            gjText(e){
                console.log(this.gjtextarea)
            },
            handleClicks(){
                console.log(this.gjChoose)
            },
            timeClick(){
                this.gjTime=this.timeZh(this.gjTime)
                console.log(this.gjTime)
            },
            timeZh(time){ 
                var date = new Date(time);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? "0" + m : m;
                var d = date.getDate();
                d = d < 10 ? "0" + d : d;
                var h = date.getHours();
                h = h < 10 ? "0" + h : h;
                var minute = date.getMinutes();
                minute = minute < 10 ? "0" + minute : minute;
                var s = date.getSeconds();
                s = s < 10 ? "0" + s : s;
                const times = y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + s;

                return times
            },
            switchChang(){
                console.log(this.valueSwitch)
            },
            //删除全部跟进
            deleteAllgj(){
                this.visible=false
                this.gjLists=[]
            },
            //单条数据删除
            deletesgj(index){
                console.log(index)
                this.visibles=false
                this.gjLists.splice(index,1)
            },
            edit(index,id){
                this.innerDrawer=true
            },
            
        }
    }
</script>
<style scoped lang="less">
  .halfC{
    width: 1140px;
    background: #FFFFFF;
    border: 1px solid #F3F3F3;
    border-radius: 5px;
    margin: 0 auto;
    padding: 30px 30px;
   
  /deep/.el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2) {
    padding-left: 0;
    }
    .el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:last-child {
        padding-right: 20px;  
    }
   /deep/.el-tabs{
    .el-tabs__item{
    height: 40px !important;
    line-height: 22px;
    font-size: 16px !important;
   }
  }
  /deep/.ant-content{
    .xgjIcon{
        .el-button{
        border: none;
        padding: 0;
        margin-top: 2px;
    }
    .el-button.is-plain:focus, .el-button.is-plain:hover {
        background: #FFF;
        border-color: none;
        color:#50545A;
    }
   }
  }
  

}

.gGdJlei{
    margin-bottom: 14px;
    text-align: center;
}
.ant-tag-orange.bordered {
    border-color: #f9ad14 !important;
}
.ant-tag.bordered {
    border-color: #d9d9d9;
}
.ant-tag{
    color: #666;
    box-sizing: border-box;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum","tnum";
    display: inline-block;
    height: auto;
    margin: 0 8px 0 0;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    background: #f6f6f6;
    border: 1px solid #d9d9d9;
    cursor: default;
    opacity: 1;
    transition: all .3s cubic-bezier(.78,.14,.15,.86);
    border-radius: 2px;
    font-family: none;
}
.ant-tag-orange {
    background: #fffaf0;
    border-color: #fffaf0;
    color: #f9ad14;
}
.ant-tag-blue{
    background: #fffaf0;
    border-color: #fffaf0;
    color: #0078FF; 
}
.ant-tag-blue.bordered {
    border-color: #0078FF !important;
}
.fiklj9cw {
    color: #333;
}
.fiklj9cw .smailDiv {
    line-height: 22px;
    margin: 5px 0;
    max-width: 100%;
}
.fiklj9cw .smailDiv, .fiklj9cw .smailDiv>div {
    display: inline-block;
}
.fiklj9cw .smailDiv>div:first-child {
    color: #999;
    padding-right: 10px;
}
.fiklj9cw .smailDiv:not(:last-child) {
    margin-right: 30px;
}
.blueColor{
    color: #0078FF ;
    cursor: pointer;
}
a{
    color:#000
}
.htitle{
    text-align: center;
    padding: 0;
    margin: 0px 0 17px 0;
    font-size: 31px;
    font-weight: bold;
    color:#222222;
}
.xmTags{
    background: #F5F7FA;
    border-radius: 5px;
    padding: 10px 15px;
    margin-top: 22px;
    font-size: 16px;
}
.xgjIcon{
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #50545A;
    margin: 15px 0;
}
.iconImg{
  width:18px;
  height: 18px;
  margin-right:5px
}
.iconHalf:last-child{
    margin-right:0;
}
.noticeCon{
    font-size: 16px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #222222;
}
.huiColor{
     color:#999999;
}
.noticTitle{
    line-height: 32px;
    font-size:16px
}
.titTop{
    margin-top: 15px;
}

.gjTitle{
    margin: 22px 0 15px 0;
    font-weight: 700;
}

 /deep/
//    .el-drawer__wrapper{
//     .el-drawer__container{
        .el-drawer{
        .el-drawer__header {
            padding: 0 0 15px 0 !important;
            font-size: 16px !important;
            color: #333 !important;
            font-weight: 700;
            border-bottom: 1px solid #F3F3F3 !important; 
            margin: 20px 20px 0 20px !important;
        }
        .el-drawer__body{
            padding:20px;
            .demo-drawer__footer{
                position: absolute;
                right: 0;
                bottom: 0;
                width: 100%;
                border-top: 1px solid #e9e9e9;
                background: #fff;
                text-align: right;
                z-index: 1;
                padding: 20px 16px;
                .el-button{
                    padding: 0 15px;
                    height: 32px;
                    font-size: 14px;
                    width: 74px;
              }
            }
            .el-popover__reference-wrapper{
                .el-button{
                    border: none;
                    padding: 0;
                    font-size: 14px;
                }
            }
         
        }
      }
//     }
   
// }

.el-input{
    width:100%
}


/deep/ .el-tabs--card>.el-tabs__header {
        border-bottom: none !important;
        margin: 0;
        .el-tabs__nav{
            border: none !important;
            border-bottom: none !important;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            
            .el-tabs__item {
                padding:0;
                width: 98px;
                border: 1px solid #d6d6d6;
                border-radius: 4px;
                margin-bottom: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                
            }
            .el-tabs__item.is-active{
                border-bottom-color: none;
                border-color: #409EFF;
            }
        }
    }

    .gjCon{
        .gjHalf{
            display: flex;
    align-items: flex-start;
    background-color: #f8f8f8;
    border-radius: 4px;
    padding: 10px;
        }

    }
  .cicle{
    content: "";
    width: 10px;
    height: 10px;
    background: #409EFF;
    border: 1px solid #e3ecfa;
    border-radius: 50%;
    margin-right: 10px;
  }
  .ggTitles{
    max-width: 85px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 10px;
    font-weight: 700;
    line-height: 22px;
  }
  .ggTips {
    background: #e3ecfa;
    border-color: #e3ecfa;
    color: #409EFF;
    border-radius: 2px;
    height: auto;
    margin: 0 8px 0 0;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
  }
  .timeTx{
    color: #666;
    line-height: 22px;
    margin-top: 5px;
  }
  /deep/.el-popover{
    .el-button--text {
    border-color: transparent;
    color: #409EFF;
    background: 0 0;
    border: 1px solid #DCDFE6;
    padding: 7px 15px;
    }
  }
  .gjColor:hover{
    color: #409EFF;
  }
  /deep/.titleGj {
    .el-popover{
        right: 27px;
        margin-top: 21px;
    }
  }
  /deep/.el-dialog__body{
    text-align: center;
  }

  .ant-spin-container {
    position: relative;
    transition: opacity .3s;
  }
  .ant-list-items {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    border-bottom: 1px solid #F3F3F3;
    padding: 10px 0 25px 0;
  }
  .ant-list-items:last-child{
    border-bottom:none
  }
  .tc_list_item.tc_list_hover {
   // padding: 0 20px;
    transition: background-color .2s
  }
 
  .tc_list_item:not(:last-child):after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    border-bottom: 1px solid #eee;
  }
  .R-Dvn3zA{
    padding-top: 10px;
    display: flex;
  }
  .pYreFzHC {
    padding-right: 20px;
    // width: 80px;
    height: 160px;
  }
  .pYreFzHC img {
    border: 1px solid #eee;
    width: 60px;
    height: 60px;
    border-radius: 4px;
  }
  ._0aDOKXj6 {
    position: relative;
   width: calc(100% - 80px);
  }
  .FpwufnYn {
    display: flex;
    margin-bottom: 10px;
    cursor: pointer;
    justify-content: space-between;
  }
  .FpwufnYn a {
    font-size: 16px;
    font-family: PingFangSC-Medium,PingFang SC;
    font-weight: 700;
    color: #333;
}
.WMSbIy8X {
    // position: absolute;
    // top: 0;
    // right: 0;
    height: 24px;
    color: #000;
    font-weight: 700;
}
.jutRowsA{
    height: 46px;
    background: #F5F7FA;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    margin-top: 10px;
}
.noticTitles{
    line-height:46px;
    margin-right: 20px;
}
.noticTitles:last-child{
    margin-right: 0;
}
.noticTitleA{
    font-size: 16px;
    line-height: 25px;
    margin: 5px 0;
    max-width: 100%;
}
.title{
    font-size: 18px;
    font-weight: 700;
    color: #333;
    cursor: pointer;
}
/deep/.iconHalf{
    margin-right:15px;
    cursor: pointer;
    margin-left: 0;
    .el-button{
        span{
        font-size: 14px;
        color:#606266;
        font-family: none;
    }
}
}






/deep/.tkPay{
    .el-dialog{
        border-radius: 5px;
    .el-dialog__header{
        background-image: url(../assets/images/tcTop.png);
        width:100%;
        height:140px;
        padding:0;
        .el-dialog__close{
            color: #fff;
        }
    }
    .el-dialog__body{
       border-radius: 5px;
       padding: 0;
       padding-bottom: 20px;
       .nav{
        .nav-top{
            padding: 30px 30px 20px 20px;
            margin: -50px 15px 20px 15px;
            background: #fff;
           // box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 8%);
            box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.08);
            border-radius: 10px;
        }
        .nav-tabel{
            margin: 20px 15px 0 15px;
            max-height: 300px;
            overflow: auto;
        }
       }
       .navK{
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.08);
        border-radius: 10px;
        margin: -50px 15px 0 15px;
        padding: 30px;
        .fYcolor{
            background: #FFFCF6;
            border-radius: 5px;
            padding: 15px;
            display:flex;
            .cpLeft{
                width: 160px;
                height: 200px;
                background: #FFFFFF;
                border-radius: 5px;
                margin-right: 60px;
                .ewmImg{
                    width:160px;
                    height:160px;
                    margin-bottom:7px;
                }
            }
            .cpRight{
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #222222;
                line-height: 30px;
                margin-top: 20px;
                text-align: left;
            }
        }
       }
    }
  }
}

.tcTabelImg{
    width:100%;
    height:757px
}
.kaiTong{
    background-image: url(../assets/images/kaiTong.png);
    width:200px;
    height:46px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #222222;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.ktText{
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #B28054;
    position: absolute;
    top: 20px;
    left: 94px;
}
.tabs{
    .btn{
        position: relative;
        flex: 1;
        margin: 0 10px;
        border: 1px solid #CCCCCC;
        border-radius: 5px;
        padding: 19px 15px;
        cursor: pointer;
        transition: border-color .2s;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #222222;
        .tc_purchase-order_check {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 30px;
            height: 30px;
        }
    }
    .active{
        border-color: #432107;
        background-color: #fff;
        .tc_purchase-order_check{
            background-image: url(../assets/images/gou.png);
           background-size: 100% 100%;
        }
    }
}
.rows{
    justify-content: space-between;
}

.sborder{
    border-top: 1px solid #13C261;
    border-left: 1px solid #13C261;
}
.mtop12{
    margin:12px 0;
}
.ntable{
    width: 100%;
    margin: 0 auto;
    margin-bottom: 15px;
    font-size: 14px;
    border-spacing: 0;
    .tdA{
    padding: 10px 10px 11px 10px;
    word-break: break-all;
    line-height: 1.6;
    color: #333;
    word-wrap: break-word;
    padding-right: 6px;
    background: #EDFFF0;
    width: 6%;
    border-bottom: 1px solid #13C261;
    border-right: 1px solid #13C261;
    // font-weight: bold;
  }
  .tds{
    width: 30%;
    padding: 10px 10px 11px 10px;
    word-break: break-all;
    line-height: 1.6;
    color: #606266;
    word-wrap: break-word;
    padding-right: 6px;
    background: #FFFFFF;
    border-bottom: 1px solid #13C261;
    border-right: 1px solid #13C261;
  }
}
.tabTitle {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #222222;
}
// .dSitcontent{
//     border:1px solid #000;
//     .bordered{
//         border:1px solid #000
//     }
// }
.fSpan{
    font-size: 12px;
    font-family: none;
}
</style>