<template>
    <div class="">
      <div class='beij'  v-if="showTk"  @touchmove.prevent></div>
      <div class='zhuce' v-if="showTk"  @touchmove.prevent>
        <div style="position: relative;text-align:center;width: 100%;height: 100%;">
            <img src="../assets/images/vipDel.png" class="closeImg"  @click="quxiao" />
             <div class="hyTop">
              <div>会员免费领</div>
              <div style="font-size: 30px;margin-top: 5px;">更多活动等你探究</div>
             </div>
             <!-- <div>flag:{{ flagActivity }}</div> 
             <div v-if="flagActivity=='0'">aaa</div>
             <div v-else>bbb</div> -->
             <div class="hyFoot">
              <div class="ewmHalf">
               <!-- <img v-if="qrCodeXcxVipUrl!=''&&qrCodeXcxVipUrl!=undefined" :src="qrCodeXcxVipUrl" class="ewmIcon" /> -->
               <img v-if="flagActivity=='0'" src="../assets/images/vipCenterEwm2.jpg" class="ewmIcon">
               <img v-else src="../assets/images/vipCenterEwm.jpg" class="ewmIcon">
              </div>
              <div>扫码进入标圈圈小程序领取免费会员</div>
             </div>
             
        </div>  

    </div>
    </div>
    
</template>

<script>
    export default {
        props:['showTk','showActivity'],
        data() {
            return {
               //签到
                beiji:'',
                sign: false,
                state:'',
                str3:'',
                str4:'',
                rlArr:[],
                showTks:'',
                strUrl:'',
                phoneNo:'',
                qrCodeXcxVipUrl:'',
                flagActivity:''
            };
        },
        mounted() {
            var that=this
            that.showQd()
            that.querInfo()

              let urls=window.location.href
              let index = urls.indexOf('#');//获取“=”首次出现的位置
              let o = urls.substring( index + 1 );
              // let indexs = o.indexOf("?")
              // let resolve = o.substring(0, indexs);
              let indexss = o.indexOf("/");
              that.strUrl = o.substring(indexss + 1,o.length);
        },
        watch:{
          showActivity(newV){
           this.flagActivity=newV
          },
          showTk(newvalue){
              var that=this
                that.$axios.post('/webInterface/querySignDetail?flag=1').then(res => {
                  //console.log(res) 
                    if(res.data.resultcode==1){
                      for(var i=0;i<res.data.webObjList.length;i++){
                        if(i<3){
                          that.rlArr[i]=res.data.webObjList[i]
                          that.str3=res.data.str2
                          that.str4=3-res.data.str2
                          that.state=res.data.status
                        }
                      }
                  }
                 }).catch(err => {
                   // console.log('请求失败')
                  })
             }  
          },

        methods: {
          //查询登录信息是否失效
          querInfo(){
                var that=this
                that.$axios.post('/webInterface/queryUserInfo')
                    .then(function (res) {
                     //console.log(res);
                     if(res.data.resultcode==1){ 
                        that.userInfos=res.data.userInfo;
                        that.phoneNo=that.userInfos.phoneNo
                       // that.qrCodeXcxVipUrl=that.userInfos.qrCodeXcxVipUrl
                     }else if(res.data.resultcode==-9999){
                        that.phoneNo=''
				                localStorage.clear();
                        that.$emit('change', false);
                     } 
                    })
                    .catch(function (err) {
                        console.log(err);
                    })
            },
            //显示签到
            quxiao(){
                 this.showTks=false
                 this.$emit('change', this.showTks);

                 var that=this
                 that.$axios.post('/webInterface/querySignDetail?flag=1').then(res => {
                    //console.log(res) 
                    if(res.data.resultcode==1){
                      for(var i=0;i<res.data.webObjList.length;i++){
                        if(i<3){
                          that.rlArr[i]=res.data.webObjList[i]
                          that.str3=res.data.str2
                          that.str4=3-res.data.str2
                          that.state=res.data.status
                        }
                      }
                         
                  }
                 }).catch(err => {
                   // console.log('请求失败')
                  })
            },
             showQd(){
              var that=this
              that.$axios.post('/webInterface/querySignDetail?flag=1').then(res => {
                  // console.log(res) 
                    if(res.data.resultcode==1){
                      for(var i=0;i<res.data.webObjList.length;i++){
                        if(i<3){
                          that.rlArr[i]=res.data.webObjList[i]
                          that.str3=res.data.str2
                          that.str4=3-res.data.str2
                          that.state=res.data.status
                            if(that.state==1){
                              that.$emit('change', false);
                            }else{
                              if(that.strUrl==''){
                                that.$emit('change', true);
                              }else{
                                that.$emit('change', false);
                              }
                            }
                        }
                      }
                     
                    }else{
                      
                      if(that.phoneNo!=''&&that.phoneNo!=undefined){
                        if(that.strUrl==''){
                            that.$emit('change', true);
                          }else{
                            that.$emit('change', false);
                        }
                      }else{
                        that.$emit('change', false);
                      } 
                    }
                  
                }).catch(err => {
                   // console.log('请求失败')
                })
           },
           tgVip(){
                 this.showTks=false
                 this.$emit('change', this.showTks);
                 
            if(this.strUrl=='userCenter'){
              this.$router.push({
                  // 跳转到的页面路径
                  path: '/userCenter',
                  // 传递的参数集合
                  query: {
                    actives: 4,
                    str:1
                  }
                })
            }else{
              this.$router.push({
                  // 跳转到的页面路径
                  path: '/userCenter',
                  // 传递的参数集合
                  query: {
                    actives: 4,
                    str:''
                  }
                })
            }
              
          },
           //点击签到
           djQd(){
            var that=this
            that.$axios.post('/webInterface/upSign').then(res => {
                  //console.log(res) 
                  if(res.data.resultcode==1){
                    // that.showQd()
                   that.$axios.post('/webInterface/querySignDetail?flag=1').then(res => {
                    console.log(res) 
                    if(res.data.resultcode==1){
                      for(var i=0;i<res.data.webObjList.length;i++){
                        if(i<3){
                          that.rlArr[i]=res.data.webObjList[i]
                          that.str3=res.data.str2
                          that.str4=3-res.data.str2
                          that.state=res.data.status
                        }
                      }
                  }
                 }).catch(err => {
                   // console.log('请求失败')
                  })
                   
                }else{
                  this.$message.error(res.data.resultmessage)
                }
              }).catch(err => {
                  // console.log('请求失败')
              })
           },

        }
    }
</script>
<style scoped lang="less">
   // 弹框
.beij{
    width: 100%;
        height: 100%;
        background: #000000;
        opacity: 0.2;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
}
.zhuce{
    // position: fixed;
    // width:576px; //33.3% 640
    // height:735px; //75%
    position: fixed;
    // width: 33.7%;
    // height: 72.3%;
    width: 440px;
    height: 460px;
    z-index: 99;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url(../assets/images/vipBjA.png);
    // padding:40px 32px;
    // box-shadow: 0px 4px 30px 0px rgba(0,0,0,0.2);
    background-size: 100% 100%;
    color:#fff
}

.r-header {
    background-color: #fff;
    color: #222222;
    position: relative;
    .title {
      height: 88upx;
      line-height: 88upx;
      font-size: 28upx;
      text-align: center;
    }
  }
  .texts{
    box-sizing: border-box;
    width: 60upx;
    height: 60upx;
    text-align: center;
    font-size: 22upx;
    line-height: 60upx;
    color: #999;
    margin: 0 auto;
  }
  .r-header::before, .r-header::after, .r-content::after {
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    content: '';
    background-color: #f2f2f2;
    transform: scaleY(.6);
  }
  .r-header::before {
    top: 0;
  }
  .r-header::after, .r-content::after {
    bottom: 0;
  }
  .r-header-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 88upx;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666;
    font-size: 26upx;
  }
  .r-header-icon.prev {
    left: 10px;
  }
  .r-header-icon.next {
    right: 10px;
  }
  .r-week {
	padding: 0 11px;
	height: 48px;
	display: flex;
	align-items: center;
	font-size: 13px;
	color: #222;
    .item {
      flex: 1;
      text-align: center;
    }
  }
  .r-content {
    padding: 0 20upx 10upx;
    position: relative;
  }
  .r-content-box {
    display: flex;
    flex-wrap: wrap;
	min-height: 265px;
    .item {
      box-sizing: border-box;
      width: 14.285%;
      padding-bottom: 20upx;
    }
    .text {
      box-sizing: border-box;
      width: 60upx;
      height: 60upx;
      text-align: center;
      font-size: 22upx;
      line-height: 60upx;
      color: #999;
      border: 1upx solid #999;
      border-radius: 50%;
      margin: 0 auto;
    }
    // 选中样式
    .text.active {
      /* color: #4CD964;
      border-color: #4CD964; */
	  color: #FFC600;
	      border-color: #FFC600;
    }
    // 漏签样式
    .text.not {
      color: #fff;
      border-color: #e4e4e4;
      background-color: #e4e4e4;
    }
    // 补全样式
    .text.after {
      color: #eee;
      border-color: #f2f2f2;
    }
  }
  .icon-direction{
    display: flex;
    align-items: center;
    color: #222;
    font-size: 14px;
  }
  .iconImg{
    width: 17px;
    height: 19px;
    margin-top: 3px;
  }
  .week-box {
    white-space: nowrap;
    height: 70upx;
  }
  .r-content-week {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    .item {
      display: inline-block;
      vertical-align: top;
      box-sizing: border-box;
      width: 14.285%;
      padding-bottom: 20upx;
    }
    .text {
      box-sizing: border-box;
      width: 60upx;
      height: 60upx;
      text-align: center;
      font-size: 22upx;
      line-height: 60upx;
      color: #999;
      border: 1upx solid #999;
      border-radius: 50%;
      margin: 0 auto;
    }
    // 选中样式
    .text.active {
      color: #4CD964;
      border-color: #4CD964;
    }
    // 漏签样式
    .text.not {
      color: #fff;
      border-color: #e4e4e4;
      background-color: #e4e4e4;
    }
    // 补全样式
    .text.after {
      color: #eee;
      border-color: #f2f2f2;
    }
  }
  .role {
    padding: 20px 15px;
    .role-title {
      margin-bottom: 5px;
      font-weight: bold;
    }
    .role-content {
      font-size: 13px;
      color: #333333;
    }
  }
  .r-contentA{
    border-radius: 8px;
    overflow: hidden;
    box-shadow: rgba(225,225,225,0.7) 0  10px 20px 0;
    margin: 15px 15px 0 15px;
  }
  .closeImg{
    width: 40px;
    height: 70px;
    position: absolute;
    top: 460px;
    left: 200px;
    cursor: pointer;
    // width: 30px;
    // height: 30px;
    // position: absolute;
    // top: -43px;
    // left: 670px;

  }
  .textI1{
    width: 321px;
    height:48px;
    background: url(../assets/images/tkbj3.png);
    text-align:center;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #222222;
    line-height: 48px;
    box-shadow: 0px 10px 15px 0px rgba(254,96,84,0.3);
    border-radius: 18px;
  }
  .red{
    color: #FD3333;
  }
  .qdHalf{
    text-align:left;
  }
  .qdindex{
    line-height: 30px;
    text-align: center;
    margin: 5px 2px;
    width: 30px;
    height: 30px;
    background: #FE9A92;
    border-radius: 50%;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
  }
   .yqdText{
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
  }
  .wqdText{
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
  }
  .heng{
    height: 4px;
    background: #FFB0B0;
    opacity: 0.2;
    width: 95px;
  }
  .tkgouImg{
    width:30px;
    height:30px;
    margin: 5px 2px;
  }
  .qdtc{
    opacity: 0.5;
  }
  .qdIcon{
     width:416px;
     height:73px;
     background-image:url(../assets/images/tkbj4.png);
    padding:37px 57px 27px 66px;
    justify-content: space-between;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #222222;
   // background: linear-gradient(0deg, #FFE8E8 0%, #FFFFFF 100%);
    border-radius: 0px 0px 16px 16px;
  }
  .tkbj4Img{
    width:62px;
    height:73px;
    box-shadow: 0px 4px 16px 0px rgba(183,31,31,0.25);
  }
  .yqdSign{
    width: 110px;
    height: 46px;
    background: #E2DEDE;
    border-radius: 23px;
    font-size: 21px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height:46px
  }
  .wqdSign{
    
    background: url(../assets/images/tkqd.png);
    width:150px;
    height:40px;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  font-family: Source Han Serif CN;
  font-weight: bold;
  color: #C11D00;
    cursor: pointer;
  }
  .qdColor{
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
  }
  .tk2Bj{
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #222222;
    line-height:48px;
    height:48px;
  }
  .tk1Img{
    background: url(../assets/images/tkbj1.png);
    width:531px;
    box-shadow: 0px 10px 15px 0px rgba(254,96,84,0.3);
    border-radius: 18px;
  }
  .tk2Img{
    background: url(../assets/images/tkbj2.png);
    box-shadow: 0px 10px 15px 0px rgba(254,96,84,0.3);
    border-radius: 18px;
    width:411px;
  }
  .tkNums{
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #fff;
    line-height: 20px;
    margin-top: 12px;
    display: flex;
    justify-content: center;
    margin-left: 10px;
  }
  .tkFoot{
    background: url(../assets/images/tkqd.png);
    width:150px;
    height:40px;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  font-family: Source Han Serif CN;
  font-weight: bold;
  color: #C11D00;
  cursor: pointer;
  }
  /deep/.el-message-box__wrapper{
     z-index:99999
  }
  .qdsText{
    font-size: 24px;
    font-family: SourceHanSerifCN-Bold;
    font-weight: bold;
    color: #FFEDD7;
    margin-top: 28px;
  }
  .vipBj{
    background: url(../assets/images/vipBj.png);
    width: 74px;
    height: 74px;
     line-height: 47px;
    align-items: center;
    display: flex;
    font-size: 36px;
    font-family: SourceHanSerifCN-Bold;
    justify-content: center;
    margin-left:5px;
  }
  .xinChun{
    font-size: 24px;
  font-family: Source Han Serif CN;
  font-weight: bold;
  color: #FFEDD7;
  text-shadow: 4px 2px 2px #B30001;
  margin-top:127px;
  margin-left:14px;
  }
  .lxQdText{
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    
  }
  .leftNus{
    width: 390px;
    text-align: left;
  }

  .ewmHalf{
    position: relative;
    width: 135px;
    height: 135px;
    background: linear-gradient(-45deg, #0052F0, #0078FF);
    border-radius: 10px;
    margin: 0 auto;
    margin-bottom: 18px;
  }
  .ewmIcon{
    width: 120px;
    height: 120px;
    position: absolute;
    left: 7px;
    top: 8px;
    border-radius: 10px;
  }
  .hyTop{
    position: absolute;
    top: 28px;
    left: 35px;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    text-align: left;
  }
  .hyFoot{
    position: absolute;
    bottom: 40px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    left: 93px;
  }
</style>