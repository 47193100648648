<template>
    <div >
      <common-header></common-header>   
      
      <div class="background">
        <img :src="imgSrc" width="100%" height="100%" alt/>
        <div class="content">
            <div class="leftCon">
                <div class="leftBj">
                     <div class="flex alignCenter biaoqq">
                        <img style="width:46px;height:46px;margin-right: 8px;border-radius: 50%;" src="../assets/images/logobqq1.png" />
                        <span>标圈圈</span>
                    </div>
                    <div style="margin:10px 0 0 20px">
                        <div class="flex alignCenter ltext">
                        <div class="cicle"></div>
                        <div>一站式标讯服务平台</div>
                        </div>
                        <div class="flex alignCenter ltext">
                            <div class="cicle"></div>
                            <div>精准查询招标采购信息</div>
                        </div>
                        <div class="flex alignCenter ltext">
                            <div class="cicle"></div>
                            <div>全流程招标信息服务</div>
                        </div>
                        <div class="flex alignCenter ltext">
                            <div class="cicle"></div>
                            <div>全网招采情报分析</div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="rightCon">
                <div class="rightBj">
                    <div>
                            <div class="register">忘记密码</div>
                                <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                                    <el-form-item label="手机号" prop="user">
                                        <el-input placeholder="请输入手机号" v-model.number="ruleForm.user" maxlength="11"></el-input>
                                    </el-form-item>
                                    <el-form-item prop="checkCode" label="验证码">
                                        <el-input
                                            class="check-code-box"
                                            size="small" maxlength="6"
                                            v-model="ruleForm.checkCode"
                                            placeholder="请输入验证码">
                                            <el-button :disabled="codeCd"  size="small" slot="append" @click="handleCaptcha('ruleForm')">
                                                获取验证码
                                               <span v-if="codeCd">({{long}})</span>
                                            </el-button>
                                        </el-input>
                                    </el-form-item>
                               
                                   <el-form-item label="新密码" prop="pass">
                                        <el-input type="password" placeholder="请输入新密码" v-model="ruleForm.pass" 
                                        auto-complete="new-password" autocomplete="off"></el-input>
                                    </el-form-item>

                                    <el-form-item label="确认密码" prop="checkPass">
                                        <el-input type="password" placeholder="请再次输入密码" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item class="btnsA">
                                        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                                    </el-form-item>
                                    
                                    <div class="flex jutSpace btnw">
                                         <div >
                                            <router-link to="/login">返回登录</router-link>
                                        </div> 
                                    </div> 

                                </el-form>
                          
                    </div>
                </div>
            </div>

        </div>
    </div>
     
    </div>
</template>
<script>
 import CommonHeader from '@/components/CommonHeader.vue';
 export default { 
    components:{
       CommonHeader
       }, 
        data() {

      let checkAge = (rule, value, callback) => {
         var reg = /^1[3-9]\d{9}$/;
          if (value === "") {
            return callback(new Error("请输入手机号码"));
          } else if (!Number.isInteger(value)) {
            return callback(new Error("手机号码必须是数字"));
          } else if (value.toString().length !== 11) {
            return callback(new Error("手机号码必须是11位数字"));
          } else if (!reg.test(value)) {
            return callback(new Error("请输入有效的手机号码"));
          } else {
            callback();
          }
       };
    let checkCodes=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入验证码'));
        }else{
            callback();
        }
     }
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        }else if (value.length<6) {
          callback(new Error('密码长度最少6位数'));
        } else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };

            return {
                imgSrc: require("../assets/images/login_bj.png"),
                activeName: 'one',

                codeCd: false,
                // CD长度
                long: 60,

                ruleForm: {
                pass: '',
                checkPass: '',
                user: '',
                checkCode: ''
                },
                rules: {
                    pass: [
                        { validator: validatePass, trigger: 'blur' }
                    ],
                    checkPass: [
                        { validator: validatePass2, trigger: 'blur' }
                    ],
                    user: [
                        { validator: checkAge, trigger: 'blur' }
                    ],
                    checkCode: [
                            { validator:checkCodes, trigger: 'blur' }
                    ]
                },


            };
        },
        methods: {
            handleClick(tab, event) {
                console.log(tab, event);
            },

            // 获取手机验证码
            handleCaptcha (form) {
                var that=this
                that.$refs[form].validateField('user', async (valid) => {
                if (!valid) {
                // 获取验证码
                that.$axios.get('/webInterface/getCode', {  //params参数必写 , 如果没有参数传{}也可以
                        params: {  
                            phoneNo:that.ruleForm.user,
                            flag:2,
                        }
                    })
                    .then(function (res) {
                     console.log(res);
                     if(res.data.resultcode==1){
                        that.$message.success(res.data.resultmessage)
                        that.codeCd = true
                        const timer = setInterval(() => {
                            that.long--
                            if (that.long <= 0) {
                                that.long = 60
                                that.codeCd = false
                            clearInterval(timer)
                            }
                        }, 1000)
                     }else{
                        that.$message.error(res.data.resultmessage)
                     }  
                    })
                    .catch(function (err) {
                        console.log(err);
                        that.$message.error('发送失败')
                    })

                
                } else {
                console.log('error submit!!')
                return false
                }
            })
            },
            submitForm(formName) {
                var that=this
                that.$refs[formName].validate((valid) => {
                    console.log(that.ruleForm.checkCode)
                if (valid) {
                    that.$axios.post('/webInterface/changeMyPassword?phoneNo='+that.ruleForm.user+'&checkCode='+that.ruleForm.checkCode+'&password='+that.ruleForm.pass
                    )
                    .then(function (res) {
                     console.log(res);
                     if(res.data.resultcode==1){
                        that.$message.success('密码修改成功')
                        setTimeout(()=>{  //设置延迟执行
                            that.$router.push({path: '/login'});
                       },1500);
                     }else{
                        that.$message.error(res.data.resultmessage)
                     }
                    })
                    .catch(function (err) {
                        console.log(err);
                        that.$message.error('密码修改失败，请重试')
                    })

                } else {
                    console.log('error submit!!');
                    return false;
                }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },

        }
    }
</script>
<style scoped lang="less">
.background {
    width: 100%;
    height: 100%;
    z-index: -1;
    position: fixed;
    top: 74px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    min-height: 980px;
    overflow: hidden;
    }
 .content{
    display: flex;
    position: absolute;
    left: 60%;
    top: 55%;
    transform: translate(-50%,-50%);
    width: 90%;
    height: 95%;
 }
 .leftCon{
    background: url('../assets/images/login-left.png');
    width: 35%;
    height: 60%;
    background-size: 100% 100%;
 }
 .rightCon{
    background: url('../assets/images/login_right.png');
    width: 37.5%;
    height: 60%;
    background-size: 100% 100%;
    position: relative;
 }
 .cicle{
    width: 8px;
    height: 8px;
    background: #F5F7FA;
    opacity: 0.4;
    margin-right: 20px;
 }
 .ltext{
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 44px;
 }
 .leftBj{
    margin: 40px;
    .biaoqq{
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 44px;
    }
 }
 .rightBj{
    position: absolute;
    width: 404px;
    top: 50%;
    left: 48%;
    transform: translate(-50%, -50%);
 }
 /deep/.el-tabs__item{
    font-size: 24px;
    line-height: 20px;
 }
 /deep/.el-tabs__header{
    margin: 0 0 40px;
 }
 /deep/.el-form-item{
    margin-bottom: 20px;
 }
 /deep/ .el-form-item__label{
          width: 85px !important;
          font-size: 17px;
        }
 /deep/ .el-form-item__content{
            margin-left: 90px !important;
            line-height: 48px !important;
        }
/deep/.el-input__inner{
    font-size: 17px;
    height: 45px;
    line-height: 45px;
}
    .btn{
        text-align: center;
       padding: 0 10px;
    }
    .btns{
        width: 100%;
        height: 55px;
        background: #0078FF;
        border-radius: 30px;
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 55px;
        margin-top: 50px;
        cursor: pointer;
    }
    .btnw{
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        padding: 0 18px;
        margin-top: 15px;
        color: #B9C3D1;
        cursor: pointer;
    }
    a{
        color: #B9C3D1;
    }

    .register{
        color:#409EFF;
        font-size:24px;
        margin-bottom: 30px;
    }
    /deep/.el-button{
        width:100%;
    }
    /deep/.btnsA{
      .el-button{
        width:100%;
        font-size: 16px;
        letter-spacing: 3px;
     }
    }
</style>